import { Chart as ChartJs } from 'chart.js'
import { useEffect, useState } from 'react'

import { DoughnutChart, Tabs } from '@/components'
import { useAnalytics } from '@/hooks'
import { api } from '@/services'

type SalesTrackingProps = {
    className?: string
    onChange: any
    processing: boolean
}

const SalesTracking = ({ className, processing, ...props }: SalesTrackingProps) => {
    const { trackEvent } = useAnalytics()

    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const [data, setData] = useState({
        current: 0,
        previous: 0,
        percentage: 0
    })

    type PeriodOption = 'quarter' | 'month' | 'year'
    const [period, setPeriod] = useState<PeriodOption>('quarter')

    const getPreviousPeriod = (short = false) => {
        if (period === 'quarter') {
            const year = `${new Date().subtract('quarter', 1).format({ day: null, month: null })}`
            const quarter = `Q${Math.ceil((new Date().subtract('quarter', 1).getMonth() + 1) / 3)}`
            const formattedDate = `${quarter} ${new Date().subtract('quarter', 1).format({ day: null, month: null, year: 'numeric' })}`
            return short ? `${quarter} ${year}` : formattedDate
        }
        if (period === 'month') {
            return new Date().subtract('month', 1).format({ day: null, month: 'long', year: 'numeric' })
        }
        return new Date().subtract('year', 1).format({ day: null, month: null, year: 'numeric' })
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const [prevData, curData] = await api.dashboard.warrantySales({
                period,
                start_date: new Date().startOf(period).subtract(period, 1)
                    .toISODate(),
                end_date: new Date().toISODate()
            }, { signal: controller.signal })
            const previous = prevData.total_sum
            const current = curData.total_sum
            setData({
                current,
                previous,
                percentage: previous ? current / previous * 100 : 0
            })
        } finally {
            props.onChange('salesTracking')
        }
    }

    const handleTabChange = (value: PeriodOption) => {
        setPeriod(value)
        trackEvent(`click_contractors_dashboard_${value}_toggle`, 'User Interaction', `Tab ${value}`)
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => {
        fetchData()
    }, [period])

    useEffect(() =>
        () => {
            abortController?.abort('canceled')
        }, [abortController])

    return <div className={`h-full flex flex-col justify-between ${className || ''}`}>
        <div>
            <h3 className="text-lg">Warranty Sales Tracking</h3>
            <h4 className="text-gray-500">Q{(Math.floor((new Date().getMonth() / 3) + 1))} {new Date().format({ day: null, month: 'long' })}</h4>
        </div>
        <div className="relative mx-auto w-full h-fit">
            <div className="w-full relative mx-auto flex justify-center">
                <DoughnutChart
                    datasets={[{
                        data: [Math.min(data.percentage, 100), 100 - Math.min(data.percentage, 100)],
                        backgroundColor: ['#007a96', '#edebe7'],
                        hoverBackgroundColor: ['#00647a', '#edebe7'],
                        borderWidth: 0,
                        borderColor: 'transparent',
                        radius: 128,
                        borderRadius: 60,
                        cutout: '80%',
                        circumference: 180,
                        rotation: -90
                    }]}
                    plugins={[{
                        beforeDraw(chart: ChartJs) {
                            const { ctx, chartArea: { width, height } } = chart
                            ctx.save()
                            const centerX = width / 2
                            const centerY = height / 2
                            const radius = 112
                            ctx.lineCap = 'round'
                            ctx.beginPath()
                            ctx.arc(centerX, centerY + 60, radius, Math.PI + 0.10, - 0.10, false)
                            ctx.lineWidth = 25.6
                            ctx.strokeStyle = '#edebe7'
                            ctx.stroke()
                        }
                    }]}
                    customTooltip={index => index === 0 && <div className="px-3 py-2 font-body bg-white shadow-md rounded-md">
                        <h6 className="text-xs text-gray-700 font-semibold">Compared to {getPreviousPeriod()}</h6>
                        <h6 className="text-gray-500 text-sm">
                            <span className="capitalize">{period} </span>
                            To Date: {data.current.money()}
                        </h6>
                        <h6 className="text-gray-500 text-sm">
                            {getPreviousPeriod(true)}: {data.previous.money()}
                        </h6>
                    </div>}
                    options={{
                        aspectRatio: 2,
                        responsive: true,
                        maintainAspectRatio: false
                    }}
                />
            </div>
            <div className="absolute font-display top-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center justify-center">
                <span className="text-xs text-gray-500">{data.current.money()}</span>
                <span className="text-2xl">{Math.round(data.percentage)}%</span>
            </div>
        </div>
        <div className="flex flex-col justify-center gap-8">
            <div className="font-body">
                <div className="flex justify-center border-t pt-3">
                    <Tabs
                        value={period}
                        onChange={id => handleTabChange(id as PeriodOption)}
                        design="buttons"
                        tabs={[
                            {
                                id: 'quarter',
                                title: 'By Quarter'
                            },
                            {
                                id: 'month',
                                title: 'By Month'
                            },
                            {
                                id: 'year',
                                title: 'By Year'
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default SalesTracking
