import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Logo, IconMail, IconCheckSteps, IconHamburgerMenu } from '@/components'

type ComponentProps = {
    steps: any
    current: number
}

const StepsInfo = ({ steps, current }: ComponentProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="md:w-1/3 z-10">
            <button onClick={() => setIsOpen(!isOpen)} className="z-10 fixed block sm:hidden mobile-toggle-button">
                <IconHamburgerMenu
                    className="fill-blue-700"
                    size="lg"
                />
            </button>
            <div className={`bg-blue-900 steps-navigation fixed md:relative inline-block h-full font-body ${isOpen ? 'is-open pr-8 ml-0' : 'is-closed'}`}>
                <Link to="/dashboard">
                    <Logo type="primaryWhite" className="w-36 mt-12 ml-6 logo ml-12"/>
                </Link>
                <div className="signup-steps mt-28 ml-12 relative left-4">
                    {steps.map((item: any) => {
                        if (!item.title) return null
                        return <div className={`step h-24 flex last:!border-transparent ${item.id < current ? 'completed' : ''}`} key={item.id}>
                            <div
                                className={
                                    `circle flex justify-center items-center w-8 h-8 relative cursor-pointer 
                                    ${item.id < current ? 'completed bg-blue-50 border-2 border-blue-600' : ''} 
                                    ${item.id === current ? 'active bg-primary-50 border-2 border-blue-900' : ''}
                                    ${item.id > current ? 'border-2 border-primary-200 bg-blue-900' : ''}`
                                }
                            >
                                {item.id < current && <IconCheckSteps size="md" className="fill-primary-700" viewBox="4 4 24 24"/>}
                                {item.id >= current &&
                                    <div className="circle-inner w-2 h-2 rounded-full bg-primary-200"></div>}
                            </div>
                            <div className="step-intro flex flex-col w-full">
                                <span className="step-header font-light cursor-pointer text-primary-50">{item.title}</span>
                                <span className="step-subheader font-hairline cursor-pointer text-primary-200 max-w-xs">{item.subTitle}</span>
                            </div>
                        </div>
                    })}
                </div>
                <div className="footer-email flex justify-center absolute left-2/4 bottom-10 -translate-x-1/2">
                    <IconMail className="stroke-blue-200 mr-2 w-4"/>
                    <a href="mailto:help@solarinsure.com" className="text-sm font-light">help@solarinsure.com</a>
                </div>
            </div>
        </div>
    )
}

export default StepsInfo
