import { ReactNode } from 'react'

import Model from './Model'
import { NotificationType } from '@/models/NotificationType'
import { api, Options, Params } from '@/services'


export class Notification extends Model {
    static endpoint = 'api/v1/notifications'

    type: NotificationType

    constructor(props: any) {
        super(props)
        this.type = NotificationType.find(props.key)
    }

    get message(): ReactNode {
        return this.type.message(this.data)
    }

    static async index<T extends typeof Model>(
        this: T,
        params: any = {},
        options: any = {}
    ): Promise<{ data: InstanceType<T>[], [key: string]: any }> {
        const res = await api.http.get(this.endpoint, params, options)
        res.data = res.data?.map((item: any) => new this(item)) || []
        for (const key in res.data) {
            const item = res.data[key]
            if (item.data['jobs-batch']?.percentage < 100) {
                const { data } = await api.jobs.progress(item.data['jobs-batch'].id)
                res.data[key].data['jobs-batch'] = data
            }
        }
        res.data.sort((a: Notification, b: Notification) => {
            if (a.data['jobs-batch']?.percentage < 100) {
                return -1
            }
            if (b.data['jobs-batch']?.percentage < 100) {
                return 1
            }
            return 0
        })
        return res
    }

    markAsRead() {
        return this.show(this.id, { 'mark-as-read': true })
    }

    static destroy(params?: Params, options?: Options) {
        return api.http.delete(`${this.endpoint}`, params, options)
    }

    static markAllAsRead() {
        return this.index({ 'mark-as-read': true })
    }
}
