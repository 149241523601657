import { ChartOptions } from 'chart.js'

import Chart from './Chart'

type BarChartProps = {
    datasets: any
    labels: string[]
    plugins?: any
    options?: ChartOptions
}

const BarChart = ({ datasets, labels, plugins = [], options = {} }: BarChartProps) => <Chart
    type="bar"
    data={{
        datasets,
        labels
    }}
    plugins={plugins}
    options={{
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                grid: { color: '#f5f4f1' },
                border: { display: false }
            },
            x: {
                grid: { display: false },
                border: { display: false }
            }
        },
        ...options
    }}
/>

export default BarChart
