import { combineReducers, configureStore } from '@reduxjs/toolkit'

import appReducer from './appSlice'
import authReducer from './authSlice'
import notificationsReducer from './notificationsSlice'
import toastNotificationsReducer from './toastNotificationsSlice'

export const solarReducers = combineReducers({
    app: appReducer,
    auth: authReducer,
    notifications: notificationsReducer,
    toastNotifications: toastNotificationsReducer
})

const store = configureStore({
    reducer: solarReducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    devTools: import.meta.env.DEV
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

export * from './appSlice'
export * from './authSlice'
export * from './notificationsSlice'
export * from './toastNotificationsSlice'
