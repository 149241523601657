import { Table } from '@/components'
import { Warranty } from '@/models'

type WarrantyUpdateDetailsProps = {
    oldValues: any
    newValues: any
}

export const WarrantyUpdateDetails = ({ oldValues, newValues }: WarrantyUpdateDetailsProps) => {
    const oldPrice = oldValues.price || oldValues.other_changes?.price || 0
    const newPrice = newValues.price || newValues.other_changes?.price || 0

    const getRows = () => {
        if (oldValues.batteries?.length || newValues.batteries?.length) {
            oldValues.num_batteries = oldValues.batteries?.length
            newValues.num_batteries = newValues.batteries?.length
        }
        const rows = Object.keys(newValues).reduce((acc: any[], key) => {
            if (['price', 'batteries'].includes(key)) return acc
            const field = Warranty.getField(key)
            if (field.isDefault) return acc
            const oldValue = field.format(oldValues[key])
            const newValue = field.format(newValues[key])
            if (oldValue === newValue) return acc
            return [{
                id: key,
                attribute: field.title,
                old_value: oldValue,
                new_value: newValue
            }, ...acc]
        }, [])

        for (let i = 0; i < Math.max(oldValues.batteries?.length, newValues.batteries?.length); i = i + 1) {
            const oldBattery = oldValues.batteries[i]
            const newBattery = newValues.batteries[i]
            const oldMake = Warranty.getField('battery_make').format(oldBattery?.product?.model_name)
            const newMake = Warranty.getField('battery_make').format(newBattery?.product?.model_name)
            const oldSizeKw = Warranty.getField('battery_size_kw').format(oldBattery?.size_kw)
            const newSizeKw = Warranty.getField('battery_size_kw').format(newBattery?.size_kw)
            if (oldMake === newMake && oldSizeKw === newSizeKw) {
                continue
            }
            rows.push(...[{
                id: `battery_make_model_${i}`,
                attribute: `Battery ${i + 1}: Make & Model`,
                old_value: oldMake,
                new_value: newMake
            }, {
                id: `battery_size_kw_${i}`,
                attribute: `Battery ${i + 1}: Capacity (kWh)`,
                old_value: oldSizeKw,
                new_value: newSizeKw
            }])
        }

        return rows
    }

    return <>
        <Table
            sortable={false}
            pagination={false}
            searchable={false}
            useQueryParams={false}
            className="table-secondary"
            data-test="warranty-update-details-table"
            columns={[
                { field: 'attribute', title: 'Attribute', cellClassName: 'font-semibold' },
                { field: 'old_value', title: 'Old Value' },
                { field: 'new_value', title: 'New Value' }
            ]}
            rows={getRows()}
        />

        <p className="text-sm font-semibold mt-4" data-test="edit-affect-price-text">
            Did the Edit affect the Warranty Price?
            <span className="text-gray-500 ml-1">
                {oldPrice === newPrice ? 'No' : 'Yes'}
            </span>
        </p>

        {oldPrice !== newPrice && <Table
            sortable={false}
            pagination={false}
            searchable={false}
            useQueryParams={false}
            data-test="warranty-update-details-price-table"
            columns={[
                { field: 'old_price', title: 'Old Warranty Price' },
                { field: 'new_price', title: 'New Warranty Price' }
            ]}
            rows={[{
                id: 1,
                old_price: Warranty.getField('price').format(oldPrice),
                new_price: Warranty.getField('price').format(newPrice)
            }]}
        />}
    </>
}
