import classnames from 'classnames'
import { InputHTMLAttributes, ReactNode } from 'react'

export const SWITCH_SIZE_CLASS_MAP = {
    sm: 'switch-sm',
    md: 'switch-md'
}

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: ReactNode
    'input-size'?: 'sm' | 'md'
    'data-test'?: string
}

export const Switch = ({
    label,
    'input-size': size = 'md',
    'data-test': dataTest,
    ...props
}: SwitchProps) => {
    const className = classnames('switch', props.className, SWITCH_SIZE_CLASS_MAP[size], {
        disabled: props.disabled,
        checked: props.checked || props.defaultChecked
    })

    const computedDataTest = dataTest || props.id
    return <label data-test={computedDataTest} className={className}>
        <label>
            <input
                type="checkbox"
                {...props}
                onClick={e => {
                    if (props.onClick) {
                        e.stopPropagation()
                        props.onClick(e)
                    }
                }}
            />
        </label>
        {label}
    </label>
}
