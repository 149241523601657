import { IconHelpCircle, Input, Tooltip } from '@/components'
import IconEye from '@/components/icons/IconEye'

type EnphaseFormProps = {
    form: any
    errors?: any
    warnings?: any
    onChange: any
    disabled?: boolean
    inputClassName?: string
}
const EnphaseForm = ({ form, inputClassName, disabled, errors, warnings, ...props }: EnphaseFormProps) => {
    const getUserIcon = () => {
        if (warnings.user) {
            return <IconHelpCircle className="stroke-warning-600" size="md"/>
        }
        if ((typeof errors === 'string' && errors) || errors.user) {
            return <IconHelpCircle className="stroke-error-600" size="md"/>
        }
        return <Tooltip
            content={<>
                <h4 className="mb-1 font-semibold">
                    Why do I need to enter my email and password to integrate my Enphase systems within Solar Insure?
                </h4>
                <p>
                    Solar Insure collects permission to monitor solar systems at the time of sale and is contractually
                    obligated to not contact homeowners on behalf of our installers. Without credentials, each installer
                    would be required to maintain their own Enphase developer subscription and Solar Insure would be
                    required to contact each homeowner we monitor directly to monitor before we gain access to the
                    system.
                </p>
            </>}
        >
            <IconHelpCircle className="stroke-gray-400"/>
        </Tooltip>
    }

    const getSecretIcon = () => {
        if (warnings.secret) {
            return <IconEye className="stroke-warning-600" size="md"/>
        }
        if ((typeof errors === 'string' && errors) || errors.secret) {
            return <IconEye className="stroke-error-600" size="md"/>
        }
        return null
    }

    return <>
        <Input
            id="enphase-user"
            name="user"
            label="Enphase Username"
            placeholder="Username"
            autoComplete="new-password"
            onChange={props.onChange}
            value={form.user}
            errors={errors.user}
            warnings={warnings.user}
            postIcon={getUserIcon()}
            className={`w-80 ${inputClassName} ${typeof errors === 'string' ? 'has-error' : ''} ${typeof warnings === 'string' ? 'has-warning' : ''}`}
            disabled={disabled}
        />
        <Input
            id="enphase-password"
            name="secret"
            type="password"
            label="Enphase Password"
            placeholder="Password"
            autoComplete="new-password"
            onChange={props.onChange}
            value={form.secret}
            errors={errors.secret}
            warnings={warnings.secret}
            postIcon={getSecretIcon()}
            className={`w-70 ${inputClassName} ${typeof errors === 'string' ? 'has-error' : ''} ${typeof warnings === 'string' ? 'has-warning' : ''}`}
            disabled={disabled}
        />
    </>
}

export default EnphaseForm
