import { useEffect, useState } from 'react'

import {
    Input,
    Checkbox,
    Select,
    Tooltip,
    IconHelpCircle,
    FormErrorsType,
    FormDataType,
    FormChangeEventHandler, FormChangeEvent
} from '@/components'
import { Homeowner } from '@/models'
import { api } from '@/services'
import { AddressType, StatesListType } from '@/types'

interface CommonAddressType extends FormDataType {
    duplicate_override?: boolean | 1 | 0
    allow_invalid?: boolean | 1 | 0
}

export interface SingleAddressFormDataType extends CommonAddressType {
    address: string
}

export interface AddressFormDataType extends CommonAddressType {
    street_address: string
    city: string
    state: string
    zip: string
}

type AddressFormProps = {
    form: SingleAddressFormDataType | AddressFormDataType
    inline?: boolean
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
}
export const AddressForm = ({
    form,
    errors,
    inputClassName,
    inline,
    ...props
}: AddressFormProps) => {
    const [addresses, setAddresses] = useState<AddressType[]>([])
    const [states, setStates] = useState<StatesListType>({})
    const [abortController, setAbortController] = useState<AbortController | null>()

    const isDuplicateAddress = Homeowner.possibleDuplicateErrors.some(item =>
        errors.street_address?.includes(item) || errors.address?.includes(item)) || errors.duplicated_address
    const isUnverifiedAddress = Homeowner.possibleUnverifiedErrors.some(item =>
        errors.street_address?.includes(item) || errors.address?.includes(item))

    const handleChange = async (e: FormChangeEvent) => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        props.onChange(e)
        if (e.target.value) {
            const res = await api.address.autocomplete(e.target.value, { signal: controller.signal })
            setAddresses(res)
        } else {
            setAddresses([])
        }
    }

    const fetchStates = async () => {
        if (inline) return
        const res = await api.address.states()
        setStates(res)
    }

    useEffect(() => {
        fetchStates()
    }, [])

    useEffect(() => {
        if (typeof form.duplicate_override === 'undefined' && isDuplicateAddress) {
            props.onChange({ target: { name: 'duplicate_override', value: '' } })
        }
        if (typeof form.allow_invalid === 'undefined' && isUnverifiedAddress) {
            props.onChange({ target: { name: 'allow_invalid', value: '' } })
        }
    }, [errors])

    const handleSuggestionSelect = (index: number) => {
        const address = addresses[index]
        if (inline) {
            props.onChange({
                target: {
                    name: 'address',
                    value: `
${address.street_address} ${address.secondary_line || ''}
${address.city}, ${address.state} ${address.zip}
`
                }
            })
        } else {
            props.onChange({ target: { name: 'street_address', value: address.street_address } })
            props.onChange({ target: { name: 'city', value: address.city } })
            props.onChange({ target: { name: 'state', value: address.state } })
            props.onChange({ target: { name: 'zip', value: address.zip } })
        }
    }

    if (inline) return <Input
        id="address"
        name="address"
        errors={errors.address || []}
        className={inputClassName}
        label="Address*"
        placeholder="Address"
        onChange={handleChange}
        onSuggestionSelect={handleSuggestionSelect}
        value={(form as SingleAddressFormDataType).address}
        autoComplete="off"
        suggestions={addresses.map(item =>
            `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
    />
    return <>
        <Input
            id="street_address"
            name="street_address"
            errors={errors.street_address || []}
            className={inputClassName}
            label="Street Address Line 1*"
            placeholder="Street Address Line 1"
            onChange={handleChange}
            onSuggestionSelect={handleSuggestionSelect}
            value={(form as AddressFormDataType).street_address}
            autoComplete="off"
            suggestions={addresses.map(item =>
                `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
        />
        <Input
            id="city"
            name="city"
            errors={errors.city || []}
            className={inputClassName}
            label="City*"
            placeholder="City"
            onChange={props.onChange}
            value={(form as AddressFormDataType).city}
        />
        <Select
            id="state"
            name="state"
            errors={errors.state}
            className={inputClassName}
            label="State*"
            options={[{
                value: '',
                title: 'State'
            }, ...Object.keys(states).map(key => ({ value: key, title: states[key] }))]}
            disabled={!Object.keys(states).length}
            value={(form as AddressFormDataType).state}
            onChange={props.onChange}
        />
        <Input
            id="zip"
            name="zip"
            errors={errors.zip}
            className={inputClassName}
            label="Zip Code*"
            placeholder="Zip Code"
            onChange={props.onChange}
            value={(form as AddressFormDataType).zip}
        />
        {typeof form.duplicate_override !== 'undefined' && <Checkbox
            id="allow-duplicate"
            name="duplicate_override"
            label={<span className="flex items-center gap-1">
                Allow Duplicate Address
                <Tooltip content="Use this checkbox only when a duplicate address error signals a possible mistake warranty registration. Sometimes, multiple warranties at the same address are legitimate, like when installing systems on different structures within the same property. If the warranty is valid and not a mistaken duplicate, select this option to mark it as unique.">
                    <IconHelpCircle className="stroke-gray-400"/>
                </Tooltip>
            </span>}
            checked={!!form.duplicate_override}
            onChange={props.onChange}
        />}
        {typeof form.allow_invalid !== 'undefined' && <Checkbox
            id="allow-invalid"
            name="allow_invalid"
            label={<span className="flex items-center gap-1">
                Allow Invalid Address
                <Tooltip content="Use this checkbox only when the address verification system is unable to confirm an entered address, which often happens with new-build houses not yet in the postal database. When registering a warranty for a new construction, double-check the accuracy of the address first. If it’s a confirmed valid address, then proceed by checking this box.">
                    <IconHelpCircle className="stroke-gray-400"/>
                </Tooltip>
            </span>}
            checked={!!form.allow_invalid}
            onChange={props.onChange}
        />}

        {isDuplicateAddress && !form.duplicate_override &&
            <div className="col-span-2">
                <p className="text-error-700 font-semibold">Another warranty already exists at this address.</p>
                <p className="text-error-700">
                    If this is a unique registration, select &apos;Allow Duplicate Address&apos; checkbox.
                </p>
            </div>}
    </>
}
