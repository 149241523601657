import classnames from 'classnames'
import { HTMLAttributes, MouseEventHandler, ReactNode, useMemo } from 'react'

import { Button } from './Button'
import { IconAlertCircle, IconAlertTriangle, IconCertificate, IconCheckCircle, IconClose } from '../icons'

interface AlertProps extends HTMLAttributes<HTMLDivElement> {
    type?: 'custom' | 'info' | 'default' | 'error' | 'warning' | 'purple' | 'success',
    icon?: ReactNode,
    onClose?: MouseEventHandler
}

export const Alert = ({
    type = 'default',
    children,
    className,
    icon,
    ...props
}: AlertProps) => {
    const computedIcon = useMemo(() => {
        if (icon) return icon
        switch (type) {
            case 'warning': {
                return <IconAlertTriangle/>
            }
            case 'success': {
                return <IconCheckCircle/>
            }
            case 'purple': {
                return <IconCertificate/>
            }
            case 'default': {
                return <IconAlertCircle/>
            }
        }
    }, [type, icon])

    return <div className={classnames('alert', type, className)} {...props}>
        {computedIcon && <div className="alert-icon">{computedIcon}</div>}
        {props.onClose && <Button design="btn-link" className="alert-close" onClick={props.onClose}>
            <IconClose size="lg"/>
        </Button>}
        <div className="alert-body">
            {children}
        </div>
    </div>
}
