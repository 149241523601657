import { api, Payload } from '@/services'

export default {
    updatePreferences(id: string | number, payload: Payload) {
        return api.http.put(`api/v1/users/${id}/preferences`, payload)
    },
    updatePassword(id: string | number, payload: Payload) {
        return api.http.put(`api/v1/users/${id}/update-password`, payload)
    },
    toggleVerification(id: string | number) {
        return api.http.put(`api/v1/users/${id}/toggle-verification`)
    },
    async activities(id: string | number) {
        const res = await api.http.get(`api/v1/users/${id}/activities`)
        return res.data
    },
    impersonate(companyId: number, password: string) {
        return api.http.put('api/v1/users/impersonate', { company_id: companyId, password })
    }
}
