import { useMemo, useState } from 'react'
import { Link, useLoaderData, useParams } from 'react-router-dom'

import {
    Button,
    Card, Feature, IconBuilding07, IconCertificate, IconCheck,
    IconDownload,
    IconEdit,
    IconGlobe06, IconHome, IconLockUnlocked, IconMail, IconPhone, IconPin, IconSun,
    IconUpload01,
    IconUsers01, IconUserSquare,
    IconX, Tooltip, UserHeader
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAuth, useToastNotifications } from '@/hooks'
import { AuthUser, User } from '@/models'
import { sessionStorage } from '@/services'

const UsersShow = () => {
    const { affiliateId, companyId, id } = useParams()
    const data = useLoaderData() as User
    const { success } = useToastNotifications()
    const auth = useAuth()

    const [user, setUser] = useState(data)

    const basePath = useMemo(() => {
        if (affiliateId) {
            return `/companies/${affiliateId}/subsidiaries/${companyId}`
        } else if (companyId) {
            return `/companies/${companyId}`
        }
        return ''
    }, [affiliateId, companyId, id])

    const backUrl = useMemo(() => {
        if (affiliateId || companyId) return basePath
        return '/users'
    }, [affiliateId, companyId, id])
    const breadcrumb = useMemo(() => {
        if (affiliateId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={3} to={`/companies/${affiliateId}`}>Company Details</Link>,
                <Link key={4} to={`/companies/${affiliateId}/subsidiaries/${companyId}`}>Subcontractor Details</Link>,
                'User Details'
            ]
        }
        if (companyId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={3} to={`/companies/${companyId}`}>Company Details</Link>,
                'User Details'
            ]
        }
        return [
            <IconUsers01 key={1} className="stroke-gray-500"/>,
            <Link key={2} to="/users">Users</Link>,
            'User Details'
        ]
    }, [companyId, id])

    const fetchUser = async () => {
        const res = await User.show(id as string) as User
        res.activity = await res.activities()
        setUser(res)
    }

    const handleToggleVerificationCLick = async () => {
        await user.toggleVerification()
        fetchUser()
    }

    const handleResetPasswordClick = async () => {
        await AuthUser.forgotPassword({ email: user.email })
        success(`Reset link successfully sent to ${user.email}.`)
    }

    return <AuthLayout
        breadcrumb={breadcrumb}
        heading={<UserHeader
            user={user}
            backUrl={backUrl + sessionStorage.get('preservedQuery.users')}
        />}
    >
        <div className="flex justify-end mb-8 gap-3">
            {auth.user?.isAdminOrStaff && <Tooltip content="Edit User">
                <Button
                    square
                    design="btn-secondary-gray"
                    href={`${basePath}/users/${id}/edit`}
                >
                    <IconEdit/>
                </Button>
            </Tooltip>}
            {auth.user?.isAdminOrStaff && <Tooltip content={user.email_verified_at ? 'Mark As Not Verified' : 'Mark As Verified'} disabled={user.isAdmin}>
                <Button square design="btn-secondary-gray" onClick={handleToggleVerificationCLick} disabled={user.isAdmin}>
                    {user.email_verified_at ? <IconX/> : <IconCheck/>}
                </Button>
            </Tooltip>}
            {auth.user?.isAdminOrStaff && <Tooltip content="Reset Password">
                <Button square design="btn-secondary-gray" onClick={handleResetPasswordClick}>
                    <IconLockUnlocked/>
                </Button>
            </Tooltip>}
            {user.isContractor && auth.user?.isAdminOrStaff && <Tooltip content="Upload Warranties">
                <Button square design="btn-secondary-gray" href={`/warranties/upload?user_id=${id}`}>
                    <IconUpload01/>
                </Button>
            </Tooltip>}
            <Feature name="onboarding">
                <Tooltip content="Download">
                    <Button square design="btn-secondary-gray">
                        <IconDownload/>
                    </Button>
                </Tooltip>
            </Feature>
        </div>

        <div className="grid gap-6 lg:grid-cols-4">
            <Card className="col-span-1">
                <div className="flex justify-between">
                    <div className="w-1/2 flex justify-center items-center w-18 h-18 shadow-md rounded-full font-display text-3xl text-gray-600">
                        {user.name[0]}
                    </div>

                    <div className="text-right w-1/2">
                        <Tooltip content={user.name}>
                            <h2 className="text-lg mb-4 truncate">{user.name}</h2>
                        </Tooltip>

                        <div className="text-primary-600 text-sm mb-4">{user.accountType}</div>
                        <Feature name="onboarding">
                            <div className="text-gray-500 text-sm mb-4">
                                Preferred Features: <div className="flex gap-1 justify-end mt-4">{user.preferredFeaturesBadges}</div>
                            </div>
                        </Feature>
                    </div>
                </div>
            </Card>
            <Card className="col-span-1">
                <h2 className="text-lg mb-4">User Info</h2>
                <div className="text-gray-500 text-sm flex flex-col items-start">
                    <Tooltip content="Email">
                        <div className="flex mb-4">
                            <IconMail className="stroke-gray-500 mr-2"/>
                            {user.email}
                        </div>
                    </Tooltip>

                    <Feature name="onboarding">
                        <Tooltip content="Employee Role/Job Title">
                            <div className="flex mb-4">
                                <IconUserSquare className="stroke-gray-500 mr-2"/>
                                {user.job_title || '-'}
                            </div>
                        </Tooltip>
                    </Feature>

                    <Feature name="onboarding">
                        <Tooltip content="Phone">
                            <div className="flex mb-4">
                                <IconPhone className="stroke-gray-500 mr-2"/>
                                {user.phone || '-'}
                            </div>
                        </Tooltip>
                    </Feature>
                </div>
            </Card>
            <Card className="col-span-1">
                <h2 className="text-lg mb-4">Company Info</h2>
                <div className="text-gray-500 text-sm flex flex-col items-start">
                    <Tooltip content="Company Name">
                        <div className="flex mb-4">
                            <IconBuilding07 className="stroke-gray-500 mr-2"/>
                            {user.company?.name}
                        </div>
                    </Tooltip>

                    <Tooltip content="Salesforce Unique ID">
                        <div className="flex mb-4">
                            <div className="text-gray-500 font-semibold mr-2">ID</div>
                            {user.company?.unique_id || '-'}
                        </div>
                    </Tooltip>

                    <Tooltip content="Company Address">
                        <div className="flex mb-4">
                            <IconPin className="stroke-gray-500 mr-2"/>
                            {user.company?.address || '-'}
                        </div>
                    </Tooltip>
                </div>
            </Card>
            <Card className="col-span-1">
                <h2 className="text-lg mb-4">User Activity</h2>
                <div className="text-gray-500 text-sm flex flex-col items-start">
                    <Tooltip content="Last Online" className="flex mb-4">
                        <IconGlobe06 className="stroke-gray-500 mr-2"/>
                        {user.activity.last_online || '-'}
                    </Tooltip>

                    <Tooltip content="Last Warranty Submission Date" className="flex mb-4">
                        <IconCertificate className="stroke-gray-500 mr-2"/>
                        {user.activity.last_warranty_submitted_at ? new Date(user.activity.last_warranty_submitted_at).format() : '-'}
                    </Tooltip>

                    <Tooltip content="# of Warranties registered this month" className="flex mb-4">
                        <IconHome className="stroke-gray-500 mr-2"/>
                        {user.activity.warranties_registered_this_month?.format() || 0} warranties this month
                    </Tooltip>

                    <Tooltip content="Lifetime Number # of Warranties" className="flex mb-4">
                        <IconSun className="stroke-gray-500 mr-2"/>
                        {user.activity.warranties_registered_lifetime?.format() || 0} lifetime warranties
                    </Tooltip>
                </div>
            </Card>
        </div>
    </AuthLayout>
}

export default UsersShow
