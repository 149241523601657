import { MouseEvent, ReactNode } from 'react'

import { Button, Popup } from '@/components'

export type ConfirmPopupProps = {
    isOpen: boolean
    tooltip?: ReactNode
    title?: ReactNode
    description?: ReactNode
    confirmButtonText?: ReactNode
    cancelButtonText?: ReactNode
    className?: string

    onConfirm: (e: MouseEvent<HTMLButtonElement>) => void
    onCancel?: (e?: MouseEvent<HTMLButtonElement>) => void
    onOpen?: (e: MouseEvent<HTMLButtonElement>) => void
}

export const ConfirmPopup = ({
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    tooltip,
    className,
    title = 'Are you sure?',
    description,
    isOpen,
    ...props
}: ConfirmPopupProps) => {
    const handleCancel = async (e?: MouseEvent<HTMLButtonElement>) => {
        if (props.onCancel) {
            await props.onCancel(e)
        }
    }
    const handleConfirm = (e: MouseEvent<HTMLButtonElement>) => {
        props.onConfirm(e)
    }
    return <Popup open={isOpen} onClose={props.onCancel ? handleCancel : undefined} className={className}>
        <h4 className="text-xl text-gray-900">{title}</h4>
        <div className="text-gray-500 mt-4">{description}</div>

        <div className="flex gap-4 justify-end mt-8">
            {handleCancel && <Button type="button" design="btn-secondary-gray" onClick={handleCancel}>
                {cancelButtonText}
            </Button>}
            <Button type="button" onClick={handleConfirm}>
                {confirmButtonText}
            </Button>
        </div>
    </Popup>
}
