import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
    Button,
    IconDownload,
    Tabs,
    IconEdit05,
    IconMail,
    WarrantyDuplicateAlert,
    WarrantyCancelledBanner,
    WarrantyEditRequestBanner,
    RefreshMonitoringButton,
    WarrantyGoToManufacturerButton,
    WarrantiesCancelOrReinstateButton,
    WarrantyReviewReinstateOrCancelBanner
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyNavigationProps = {
    warranty: Warranty
    onChange: () => void
}

export const WarrantyNavigation = ({
    warranty,
    ...props
}: WarrantyNavigationProps) => {
    const { type, tab = 'warranty' } = useParams()
    const auth = useAuth()
    const [processing, setProcessing] = useState(false)

    const handleSend = async () => {
        setProcessing(true)
        try {
            await warranty.send()
        } finally {
            setProcessing(false)
        }
    }

    return <>
        <div className="flex flex-col lg:flex-row justify-between gap-3 mb-6">
            {!warranty.warrantyStatus.isInactive && !auth.user?.isSoftwareAffiliate &&
                <Tabs
                    className="text-primary-700 text-base font-body"
                    value={tab}
                    tabs={[
                        { to: `/${type}/${warranty.id}/warranty`, id: 'warranty', title: 'Warranty' },
                        { to: `/${type}/${warranty.id}/monitoring`, id: 'monitoring', title: 'Monitoring' },
                        { to: `/${type}/${warranty.id}/system-history`, id: 'system-history', title: 'System History' },
                        { to: `/${type}/${warranty.id}/devices`, id: 'devices', title: 'Device Details' }
                    ]}
                />}
            {!auth.user?.isAffiliate &&
                <div className="flex flex-wrap-reverse gap-5 lg:gap-3 justify-end items-end mt-5 lg:mt-0 ml-auto">
                    {tab === 'warranty' && (auth.user?.isAdminOrStaff || auth.user?.isContractor) && <>
                        <WarrantiesCancelOrReinstateButton
                            selected={[warranty]}
                            onChange={props.onChange}
                        />
                        <Button
                            tooltip="Edit Warranty"
                            data-test="edit-warranty-button"
                            design="btn-secondary-gray"
                            square
                            href={`/${type}/${warranty.id}/warranty/edit`}
                            disabled={warranty.warrantyStatus.isInactive ||
                                (auth.user?.isContractor && warranty.warrantyStatus.isEditRequested)}
                        >
                            <IconEdit05/>
                        </Button>
                        <Button
                            square
                            tooltip="Email Warranty"
                            design="btn-secondary-gray"
                            processing={processing}
                            onClick={handleSend}
                            disabled={warranty.warrantyStatus.isInactive}
                        >
                            <IconMail/>
                        </Button>
                    </>}
                    <Button
                        square
                        tooltip="Download Warranty"
                        design="btn-secondary-gray"
                        onClick={() => warranty.download()}
                        disabled={warranty.warrantyStatus.isInactive}
                    >
                        <IconDownload/>
                    </Button>

                    {tab === 'monitoring' &&
                        (auth.user?.isAdminOrStaff || auth.user?.isContractor) &&
                        warranty.homeowner?.system?.systemStatus?.refreshable &&
                        <RefreshMonitoringButton
                            warranty={warranty}
                            onChange={props.onChange}
                        />}

                    <WarrantyGoToManufacturerButton
                        warranty={warranty}
                    />
                </div>}
        </div>

        {(warranty.warrantyStatus.isReinstatementRequested || warranty.warrantyStatus.isCancellationRequested) &&
            <WarrantyReviewReinstateOrCancelBanner
                warranty={warranty}
                onChange={props.onChange}
            />}

        {warranty.warrantyStatus.isEditRequested &&
            <WarrantyEditRequestBanner
                warranty={warranty}
                onChange={props.onChange}
            />}

        {warranty.warrantyStatus.isDuplicate &&
            <WarrantyDuplicateAlert/>}

        {warranty.warrantyStatus.isCanceled &&
            <WarrantyCancelledBanner warranty={warranty}/>}
    </>
}
