
import AdminDashboard from './AdminDashboard'
import AffiliateDashboard from './AffiliateDashboard'
import { useAppSelector } from '@/hooks'

const Dashboard = () => {
    const auth = useAppSelector(({ auth }) => auth)

    if (auth.user?.isAffiliate) {
        return <AffiliateDashboard/>
    } else if (auth.user?.isAdminOrStaffOrAdvisor || auth.user?.isContractor) {
        return <AdminDashboard/>
    }

    return null
}

export default Dashboard
