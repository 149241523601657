import Homeowner from './Homeowner'
import Device from '@/models/Device'
import DeviceCategory from '@/models/DeviceCategory'
import { GeneracSystem, InverterManufacturer } from '@/models/index'
import Model from '@/models/Model'
import SystemStatus from '@/models/SystemStatus'
import { api, Options, Params } from '@/services'


export default class System extends Model {
    static endpoint = 'api/v1/monitoring-index'

    systemStatus: SystemStatus

    homeowner: Homeowner

    inverterManufacturer: InverterManufacturer

    generacSystems?: GeneracSystem[]

    constructor(props: any = {}) {
        super(props)
        this.systemStatus = SystemStatus.find(props?.status)
        this.systemStatus.system = this
        this.homeowner = props.homeowner
        this.inverterManufacturer = InverterManufacturer.find(props.source || props.manufacturer)

        if (this.inverterManufacturer.isGenerac) {
            this.generacSystems = props.summary?.systems?.map((item: any, index: number) =>
                new GeneracSystem({
                    system: this,
                    summary: item,
                    devices: this.inventory?.systems?.[index].devices || {}
                })) || []
        }
    }

    static report() {
        return api.monitoring.report()
    }

    static summary(params: Params = {}, options: Options = {}) {
        return api.monitoring.summary(params, options)
    }

    get manufacturerUrl() {
        return this.inverterManufacturer.getUrl(this.homeowner?.system_id)
    }

    get deviceCategories(): DeviceCategory[] {
        if (this.inverterManufacturer.isGenerac) {
            return this.generacSystems?.reduce((acc: DeviceCategory[], item) => {
                item.deviceCategories.forEach(item => {
                    const index = acc.findIndex(({ type }) => type === item.type)
                    if (index === -1) {
                        acc.push(new DeviceCategory(item))
                    } else {
                        acc[index].devices = [...acc[index].devices, ...item.devices]
                    }
                })
                return acc
            }, []) || []
        }
        const devices = this.inventory?.devices || {}
        return Object.keys(devices).map(key => new DeviceCategory({
            ...devices[key],
            type: key,
            provider: this.source,
            devices: devices[key],
            warranty: this.homeowner.warranty,
            system: this
        }))
    }

    get allDevices(): Device[] {
        return this.deviceCategories.reduce((acc: Device[], item) => [...acc, ...item.devices], [])
    }

    get normalDevices() {
        return this.allDevices.filter(item => item.isNormal)
    }

    get disabledDevices() {
        return this.allDevices.filter(item => item.isDisabled)
    }

    get erroredDevices() {
        return this.allDevices.filter(item => item.isErrored)
    }

    static export(params: any) {
        return api.monitoring.export({
            ids: params.ids,
            select_all: params.select_all,
            excluded_ids: params.excluded_ids,
            company_id: params.company_id,
            format: params.format,
            manufacturers: params.manufacturers,
            search: params.search,
            statuses: params.statuses,
            type: params.type
        })
    }
}
