enum SystemStatusEnum {
    // Tier 1
    POWER = 'power',
    IMPACT_ALERT_LEVEL_9 = 'impact_alert_level_9',
    IMPACT_ALERT_LEVEL_8 = 'impact_alert_level_8',
    IMPACT_ALERT_LEVEL_7 = 'impact_alert_level_7',
    METER_ISSUE = 'meter_issue',
    STORAGE_IDLE = 'storage_idle',
    GATEWAY_CRITICAL = 'gateway_critical',
    NEVER_CONNECTED = 'never_connected',
    COMPONENT_ERROR = 'component_error',

    // Tier 2
    IMPACT_ALERT_LEVEL_6 = 'impact_alert_level_6',
    IMPACT_ALERT_LEVEL_5 = 'impact_alert_level_5',
    IMPACT_ALERT_LEVEL_4 = 'impact_alert_level_4',
    MICRO = 'micro',
    ENCHARGE_ISSUE = 'encharge_issue',
    SYSTEM = 'system',
    ENPOWER_ISSUE = 'enpower_issue',
    GATEWAY = 'gateway',
    SYSTEM_DISCONNECTED = 'system_disconnected',

    // Tier 3
    IMPACT_ALERT_LEVEL_3 = 'impact_alert_level_3',
    IMPACT_ALERT_LEVEL_2 = 'impact_alert_level_2',
    IMPACT_ALERT_LEVEL_1 = 'impact_alert_level_1',
    BATTERY = 'battery',
    ENCHARGE = 'encharge',
    METER = 'meter',
    ENPOWER = 'enpower',
    ACTIVATION = 'activation',
    UNKNOWN_STATUS = 'unknown_status',

    // Normal
    NORMAL = 'normal',

    // Unmonitored
    PENDING = 'pending',
    DELAYED = 'delayed',
    SYSTEM_NOT_FOUND = 'system_not_found',

    // Excluded
    AUTH_ERROR = 'auth_error',
    UNKNOWN_MANUFACTURER = 'unknown_manufacturer',
    INTEGRATION_UPDATE_REQUIRED = 'integration_update_required',
    MISSING_INTEGRATION = 'missing_integration'
}

export default SystemStatusEnum
