import http, { Payload } from '../http'

export default {
    signIn(payload: Payload) {
        return http.post('api/v1/auth/login', payload)
    },
    signOut() {
        return http.get('api/v1/auth/logout')
    },
    forgotPassword(payload: Payload) {
        return http.post('api/v1/auth/forget-password', payload)
    },
    resetPassword(payload: Payload) {
        return http.post('api/v1/auth/reset-password', payload)
    },
    updatePreferences(payload: Payload) {
        return http.put('api/v1/auth/user/preferences', payload)
    },
    updatePassword(payload: Payload) {
        return http.post('api/v1/auth/update-password', payload)
    },
    user() {
        return http.post('api/v1/auth/user')
    }
}
