import { ChartOptions } from 'chart.js'

import Chart from './Chart'

type LineChartProps = {
    datasets: any
    labels: string[]
    plugins?: any
    options?: ChartOptions
}

const LineChart = ({ datasets, labels, plugins = [], options = {} }: LineChartProps) =>
    <Chart
        type="line"
        data={{
            datasets,
            labels
        }}
        options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                y: {
                    beginAtZero: true,
                    grid: { color: '#f5f4f1' },
                    border: { display: false }
                },
                x: {
                    grid: { display: false },
                    border: { display: false }
                }
            },
            elements: {
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                tooltip: {
                    usePointStyle: true,
                    padding: 12,
                    backgroundColor: '#181717',
                    bodySpacing: 8,
                    boxPadding: 8,
                    boxWidth: 8,
                    boxHeight: 8,
                    callbacks: {
                        labelPointStyle(): any {
                            return {
                                pointStyle: 'circle',
                                rotation: 0
                            }
                        },
                        labelColor(tooltipItem): any {
                            return {
                                borderColor: tooltipItem.dataset.borderColor,
                                backgroundColor: tooltipItem.dataset.borderColor
                            }
                        },
                        label(tooltipItem): string | string[] | void {
                            if (typeof tooltipItem.raw === 'number') {
                                return tooltipItem.raw.money()
                            }
                            return (0).money()
                        }
                    }
                }
            },
            ...options
        }}
        plugins={plugins}
    />

export default LineChart
