import { useState } from 'react'

import { Button, Input, Logo } from '@/components'

type ComponentProps = {
    onSubmit: any
}

const StepTwo = ({ ...props }: ComponentProps) => {
    const [errors, setErrors] = useState<any>({})
    const [form, setForm] = useState<any>({
        company: '',
        email: '',
        name: '',
        employee_role: '',
        phone_number: ''
    })

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!form.email) {
            setErrors({ email: ['This field is required'] })
        } else {
            props.onSubmit(e)
        }
    }

    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    return <div className="h-auto">
        <Logo type="small" className="mx-auto small-logo w-12 h-12 object-contain mb-8"/>
        <h1 className="text-3xl text-center text-header font-medium">
            Confirm Your Details
        </h1>
        <h3 className="text-gray-600 text-center signup-subheader text-gray-700 font-light">
            Please provide your name and email and verify <br/> that the correct company name is populated.
        </h3>
        <form onSubmit={handleSubmit}>
            <div>
                <Input
                    id="company"
                    name="company"
                    value={form.company}
                    errors={errors.company}
                    label="Company*"
                    className="mb-4"
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    id="email"
                    name="email"
                    value={form.email}
                    errors={errors.email}
                    label="Email*"
                    type="email"
                    className="mb-4"
                    onChange={handleChange}
                    placeholder="placeholder@company.com"
                />
            </div>
            <div>
                <Input
                    id="name"
                    name="name"
                    value={form.name}
                    errors={errors.name}
                    label="Name*"
                    className="mb-4"
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    id="employeeRole"
                    name="employee_role"
                    value={form.employee_role}
                    errors={errors.employee_role}
                    label="Employee Role/Job Title*"
                    className="mb-4"
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    id="phoneNumber"
                    name="phone_number"
                    value={form.phone_number}
                    errors={errors.phone_number}
                    label="Phone Number"
                    type="tel"
                    className="mb-6"
                    onChange={handleChange}
                />
            </div>
            <Button className="w-full">
                Continue
            </Button>
        </form>
    </div>
}

export default StepTwo
