import { Card, PageHeader } from '@/components'
import { AuthLayout } from '@/containers'

const ClaimsCreate = () =>
    <AuthLayout heading={<PageHeader title="File a Claim"/>}>
        <Card className="!p-0 w-full">
            <iframe className="h-[calc(100vh-220px)]" title="Form" src="/claims-form.html" height="100%" width="100%"></iframe>
        </Card>
    </AuthLayout>

export default ClaimsCreate
