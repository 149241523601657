import { useMemo, useState } from 'react'

import {
    ActionReasonForm,
    Button, ButtonSize, Form, FormChangeEvent,
    FormErrorsType,
    IconFlipBackward, IconX,
    IconXCircle,
    Popup,
    WarrantyIndexPreview, WarrantyReviewCancelOrReinstateRequestButton
} from '@/components'
import {
    WarrantyByContractorsIndexData,
    WarrantyIndexData
} from '@/containers'
import { WarrantyStatusEnum } from '@/enums'
import { useAppDispatch, useAuth, useQuery, useToastNotifications } from '@/hooks'
import { Warranty, WarrantyStatus } from '@/models'
import { fetchNotifications, showNotifications } from '@/store'
import { JobsBatchType } from '@/types'

type WarrantiesCancelOrReinstateButtonProps = {
    selected: Warranty[] | 'all'
    excluded?: Warranty[]
    data?: WarrantyIndexData | WarrantyByContractorsIndexData
    quantity?: number
    size?: ButtonSize

    onChange: () => void
    onPingJob?: (jobData: JobsBatchType) => void
}

const wordings = {
    cancel: {
        action: 'cancel',
        noun: 'cancellation',
        verb: 'cancelling',
        Icon: IconXCircle,
        ButtonIcon: IconX
    },
    reinstate: {
        action: 'reinstate',
        noun: 'reinstatement',
        verb: 'reinstating',
        Icon: IconFlipBackward,
        ButtonIcon: IconFlipBackward
    }
}

export const WarrantiesCancelOrReinstateButton = ({
    quantity = 1,
    data,
    size,
    selected,
    excluded = [],
    ...props
}: WarrantiesCancelOrReinstateButtonProps) => {
    const query = useQuery()
    const auth = useAuth()

    const [isOpen, setIsOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})
    const dispatch = useAppDispatch()
    const { success, error } = useToastNotifications()
    const [form, setForm] = useState({
        reason: '',
        contact_email: ''
    })

    const selectedWarranty = useMemo<Warranty>(() => selected === 'all' && data
        ? data.rows.find(item => excluded.every(excludedRow => excludedRow.id !== item.id)) as Warranty
        : selected[0] as Warranty, [selected, excluded])

    const isAllInactive = useMemo(() => {
        if (quantity > 1 && selected === 'all') {
            return query.statuses?.every((status: WarrantyStatusEnum) => WarrantyStatus.find(status).isInactive)
        } else if (quantity > 1 && selected !== 'all') {
            return selected.every(item => item.warrantyStatus.isInactive)
        } else if (quantity === 1) {
            return selectedWarranty.warrantyStatus.isInactive
        }
        return false
    }, [selected])

    const isAllNotInactive = useMemo(() => {
        if (quantity > 1 && selected === 'all') {
            return query.statuses?.every((status: WarrantyStatusEnum) => !WarrantyStatus.find(status).isInactive)
        } else if (quantity > 1 && selected !== 'all') {
            return selected.every(item => !item.warrantyStatus.isInactive)
        } else if (quantity === 1) {
            return !selectedWarranty?.warrantyStatus.isInactive
        }
        return true
    }, [selected])

    const {
        action,
        noun,
        verb,
        Icon,
        ButtonIcon
    } = isAllInactive ? wordings.reinstate : wordings.cancel

    const title = useMemo(() => {
        if (quantity > 1 || selected === 'all') {
            return `Warranty Bulk ${noun}`
        }
        if (quantity === 1 && auth.user?.isContractor) {
            if (!selected[0].isLocked) {
                return `${action} Same-Day Registered Warranty`
            }
            return `Warranty ${noun} Request`
        }
        return `Warranty ${noun}`
    }, [quantity])

    const isActionRestricted = useMemo(() =>
        !quantity ||
        (quantity > 1 && !auth.user?.isAdminOrStaff) ||
        (quantity > 1 && (!isAllNotInactive && !isAllInactive)) ||
        (quantity === 1 && (selectedWarranty.warrantyStatus.isCancellationRequested ||
            selectedWarranty.warrantyStatus.isReinstatementRequested)) ||
        (selected === 'all' && quantity > 1 &&
            !query.company_id &&
            !query.end_date &&
            !query.start_date &&
            !query.state &&
            !query.type &&
            !query.statuses &&
            !query.search), [selected, query])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})

        try {
            if (quantity > 1) {
                const { data } = await Warranty.bulkCancel({
                    ids: selected === 'all' ? [] : selected.map(item => item.id),
                    select_all: selected === 'all',
                    excluded_ids: excluded.map(({ id }) => id),
                    ...form,
                    ...query
                })
                dispatch(fetchNotifications)
                const toast = success(
                    `Bulk ${noun} is being processed. You can track the progress in the notifications center.`)
                toast.onClose = () => dispatch(showNotifications())
                if (props.onPingJob) {
                    props.onPingJob(data)
                }
            } else {
                if (auth?.user?.isAdminOrStaff ||
                    (!selectedWarranty?.isLocked && !selectedWarranty?.warrantyStatus.isCanceled)) {
                    await selectedWarranty?.destroy(form)
                } else {
                    await selectedWarranty?.submitCancellationRequest(form)
                }
            }
            setIsOpen(false)
            setForm({
                reason: '',
                contact_email: ''
            })
            props.onChange()
        } catch (err: any) {
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }
    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    if (quantity === 1 && auth.user?.isAdminOrStaff &&
        (selectedWarranty.warrantyStatus.isCancellationRequested ||
        selectedWarranty.warrantyStatus.isReinstatementRequested)) {
        return <WarrantyReviewCancelOrReinstateRequestButton
            warranty={selectedWarranty}
            onChange={props.onChange}
            size={size}
        />
    }

    return <>
        <Button
            square
            hasError
            size={size}
            design="btn-secondary-gray"
            tooltip={`${action.capitalize()} ${quantity > 1 ? 'Warranties' : 'Warranty'}`}
            onClick={() => setIsOpen(true)}
            disabled={isActionRestricted}
        >
            <ButtonIcon/>
        </Button>

        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="w-200">
            {!isActionRestricted && <Form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900" noValidate>
                <h3 className="flex items-center gap-2 text-gray-900 text-lg font-semibold capitalize">
                    <Icon className="stroke-error-600 w-6 h-6"/>
                    {title}
                </h3>
                <span className="text-gray-500 mb-4">
                    Do you want to {action} {quantity > 1
                        ? <>{quantity.format()} warranties</>
                        : <><span className="font-semibold">Plan ID {selectedWarranty?.policy_num}</span> warranty</>}
                </span>

                {quantity > 1 && data && <WarrantyIndexPreview
                    data={data}
                    excluded={excluded}
                    selected={selected}
                />}

                {quantity === 1 && selectedWarranty && auth.user?.isContractor && <>
                    {!selectedWarranty.isLocked
                        ? !isAllInactive && <span className="text-gray-500 font-light">
                            Since your cancellation request is being
                            made <span className="font-medium">within 24 hours</span> of registration, we will process
                            it immediately. <br/>
                            Please note that if a cancellation request is
                            submitted <span className="font-medium">after 24 hours</span>, it will be reviewed by our
                            Solar Insure staff to ensure any necessary billing adjustments are made. Thank you for
                            choosing our warranty services.
                        </span>
                        : <span className="text-gray-500 font-light">
                            Please note <span className="font-medium">the reason for {noun}</span> below. A Solar
                            Insure representative will contact you shortly to review the request. If any adjustments to
                            billing are necessary, they will be reflected accordingly in your subsequent invoice.
                        </span>}
                </>}

                <ActionReasonForm
                    form={form}
                    errors={errors}
                    onChange={handleChange}
                    actionName={noun}
                />

                <div className="flex flex-col lg:flex-row gap-2 justify-center mt-2">
                    <Button
                        hasError
                        className="whitespace-nowrap"
                        design="btn-secondary-gray"
                        onClick={() => setIsOpen(false)}
                        type="button"
                        processing={processing}
                    >
                        No, Exit without {verb}
                    </Button>
                    <Button className="whitespace-nowrap capitalize" processing={processing}>
                        Yes, {action} {quantity > 1 ? quantity.format() : null} Warranties
                    </Button>
                </div>
            </Form>}
        </Popup>
    </>
}
