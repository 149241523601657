import { Link } from 'react-router-dom'

import { BatteryEligibilityTypeEnum, CompanyAccountActivityStatusEnum, CompanyTypeEnum } from '@/enums'
import { CompanyBatteryEligibility, CompanyAccountActivityStatus, CompanyType } from '@/models'
import CompanyMonitoringStatus from '@/models/CompanyMonitoringStatus'
import Integration from '@/models/Integration'
import InverterManufacturer from '@/models/InverterManufacturer'
import Model from '@/models/Model'
import User from '@/models/User'
import { api, Options, Params, Payload, UpdateCompanyUsersPayloadType } from '@/services'

const warrantyMatchMethods = [
    { value: 'full_subcontractor_match', title: '100% Subcontractor Warranties Matched' },
    { value: 'external_upload_match', title: 'External Data Uploads Matching' }
]

export default class Company extends Model {
    static endpoint = 'api/v1/company'

    name: string

    policy_rate?: number

    battery_20_year_rate?: number

    battery_30_year_rate?: number

    warranty_match_method?: string

    email?: string

    address?: string

    unique_id?: string

    users: User[]

    integrations: Integration[]

    monitoringStatus: CompanyMonitoringStatus

    subcontractors: Company[]

    last_warranty_date: string

    lifetime_eligible_commission: number

    warranties_lifetime: number

    warranties_this_month: number

    eligible_commission_rates: any[]

    conglomerate?: Company

    affiliate?: Company

    batteryEligibility: CompanyBatteryEligibility

    companyAccountStatus: CompanyAccountActivityStatus

    type: CompanyType

    constructor(props: any = {}) {
        super(props)
        this.name = props.name
        this.warranty_match_method = props.warranty_match_method
        this.policy_rate = props.policy_rate
        this.battery_20_year_rate = props.battery_20_year_rate
        this.battery_30_year_rate = props.battery_30_year_rate
        this.email = props.email
        this.address = props.address
        this.unique_id = props.unique_id

        this.users = props.users?.map((item: any) => new User(item)) || []
        this.subcontractors = props.subcontractors?.map((item: any) => new Company(item)) || []

        this.integrations = (props.integrations?.map((item: any) => new Integration(item)) || [])
            .sort((a: Integration, b: Integration) => {
                const orderA = Integration.DEFAULT_ORDER[a.provider] || Integration.DEFAULT_ORDER.other
                const orderB = Integration.DEFAULT_ORDER[b.provider] || Integration.DEFAULT_ORDER.other
                return orderA - orderB
            })
        this.monitoringStatus = new CompanyMonitoringStatus({ ...props.monitoring || {}, company: this })

        this.last_warranty_date = props.last_warranty_date
        this.lifetime_eligible_commission = props.lifetime_eligible_commission
        this.warranties_lifetime = props.warranties_lifetime
        this.warranties_this_month = props.warranties_this_month

        this.eligible_commission_rates = props.eligible_commission_rates
            ?.sort((a: any, b: any) => +new Date(b.effective_date) - +new Date(a.effective_date))

        if (props.affiliate) {
            this.affiliate = new Company(props.affiliate)
        }
        if (props.conglomerate) {
            this.conglomerate = new Company(props.conglomerate)
        }

        this.batteryEligibility = CompanyBatteryEligibility
            .find(props.battery_eligibility as BatteryEligibilityTypeEnum)

        this.companyAccountStatus = CompanyAccountActivityStatus
            .find(props.account_activity_status as CompanyAccountActivityStatusEnum)

        this.type = CompanyType
            .find(props.type as CompanyTypeEnum)
    }

    static warrantyMatchMethods = warrantyMatchMethods

    updateSingleField(payload: any) {
        return this.update({
            name: this.name,
            email: this.email,
            address: this.address,
            monthly_sales_quota: this.monthly_sales_quota,
            policy_rate: this.policy_rate,
            battery_20_year_rate: this.battery_20_year_rate,
            battery_30_year_rate: this.battery_30_year_rate,
            conglomerate_id: this.conglomerate?.id,
            unique_id: this.unique_id,
            update_policy: 'all',
            uses_enphase: this.supported_inverters.uses_enphase,
            uses_solaredge: this.supported_inverters.uses_solaredge,
            battery_eligibility: this.battery_eligibility,
            ...payload
        })
    }

    static onlyAffiliates() {
        return Company.autocomplete({ type: 'conglomerate' })
    }

    static onlyContractors() {
        return Company.autocomplete({ type: 'contractor' })
    }

    downloadSystemData() {
        return api.companies.downloadSystemData(this.id)
    }

    static async autocomplete(params?: any) {
        const res = await api.companies.autocomplete(params)
        return res.data?.map((item: any) => new Company(item)) || []
    }

    activities() {
        return api.companies.activities(this.id)
    }

    static contractors(params?: Params, options?: Options) {
        return api.companies.contractors(params, options)
    }

    static updateSelf(payload: Payload, params?: Params, options?: Options) {
        return api.http.put(Company.endpoint, payload, params, options)
    }

    eligibleCommissions() {
        return api.companies.eligibleCommissions(this.id)
    }

    updateContacts(payload: UpdateCompanyUsersPayloadType) {
        return api.companies.updateCompanyUsers(this.id, payload)
    }

    updateEligibleCommissions(payload: Payload) {
        return api.companies.updateEligibleCommissions(this.id, payload)
    }

    get isEligibleForBatteries() {
        return this.isEligibleFor20YearBatteries || this.isEligibleFor30YearBatteries
    }

    get isEligibleFor20YearBatteries() {
        return this.batteryEligibility.key === BatteryEligibilityTypeEnum.BATTERY_20_YEAR
    }

    get isEligibleFor30YearBatteries() {
        return this.batteryEligibility.key === BatteryEligibilityTypeEnum.BATTERY_30_YEAR
    }


    get isSoftwareAffiliate() {
        return this.type?.key === CompanyTypeEnum.SOFTWARE_AFFILIATE
    }

    get isLenderAffiliate() {
        return this.type?.key === CompanyTypeEnum.LENDER_AFFILIATE
    }

    get isContractor() {
        return this.type?.key === CompanyTypeEnum.CONTRACTOR
    }

    get isAffiliate() {
        return this.isLenderAffiliate || this.isSoftwareAffiliate
    }

    get isSubsidiary() {
        return !!this.conglomerate_id
    }

    get solarEdgeIntegration() {
        return this.integrations?.find((item: Integration) => item.provider === InverterManufacturer.solaredge.key)
    }

    get enphaseIntegration() {
        return this.integrations?.find((item: Integration) => item.provider === InverterManufacturer.enphase.key)
    }

    get generacIntegration() {
        return this.integrations?.find((item: Integration) => item.provider === InverterManufacturer.generac.key)
    }

    get supportedIntegrations() {
        return this.integrations.filter(integration => !integration.isUnsupported)
    }

    get unsupportedIntegrations() {
        return this.integrations.filter(integration => integration.isUnsupported)
    }

    get statusBadge() {
        switch (this.status) {
            case 'active': {
                return <span className="badge bg-success-100 text-success-700">
                    active
                </span>
            }
            default: {
                return <span className="badge bg-error-100 text-error-700">
                    inactive
                </span>
            }
        }
    }

    get isExternalUploadMatch() {
        return this.warranty_match_method === 'external_upload_match'
    }

    get isFullSubcontractorMatch() {
        return this.warranty_match_method === 'full_subcontractor_match'
    }

    get currentEligibleCommission() {
        return this.eligible_commission_rates.find((item: any) => new Date(item.effective_date) < new Date())
    }

    static get eligibleCommissionTooltipContent() {
        return <div className="text-sm p-1">
            <p className="font-bold">Commission Calculation</p>
            <ul className="list-disc pl-4 py-2">
                <li>Commission rate: 20% per watt of every warranty sold by your subcontractors.</li>
                <li className="text-yellow-100">
                    For example, if a subcontractor sells system with a 30-year warranty priced at $5,000, the
                    commission would be 0.2 x $5,000 = $1000.
                </li>
                <li>Commission is calculated automatically based on your affiliate profile.</li>
                <li>To receive commission, your subcontractors must have paid their invoices.</li>
            </ul>
            <p className="mt-1">
                Please ensure that your subcontractors have paid their invoices on time to ensure timely payment of
                commissions. If you have any questions, please contact us for further assistance.
            </p>
            <div className="w-full block text-right">
                <Link className="text-orange-700" to="/support/contact">
                    Contact Us
                </Link>
            </div>
        </div>
    }
}
