import { Button, CustomManufacturerForm, IconX, Tooltip } from '@/components'

type CustomManufacturersFormProps = {
    form: any
    errors?: any
    warnings?: any
    onChange: (e: any) => void
    onDelete: (e: any) => void
    inputClassName?: string
    className?: string
}
const CustomManufacturersForm = ({
    form,
    errors,
    warnings,
    inputClassName,
    className,
    ...props
}: CustomManufacturersFormProps) => {
    const handleChange = ({ target: { name, value } }: any, index: number) => {
        const integrations = [...form.integrations]
        integrations[index][name] = value
        props.onChange({ target: { name: 'integrations', value: integrations } })
    }

    const handleDeleteRow = async (index: number) => {
        props.onDelete(form.integrations[index])
    }

    const isDisabled = () => {
        const [last] = form.integrations
        return form.integrations.length === 1 && !last.provider && !last.user && !last.secret
    }

    return <div className={`flex flex-col gap-6 ${className}`}>
        {form.integrations.map((item: any, index: number) =>
            <div key={item.id} className="flex flex-col md:flex-row items-center gap-6">
                <CustomManufacturerForm
                    index={index}
                    form={item}
                    errors={errors.integrations?.[index]}
                    warnings={warnings}
                    onChange={(e: any) => handleChange(e, index)}
                    inputClassName={inputClassName}
                />

                <Button design="btn-link" type="button" onClick={() => handleDeleteRow(index)} disabled={isDisabled()}>
                    <Tooltip content="Remove & Delete Credentials" className="md:mt-5">
                        <IconX className={isDisabled() ? 'stroke-error-300' : 'stroke-error-600'}/>
                    </Tooltip>
                </Button>
            </div>)}
    </div>
}

export default CustomManufacturersForm
