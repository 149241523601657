import { useEffect, useState } from 'react'

import {
    Button, Select, Feature,
    IconDownload,
    IconX, PageHeader, Switch, Tooltip
} from '@/components'
import { AuthLayout, GlobalIndex, GlobalIndexDataType } from '@/containers'
import { useAuth, useNavigateWithQuery, useQuery } from '@/hooks'
import { Company, CompanyMonitoringStatus, CompanyBatteryEligibility, CompanyType } from '@/models'
import { feature, loadingBar } from '@/services'

interface CompanyIndexData extends GlobalIndexDataType {
    rows: Company[]
}
const CompaniesIndex = () => {
    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const auth = useAuth()
    const getDefaultFilters = () => ({
        type: query.type || '',
        conglomerate_id: query.conglomerate_id || '',
        monitoring_status: query.monitoring_status || '',
        battery_eligibility: query.battery_eligibility || ''
    })

    const [companies, setCompanies] = useState<Company[]>([])
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const [filters, setFilters] = useState(getDefaultFilters())
    const [data, setData] = useState<CompanyIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleChange = ({ name, value }: any) => {
        setData(data => ({ ...data, [name]: value }))
    }
    const handleFiltersChange = ({ target: { name, value } }: any) => {
        navigateWithQuery({ page: null, [name]: value })
        setFilters({ ...filters, [name]: value })
    }

    const fetchAffiliates = async () => {
        const data = await Company.onlyAffiliates()
        setAffiliates(data)
    }

    useEffect(() => {
        fetchAffiliates()
    }, [])

    const handleStatusChange = (company: Company, event: any) => {
        const index = companies.findIndex(({ id }: Company) => id === company.id)
        const clone = [...companies]
        clone[index].status = event.target.checked
        setCompanies(clone)
    }

    return <AuthLayout heading={<PageHeader title="Companies"/>}>
        <GlobalIndex
            id="companies"
            dataType="Companies"
            api={(...args) => Company.index(...args)}
            data={data}
            onChange={handleChange}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            onFiltersChange={setFilters}
            maxColumnsAmount={6}
            columns={[
                { title: 'Company Name', field: 'name' },
                { title: 'Salesforce Unique ID', field: 'unique_id' },
                { title: 'Company Type', field: 'type' },
                { title: 'Policy Rate', field: 'policy_rate' },
                { title: 'Status', field: 'status', clickable: false, showIf: feature('company-status') },
                { title: 'Last Warranty', field: 'last_warranty_created_at' },
                { title: 'Monitoring', field: 'monitoring_status', sortable: false },
                { title: 'Battery Eligibility', field: 'battery_eligibility' },
                { title: 'Account Manager', field: 'account_manager' },
                { title: 'Actions', field: 'actions', clickable: false, sortable: false }
            ]}
            rows={data.rows.map(item => ({
                id: item.id,
                unique_id: item.unique_id,
                name: <div className="flex flex-col items-start gap-1">
                    {item.name}
                    {item.companyAccountStatus?.badge()}
                </div>,
                type: item.type?.title,
                policy_rate: item.policy_rate ? item.policy_rate.money() : '',
                last_warranty_created_at: item.last_warranty_created_at ? new Date(item.last_warranty_created_at).format() : '-',
                status: <Switch
                    checked={item.status}
                    onChange={e => handleStatusChange(item, e)}
                    input-size="sm"
                />,
                monitoring_status: <div className="w-10 h-10">{item.monitoringStatus.getBadge()}</div>,
                battery_eligibility: <div className="flex gap-2 items-center">
                    {item.batteryEligibility?.icon('md')}
                </div>,
                account_manager: item.account_manager || '-',
                actions: <div className="flex items-center gap-2">
                    <Feature name="company-verification">
                        <Tooltip content="Mark as Not Verified">
                            <Button design="btn-link">
                                <IconX className="stroke-gray-300 hover:stroke-error-600"/>
                            </Button>
                        </Tooltip>
                    </Feature>
                    <Tooltip content="Download System Data">
                        <Button design="btn-link" onClick={async () => {
                            loadingBar.start()
                            await item.downloadSystemData()
                            loadingBar.stop()
                        }}>
                            <IconDownload className="stroke-gray-300 hover:stroke-primary-600"/>
                        </Button>
                    </Tooltip>
                </div>,
                _path: `/companies/${item.id}`
            }))}
            leftSide={<>
                <Select
                    name="conglomerate_id"
                    id="affiliate-id"
                    options={[
                        { value: '', title: 'Any Affiliate' },
                        ...affiliates.map(item => ({
                            value: item.id,
                            title: item.name
                        }))
                    ]}
                    value={filters.conglomerate_id}
                    onChange={handleFiltersChange}
                />
                <Select
                    name="type"
                    id="company-type"
                    options={[
                        { value: '', title: 'Any Company Type' },
                        ...CompanyType.all.map(item => ({ value: item.key, title: item.title }))
                    ]}
                    value={filters.type}
                    onChange={handleFiltersChange}
                />

                <Select
                    name="monitoring_status"
                    id="monitoring-status"
                    options={[
                        ...CompanyMonitoringStatus.all.filter(item => !item.isUnknown).map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2 items-center">
                                {item.getBadge('xs')} {item.rawTitle}
                            </div>
                        })),
                        { value: '', title: 'Any Monitoring Status' }
                    ]}
                    value={filters.monitoring_status}
                    onChange={handleFiltersChange}
                />
                <Select
                    name="battery_eligibility"
                    id="battery-eligibility-filter"
                    options={[
                        { value: '', title: 'Any Battery Eligibility' },
                        ...CompanyBatteryEligibility.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2 items-center">
                                {item.icon()} {item.title}
                            </div>
                        }))
                    ]}
                    value={filters.battery_eligibility}
                    onChange={handleFiltersChange}
                />
            </>}
            rightSide={<>
                {auth.user?.isAdminOrStaff && <Button className="mt-2 lg:mt-0 text-center" href="/companies/create">
                    New Account
                </Button>}
            </>}
        />
    </AuthLayout>
}

export default CompaniesIndex
