import { WarrantyHistory } from '@/models'

type WarrantyStatusChangeProps = {
    event: WarrantyHistory
}

const WarrantyStatusChange = ({ event }: WarrantyStatusChangeProps) =>
    <div>
        <div className="flex items-center gap-2">
            {event.eventObject.icon}
            <div>
                <h2 className="text-xl leading-8 font-semibold text-gray-900 capitalize">
                    Warranty {event.eventObject.title}{event.event.endsWith('requested') ? ' Request' : ''} Details
                </h2>
                <h3 className="text-sm text-gray-750 leading-5">
                    Request Date - {event.created_at?.format({
                        hour: '2-digit',
                        minute: '2-digit'
                    }) || 'N/A'}
                </h3>
            </div>
        </div>

        {event.properties.contact_email && <div className="mt-4 text-sm">
            <div>
                <span className="font-semibold capitalize">{event.eventObject.title} Reason:</span>
                <span className="italic font-light"> {event.properties.reason}</span>
            </div>
            <div>
                <span className="font-semibold">Contact Email:</span>
                <a href={`mailto:${event.properties.contact_email}`} className="italic font-light"> {event.properties.contact_email}</a>
            </div>
        </div>}

        {event.event.endsWith('requested') && <p className="text-sm mt-4 text-gray-500">
            For more information, please reach out to a Solar Insure representative.
        </p>}
    </div>

export default WarrantyStatusChange
