import { ReactNode } from 'react'

import { ClickToCopy } from '@/components'
import { Warranty } from '@/models'

type WarrantyDetailsListField = {
    key: string
    icon?: ReactNode
    title?: ReactNode
    body?: ReactNode
    value: any
    compareAtValue: any
    showIf?: boolean
}

type WarrantyDetailsListProps = {
    fields: WarrantyDetailsListField[]
    compare: boolean
}

export const WarrantyDetailsList = ({ fields, compare }: WarrantyDetailsListProps) =>
    <>
        {fields.map(item => {
            if (item.showIf === false) return null

            const field = Warranty.getField(item.key)
            const value = field.format(item.value)
            const compareAtValue = field.format(item.compareAtValue)
            const isHighlighted = compare &&
                ((compareAtValue && compareAtValue.length) || (value && value.length)) &&
                !field.compare(item.value, item.compareAtValue)

            return <div key={item.key} className={`card-row mb-6 p-1 rounded-lg ${isHighlighted ? 'bg-orange-100' : ''}`} data-test={`warranty-details-${field.key}`}>
                <h3 className="card-row-title gap-1">{item.title || field.title} {item.icon}</h3>
                {(typeof value === 'string' ? [value] : value).map((item, index) =>
                    <div key={index} className="card-row-copy flex flex-wrap gap-1">
                        {!!item && <>{item}<ClickToCopy content={item}/></>}
                    </div>)}
            </div>
        })}
    </>
