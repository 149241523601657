import { ChangeEvent, useMemo, useState } from 'react'

import { Select, SelectOptionProps, Switch } from '@/components'
import { InverterManufacturer } from '@/models'

type InverterManufacturerDropdownProps = {
    onChange: (e: { target: { value: string[], name: string } }) => void
    value: string[]
    name: string
    id: string
    'data-test': string
    summary?: any
    className?: string
}

const InverterManufacturerDropdown = ({
    value,
    name,
    id,
    'data-test': dataTest,
    summary,
    className,
    ...props
}: InverterManufacturerDropdownProps) => {
    const [showInactiveOptions, setShowInactiveOptions] = useState(false)
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === 'toggle') {
            setShowInactiveOptions(value => !value)
            return
        }
        props.onChange({ target: { value: e.target.value as unknown as string[], name } })
    }

    const getStatusCount = (item: InverterManufacturer) => (summary.manufacturers?.[item.key] || 0).format()

    const options = useMemo(() => [
        {
            value: 'toggle',
            title: <div className="flex w-full gap-4 items-center justify-between font-body text-gray-900 text-sm">
                Show Inactive Manufacturers
                <Switch
                    name="toggle"
                    input-size="sm"
                    checked={showInactiveOptions}
                    value="toggle"
                    onChange={() => {}}
                    onClick={() => {}}
                />
            </div>
        },
        {
            value: '',
            title: 'Any Manufacturer'
        },
        ...InverterManufacturer.all
            .filter(item => !item.isAlternative)
            .sort((a, b) => {
                if (a.isUnknown !== b.isUnknown) return a.isUnknown ? 1 : -1
                return a.title.localeCompare(b.title)
            })
            .reduce((acc, item) =>
                summary.manufacturers?.[item.key] || showInactiveOptions ? [...acc, {
                    value: item.key,
                    title: <div className="flex gap-4 items-center justify-between font-body text-gray-900 text-sm w-[18rem]">
                        <div className="flex gap-2 items-center">
                            {item.getLogo()} {item.title}
                        </div>
                        {getStatusCount(item)}
                    </div>
                }] : acc, [] as SelectOptionProps[])
    ], [value, summary, showInactiveOptions])

    return <Select
        id={id}
        name={name}
        className={className}
        data-test={dataTest}
        multiple
        persistent
        value={value}
        onChange={handleChange}
        options={options}
    />
}

export default InverterManufacturerDropdown
