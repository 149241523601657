import { IconXCircle } from '@/components'
import { Warranty } from '@/models'


type WarrantyCancelBannerProps = {
    warranty: Warranty
}

export const WarrantyCancelledBanner = ({ warranty }: WarrantyCancelBannerProps) =>
    <div className="flex w-full justify-between items-center rounded border shadow-md mb-6 p-2 bg-white">
        <div className="flex items-center">
            <div className="w-13 h-13 rounded-full bg-error-50 flex justify-center items-center">
                <div className="w-9 h-9 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                    <IconXCircle size="lg"/>
                </div>
            </div>
            <p className="font-medium leading-5 sm:leading-3 ml-4">
                This warranty was canceled on {warranty.deleted_at.format()}.
            </p>
        </div>
    </div>
