import { IconLoading02 } from '@/components'
import { Integration } from '@/models'

const IntegrationSetupNotConfirmedBanner = ({ integration }: { integration: Integration }) => {
    if (!integration?.isComplianceNotVerified) return null
    return <div className="w-full flex justify-between items-start gap-3 my-4 px-6 py-3 text-purple-500 bg-purple-50 text-xs rounded-2xl">
        <p>
            <strong>Integration Setup Not Yet Confirmed:</strong> Solar_Insure_Maintainer_Account <br/>
            We are currently trying to retrieve information for solar systems under an active warranty through the Solar
            Insure owned Maintainer account for this manufacturer. <br/>
            However, we have not yet been successful for any system. Please ensure each Solar Insure warranted system is
            correctly associated on the manufacturer&apos;s portal.
        </p>
        <div className="rounded-full flex justify-center items-center bg-purple-100 w-6 h-6">
            <IconLoading02 className="stroke-purple-600" size="sm"/>
        </div>
    </div>
}

export default IntegrationSetupNotConfirmedBanner
