import { useEffect, useMemo, useState } from 'react'

import { IconArrowLeft, IconArrowRight, Table } from '@/components'
import { useAppSelector } from '@/hooks'
import { api } from '@/services'
import { WarrantySalesItemDataType } from '@/types'

type SalesTrackingByMonthProps = {
    className?: string
    onChange: (key: string) => void
    processing: boolean
}

const SalesTrackingByMonth = ({ className, processing, ...props }: SalesTrackingByMonthProps) => {
    const auth = useAppSelector(({ auth }) => auth)
    const [data, setData] = useState<WarrantySalesItemDataType[]>([])
    const [abortController, setAbortController] = useState<AbortController | null>(null)

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.warrantySales({
                start_date: new Date().startOf('month').subtract('months', 11)
                    .toISODate(),
                end_date: new Date().endOf('month').toISODate(),
                period: 'month'
            }, { signal: controller.signal })

            setData(res)
        } finally {
            props.onChange('salesTrackingByMonth')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() =>
        () => {
            abortController?.abort('canceled')
        }, [abortController])

    const months = useMemo(() => {
        const res = []
        const start = new Date().startOf('month')
        const end = new Date().subtract('months', 11).startOf('month')

        while (start >= end) {
            res.push(start.clone())
            start.subtract('month', 1)
        }
        return res
    }, [])

    const rows = useMemo(() => months.map((month: Date) => ({
        id: month.toISODate(),
        month: month.format({ day: null }),
        warranties: data.reduce((acc, { total_count: totalCount, date }) =>
            new Date(`${date} 00:00`).endOf('month').toISODate() === month.endOf('month').toISODate() // Adding " 00:00" to ignore timezone
                ? acc + totalCount
                : acc, 0).format(),
        sales: data.reduce((acc, item) =>
            new Date(`${item.date} 00:00`).endOf('month').toISODate() === month.endOf('month').toISODate() // Adding " 00:00" to ignore timezone
                ? acc + item.total_sum
                : acc, 0)
            .money()
    })), [data])

    return <div className={`${className || ''}`}>
        <Table
            className="shadow-sm"
            useQueryParams={false}
            sortable={false}
            searchable={false}
            defaultPerPage={auth.user?.isContractor ? 5 : 6}
            showPerPage={!auth.user?.isContractor}
            columns={[
                { field: 'month', title: 'Month', cellClassName: '!py-5' },
                { field: 'warranties', title: 'Total Warranties', cellClassName: '!py-5' },
                { field: 'sales', title: 'Total Sales', cellClassName: '!py-5' }
            ]}
            rows={rows}
            nextButtonText={<IconArrowRight/>}
            prevButtonText={<IconArrowLeft/>}
            data-test="dashboard-sales-tracking-table"
        />
    </div>
}

export default SalesTrackingByMonth
