import { IconBatteryEmpty, IconLightning02, IconModem, IconSpeedometer, IconTool01, IconWifiOff } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusEnum } from '@/enums'

export default [
    {
        value: SystemStatusEnum.POWER,
        title: 'Production Issue',
        refreshable: true,
        icon: (size: IconSize) => <IconLightning02 size={size}/>,
        secondaryOrder: 1,
        description: 'System production is below expected levels, indicating potential hardware issues or setting inaccuracies.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of Production issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer">https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list,</span> and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span> for detailed information.
                </li>
                <li>
                    <span className="font-semibold">Look for</span> Microinverters with a <span className="font-semibold">Production Issue</span>, which will be indicated by a flag.
                </li>
                <li>
                    <span className="font-semibold">Click</span> the <span className="font-semibold">status link</span> of a specific microinverter to view its current issues.
                </li>
                <li>
                    For additional support, <span className="font-semibold">contact</span>
                    <span className="font-semibold">Enphase Customer Support</span> for detailed troubleshooting steps.
                </li>
                <li>
                    If no microinverters with production issues appear in the list, consider checking the system&apos;s <span className="font-semibold">Production Threshold settings</span>. They might be set too conservatively.
                </li>
                <li>
                    <span className="font-semibold">Assess</span> recent weather conditions in the area, as unusual weather can trigger production issues by causing actual system production to fall below estimated production, even when the system is working properly.
                </li>
                <li>
                    If weather frequently triggers false production issues, you may need to <span className="font-semibold">adjust the Production Threshold</span> lower than the current estimate.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_9,
        title: 'High Impact Alert',
        refreshable: true,
        icon: () => 9,
        secondaryOrder: 5,
        description: 'Critical alert with maximum severity score, indicating substantial energy loss or system risk. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of Impact Alert Level 9 issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_8,
        title: 'High Impact Alert',
        refreshable: true,
        secondaryOrder: 6,
        icon: () => 8,
        description: 'Urgent alert with high severity score, denoting significant risk and requiring immediate attention. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of Impact Alert Level 8 issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_7,
        title: 'High Impact Alert',
        refreshable: true,
        secondaryOrder: 7,
        icon: () => 7,
        description: 'Serious alert with elevated impact score, signifying notable energy loss or hazard. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of Impact Alert Level 7 issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.METER_ISSUE,
        title: 'Meter Issue',
        refreshable: true,
        secondaryOrder: 2,
        icon: (size: IconSize) => <IconSpeedometer size={size}/>,
        description: 'Possible problem with measurement equipment, likely affecting data accuracy and requiring on-site maintenance.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <div>
                        <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer">https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list,</span> and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span> for detailed information.
                    </div>
                </li>
                <li>
                    <div>Check if the issue is with <span className="font-semibold">CT Measurements</span> (negative production or consumption readings) or <span className="font-semibold">Storage Measurements</span> (storage activity not reflected).
                    </div>
                </li>
                <li>
                    <div>
                        For CT issues, <span className="font-semibold">inspect</span>
                        <span className="font-semibold">CT orientation and leads</span>. Compare with voltage measurements using your regional troubleshooting guide.
                    </div>
                </li>
                <li>
                    <div>
                        For Storage issues, <span className="font-semibold">verify wiring</span> to include AC Batteries in both production and consumption circuits.
                    </div>
                </li>
                <li>
                    <div>
                        The <span className="font-semibold">Meter Issue status</span> will update after 24 hours of corrected data.
                    </div>
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.STORAGE_IDLE,
        title: 'Battery Storage Inactive',
        refreshable: true,
        secondaryOrder: 3,
        icon: (size: IconSize) => <IconBatteryEmpty size={size}/>,
        description: 'The AC Battery(ies) charging state has not changed in 72+ hours.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer">https://enlighten.enphaseenergy.com/</a>, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list,</span> and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span> for detailed information.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">status link</span> of the specific device in question.
                </li>
                <li>
                    <span className="font-semibold">View</span> the <span className="font-semibold">current issues</span> and <span className="font-semibold">recommended actions</span> for that device.
                </li>
                <li>
                    For more detailed instructions, refer to the relevant <span className="font-semibold">Installation and Operation Manual</span>.
                </li>
                <li>
                    If you require additional support, <span className="font-semibold">contact Customer Support</span>.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.GATEWAY_CRITICAL,
        title: 'Gateway Critical',
        refreshable: true,
        secondaryOrder: 4,
        icon: (size: IconSize) => <IconModem size={size}/>,
        description: 'The gateway stopped reporting more than 8 days ago, indicating possible connectivity or hardware problems. Since gateway issues extending beyond a week usually point to serious, ongoing component malfunctions or necessary changes in the connection setup, these are critical concerns that demand prompt manual intervention.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection type: <span className="font-semibold">Wi-Fi, Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to your <span className="font-semibold">ISP, network password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech modem, <span className="font-semibold">unplug</span> the USB cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.NEVER_CONNECTED,
        title: 'Never Connected',
        refreshable: true,
        secondaryOrder: 8,
        icon: (size: IconSize) => <IconWifiOff size={size}/>,
        description: 'One or more systems have been registered but not yet internet connected.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection type: <span className="font-semibold">Wi-Fi, Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to your <span className="font-semibold">ISP, network password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech modem, <span className="font-semibold">unplug</span> the USB cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.COMPONENT_ERROR,
        title: 'Component Error',
        refreshable: true,
        secondaryOrder: 9,
        icon: (size: IconSize) => <IconTool01 size={size}/>,
        description: 'A malfunction has been identified in one or more key components: the Inverter, PVL, or Battery. To diagnose and address this issue, please refer to the Device Details page for specific error codes.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Determine</span> your connection type: <span className="font-semibold">Wi-Fi, Ethernet,</span> or <span className="font-semibold">Cellular</span>.
                </li>
                <li>
                    For Wi-Fi: <span className="font-semibold">Verify</span> if any changes were made to your <span className="font-semibold">ISP, network password,</span> or <span className="font-semibold">network name</span>.
                </li>
                <li>
                    For Ethernet: <span className="font-semibold">Reconnect</span> the ethernet cable to restore communication.
                </li>
                <li>
                    For Cellular: <span className="font-semibold">Locate</span> the Multi-Tech modem, <span className="font-semibold">unplug</span> the USB cable, <span className="font-semibold">power off and on</span> the Gateway, ensure the modem antenna is vertical, and then <span className="font-semibold">reconnect</span> the USB.
                </li>
            </ol>
        </div>
    }
]
