import { SystemStatusCategoryEnum } from '@/enums'
import { SystemStatus } from '@/models/index'
import Model from '@/models/Model'
import systemStatusCategories from '@/models/modules/system-statuses/system-status-categories'
import SystemTierCategory from '@/models/SystemTierCategory'

export default class SystemStatusCategory extends Model {
    tiers: SystemTierCategory[]

    value: SystemStatusCategoryEnum

    title: string

    constructor(props?: any) {
        super(props)
        this.value = props.value
        this.title = props.title
        this.tiers = props?.tiers?.map((item: any) => new SystemTierCategory(item)) || []
    }

    static get defaultCategory() {
        return new SystemStatusCategory({
            value: 'unknown',
            title: 'Unknown'
        })
    }

    get isUnknown() {
        return this.value === SystemStatusCategory.defaultCategory.value
    }

    get isKnown() {
        return !this.isUnknown
    }

    static get all() {
        return systemStatusCategories.map(item => new SystemStatusCategory(item))
    }

    get statuses() {
        return this.tiers.reduce((acc: SystemStatus[], item) => [...acc, ...item.statuses], [])
    }

    hasStatus(status: SystemStatus | string) {
        return this.statuses
            .some(item => status instanceof SystemStatus ? item.value === status.value : item.value === status)
    }

    hasTier(tier: SystemTierCategory | string) {
        return this.tiers
            .some(item => tier instanceof SystemTierCategory ? item.value === tier.value : item.value === tier)
    }

    get secondarySortedTiers() {
        return this.tiers
            .sort((a, b) => a.secondaryOrder && b.secondaryOrder ? a.secondaryOrder - b.secondaryOrder : 0)
    }

    get isConnected() {
        return this.value === SystemStatusCategoryEnum.MONITORED
    }

    get isExcluded() {
        return this.value === SystemStatusCategoryEnum.EXCLUDED_SITES
    }

    get isUnmonitored() {
        return this.value === SystemStatusCategoryEnum.UNMONITORED_SITES
    }

    static get connectedSitesCategory() {
        return SystemStatusCategory.find(SystemStatusCategoryEnum.MONITORED) || SystemStatusCategory.defaultCategory
    }

    static get unmonitoredSitesCategory() {
        return SystemStatusCategory.find(SystemStatusCategoryEnum.UNMONITORED_SITES)
    }

    static get excludedSitesCategory() {
        return SystemStatusCategory.find(SystemStatusCategoryEnum.EXCLUDED_SITES)
    }

    static find(value: string) {
        return SystemStatusCategory.all.find(item => item.value === value) ||
            SystemStatusCategory.defaultCategory
    }

    static findByStatus(value: string) {
        return SystemStatusCategory.all.find(item => item.statuses.some(item => item.value === value)) ||
            SystemStatusCategory.defaultCategory
    }

    static findByTier(value: string) {
        return SystemStatusCategory.all.find(item => item.tiers.some(item => item.value === value)) ||
            SystemStatusCategory.defaultCategory
    }
}
