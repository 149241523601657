import Model from './Model'
import { Badge } from '@/components'

export default class Activity extends Model {
    static endpoint = 'api/v1/activities'

    get actionBadge() {
        switch (this.description) {
            case 'created': {
                return <Badge className="bg-success-50 text-success-700">
                    Created
                </Badge>
            }
            case 'updated': {
                return <Badge className="bg-blue-50 text-blue-700">
                    Updated
                </Badge>
            }
            case 'deleted': {
                return <Badge className="bg-error-50 text-error-700">
                    Canceled
                </Badge>
            }
            case 'restored': {
                return <Badge className="bg-purple-50 text-purple-700">
                    Restored
                </Badge>
            }
        }
    }
}
