export const convert = (value: any, data: any = new FormData(), namespace = '') => {
    const isFormData = data instanceof FormData
    if (value instanceof Date) {
        if (isFormData) data.append(namespace, value.toISODateTime())
        else data[namespace] = value.toISODateTime()
    } else if (value instanceof File) {
        if (isFormData) data.append(namespace, value)
        else data[namespace] = value
    } else if (value instanceof Array) {
        for (const index in value) {
            convert(value[index], data, `${namespace}[${index}]`)
        }
    } else if (value instanceof Object) {
        for (const key in value) {
            const name = namespace ? `${namespace}[${key}]` : key
            convert(value[key], data, name)
        }
    } else if (typeof value === 'undefined') {
        // skip undefined value
    } else {
        if (isFormData) data.append(namespace, value)
        else data[namespace] = value
    }

    return data
}

export default () => ({ convert })
