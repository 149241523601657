import { Popup } from '@/components'
import { Warranty, WarrantyHistory } from '@/models'

type WarrantyHistoryItemDetailsPopupProps = {
    item: WarrantyHistory
    warranty: Warranty
    onClose: any
}

const WarrantyHistoryItemDetailsPopup = ({ item, warranty, ...props }: WarrantyHistoryItemDetailsPopupProps) => {
    if (!item) return null
    const { Component } = item.eventObject

    return item && <Popup open={!!item} onClose={props.onClose}>
        <div className="p-4 min-w-[38rem] warranty-edit">
            <Component event={item} warranty={warranty}/>
        </div>
    </Popup>
}

export default WarrantyHistoryItemDetailsPopup
