enum SystemStatusEnum {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    DISCONNECTED = 'DISCONNECTED',
    NEVER_CONNECTED = 'NEVER_CONNECTED',
    STATUS_CODE_NOT_FOUND = 'STATUS_CODE_NOT_FOUND',
    UNKNOWN = 'UNKNOWN',

    // Inverter Device Status
    GRID_CONNECTED = 'GRID_CONNECTED',
    ISLANDED = 'ISLANDED',
    GROUND_FAULT = 'GROUND_FAULT',
    ERROR_REBUS_FAULT = 'ERROR_REBUS_FAULT',
    INTERNAL_BUS_FAULT = 'INTERNAL_BUS_FAULT',
    CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
    BAD_RESET_ERROR = 'BAD_RESET_ERROR',
    DISABLED = 'DISABLED'
}

export default SystemStatusEnum
