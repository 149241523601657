import Model from './Model'
import Homeowner from '@/models/Homeowner'
import { toastNotifications, api, Options, Params } from '@/services'

export default class Invoice extends Model {
    static endpoint = 'api/v1/invoices'

    constructor(props?: any) {
        super(props)
        const date = new Date(props?.date).isValid() ? new Date(`${props?.date} 00:00`) : new Date(`1 ${props?.date}`)
        this.id = `${props?.row_id}/${date.toISODate()}`
        this.company_id = props?.row_id
    }

    static async show<T extends typeof Model>(
        this: T,
        id: number | string,
        params?: Params,
        options?: Options
    ): Promise<InstanceType<T> | null> {
        const res = await api.http.get(`${Invoice.endpoint}/${id}`, params, options)
        if (!id) return null
        const [companyId, date] = id.toString().split('/')
        return new this({
            date,
            row_id: companyId,
            homeowners: res.data?.map((item: any) => new Homeowner(item)) || []
        }) as InstanceType<T>
    }


    email = async (email: string) => {
        const res = await api.invoices.email(this.id, email)
        toastNotifications.success('Invoice successfully sent.')
        return res
    }

    static downloadMany(invoiceItems: Invoice[]) {
        return api.invoices.downloadMany(invoiceItems)
    }

    download() {
        return api.invoices.download(this.id)
    }
}
