import { ReactNode } from 'react'

import {
    IconAlertCircle,
    IconCheck,
    IconHelpCircle,
    IconPasscodeLock,
    IconSlashCircle01,
    IconX,
    Tooltip
} from '@/components'
import { BadgeSizes, IconSize } from '@/components/icons/Icon'
import { CompanyMonitoringStatusEnum } from '@/enums'
import Company from '@/models/Company'

const statuses = [
    {
        index: 0,
        key: CompanyMonitoringStatusEnum.MISSING,
        title: 'Missing Required Credentials',
        icon: (size: IconSize = 'md') => <IconX size={size} className="stroke-error-600"/>,
        color: 'text-error-500',
        secondaryColor: 'bg-error-100'
    },
    {
        index: 1,
        key: CompanyMonitoringStatusEnum.INVALID,
        title: 'Invalid Required Credentials',
        icon: (size: IconSize = 'md') => <IconPasscodeLock size={size} className="stroke-warning-600"/>,
        color: 'text-orange-700',
        secondaryColor: 'bg-warning-100'
    },
    {
        index: 2,
        key: CompanyMonitoringStatusEnum.NOT_REQUIRED,
        title: 'Credentials Not Required',
        icon: (size: IconSize = 'md') => <IconSlashCircle01 size={size} className="stroke-gray-600"/>,
        color: 'text-gray-600',
        secondaryColor: 'bg-gray-200'
    },
    {
        index: 3,
        key: CompanyMonitoringStatusEnum.VALID,
        title: 'Credentials Submitted',
        icon: (size: IconSize = 'md') => <IconCheck size={size} className="stroke-success-600"/>,
        color: 'text-success-600',
        secondaryColor: 'bg-success-100'
    },
    {
        index: 4,
        key: CompanyMonitoringStatusEnum.INCOMPLETE,
        title: 'Certification Setup Incomplete',
        subtitle: 'Submit Required Credentials',
        icon: (size: IconSize = 'md') => <IconAlertCircle size={size} className="stroke-primary-700"/>,
        color: 'text-primary-800',
        secondaryColor: 'bg-primary-100'
    },
    {
        index: 5,
        key: CompanyMonitoringStatusEnum.UNKNOWN,
        title: 'Unknown',
        icon: (size: IconSize = 'md') => <IconHelpCircle size={size} className="stroke-gray-600"/>,
        color: 'text-gray-600',
        secondaryColor: 'bg-gray-100'
    }
]

export type StatusKey = CompanyMonitoringStatusEnum

export default class CompanyMonitoringStatus {
    key: StatusKey

    company?: Company

    rawTitle: string

    rawSubTitle: string

    color: string

    secondaryColor: string

    discovered_at: Date | null

    index: number

    icon: (size?: IconSize) => ReactNode

    constructor({
        status: key,
        company,
        discovered_at: discoveredAt
    }: {
        status: StatusKey,
        discovered_at?: string,
        status_change_detected_at?: string,
        company?: Company
    } = { status: CompanyMonitoringStatusEnum.UNKNOWN }) {
        this.company = company
        this.key = this.company?.isContractor && key === CompanyMonitoringStatusEnum.NOT_REQUIRED
            ? CompanyMonitoringStatusEnum.INCOMPLETE
            : key
        this.discovered_at = discoveredAt ? new Date(discoveredAt) : null
        const status = CompanyMonitoringStatus.find(this.key)
        this.rawTitle = status.title
        this.rawSubTitle = status.subtitle
        this.color = status.color
        this.index = status.index
        this.secondaryColor = status.secondaryColor
        this.icon = status.icon
    }

    get title() {
        return <span className={this.color}>{this.rawTitle}</span>
    }

    get subTitle() {
        if (!this.company) return null
        if (this.rawSubTitle) return this.rawSubTitle
        const invalidOrMissingIntegrations = this.company.integrations.reduce((acc: string[], item) =>
            item.isInvalidOrMissing ? [...acc, item.inverterManufacturer.title] : acc, []) || []
        return invalidOrMissingIntegrations.length ? `Resolve ${invalidOrMissingIntegrations.join('/')}` : ''
    }

    static find(key: StatusKey): any {
        return statuses.find(item => item.key === key) ||
            CompanyMonitoringStatus.find(CompanyMonitoringStatusEnum.UNKNOWN)
    }

    static get all() {
        return statuses.map(item => new CompanyMonitoringStatus({ status: item.key as StatusKey }))
    }

    get isValid() {
        return this.key === CompanyMonitoringStatusEnum.VALID
    }

    get isNotRequired() {
        return this.key === CompanyMonitoringStatusEnum.NOT_REQUIRED
    }

    get isIncomplete() {
        return this.key === CompanyMonitoringStatusEnum.INCOMPLETE
    }

    get isInvalid() {
        return this.key === CompanyMonitoringStatusEnum.INVALID
    }

    get isMissing() {
        return this.key === CompanyMonitoringStatusEnum.MISSING
    }

    get isUnknown() {
        return this.key === CompanyMonitoringStatusEnum.UNKNOWN
    }

    getBadge(size: IconSize = 'md') {
        const icon = this.icon(size)
        return <Tooltip content={this.rawTitle}>
            <div className={`rounded-full flex justify-center items-center ${this.secondaryColor} ${BadgeSizes[size]}`}>
                {icon}
            </div>
        </Tooltip>
    }
}
