import { ChangeEvent, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'

import {
    AddressInput,
    Button,
    ConfirmPopup, HandleDuplicatesPopup, HandleUnverifiedAddressPopup,
    IconAlertCircle, IconCheckCircleBroken, IconHelpCircle,
    IconTrash02, Input,
    RowProps,
    Table,
    Tabs,
    Tooltip
} from '@/components'
import { ColumnType } from '@/components/bulk-upload/default-coolumns'
import { FormErrorsType } from '@/components/ui/InputError'
import { useAnalytics } from '@/hooks'
import { Homeowner, User } from '@/models'

type StepValidateDataProps = {
    form: {
        warranties: any[]
    }
    errors: FormErrorsType
    columns: ColumnType[]
    headers: any
    onNext: () => void
    onPrev: () => void
    onChange: (e: any) => void
    processing: boolean
    uploadAs: User | null
}

type TabType = 'all' | 'clean' | 'errored'

const StepValidateData = ({
    form,
    columns,
    errors,
    processing,
    headers,
    uploadAs,
    ...props
}: StepValidateDataProps) => {
    const { trackEvent } = useAnalytics()
    const [tab, setTab] = useState<TabType>('all')
    const [showDuplicatePopup, setShowDuplicatePopup] = useState(false)
    const [showUnverifiedPopup, setShowUnverifiedPopup] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState<RowProps | null>(null)
    const [focusedCell, setFocusedCell] = useState<any>({
        index: null,
        key: null
    })

    const errorsMemo = useMemo(() => Object.keys(errors).reduce((acc: any, item) => {
        const [, index, field] = item.split('.')
        return typeof index === 'undefined' ? acc : {
            ...acc,
            [index]: {
                ...acc[index] || {},
                [field]: errors[item]
            }
        }
    }, {}), [errors])

    const trackHoverEvent = (eventType: string) => {
        const category = 'User Interaction'

        if (eventType === 'bulk_upload_step_3_back') {
            trackEvent('hover_bulk_upload_step_3_back', category, 'Bulk Upload Back Button Hover')
        } else if (eventType === 'bulk_upload_step_3_save') {
            trackEvent('hover_bulk_upload_step_3_save', category, 'Bulk Upload Save Button Hover')
        } else if (eventType === 'email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Bulk Upload Email Homeowner Button Hover')
        } else if (eventType === 'hover_email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Email Homeowner Toggle Hover')
        }
    }

    const handleClickBack = () => {
        props.onPrev()
        trackEvent('click_bulk_upload_step_3_back', 'User Interaction', 'Bulk Upload Step 2 Back Button Click')
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const clone = [...form.warranties]
        clone[e.target.dataset.index as unknown as number][e.target.name] = e.target.value
        props.onChange({ target: { name: 'warranties', value: clone } })
    }

    const handleErrorInfoClick = (row: RowProps) => {
        setSelectedRow(row)
        const rowErrors = errorsMemo[row.index as number]
        if (Homeowner.possibleDuplicateErrors.some(item =>
            rowErrors?.street_address?.includes(item) || rowErrors?.address?.includes(item))) {
            setShowDuplicatePopup(true)
        } else if (Homeowner.possibleUnverifiedErrors.some(item =>
            rowErrors?.street_address?.includes(item) || rowErrors?.address?.includes(item))) {
            setShowUnverifiedPopup(true)
        }
    }


    const handleDeleteRow = (id: number) => {
        const payloadClone = [...form.warranties]
        const index = id - 1
        payloadClone.splice(index, 1)
        props.onChange({ target: { name: 'warranties', value: payloadClone } })
        setShowDeletePopup(false)
        setSelectedRow(null)
    }

    const handleErrorHandling = (e: any) => {
        props.onChange(e)
    }

    const rows = useMemo<RowProps[]>(() => form.warranties.reduce((acc: RowProps[], item: any, index: number) => {
        const rowErrors = errorsMemo[index]
        if (tab === 'clean' && rowErrors) return acc
        if (tab === 'errored' && !rowErrors) return acc
        return [...acc, {
            id: 1 + index,
            action: <Tooltip content="Delete this row">
                <button type="button" onClick={() => {
                    setShowDeletePopup(true)
                    setSelectedRow({ id: 1 + index })
                }}>
                    <IconTrash02 size="sm" className="stroke-gray-700"/>
                </button>
            </Tooltip>,
            ...Object.keys(item).reduce((acc, key) => {
                const error = rowErrors?.[key]
                const isDuplicateAddress = ['street_address', 'address'].includes(key) &&
                    Homeowner.possibleDuplicateErrors.some(item =>
                        rowErrors?.street_address?.includes(item) || rowErrors?.address?.includes(item))
                const isUnverifiedAddress = ['street_address', 'address'].includes(key) &&
                    Homeowner.possibleUnverifiedErrors.some(item =>
                        rowErrors?.street_address?.includes(item) || rowErrors?.address?.includes(item))
                const showInfoButton = isDuplicateAddress || isUnverifiedAddress
                const Component = ['street_address', 'address'].includes(key) ? AddressInput : Input
                return {
                    ...acc,
                    [key]: (focusedCell.index === index && focusedCell.key === key) || error
                        ? <Component
                            id={`row-${1 + index}-${key}-input`}
                            value={item[key]}
                            data-index={index.toString()}
                            name={key}
                            onChange={handleChange}
                            errors={!!error}
                            transparent
                            tooltip={error}
                            dropdownOptions={{ strategy: 'fixed' }}
                            postIcon={showInfoButton && <Tooltip content="Click icon to fix this error">
                                <button type="button" onClick={() => handleErrorInfoClick({ index, ...item })}>
                                    <IconHelpCircle size="sm" className="stroke-gray-400"/>
                                </button>
                            </Tooltip>}
                        />
                        : <button
                            className="px-3.5 py-2.5 border border-transparent w-full text-left"
                            type="button"
                            onClick={() => {
                                flushSync(() => setFocusedCell({ index, key }))
                                const input = document.getElementById(`row-${1 + index}-${key}-input`) as HTMLInputElement
                                input.focus()
                            }}
                        >
                            {item[key]}
                        </button>
                }
            }, {})
        }]
    }, []), [form.warranties, errorsMemo, tab, focusedCell])

    return <>
        <form onSubmit={e => {
            e.preventDefault()
            props.onNext()
        }}>
            <p className="text-sm pt-5">
                Our system checks the values of each row for any errors. <br/>
                To edit the data in a cell, click on the cell, make your changes, and then press Enter or click on any
                other cell to apply the modifications.
            </p>

            {!!Object.keys(errorsMemo).length && <p className="text-error-800 text-sm mt-2 mb-8">
                Resolve all errors before uploading the spreadsheet.
            </p>}
            <div className="flex mt-4">
                <Tabs
                    value={tab}
                    onChange={id => setTab(id as TabType)}
                    size="sm"
                    tabs={[
                        { id: 'all', title: `All Rows (${form.warranties.length})` },
                        { id: 'clean', title: `Clean Rows (${form.warranties.length - Object.keys(errorsMemo).length})` },
                        { id: 'errored', title: `Rows with Errors (${Object.keys(errorsMemo).length})` }
                    ]}
                />
            </div>

            {tab === 'errored' && rows.length === 0
                ? <div className="mt-4 text-gray-900 text-sm bg-success-25 border border-gray-200 rounded px-6 py-4 flex flex-col items-center gap-3">
                    <div className="w-14 h-14 bg-success-200 rounded-full flex justify-center items-center border border-10 border-success-100">
                        <IconCheckCircleBroken size="lg" className="stroke-success-600"/>
                    </div>

                    <div className="flex flex-col items-center gap-1">
                        <p className="font-semibold">No errors found</p>
                        <p>All good! You have no errors and are ready to upload the data.</p>
                    </div>
                </div>
                : <Table
                    key={tab}
                    sortable={false}
                    className="stripped-table mt-4"
                    useQueryParams={false}
                    searchable={false}
                    columns={[{
                        field: 'id',
                        title: '#',
                        sticky: true,
                        cellClassName: (row: RowProps) => `border-r !px-5 !py-4${errorsMemo[row.id as number - 1] ? ' bg-error-100 border-error-600 sticky' : ''}`
                    }, ...columns.filter(({ show }) => show)
                        .map(item => ({
                            field: item.value,
                            title: <div className="px-3.5 py-2.5">{item.title}</div>,
                            className: 'whitespace-nowrap',
                            cellClassName: 'whitespace-nowrap !p-1.5'
                        })), {
                        field: 'action',
                        cellClassName: 'border-l',
                        title: '',
                        sticky: true
                    }]}
                    rows={rows}
                />}

            <div className="flex justify-end gap-3 mt-6">
                <Button
                    design="btn-secondary-gray"
                    onClick={handleClickBack}
                    className="w-36"
                    type="button"
                    onMouseOver={() => trackHoverEvent('bulk_upload_step_3_back')}
                    processing={processing}
                >
                    Back
                </Button>
                <Button
                    onMouseOver={() => trackHoverEvent('bulk_upload_step_3_save')}
                    disabled={!!Object.keys(errorsMemo).length}
                    processing={processing}
                    className="w-36"
                >
                    Save
                </Button>
            </div>
        </form>
        {selectedRow && showDuplicatePopup && <HandleDuplicatesPopup
            row={{ user_id: uploadAs?.id, ...selectedRow }}
            form={form}
            isOpen={showDuplicatePopup}
            onChange={handleErrorHandling}
            onClose={() => setShowDuplicatePopup(false)}
        />}
        {selectedRow && showUnverifiedPopup && <HandleUnverifiedAddressPopup
            row={selectedRow}
            form={form}
            isOpen={showUnverifiedPopup}
            onChange={handleErrorHandling}
            onClose={() => setShowUnverifiedPopup(false)}
        />}
        {selectedRow && <ConfirmPopup
            isOpen={showDeletePopup}
            tooltip="Remove Row"
            cancelButtonText="Cancel Without Changes"
            confirmButtonText="Delete Row"
            className="w-full md:w-auto"
            title={<div className="flex gap-2">
                <IconAlertCircle className="stroke-error-600" size="lg"/>
                Confirm Row Deletion
            </div>}
            description={<p className="flex flex-col gap-4">
                <span>Are you sure you want to delete row #{selectedRow.id}?</span>
                <span>
                    This action cannot be undone and will permanently remove all data in this row from your upload.
                </span>
            </p>}
            onConfirm={() => handleDeleteRow(selectedRow.id as number)}
            onCancel={() => {
                setShowDeletePopup(false)
                setSelectedRow(null)
            }}
        />}
    </>
}

export default StepValidateData
