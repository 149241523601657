import { IconFileMultiple, Table } from '@/components'
import { Warranty, WarrantyHistory } from '@/models'

type PotentialDuplicateWarrantyNoticeProps = {
    event: WarrantyHistory
    warranty: Warranty
}

const PotentialDuplicateWarrantyNotice = ({ event, warranty }: PotentialDuplicateWarrantyNoticeProps) => {
    const duplicate = event.properties?.attributes?.duplicate_with?.[0]
    return <div>
        <div className="flex items-center gap-2">
            <IconFileMultiple className="stroke-gray-700" size="xl"/>
            <div>
                <h2 className="text-xl leading-8 font-semibold text-gray-900">
                    Potential Duplicate Warranty Notice
                </h2>
                <h3 className="text-sm text-gray-750 leading-5">
                    Duplicate Creation Date - {event.created_at?.format({ hour: '2-digit', minute: '2-digit' }) || 'N/A'}
                </h3>
            </div>
        </div>

        <Table
            className="mt-6"
            searchable={false}
            sortable={false}
            columns={[
                { field: 'name', title: 'Potential Duplicate Information' },
                { field: 'value', title: '' }
            ]}
            rows={[
                {
                    id: 1,
                    name: <span className="text-gray-500 font-semibold">Warranty ID</span>,
                    value: <span className="text-gray-500">{duplicate?.policy_num || ''}</span>
                },
                {
                    id: 2,
                    name: <span className="text-gray-500 font-semibold">Current Warranty Status</span>,
                    value: <span className="text-gray-500">{warranty.warrantyStatus.title}</span>
                },
                {
                    id: 3,
                    name: <span className="text-gray-500 font-semibold">Same-Value Flagged Items</span>,
                    value: <span className="text-gray-500">
                        {duplicate?.matching_fields?.length
                            ? new Intl.ListFormat().format(duplicate.matching_fields
                                .filter((item: string) => !Warranty.getField(item).isDefault)
                                .map((item: string) => Warranty.getField(item).title))
                            : '-'}
                    </span>
                }
            ]}
        />

        <p className="text-sm mt-4 text-gray-500">
            For more information, please reach out to a Solar Insure representative.
        </p>
    </div>
}

export default PotentialDuplicateWarrantyNotice
