import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import WarrantyHistoryItemDetailsPopup from './WarrantyHistoryItemDetailsPopup'
import { Badge, Table, Button } from '@/components'
import { Warranty, WarrantyHistory } from '@/models'
import { feature } from '@/services'

type WarrantyHistoryProps = {
    warranty: Warranty
}

const WarrantyHistoryTable = ({ warranty }: WarrantyHistoryProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1)
    const { id } = useParams()

    useEffect(() => {
        setActiveIndex(-1)
    }, [id])

    if (!feature('warranty-history') || !warranty.histories?.length) return null

    return <div className="grid lg:block">
        <Table
            useQueryParams={false}
            searchable={false}
            sortable={false}
            heading={
                <div className="bg-white p-5">
                    <div className="flex items-center">
                        Warranty History
                        <Badge className="text-blue-700 bg-blue-50 ml-2 font-normal">
                            {warranty.histories.length} events
                        </Badge>
                    </div>
                </div>
            }
            columns={[
                { title: 'Date & Time', field: 'date' },
                { title: 'Event', field: 'event' },
                { title: 'Warranty Status', field: 'status' },
                { title: 'User', field: 'doer' },
                { title: 'Details', field: 'details', sortable: false }
            ]}
            rows={warranty.histories.map((item: WarrantyHistory, index) => ({
                id: item.id,
                date: new Date(item.created_at).format({ hour: '2-digit', minute: '2-digit' }),
                event: item.eventTitle,
                status: <div className="w-fit">{item.statusObject?.badge()}</div>,
                doer: <div className="flex flex-col">
                    <span>{item.causer?.email || 'System'}</span>
                    {item.causer && <span className="text-xs text-gray-500">{item.causer?.name}</span>}
                </div>,
                details: !!item.eventObject?.Component &&
                    <Button design="btn-link" className="!text-primary-700" onClick={() => setActiveIndex(index)}>
                        Expand Details
                    </Button>
            }))}
        />

        <WarrantyHistoryItemDetailsPopup
            warranty={warranty}
            item={warranty.histories[activeIndex]}
            onClose={() => setActiveIndex(-1)}
        />
    </div>
}

export default WarrantyHistoryTable
