import { Select, IconHelpCircle, Input, Tooltip } from '@/components'
import { InverterManufacturer } from '@/models'

type CustomManufacturerFormProps = {
    index: number
    form: any
    errors?: any
    warnings?: any
    onChange: any
    disabled?: boolean
    inputClassName?: string
}
const CustomManufacturerForm = ({
    index,
    form,
    inputClassName,
    disabled,
    errors,
    warnings,
    ...props
}: CustomManufacturerFormProps) =>
    <>
        <Select
            name="provider"
            id={`custom-manufacturer-provider-${index}`}
            label="Inverter Company"
            options={[
                { value: '', title: 'Inverter Company' },
                ...InverterManufacturer
                    .allUnsupported
                    .map(item => ({
                        value: item.key,
                        title: item.title
                    }))
            ]}
            value={form.provider}
            onChange={props.onChange}
            className={inputClassName}
            errors={errors?.provider}
            disabled={disabled}
        />
        <Input
            id={`custom-manufacturer-user-${index}`}
            name="user"
            label="Monitoring Portal Login Username"
            placeholder="Username"
            autoComplete="new-password"
            onChange={props.onChange}
            value={form.user}
            errors={errors?.user}
            warnings={warnings?.user}
            postIcon={<Tooltip content="Inverter Username">
                <IconHelpCircle className="stroke-gray-400" size="sm"/>
            </Tooltip>}
            className={inputClassName}
            disabled={disabled}
        />
        <Input
            id={`custom-manufacturer-secret-${index}`}
            name="secret"
            label="Monitoring Portal Login Password"
            placeholder="Password"
            type="password"
            autoComplete="new-password"
            onChange={props.onChange}
            value={form.secret}
            errors={errors?.secret}
            warnings={warnings?.secret}
            className={inputClassName}
            disabled={disabled}
        />
    </>

export default CustomManufacturerForm
