import { ChangeEvent, ReactNode, useRef, useState } from 'react'

import { ErrorsType, IconUploadCLoud02 } from '@/components'

type UploadProps = {
    id?: string
    value?: File | File[]
    validate?: boolean
    name?: string
    children: ReactNode
    accept?: string
    multiple?: boolean
    className?: string
    errors?: ErrorsType | boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}
export const Upload = ({
    children,
    accept,
    multiple,
    className,
    validate = true,
    errors,
    value,
    ...props
}: UploadProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [highlight, setHighlight] = useState(false)

    const validateFile = (file: File) =>
        !accept || accept.replace(/\s/g, '')
            .toLowerCase()
            .split(',')
            .some(type => type === file.type.toLowerCase())

    const handleClick = () => {
        inputRef.current?.click()
    }

    const handleDrop = (e: React.DragEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setHighlight(false)
        const files = multiple ? e.dataTransfer.files : [e.dataTransfer.files[0]]
        props.onChange({
            target: {
                name: props.name,
                value: [...files].filter((file: File) => validateFile(file) || !validate)
            }
        } as unknown as ChangeEvent<HTMLInputElement>)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        const files = e.target.files as FileList
        props.onChange({
            target: {
                name: props.name,
                value: [...files].filter((file: File) => validateFile(file) || !validate)
            }
        } as unknown as ChangeEvent<HTMLInputElement>)
    }

    const handleDrag = (e: React.DragEvent<HTMLButtonElement>, state: boolean) => {
        e.preventDefault()
        e.stopPropagation()
        setHighlight(state)
    }

    return <button
        type="button"
        className={`upload ${highlight ? 'highlight' : ''} ${value ? 'uploaded' : ''} ${errors ? 'errored' : ''} ${className ? className : ''}`}
        onClick={handleClick}
        onDragEnter={e => handleDrag(e, true)}
        onDragOver={e => handleDrag(e, true)}
        onDragLeave={e => handleDrag(e, false)}
        onDrop={handleDrop}
    >
        <div className="flex justify-center items-center rounded-full bg-gray-100 w-17 h-17">
            <div className="flex justify-center items-center rounded-full bg-gray-200 w-12 h-12 ">
                <IconUploadCLoud02 size="xl" className="stroke-gray-600"/>
            </div>
        </div>
        {errors
            ? <>
                {value && !Array.isArray(value) &&
                    <p className="text-sm text-gray-500 mt-3">
                        <span className="text-orange-700">{value.name}</span>
                    </p>}
                <p className="max-w-xs text-sm text-gray-500 mt-1">
                    {errors}
                </p>
            </>
            : children}
        <input
            ref={inputRef}
            type="file"
            accept={accept}
            multiple={multiple}
            {...props}
            onChange={handleChange}
        />
    </button>
}
