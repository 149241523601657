import { ChartDataset, ChartOptions } from 'chart.js'
import { ReactNode } from 'react'

import Chart from './Chart'

type DoughnutChartProps = {
    datasets: ChartDataset[] | any[]
    plugins?: any
    options?: ChartOptions
    onInit?: any
    customTooltip?: (index: number) => ReactNode
}

const defaultOptions = { plugins: { tooltip: { enabled: false } } }

const DoughnutChart = ({ plugins, datasets, customTooltip, options = defaultOptions, ...props }: DoughnutChartProps) =>
    <Chart
        type="doughnut"
        data={{ datasets }}
        options={options}
        plugins={plugins}
        customTooltip={customTooltip}
        onInit={props.onInit}
    />

export default DoughnutChart
