import { FormEvent, useState } from 'react'

import { Button, Popup, IconCertificate, ActionReasonForm, FormErrorsType, FormChangeEvent } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyMarkUniquePopupProps = {
    warranty: Warranty
    className?: string
    onChange: () => void
}

export const WarrantyMarkUniquePopup = ({
    warranty,
    className,
    ...props
}: WarrantyMarkUniquePopupProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const {
        success,
        error
    } = useToastNotifications()

    const [form, setForm] = useState({ reason: '' })

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setProcessing(true)
        try {
            await warranty.markUnique(form.reason)
            setIsOpen(false)
            success('Warranty successfully marked as unique.')
            if (props.onChange) props.onChange()
        } catch (err: any) {
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleChange = (e: FormChangeEvent) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return <>
        <Button
            square
            tooltip="Mark Warranty as Unique"
            design="btn-secondary-gray"
            onClick={() => setIsOpen(true)}>
            <IconCertificate/>
        </Button>

        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="w-200">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900" noValidate>
                <div className="flex items-center gap-2">
                    <IconCertificate className="stroke-gray-700"/>
                    <p className="font-bold">Mark Warranty as Unique</p>
                </div>
                <p className="text-gray-500">
                    Do you want to mark this warranty as unique?
                </p>
                <p className="text-gray-500">
                    Multiple warranties may be needed at the same homeowner address in certain cases. If the homeowner
                    added additional panels, inverters, or a battery after the initial solar system installation and
                    warranty registration.
                </p>
                <p className="text-gray-500">
                    By marking this warranty as unique, the {'"'}Potential Duplicate{'"'} status will be removed, and
                    billing will proceed as usual.
                </p>
                <p className="text-gray-500">
                    To help us improve our registration process, please provide a brief explanation as to why you are
                    marking this warranty as unique:
                </p>

                <ActionReasonForm
                    form={form}
                    errors={errors}
                    actionName="Edit"
                    onChange={handleChange}
                />

                <div className="flex flex-col lg:flex-row gap-2 justify-center">
                    <Button
                        hasError
                        type="button"
                        design="btn-secondary-gray"
                        onClick={() => setIsOpen(false)}
                    >
                        No, Exit without changing</Button>
                    <Button
                        processing={processing}
                    >
                        Yes, Mark Warranty as Unique
                    </Button>
                </div>
            </form>
        </Popup>
    </>
}
