import { useState } from 'react'

import { Button, IconAlertTriangleFilled, ExitImpersonationModePopup } from '@/components'
import { useAuth } from '@/hooks'
const ImpersonationModeBanner = () => {
    const auth = useAuth()
    const [showExitImpersonationPopup, setShowExitImpersonationPopup] = useState(false)

    if (!auth.user?.isImpersonated) return null

    return <div className="flex flex-col md:flex-row items-center justify-between gap-4 px-8 py-4 bg-warning-50 border border-warning-400 md:fixed top-0 w-full z-20">
        <div className="flex flex-col md:flex-row items-center gap-2">
            <IconAlertTriangleFilled size="lg"/>
            <span className="text-sm font-semibold text-gray-900 font-body text-center md:text-left">
                IMPERSONATION MODE ACTIVE: You are currently
                viewing {auth.user?.company.name || auth.user?.name}&apos;s account.
            </span>
        </div>
        <Button
            className="whitespace-nowrap" type="button" design="btn-secondary"
            onClick={() => setShowExitImpersonationPopup(true)}
        >
            Exit Impersonation Mode
        </Button>
        <ExitImpersonationModePopup
            isOpen={showExitImpersonationPopup} onClose={() => setShowExitImpersonationPopup(false)}
        />
    </div>
}

export default ImpersonationModeBanner
