import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Notification } from '@/models'

const initialState: {
    all: Notification[]
    isOpen: boolean
} = {
    all: [],
    isOpen: false
}
export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        show: state => ({ ...state, isOpen: true }),
        hide: state => ({ ...state, isOpen: false }),
        set: (state, action: PayloadAction<Notification[]>) => ({
            ...state,
            all: action.payload
        })
    }
})

export const fetchNotifications = async (dispatch: any) => {
    const res = await Notification.index()
    dispatch(notificationsSlice.actions.set(res.data))
}

export const {
    show: showNotifications,
    hide: hideNotifications,
    set: setNotifications
} = notificationsSlice.actions

export default notificationsSlice.reducer
