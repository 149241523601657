import { useParams } from 'react-router-dom'

import {
    Button,
    IconEdit05
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyEditCancellationBannerProps = {
    warranty: Warranty
    onChange: () => void
}

export const WarrantyEditRequestBanner = ({
    warranty,
    ...props
}: WarrantyEditCancellationBannerProps) => {
    const auth = useAuth()
    const { type } = useParams()

    const request = warranty.pending_update_requests[0] as {
        id: string,
        created_at: string
    }

    const handleCancel = async () => {
        await Warranty.revertEditRequest(request.id)
        props.onChange()
    }

    return <div className="flex w-full justify-between items-center rounded border border-error-200 shadow-md mb-6 py-3 px-4 bg-white" data-test="warranty-edit-requested-banner">
        <div>
            <div className="flex items-center">
                <div className="w-13 h-13 rounded-full bg-warning-50 flex justify-center items-center">
                    <div className="w-9 h-9 rounded-full bg-warning-100 flex justify-center items-center stroke-warning-700">
                        <IconEdit05 className="stroke-warning-600" size="lg"/>
                    </div>
                </div>
                <div className="flex flex-col ml-4">
                    <p className="font-medium text-gray-700" data-test="edit-request-date">
                        A warranty edit was requested
                        on {new Date(request.created_at).format()}
                    </p>
                    <p className="text-gray-600">
                        A Solar Insure representative will reach out shortly to review the request.
                    </p>
                </div>
            </div>
        </div>
        {auth.user?.isContractor && <Button
            tooltip="Cancel Edit Request"
            onClick={handleCancel}
            design="btn-secondary"
            className="whitespace-nowrap"
            data-test="cancel-edit-request-button"
        >
            Cancel Request
        </Button>}
        {auth.user?.isAdminOrStaff && <Button
            tooltip="See Details"
            href={`/${type}/${warranty.id}/warranty/edit`}
            design="btn-secondary"
            className="whitespace-nowrap"
            data-test="see-details-button"
        >
            See Details
        </Button>}
    </div>
}
