import { COMPANY_TYPES, CompanyTypeEnum } from '@/enums'

export class CompanyType {
    key: CompanyTypeEnum

    title: string

    constructor(props: any) {
        this.key = props.key
        this.title = props.title
    }

    static get all() {
        return COMPANY_TYPES.map(item => new CompanyType(item))
    }

    static find(key: CompanyTypeEnum) {
        return CompanyType.all.find(item => item.key === key) ||
            CompanyType.defaultType as CompanyType
    }

    static get defaultType() {
        return CompanyType.all.find(item => item.key === CompanyTypeEnum.CONTRACTOR)
    }
}
