import Icon, { IconProps } from './Icon'

const IconXCircle = (props: IconProps) =>
    <Icon {...props}>
        <path d="M14 2V6C14 6.26522 14.1264 6.51957 14.3515 6.70711C14.5765 6.89464 14.8817 7 15.2 7H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.8182 17H10.1818C9.60316 17 9.04821 16.7742 8.63904 16.3724C8.22987 15.9705 8 15.4255 8 14.8571V4.14286C8 3.57454 8.22987 3.02949 8.63904 2.62763C9.04821 2.22576 9.60316 2 10.1818 2H14.5455L20 7.35714V14.8571C20 15.4255 19.7701 15.9705 19.361 16.3724C18.9518 16.7742 18.3968 17 17.8182 17Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 17.4286V19.7143C15 20.3205 14.7701 20.9019 14.361 21.3305C13.9518 21.7592 13.3968 22 12.8182 22H5.18182C4.60316 22 4.04821 21.7592 3.63904 21.3305C3.22987 20.9019 3 20.3205 3 19.7143V8.28571C3 7.67951 3.22987 7.09812 3.63904 6.66947C4.04821 6.24082 4.60316 6 5.18182 6H7.36364" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>

export default IconXCircle

