import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAppDispatch, useAuth } from '@/hooks'
import { AuthUser } from '@/models'
import { RouteType } from '@/router'
import { cookies } from '@/services'
import { setRedirectUrl, login } from '@/store'
import { gaSend } from '@/utils'

export type AppRouteProps = {
    route: RouteType
}

const AppRoute = ({ route }: AppRouteProps) => {
    gaSend({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
        title: route.title
    })
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [processing, setProcessing] = useState(!!cookies.get('api_token') && !cookies.get('auth'))
    if (processing) AuthUser.currentUser().then(user => {
        dispatch(login({ user, apiToken: cookies.get('api_token') }))
    }).finally(() => setProcessing(false))
    if (processing) return null

    const auth = useAuth()
    if ((typeof route.showIf === 'function' && !route.showIf(auth.user)) || (typeof route.showIf === 'boolean' && !route.showIf)) {
        if (auth.user?.isAuthenticated) {
            return <Navigate to="/dashboard"/>
        }
        dispatch(setRedirectUrl(`${location.pathname}${location.search}`))
        return <Navigate to="/login"/>
    }
    if (route.isPublic) {
        return auth.user?.isAuthenticated ? <Navigate to="/dashboard"/> : route.element
    }
    if (route.isPrivate) {
        if (auth.user?.isAuthenticated) {
            return route.element
        }
        dispatch(setRedirectUrl(`${location.pathname}${location.search}`))
        return <Navigate to="/login"/>
    }
    return route.element
}

export default AppRoute
