import Model from './Model'
import Company from '@/models/Company'
import System from '@/models/System'

export default class Homeowner extends Model {
    static endpoint = 'api/v1/homeowners'

    systems: System[]

    company: Company

    constructor({ system, ...props }: any = {}) {
        super(props)

        this.systems = props.systems ? props.systems.map((item: any) => new System({ ...item, homeowner: this })) : []
        this.company = new Company(props.company)
    }

    get system() {
        return this.systems[0]
    }

    get fullName() {
        return `${this.first_name}${this.last_name ? ` ${this.last_name}` : ''}`
    }

    static possibleDuplicateErrors = [
        'The address already exists in the system.',
        'Duplicate row detected.',
        'Potential Duplicate Warning',
        "Potential Duplicate Warning. Another warranty already exists at this address. If this is a unique registration, select 'Allow Duplicate Address' checkbox below."
    ]

    static possibleUnverifiedErrors = [
        'The address you supplied cannot be verified.',
        'The address you supplied is not able to be verified.'
    ]
}
