import { api } from '@/services'

export default {
    indexTypes() {
        return api.http.get('api/v1/product-types')
    },
    batteries() {
        return api.http.get('api/v1/products/batteries')
    }
}
