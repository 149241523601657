import { ReactNode } from 'react'
import { NavLink, To } from 'react-router-dom'

import { Badge } from '@/components'

export type MenuItemProps = {
    id: string
    title: string
    icon: ReactNode
    path?: To
    badge?: string
    showIf?: boolean
}

const MenuItem = ({
    item,
    buttonRef
}: { buttonRef?: React.Ref<HTMLAnchorElement | HTMLButtonElement>, item: MenuItemProps }) => {
    const content = <>
        <div className="nav-item-icon">{item.icon}</div>
        <span className="nav-item-title">{item.title}</span>
        {item.badge && <Badge data-test={`sidebar-menu-item-${item.id}-badge`}><span>{item.badge}</span></Badge>}
    </>
    return item.path
        ? <NavLink
            ref={buttonRef as React.Ref<HTMLAnchorElement>}
            className={({ isActive }) => `nav-item${isActive ? ' active' : ''}`}
            to={item.path}
            data-test={`sidebar-menu-item-${item.id}`}
        >
            {content}
        </NavLink>
        : <button
            ref={buttonRef as React.Ref<HTMLButtonElement>}
            className="nav-item"
            data-test={`sidebar-menu-item-${item.id}`}
        >
            {content}
        </button>
}

export default MenuItem
