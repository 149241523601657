import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ToastNotification } from '@/models'

const initialState: ToastNotification[] = []
export const toastNotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<ToastNotification>) => [...state, action.payload],
        remove: (state, action: PayloadAction<ToastNotification>) => state.filter(item => item.id !== action.payload.id)
    }
})
export const { add: showNotification, remove: hideNotification } = toastNotificationsSlice.actions

export default toastNotificationsSlice.reducer
