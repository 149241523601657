import { useEffect, useState } from 'react'

import { DoughnutChart, IconHelpCircle, Tabs, Tooltip } from '@/components'
import { api } from '@/services'

type SalesByContractorProps = {
    className?: string
    onChange: any
    processing: boolean
}

const EligibleCommissionSummary = ({ className, processing, ...props }: SalesByContractorProps) => {
    const [data, setData] = useState<any>()
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [period, setPeriod] = useState('monthly')

    const fetchData = async () => {
        const controller = new AbortController
        setAbortController(controller)
        try {
            const res = await api.dashboard.eligibleCommissionTracking({ activity_tracker: 'user.dashboard' }, { signal: controller.signal })
            setData(res.data)
        } finally {
            props.onChange('eligibleCommissionSummary')
        }
    }

    useEffect(() => {
        if (processing) fetchData()
    }, [processing])

    useEffect(() => () => abortController?.abort('canceled'), [abortController])

    const getCurrentQuarter = () => Math.floor((new Date().getMonth() / 3) + 1)
    const getTooltipLine1 = () => {
        if (period === 'quarterly') {
            const prevDate = new Date().subtract('quarter', 1)
            return `Compared to Q${Math.floor((prevDate.getMonth() / 3) + 1)} ${prevDate.getFullYear()}`
        }
        if (period === 'monthly') {
            const prevDate = new Date().subtract('month', 1)
            return `Compared to ${prevDate.format({ year: null, day: null })} ${prevDate.getFullYear()}`
        }
        if (period === 'yearly') {
            const prevDate = new Date().subtract('year', 1)
            return `Compared to ${prevDate.getFullYear()}`
        }
    }
    const getTooltipLine2 = () => {
        if (period === 'quarterly') {
            return `Quarter To Date: ${(data?.[period]?.current || 0).money()} `
        }
        if (period === 'monthly') {
            return `Month To Date: ${(data?.[period]?.current || 0).money()}`
        }
        if (period === 'yearly') {
            return `Year To Date: ${(data?.[period]?.current || 0).money()}`
        }
    }
    const getTooltipLine3 = () => {
        if (period === 'quarterly') {
            return `Q1 2023: ${(data?.[period]?.previous || 0).money()}`
        }
        if (period === 'monthly') {
            return `Jan 2023: ${(data?.[period]?.previous || 0).money()}`
        }
        if (period === 'yearly') {
            return `2022: ${(data?.[period]?.previous || 0).money()}`
        }
    }

    return <div className={className}>
        <div className="flex justify-between items-center text-gray-900">
            <h3 className="text-lg text-center">Eligible Commission Summary</h3>

            <Tooltip
                interactive
                color="white"
                backdrop
                content={<div className="max-w-[180px]">
                    <h5 className="text-gray-700 text-xs font-semibold">Eligible Commission Summary</h5>
                    <p className="text-gray-500 text-sm">
                        Eligible Commission refers to the commission earned on completed transactions where the
                        corresponding invoices have been paid in full.
                    </p>
                </div>}
            >
                <IconHelpCircle className="stroke-gray-400"/>
            </Tooltip>
        </div>
        <h4 className="text-gray-500">
            Q{getCurrentQuarter()} {new Date().format({ day: null })}
        </h4>

        <div className="relative my-16">
            <DoughnutChart
                datasets={[{
                    data: [data?.[period]?.percentage || 0, 100 - data?.[period]?.percentage || 0],
                    backgroundColor: ['#c5e6d6', '#edebe7'],
                    hoverBackgroundColor: ['#c5e6d6', '#edebe7'],
                    borderColor: 'transparent',
                    borderWidth: 0,
                    cutout: '70%',
                    circumference: 180,
                    rotation: -90
                }]}
                options={{ aspectRatio: 2 }}
                customTooltip={() => <div className="px-3 py-2 font-body bg-white shadow-md rounded-md">
                    <div className="absolute w-2 h-2 -left-1 top-1/2 -translate-y-1/2 bg-white rotate-45 origin-center"/>
                    <p className="text-xs text-gray-700 whitespace-nowrap font-semibold">{getTooltipLine1()}</p>
                    <p className="text-gray-500 text-sm whitespace-nowrap">{getTooltipLine2()}</p>
                    <p className="text-gray-500 text-sm whitespace-nowrap">{getTooltipLine3()}</p>
                </div>}
            />

            <div className="absolute  left-1/2 -translate-x-1/2 bottom-0 flex flex-col justify-center items-center">
                <span className="text-2xl text-center font-semibold">{data?.[period]?.percentage || 0}%</span>
                <span className="text-xs text-center text-gray-500 font-light">{(data?.[period]?.current || 0).money()}</span>
            </div>
        </div>

        <Tabs
            value={period}
            onChange={id => setPeriod(id as string)}
            design="buttons"
            className="border-t pt-4"
            tabs={[
                {
                    id: 'monthly',
                    title: 'By Month'
                },
                {
                    id: 'quarterly',
                    title: 'By Quarter'
                },
                {
                    id: 'yearly',
                    title: 'By Year'
                }
            ]}
        />
    </div>
}

export default EligibleCommissionSummary
