import { Badge, Button, IconBell, IconCertificate } from '@/components'
import { useAnalytics } from '@/hooks'

const StepConfirmation = (props: { onNext: any }) => {
    const { trackEvent } = useAnalytics()

    const handleHoverNext = () => {
        trackEvent('hover_bulk_upload_step_3_close', 'User Interaction', 'Bulk Upload Step 3 Hover')
    }

    const handleClickNext = () => {
        props.onNext()
        trackEvent('click_bulk_upload_step_3_close', 'User Interaction', 'Bulk Upload Step 3 Close Button Click')
    }

    return <>
        <div className="flex flex-col items-center mt-9 mx-auto max-w-[49rem] font-body">
            <h3 className="font-semibold text-sm flex items-center justify-center text-center mr-2">
                <div className="pr-4 block">
                    <Badge className="badge-circle bg-success-50">
                        <div className="rounded-full bg-success-100 w-8 h-8 flex justify-center items-center">
                            <IconCertificate className="stroke-success-700 h-5 w-5"/>
                        </div>
                    </Badge>
                </div>
                Successfully Queued Bulk Upload!
            </h3>
            <p className="text-sm text-center mt-4">
                We have received your request to upload new warranties. We will notify you as soon as our system has
                processed them.
            </p>
            <p className="text-sm text-center mt-2">
                You may close this page, check the notifications button <Button size="btn-sm" className="mx-1 w-8 h-8 !p-0 !inline-block" design="btn-secondary-gray"><IconBell className="stroke-gray-700 w-5 h-5"/></Button> for upload status.
            </p>
        </div>
        <div className="flex justify-end items-center gap-3 mt-6">
            <Button onClick={handleClickNext} onMouseOver={handleHoverNext} className="w-28">
                Close
            </Button>
        </div>
    </>
}

export default StepConfirmation
