import Model from './Model'
import { Badge } from '@/components'
import Company from '@/models/Company'
import { api, Options, Params, Payload } from '@/services'

const accountTypes = [
    { value: 'advisor', title: 'Advisor', type: 'user' },
    { value: 'administrator', title: 'Administrator', type: 'user' },
    { value: 'staff', title: 'Staff', type: 'user' },

    { value: 'contractor', title: 'Contractor Company', shortTitle: 'Contractor', type: 'company' },
    { value: 'contractor-user', title: 'Contractor User', type: 'company-user' },

    { value: 'software_affiliate', title: 'Software Affiliate Company', shortTitle: 'Software Affiliate', type: 'company' },
    { value: 'lender_affiliate', title: 'Lender Affiliate Company', shortTitle: 'Lender Affiliate', type: 'company' },

    { value: 'affiliate-user', title: 'Affiliate User', type: 'company-user' }
]

export default class User extends Model {
    static endpoint = 'api/v1/users'

    name: string

    role: 'Administrator' | 'administrator' | 'Conglomerate' | 'conglomerate' | 'Contractor' | 'contractor' | 'Staff' | 'staff' | 'Advisor' | 'advisor'

    preferred_features: string[]

    company: Company

    impersonateEmail: string = import.meta.env.VITE_IMPERSONATION_EMAIL

    constructor(props: any = {}) {
        super(props)
        this.name = props.name?.capitalize()
        this.role = props.role?.toLowerCase()
        this.preferred_features = props.preferred_features || []
        if (props.company) {
            this.company = new Company(props.company)
        } else if (this.isAdminOrStaffOrAdvisor) {
            this.company = new Company({ name: 'Solar Insure' })
        } else {
            this.company = new Company({})
        }
    }

    static updatePreferencesSelf(payload: any) {
        return api.auth.updatePreferences(payload)
    }

    updatePreferences(payload: any) {
        return api.users.updatePreferences(this.id, payload)
    }

    activities() {
        return api.users.activities(this.id)
    }

    static updatePasswordSelf(payload: any) {
        return api.auth.updatePassword(payload)
    }

    updatePassword(payload: any) {
        return api.users.updatePassword(this.id, payload)
    }

    toggleVerification() {
        return api.users.toggleVerification(this.id)
    }

    static impersonate(companyId: number, password: string) {
        return api.users.impersonate(companyId, password)
    }

    static updateSelf(payload: Payload, params?: Params, options?: Options) {
        return api.http.put(User.endpoint, payload, params, options)
    }

    get isAdmin() {
        return ['Administrator', 'administrator'].includes(this.role)
    }

    get isAffiliate() {
        return ['Conglomerate', 'conglomerate'].includes(this.role) || this.isSoftwareAffiliate || this.isLenderAffiliate
    }

    get isSoftwareAffiliate() {
        return this.company?.isSoftwareAffiliate || ['Software Affiliate', 'software_affiliate'].includes(this.role)
    }

    get isLenderAffiliate() {
        return this.company?.isLenderAffiliate || ['Lender Affiliate', 'lender_affiliate'].includes(this.role)
    }

    get isAllowedToCreateWarranty() {
        return !this.company.monitoringStatus.isIncomplete &&
            (this.company.unique_id?.startsWith('001') && this.company.unique_id?.length === 18) &&
            [this.company.battery_20_year_rate, this.company.battery_30_year_rate]
                .every(rate => rate && rate !== 0)
    }

    get isSubsidiary() {
        return this.company?.isSubsidiary
    }

    get isContractor() {
        return ['Contractor', 'contractor'].includes(this.role)
    }

    get isStaff() {
        return ['Staff', 'staff'].includes(this.role)
    }

    get isAdvisor() {
        return ['Advisor', 'advisor'].includes(this.role)
    }

    get isAdminOrStaff() {
        return this.isAdmin || this.isStaff
    }

    get isAdminOrStaffOrAdvisor() {
        return this.isAdmin || this.isStaff || this.isAdvisor
    }

    get isAffiliateOrContractor() {
        return this.isAffiliate || this.isContractor
    }

    static get allAccountTypes() {
        return accountTypes
    }

    static get userAccountTypes() {
        return accountTypes.filter(({ type }) => type === 'user')
    }

    static get companyAccountTypes() {
        return accountTypes.filter(({ type }) => type === 'company')
    }

    static get companyUserAccountTypes() {
        return accountTypes.filter(({ type }) => type === 'company-user')
    }

    get accountType() {
        let type = { title: 'Unknown' }

        if (this.isAdminOrStaffOrAdvisor) {
            type = accountTypes.find(({ value }) => value === this.role) || type
        }
        if (this.isAffiliateOrContractor) {
            type = accountTypes.find(({ value }) => value === this.company?.type?.key) || type
        }
        return type.title
    }

    get preferredFeaturesBadges() {
        return this.preferred_features.map((item: string) => {
            switch (item) {
                case 'Monitoring': {
                    return <Badge key={item} className="text-purple-700 bg-purple-50">{item}</Badge>
                }
                case 'Invoicing': {
                    return <Badge key={item} className="text-success-700 bg-success-50">{item}</Badge>
                }
                default: {
                    return null
                }
            }
        })
    }
}
