export type WarningsType = string | string[]

type InputWarningProps = {
    errors: WarningsType
}

export type FormWarningsType = {
    [key: string]: WarningsType
}

export const InputWarning = ({ errors, ...props }: InputWarningProps) =>
    <>
        {typeof errors === 'string'
            ? <div className="text-warning-700 text-sm mt-1.5" {...props}>{errors}</div>
            : errors.map((item: string, index: number) =>
                <div className="text-warning-700 text-sm mt-1.5" {...props} key={index}>{item}</div>)}
    </>
