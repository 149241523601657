import classnames from 'classnames'
import React, { ReactEventHandler, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Checkbox, Tooltip } from '@/components'
import { ColumnProps } from '@/components/table/Thead'

export type RowProps = {
    id: string | number
    [key: string]: ReactNode | Date | ((event: React.MouseEvent<HTMLElement>) => void)
    _page?: string | number
    _className?: string
    _tooltip?: ReactNode
    _path?: string
    _group?: number
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

type TbodyProps = {
    columns: Array<ColumnProps>
    rows: Array<RowProps>
    selectedRows: Array<{ id: string | number }> | 'all'
    excludedRows: Array<any>
    selectable?: boolean
    onChange: ReactEventHandler
    onRowClick?: (row: RowProps) => void
    'data-test'?: string
}

const Tbody = ({ columns, rows, selectedRows, excludedRows, selectable, 'data-test': dataTest, ...props }: TbodyProps) => {
    const handleRowClick = (row: RowProps) => {
        if (props.onRowClick) {
            props.onRowClick(row)
        }
    }

    const getIsSelected = (row: { id: string | number }) => selectedRows === 'all'
        ? excludedRows.every(item => item.id !== row.id)
        : selectedRows.some(item => item.id === row.id)

    return <tbody>
        {rows.map((row, index) =>
            <tr key={index} data-page={row._page} data-id={row.id} data-test={`${dataTest}-row-${row.id}`}>
                {selectable && <td data-test={`${dataTest}-row-${row.id}-checkbox`}>
                    <div className="cell !pr-1.5">
                        <Checkbox
                            name="row"
                            value={row.id}
                            checked={getIsSelected(row)}
                            onChange={props.onChange}
                        />
                    </div>
                </td>}
                {columns.map(({
                    field,
                    clickable = true,
                    showIf = true,
                    sticky,
                    ...column
                }) => {
                    if (!showIf) return null
                    const content = row[field as keyof typeof row]
                    if (typeof content === 'function' || content instanceof Date) {
                        return <td key={`${field}-${index}`}/>
                    }

                    const className = classnames(row._className, { 'sticky-column': sticky })
                    const cellClassName = classnames('cell', typeof column.cellClassName === 'function' ? column.cellClassName(row) : column.cellClassName, {
                        '!pl-1.5': selectable,
                        'cursor-pointer w-full h-full': row.onClick
                    })
                    const cell = clickable && (row._path || row.onClick)
                        ? (row._path && <Link
                            onClick={() => handleRowClick(row)}
                            className={cellClassName}
                            to={row._path}
                        >
                            {content}
                        </Link>) ||
                        (row.onClick && <button
                            onClick={row.onClick}
                            className={cellClassName}
                        >
                            {content}
                        </button>)
                        : <div className={cellClassName}>{content}</div>
                    return <td key={`${field}-${index}`} className={className} data-test={`${dataTest}-row-${row.id}-${field}`}>
                        {row._tooltip ? <Tooltip content={row._tooltip}>{cell}</Tooltip> : cell}
                    </td>
                })}
            </tr>)}
    </tbody>
}

export default Tbody
