import * as Sentry from '@sentry/react'
import { useEffect, useRef } from 'react'

import MenuItem from './MenuItem'
import { IconAnnouncement01 } from '@/components'

const BugReport = () => {
    const buttonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const element = buttonRef.current

        if (element && !element.classList.contains('sentry-feedback-attached')) {
            const feedback = Sentry.feedbackIntegration({
                colorScheme: 'system',
                isNameRequired: true,
                isEmailRequired: true,
                showBranding: false,
                useSentryUser: {
                    email: 'email',
                    name: 'username'
                },
                isRequiredLabel: '*'
            })
            feedback.attachTo(element, { formTitle: 'Report a Bug!' })
            element.classList.add('sentry-feedback-attached')
        }
    }, [])

    return <MenuItem
        buttonRef={buttonRef}
        item={{
            icon: <IconAnnouncement01/>,
            id: 'bug-report',
            title: 'Report a Bug'
        }}/>
}

export default BugReport
