import { Card, WarrantyDetailsList } from '@/components'
import { Warranty, WarrantyProductType } from '@/models'

type WarrantyDetailsProps = {
    warranty: Warranty
    duplicate?: Warranty
}

export const WarrantyDetails = ({ warranty, duplicate }: WarrantyDetailsProps) => {
    const homeownerFields = [
        {
            key: 'full_name',
            value: warranty.homeowner?.fullName,
            compareAtValue: duplicate?.homeowner?.fullName
        },
        {
            key: 'company',
            value: warranty.homeowner?.company?.name,
            compareAtValue: duplicate?.homeowner?.company?.name
        },
        {
            key: 'policy_num',
            value: warranty.policy_num,
            compareAtValue: duplicate?.policy_num
        },
        {
            key: 'created_at',
            value: warranty.homeowner?.created_at,
            compareAtValue: duplicate?.homeowner?.created_at
        },
        {
            key: 'end_date',
            value: warranty.end_date,
            compareAtValue: duplicate?.end_date
        },
        {
            key: 'price',
            value: warranty.price,
            compareAtValue: duplicate?.price
        },
        {
            key: 'solar_price',
            title: `${WarrantyProductType.solar.secondaryTitle} Price`,
            icon: WarrantyProductType.solar.icon(),
            value: warranty.solar_price,
            compareAtValue: duplicate?.solar_price,
            showIf: !!warranty.solar_price || !!duplicate?.solar_price
        },
        {
            key: 'battery_price',
            title: `${warranty.productType.secondaryTitle} Price`,
            icon: warranty.productType.icon(),
            value: warranty.battery_price,
            compareAtValue: duplicate?.battery_price,
            showIf: !!warranty.battery_price || !!duplicate?.battery_price
        },
        {
            key: 'phone',
            value: warranty.homeowner?.phone,
            compareAtValue: duplicate?.homeowner?.phone
        },
        {
            key: 'email',
            value: warranty.homeowner?.email,
            compareAtValue: duplicate?.homeowner?.email
        }
    ]

    const systemFields = [
        {
            key: 'status',
            value: warranty.status,
            compareAtValue: duplicate?.status
        },
        {
            key: 'install_date',
            value: warranty.homeowner?.install_date,
            compareAtValue: duplicate?.homeowner?.install_date
        },
        {
            key: 'address',
            value: warranty.homeowner?.address,
            compareAtValue: duplicate?.homeowner?.address
        },
        {
            key: 'panel_name',
            value: warranty.panel?.model_name,
            compareAtValue: duplicate?.panel?.model_name
        },
        {
            key: 'inverter_name',
            value: warranty.inverter?.model_name,
            compareAtValue: duplicate?.inverter?.model_name
        },
        {
            key: 'num_panels',
            value: warranty.num_panels,
            compareAtValue: duplicate?.num_panels
        },
        {
            key: 'size_kw',
            value: warranty.size_kw,
            compareAtValue: duplicate?.size_kw
        },
        {
            key: 'system_id',
            value: warranty.homeowner?.system_id,
            compareAtValue: duplicate?.homeowner?.system_id
        },
        {
            key: 'batteries',
            value: warranty.batteries,
            compareAtValue: duplicate?.batteries,
            showIf: !!warranty.batteries.length || !!duplicate?.batteries?.length
        }
    ]

    return <>
        <Card>
            <h4 className="card-title mb-6 !text-sm" data-test="warranty-details-homeowner-information-title">Homeowner Information</h4>

            <WarrantyDetailsList fields={homeownerFields} compare={!!duplicate}/>
        </Card>

        <Card>
            <h4 className="card-title mb-6 !text-sm" data-test="warranty-details-system-information-title">System Information</h4>

            <WarrantyDetailsList fields={systemFields} compare={!!duplicate}/>
        </Card>
    </>
}
