import classnames from 'classnames'
import { IframeHTMLAttributes } from 'react'

interface PdfViewerProps extends IframeHTMLAttributes<HTMLIFrameElement> {
    toolbar?: boolean
}

export const PdfViewer = ({
    toolbar = true,
    src,
    className,
    ...props
}: PdfViewerProps) => {
    const isFirefox = navigator.userAgent.toLowerCase()
        .includes('firefox')

    return <div className={classnames('pdf-viewer-container', { 'firefox-toolbar-hidden': isFirefox && !toolbar })}>
        <iframe
            {...props}
            src={`${src}#toolbar=${toolbar ? 1 : 0}`}
            title={props.title}
            className={className}
        />
    </div>
}
