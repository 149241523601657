import { FormChangeEventHandler, FormDataType, FormErrorsType, Upload } from '@/components'
export interface DocumentsFormDataType extends FormDataType {
    contracts?: File[]
}

type DocumentsFormProps = {
    form: DocumentsFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
}

export const DocumentsForm = ({
    form,
    inputClassName,
    errors,
    ...props
}: DocumentsFormProps) =>
    <>
        <Upload
            multiple
            value={form.contracts}
            onChange={props.onChange}
            name="contracts"
            accept="application/pdf"
            className={inputClassName}
            errors={errors.contracts}
        >
            <div className="mt-3 text-center">
                {form.contracts?.length
                    ? <p className="text-sm">{form.contracts.map((item: File, index: number) =>
                        <span key={index}>{item.name}</span>)}</p>
                    : <>
                        <p className="text-sm">
                            <span className="text-orange-700">Click to upload</span> or drag and drop</p>
                        <p className="text-xs mt-1">PDF (max. 10mb)</p>
                    </>}
            </div>
        </Upload>
    </>
