import { useRouteError } from 'react-router-dom'

import Error404 from './Error404'
import { AuthLayout, GuestLayout } from '@/containers'
import { useAuth } from '@/hooks'

const Error = () => {
    const error: any = useRouteError()
    const auth = useAuth()
    if (error.status === 404 || error.errors === 'Not found!') {
        return <Error404/>
    }

    return auth.user?.isAuthenticated ? <AuthLayout heading={<h1 className="text-3xl font-display">Oops, something went wrong!</h1>}>
        <h2 className="text-xl font-semibold mb-4">{error.message}</h2>
        <pre>
            {error.stack}
        </pre>
    </AuthLayout> : <GuestLayout>
        <h2 className="text-xl font-semibold mb-4">{error.message}</h2>
        <pre>
            {error.stack}
        </pre>
    </GuestLayout>
}
export default Error
