export enum CompanyAccountActivityStatusEnum {
    ACTIVE = 'active',
    ONBOARDING = 'onboarding',
    NOTICE_TO_SUSPEND = 'notice_to_suspend',
    CERTIFICATION = 'certification',
    SUSPENDED = 'suspended',
    INSOLVENT = 'insolvent',
    INACTIVE = 'inactive',
    SERVICE_PARTNER = 'service_partner',
    AFFILIATE = 'affiliate',
    TERMINATED = 'terminated'
}

export const COMPANY_ACCOUNT_ACTIVITY_STATUSES: {
    key: CompanyAccountActivityStatusEnum
    title: string
    color: string
    background: string
}[] = [
    {
        key: CompanyAccountActivityStatusEnum.ACTIVE,
        title: 'Active',
        color: 'text-success-800',
        background: 'bg-success-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.ONBOARDING,
        title: 'Onboarding',
        color: 'text-blue-800',
        background: 'bg-blue-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.NOTICE_TO_SUSPEND,
        title: 'Notice to Suspend',
        color: 'text-orange-800',
        background: 'bg-warning-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.CERTIFICATION,
        title: 'Certification',
        color: 'text-teal-800',
        background: 'bg-teal-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.SUSPENDED,
        title: 'Suspended',
        color: 'text-error-800',
        background: 'bg-error-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.INSOLVENT,
        title: 'Insolvent',
        color: 'text-gray-800',
        background: 'bg-gray-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.INACTIVE,
        title: 'Inactive',
        color: 'text-purple-800',
        background: 'bg-purple-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.SERVICE_PARTNER,
        title: 'Service Partner',
        color: 'text-pink-800',
        background: 'bg-pink-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.AFFILIATE,
        title: 'Affiliate',
        color: 'text-indigo-800',
        background: 'bg-indigo-100'
    },
    {
        key: CompanyAccountActivityStatusEnum.TERMINATED,
        title: 'Terminated',
        color: 'text-error-25',
        background: 'bg-error-800'
    }
]
