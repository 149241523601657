import { Link } from 'react-router-dom'

import { Card } from '@/components'
import Logo from '@/components/Logo'

const Index = () =>
    <div className="policy bg-orange-500 p-5">
        <Link to="/" className="intro-y flex items-center pt-5 justify-center pb-5" data-test="terms-of-service-home-link">
            <Logo type="primaryWhite" className="w-60" data-test="terms-of-service-logo"/>
        </Link>

        <Card className="max-w-4xl mx-auto" data-test="terms-of-service-content">
            <h1>Terms and Conditions</h1>
            <p><strong>Last updated:</strong> November 26, 2021</p>
            <p>
                This website, <a href="https://www.solarinsure.com">www.solarinsure.com</a>, is owned and operated by
                Solar Insure, Inc. (“<strong>Solar Insure</strong>”, “<strong>we</strong>” “<strong>our</strong>”, or
                “<strong>us</strong>”). These Terms of Use (“<strong>Terms</strong>”) apply to any Solar Insure Web
                site or application (as well as any features, widgets, plug-ins, content, downloads or other services)
                that posts a link to this page (collectively, the “<strong>Site</strong>”). By accessing the Site, you
                (“<strong>you</strong>” or “<strong>your</strong>”) acknowledge and consent to: (i) these Terms, (ii)
                our collection, use and sharing of your information as described in
                our <a href="/privacy-policy">Privacy Policy</a>, (iii) any other legal notices, terms, conditions or
                guidelines located within the Site. If you do not agree to these Terms, our Privacy Policy, or any other
                legal notices, conditions or guidelines located within the Site, please exit our Site.
            </p>
            <p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SITE. THEY APPLY TO ALL USERS OF THE SITE.</p>
            <h1>1. Interpretation and Definitions</h1>
            <h2>1.1 Interpretation</h2>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </p>
            <h2>1.2 Definitions</h2>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
                <li>
                    <p>
                        <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                        control with a party, where &quot;control&quot; means ownership of 50% or more of the shares,
                        equity interest or other securities entitled to vote for election of directors or other managing
                        authority.
                    </p>
                </li>
                <li>
                    <p><strong>Country</strong> refers to: California, United States</p>
                </li>
                <li>
                    <p>
                        <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                        &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Solar Insure, Inc., 555 Anton
                        Blvd #150 Costa Mesa CA 92626.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Device</strong> means any device that can access the Service such as a computer, a
                        cellphone or a digital tablet.
                    </p>
                </li>
                <li>
                    <p><strong>Service</strong> refers to the Website.</p>
                </li>
                <li>
                    <p>
                        <strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these Terms and
                        Conditions that form the entire agreement between You and the Company regarding the use of the
                        Service. This Terms and Conditions agreement has been created with the help of
                        the <a href="https://www.termsfeed.com/terms-conditions-generator/">Terms and Conditions
                        Generator</a>.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Third-party Social Media Service</strong> means any services or content (including data,
                        information, products or services) provided by a third-party that may be displayed, included or
                        made available by the Service.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Website</strong> refers to Solar Insure, accessible
                        from <a href="https://www.solarinsure.com/">https://www.solarinsure.com/</a>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>You</strong> means the individual accessing or using the Service, or the company, or
                        other legal entity on behalf of which such individual is accessing or using the Service, as
                        applicable.
                    </p>
                </li>
            </ul>
            <h1>2. Changing Your Information</h1>
            <p>
                If applicable, you are responsible for ensuring that information you provide in connection with any
                account you create using the Site is accurate and up to date. You may change or edit your information by
                logging into your account.
            </p>
            <h1>3. Provision of Site</h1>
            <p>
                You acknowledge and agree that Solar Insure is entitled to modify, improve or discontinue any Site at
                its discretion and without notice to you even if it may result in you being prevented from accessing any
                information contained in it. Furthermore, you acknowledge and agree that Solar Insure is entitled to
                provide the Site or services to you through subsidiaries, affiliated entities, or third-party service
                providers.
            </p>
            <h1>4. Ownership of Site Content</h1>
            <p>
                Unless otherwise noted, all photographs, pictures, graphics, and all other images, including digital,
                printed and video images, and portions thereof, and all content, data, information, news, and all other
                text and materials, including digital, printed and audio materials, along with the layout and design of
                the Site and all documentation, data, services, features, products and other content on the Site
                (collectively, “<strong>Site Content</strong>”) are protected by the copyright laws of the United States
                and other jurisdictions. All Site Content is owned by, or licensed to, Solar Insure. Site Content may
                not be reproduced, modified, redistributed or otherwise used in any way without a written license with
                the applicable Site Content owner.
            </p>
            <h1>5. Use of Site Content</h1>
            <p>
                Subject to your continued compliance with these Terms, and any other terms and conditions that Solar
                Insure may provide to you from time to time, Solar Insure grants you a personal, limited, non-exclusive,
                non-assignable, non-transferable, non-sublicenseable, revocable license to access and display on your
                personal computer or mobile device for your own use, but not copy or transfer or broadcast or otherwise
                use in any way, the Site Content found at the Site for your personal, non-commercial and educational use
                only. You further agree not to change or delete any proprietary notices from materials downloaded from
                the Site.</p>
            <h1>6. Reservation of Rights</h1>
            <p>
                All rights not expressly granted by Solar Insure herein are reserved. Nothing on the Site or in these
                Terms grants, expressly or implicitly, by estoppel or otherwise, any right or license to use any Site
                Content or other materials of any third party, or may be construed to mean that Solar Insure has the
                authority to grant any license on behalf of any third party.
            </p>
            <h1>7. Solar Insure Trademarks</h1>
            <p>
                Solar Insure trademarks, the logo, all page headers, graphics, images, symbols, trade names and other
                Solar Insure marks found at the Site are the proprietary property of Solar Insure, Inc., its affiliates,
                and subsidiaries (collectively the “<strong>Marks</strong>”). Use of the Marks is strictly prohibited
                without the prior written consent of Solar Insure.
            </p>
            <h1>8. Third Party Trademarks</h1>
            <p>
                The trademarks of third parties may also appear on the Site from time to time; you may not use these
                trademarks without prior written permission of their respective owners. You acknowledge and agree that
                nothing in connection with the Site grants, expressly or implicitly, by estoppel or otherwise, any right
                or license to use any of the these trademarks, nor may anything be construed to mean that Solar Insure
                has authority to grant any right or license on behalf of any third-party trademark owner.
            </p>
            <h1>9. Restrictions, Limitations and Conditions of Use</h1>
            <p>
                Unauthorized use of our Site Content is prohibited. You will use the Site in accordance with these
                Terms. You may not use the Site or any services provided on the Site for any unlawful purpose and you
                may not:
            </p>
            <ul>
                <li>
                    <p>
                        access, solicit, collect or use any personally identifying information about any other users of
                        the Site or anyone else;
                    </p>
                </li>
                <li>
                    <p>restrict or inhibit others from using the Site;</p>
                </li>
                <li>
                    <p>
                        transmit to or introduce at the Site any viruses, harmful software, or other modifications or
                        changes to the Site or reverse engineer, decompile, disassemble, reverse assemble or modify any
                        Site source or object code or any software or other services or processes accessible through any
                        portion of the Site or Site Content (to the extent such restriction is permitted under
                        applicable law);
                    </p>
                </li>
                <li>
                    <p>
                        submit, upload, email or otherwise transmit any content or other materials, to or through the
                        Site that (a) is threatening, abusive, defamatory, obscene, vulgar, offensive, or otherwise
                        objectionable, or which may harm minors; (b) infringes or violates the rights of others, or (c)
                        has an advertising, political marketing, promotional or other commercial purpose;
                    </p>
                </li>
                <li>
                    <p>
                        engage in any activities that interfere with another user’s access to the Site or the proper
                        operation of the Site; or
                    </p>
                </li>
                <li>
                    <p>
                        distribute or copy any Site Content manually or by using any robot, scraper, crawler, or any
                        other automatic device or process; frame or utilize framing techniques to enclose any Site
                        Content; or use Site Content in a manner that suggests an unauthorized association with any of
                        our or our licensor’s products, services or brands.
                    </p>
                </li>
            </ul>
            <p>
                Solar Insure has no obligation to monitor your interaction with the Site, but reserves the right to
                review or monitor the Site and any user submissions in its sole discretion.
            </p>
            <h1>10. Links to Other Web sites</h1>
            <p>
                Our Site may contain links to third-party Web sites or services that are not owned or controlled by
                Solar Insure. Solar Insure has no control over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third party Web sites or services. You further acknowledge and agree that
                Solar Insure shall not be responsible or liable, directly or indirectly, for any damage or loss caused
                (or alleged to be caused) by or in connection with the use of or reliance on any such content, goods or
                services available on or through any such Web sites or services. We strongly advise you to read the
                terms and conditions and privacy policies of any third-party Web sites or services that you visit.
            </p>
            <h1>11. Accounts and Password</h1>
            <p>
                Certain areas of the Site may be password-protected. You may not attempt to access any
                password-protected areas of the Site without authorization. You are solely responsible for the activity
                that occurs on your account, and you are responsible for keeping your account password secure.<br/>You
                are solely responsible for maintaining the confidentiality of your passwords, account information, user
                names, and/or any security questions and answers that may be required for the Site. Accordingly, you
                should protect the confidentiality of your password and notify us immediately if you become aware of its
                unauthorized use. If you share your password with a third party, you assume all responsibility for all
                such party’s activity related to the Site. Although Solar Insure will not be liable for your losses
                caused by any unauthorized use of your account, you may be liable for the losses incurred by us or
                others due to such unauthorized use.
            </p>
            <h1>12. Termination</h1>
            <p>
                We may terminate or suspend your access to the Site immediately, without prior notice or liability, for
                any reason whatsoever, including without limitation if you breach these Terms.
            </p>
            <h1>13. Limitation of Liability</h1>
            <p>
                <strong>
                    The materials, Site Content and services offered on the Site are provided “AS IS” and without
                    warranties or
                </strong>
                <strong>
                    conditions of any kind. To the fullest extent permissible pursuant to applicable law, we disclaim
                    all warranties,
                </strong>
                <strong>
                    conditions and other terms, express, implied, statutory or otherwise, including, but not limited to,
                    implied
                </strong>
                <strong>
                    warranties of non-infringement, merchantability and fitness for a particular purpose. We do not
                    warrant that the
                </strong>
                <strong>
                    use of the Site will be uninterrupted or error-free, that defects will be corrected, or that the
                    Site or the server(s)
                </strong>
                <strong>
                    that make it available or any advertised or hyperlinked site are free of viruses or other harmful
                    components,
                </strong>
                <strong>
                    or that the Site, server(s), or advertised or Linked Sites will be accessible at all times. We do
                    not warrant or
                </strong>
                <strong>
                    make any representations, warranties or covenants regarding the use of the Site or any information
                    contained
                </strong>
                <strong>
                    therein, including Site Content, with respect to correctness, accuracy, reliability, graphics, links
                    or otherwise.
                </strong>
                <strong>
                    You assume the entire cost of all necessary servicing, repair or correction to any equipment you use
                    to access the Site.
                </strong>
                <strong>
                    To the extent that applicable law may not allow the exclusion of implied warranties or conditions,
                    the above exclusions may not apply to you.
                </strong>
            </p>
            <p>
                Documents, information, graphics and other materials appearing on the Site may include technical
                inaccuracies, miscalculations, typographical errors, and out-of-date information. Use of such documents,
                information, graphics or other materials is at your own risk.
            </p>
            <p>
                <strong>
                    You waive any and all claims and rights against us and our affiliates, parents, and successors and
                    each of our
                </strong>
                <strong>
                    employees, assignees, officers, agents and directors (the “Solar Insure Parties”) resulting from
                    injury or damage
                </strong>
                <strong>
                    to, or destruction, theft, or loss of, any property, or person, to the maximum extent permitted by
                    the applicable
                </strong>
                <strong>
                    laws of your jurisdiction of residence. None of the Solar Insure Parties will be liable to you under
                    any cause of
                </strong>
                <strong>
                    action, for any direct, indirect, special, incidental, consequential, reliance or punitive damages,
                    including loss
                </strong>
                <strong>
                    of profits or business interruption. You hereby agree to indemnify the Solar Insure Parties from and
                    against
                </strong>
                <strong>
                    any and all claims, liabilities, and expenses (including reasonable attorneys’ fees), resulting from
                    your use of the Site or any breach of these Terms by you.
                </strong>
                <em><em>In the event that a court finds the above disclaimer of direct damages to be unenforceable, in
                    no event will Solar Insure’s total aggregate liability for all damages, including without
                    limitation all claims, losses, liabilities,</em> costs and expenses (including legal fees and
                    expenses) to you related to the Site or Site Content, or these Terms, exceed the lesser of (a) the
                    direct damages suffered by you, or (b) $100.</em>* <strong>Certain jurisdictions have heightened
                consumer protection laws that may make certain portions of these Terms inapplicable to you. The
                provisions in these Terms are intended to be only as broad and inclusive as is permitted by the laws of
                your jurisdiction. No provision of these Terms shall limit or waive your rights as a consumer under the
                law of your state of residence. In any event, Solar Insure reserves all rights, defenses and permissible
                limitations under the law of your state or country of residence.</strong>
            </p>
            <h1>14. Ability to Accept Terms</h1>
            <p>
                You affirm that you are the age of majority in your jurisdiction and are fully able and competent to
                enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth
                in these Terms, and to abide by and comply with these Terms. The Site is not intended for children under
                the age of eighteen (18) and Solar Insure has no intention of collecting any personal data from
                individual users under 13 years of age without express parental consent. If you are under 18 years
                of age, then please exit now and do not use this Site.
            </p>
            <h1>15. Governing Law</h1>
            <p>
                The laws of the State of California govern these Terms. Any dispute relating to these Terms or your use
                of the Site shall be resolved solely in the state or federal courts located in Orange County,
                California. You agree to waive trial by jury in any such action.
            </p>
            <h1>16. Disputes Resolution</h1>
            <p>
                If you have any concern or dispute about the Site, you agree to first attempt to resolve the dispute
                informally by contacting Solar Insure.
            </p>
            <h1>17. Geographic Limitations</h1>
            <p>
                Solar Insure controls and operates the Site in the United States. If you use our Site from other
                locations, you are doing so on your own initiative and are responsible for compliance with applicable
                local laws regarding your online conduct, if and to the extent local laws apply. The Site may describe
                products and services that are available only in certain jurisdictions (or only parts of them). We
                reserve the right to limit the availability of our Site and/or the provision of any Site Content,
                program, product, service, or other feature described or available on our Site to any person, entity,
                geographic area, or jurisdiction, at any time and in our sole discretion, and to limit the quantities of
                any Site Content, program, product, service, or other feature that we provide. Further information on
                your rights regarding personal information collected on the Site are set forth in
                our <a href="/privacy-policy">Privacy Policy</a>
            </p>
            <h1>18. Severability and Waiver</h1>
            <h2>18.1 Severability</h2>
            <p>
                If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed
                and interpreted to accomplish the objectives of such provision to the greatest extent possible under
                applicable law and the remaining provisions will continue in full force and effect.
            </p>
            <h2>18.2 Waiver</h2>
            <p>
                Except as provided herein, the failure to exercise a right or to require performance of an obligation
                under these Terms shall not effect a party’s ability to exercise such right or require such performance
                at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
            </p>
            <h1>19. User Suggestions</h1>
            <p>
                You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other
                information provided by you to Solar Insure through this Site are not confidential and you grant us a
                worldwide, royalty-free license to distribute, publish, modify, edit or otherwise use your submissions.
                Solar Insure shall be entitled to the unrestricted use and dissemination of these submissions for any
                purpose, commercial or otherwise without any acknowledgement of or compensation to you.
            </p>
            <h1>20. General</h1>
            <p>
                We may assign, novate or subcontract any or all of our rights and obligations under these Terms at any
                time.
            </p>
            <h1>21. Changes to These Terms</h1>
            <p>
                We may revise these Terms at any time, with or without notice, by updating this page or notifying you of
                the changes, and such revisions will be effective upon posting to this page. Please check these Terms
                periodically for any changes. Your continued use of the Site following the posting of any revisions to
                these Terms will mean you accept those changes. We reserve the right to alter, suspend or discontinue
                any aspect of the Site, including your access to it. Unless explicitly stated, any new features will be
                subject to these Terms.
            </p>
            <h1>22. Contact Us</h1>
            <p>
                If you have any questions regarding our policies or your use of our Site, please contact us by e-mail
                at <a href="mailto:admin@solarinsure.com">admin@solarinsure.com</a> or by mail to: Solar Insure,
                Inc., 555 Anton Blvd #150 Costa Mesa CA 92626.
            </p>
        </Card>
    </div>

export default Index
