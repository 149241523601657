import { useState } from 'react'

import { Popup, IconPlay, Button } from '@/components'

const IntegrationTutorialPopup = () => {
    const [showPopup, setShowPopup] = useState(false)

    return <>
        <span role="button" tabIndex={-1}>
            <Button className="flex gap-1" design="btn-secondary-gray" onClick={() => setShowPopup(true)} data-test="integration-tutorial-button">
                <IconPlay className="stroke-primary-700"/>
                Watch Integration Tutorial
            </Button>
        </span>
        <Popup
            open={showPopup}
            onClose={() => setShowPopup(false)}
            className="integration-tutorial-popup min-w-[90vw] lg:min-w-[718px]"
        >
            <div className="w-full h-full relative pt-[56.25%]">
                <iframe
                    src="https://drive.google.com/file/d/13uzVZvaYNf5rbsiLDD3g_EARzzUlac3l/preview"
                    allow="autoplay; fullscreen; picture-in-picture"
                    className="absolute top-0 left-0 w-full h-full rounded"
                    allowFullScreen
                    title="Integration Tutorial Video"
                >
                </iframe>
            </div>
        </Popup></>
}

export default IntegrationTutorialPopup
