export default class Field {
    key: string

    title: string

    _format: 'string' | 'date' | 'datetime' | 'number' | 'money' | 'phone' | ((value: any) => string)

    constructor(props: any) {
        this.key = props.key
        this.title = props.title
        this._format = props.format || 'string'
    }

    get isDefault() {
        return this.key === Field.defaultField.key
    }

    static get defaultField() {
        return new Field({
            key: 'default',
            title: 'Default'
        })
    }

    static formatters = {
        string(value: any) {
            return value ? value.toString() : ''
        },
        float(value: any) {
            return value ? parseFloat(value).format({ minimumFractionDigits: 2 }) : ''
        },
        number(value: any) {
            return value ? parseFloat(value).format() : ''
        },
        money(value: any) {
            return value ? parseFloat(value).money({ minimumFractionDigits: 2 }) : ''
        },
        phone(value: any) {
            return value ? value.replace(/\D/g, '') : ''
        },
        date(value: any) {
            return value ? new Date(value).format() : ''
        },
        datetime(value: any) {
            return value ? new Date(value).format({
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short',
                hour12: false
            }) : ''
        }
    }

    format(value: any): string | string[] {
        if (typeof this._format === 'string') {
            return Field.formatters[this._format](value)
        }
        return this._format(value)
    }

    compare(a: any, b: any) {
        return this.format(a)?.toString() === this.format(b)?.toString()
    }
}
