import { ReactNode, useState } from 'react'
import { useBlocker } from 'react-router-dom'

import { Button, Popup, IconSave02 } from '@/components'

const useConfirmRouteChange = (
    isBlocked: boolean,
    onSave: any,
    title: ReactNode = <div className="flex items-center gap-2">
        <IconSave02 size="lg" className="stroke-primary-700"/>
        You Have Unsaved Changes
    </div>,
    description: ReactNode = <div>
        It looks like you{'\''}ve made some changes that haven{'\''}t been saved yet. If you navigate away from this page
        now, those changes will be lost. To keep your edits safe, please consider saving before you leave.
    </div>
) => {
    const [processing, setProcessing] = useState(false)
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => isBlocked &&
                (currentLocation.pathname !== nextLocation.pathname || currentLocation.hash !== nextLocation.hash)
    )

    const handleSave = async () => {
        try {
            setProcessing(true)
            await onSave()
            if (blocker.reset) {
                blocker.proceed()
            }
        } finally {
            setProcessing(false)
        }
    }

    return <Popup
        open={blocker.state === 'blocked'}
        onClose={() => blocker.reset && blocker.reset()}
        className="w-full md:w-auto"
    >
        <div className="max-w-2xl">
            <h4 className="text-xl text-gray-900">{title}</h4>
            {description && <div className="mt-2 text-gray-500">
                {description}
            </div>}

            <div className="flex flex-col md:flex-row gap-4 justify-center mt-8">
                <Button type="button" design="btn-secondary" hasError processing={processing} onClick={() => blocker.reset && blocker.proceed()}>
                    Leave without Saving
                </Button>
                <Button type="button" onClick={handleSave} processing={processing}>
                    Leave and Save Changes
                </Button>
            </div>
        </div>
    </Popup>
}

export default useConfirmRouteChange
