import { ChangeEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, IconInfoCircle, Popup, WarrantyUpdateDetails, Tooltip, Form } from '@/components'
import { Warranty } from '@/models'


type WarrantyReviewEditRequestPopupProps = {
    warranty: Warranty
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const WarrantyReviewEditRequestPopup = ({ warranty, ...props }: WarrantyReviewEditRequestPopupProps) => {
    const navigate = useNavigate()
    const { type } = useParams()
    const [processing, setProcessing] = useState(false)
    const [isOpen, setIsOpen] = useState(warranty.warrantyStatus.isEditRequested)

    const request = warranty.pending_update_requests[0] as {
        id: string
        new_price: number
        requested_changes: {
            [key: string]: any
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        try {
            await Warranty.updateRequest(request.id, e.nativeEvent.submitter.name)
            setIsOpen(false)
            navigate(`/${type}/${warranty.id}/warranty`)
        } finally {
            setProcessing(false)
        }
    }

    const handleModifyForm = () => {
        Object.keys(request.requested_changes).forEach(key => {
            let value: any
            if (key === 'install_date') {
                if (new Date(request.requested_changes[key]).isValid()) {
                    value = new Date(request.requested_changes[key])
                } else if (new Date(request.requested_changes[key].replaceAll('-', '/')).isValid()) {
                    value = new Date(request.requested_changes[key].replaceAll('-', '/'))
                } else {
                    value = ''
                }
            } else if (key === 'batteries') {
                value = request.requested_changes[key] || []
            } else {
                value = request.requested_changes[key] || ''
            }
            props.onChange({ target: { name: key, value } } as unknown as ChangeEvent<HTMLInputElement>)
        })
        props.onChange({ target: { name: 'warranty_update_request_id', value: request.id } } as unknown as ChangeEvent<HTMLInputElement>)
        setIsOpen(false)
    }

    return request && <Popup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        data-test="approve-warranty-edits-popup"
    >
        <Form onSubmit={handleSubmit} className="flex flex-col gap-4 text-gray-900 lg:min-w-[45rem]" noValidate>
            <p className="text-lg font-semibold flex gap-2 items-center">
                <IconInfoCircle className="stroke-warning-600" size="lg"/>
                Approve Warranty Edits
            </p>

            <WarrantyUpdateDetails
                oldValues={{
                    ...warranty,
                    ...warranty.homeowner,
                    affiliate_name: warranty.affiliate?.name,
                    panel_name: warranty.panel.model_name,
                    inverter_name: warranty.inverter.model_name,
                    inverter_manufacturer: warranty.inverter.provider
                }}
                newValues={{
                    ...request.requested_changes,
                    price: request.new_price
                }}
            />

            <p className="text-sm font-semibold" data-test="approve-edits-text">
                Do you want to save these edits to the warranty?
            </p>

            <div className="flex flex-col lg:flex-row gap-2 justify-between">
                <Tooltip content="Modify Warranty Edits">
                    <Button type="button" design="btn-secondary" onClick={handleModifyForm} data-test="modify-warranty-edits-button">Modify Warranty Edits</Button>
                </Tooltip>

                <div className="flex justify-end gap-2">
                    <Button className="w-40" design="btn-secondary-gray" hasError name="deny" processing={processing} data-test="deny-warranty-edits-button">
                        Deny Edits
                    </Button>
                    <Button className="w-40" name="approve" processing={processing} data-test="approve-warranty-edits-button">
                        Yes, Approve Edits
                    </Button>
                </div>
            </div>
        </Form>
    </Popup>
}
