import {
    EditGeneracMaintainerForm,
    IntegrationSetupConfirmedBanner,
    IntegrationSetupNotConfirmedBanner
} from '@/components'
import { Company, Integration } from '@/models'

type EditGeneracFormProps = {
    company: Company
    integration?: Integration

    onChange: () => void
    onAdded: () => void
}

const EditGeneracForm = ({ integration, company, ...props }: EditGeneracFormProps) => <div className="flex flex-col gap-6">
    {integration?.isMissing && <p className="text-error-500 max-w-2xl text-sm">
        A warranty registration was detected using a Generac Inverter. All contractors must provide Solar Insure
        monitoring access to stay compliant with our certification requirements.
    </p>}
    <EditGeneracMaintainerForm
        company={company}
        integration={integration}
        onChange={props.onChange}
        onAdded={props.onAdded}
    />

    {integration && <IntegrationSetupConfirmedBanner integration={integration}/>}

    {integration && <IntegrationSetupNotConfirmedBanner integration={integration}/>}
</div>

export default EditGeneracForm
