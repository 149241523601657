import Model from './Model'
import System from '@/models/System'
import { api, Options, Params } from '@/services'

export default class SystemHistory extends Model {
    static endpoint = 'api/v1/monitoring/systems-history'

    static async index<T extends typeof Model>(
        this: T,
        { id, ...params }: Params,
        options?: Options
    ): Promise<{ data: InstanceType<T>[], [key: string]: any }> {
        const res = await api.http.get(`${SystemHistory.endpoint}/${id}`, params, options)
        res.data = res.data?.map((item: any) => new SystemHistory(item)) || []
        return res
    }

    get system() {
        return new System({ status: this.status })
    }
}
