import http, { Options, Params } from '../http'
import { WarrantySalesItemDataType } from '@/types'

export default {
    async inactiveCompanies(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/inactive-accounts', params, options)
        return data
    },
    downloadInactiveCompanies(params: Params) {
        return http.get('api/v1/dashboard/widgets/inactive-accounts/download', params)
    },
    async topCompanies(params: Params, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/top-companies', params, options)
        return data
    },
    downloadTopCompanies(params: Params) {
        return http.get('api/v1/dashboard/widgets/top-companies/download', params)
    },
    async warrantySales(params: Params, options: Options): Promise<WarrantySalesItemDataType[]> {
        const { data } = await http.get('api/v1/dashboard/widgets/warranty-sales', params, options)
        return data
    },
    async salesByContractor(params: {
        period: 'week' | 'month' | 'quarter' | 'year',
        sort_by: 'top' | 'bottom'
    }, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/sales-by-contractor', params, options)
        return data
    },
    async revenueByState(params: { period: 'week' | 'month' | 'quarter' | 'year' }, options: Options) {
        const { data } = await http.get('api/v1/dashboard/widgets/revenue-by-state', params, options)
        return data
    },

    eligibleCommissionTracking(params: Params, options: Options) {
        return http.get('api/v1/dashboard/widgets/eligible-commission-tracking', params, options)
    },

    linkedInInsights(params: Params, options: Options) {
        return http.get('api/v1/dashboard/widgets/linkedin-latest-post', params, options)
    }
}
