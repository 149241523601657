import { Link } from 'react-router-dom'

import { AccountCreateForm, IconUsers01, PageHeader } from '@/components'
import { AuthLayout } from '@/containers'

const breadcrumb = [
    <IconUsers01 key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/users">Users</Link>,
    'Create New Account'
]

const UsersCreate = () =>
    <AuthLayout heading={<PageHeader title="Create a New Account" backUrl="/users"/>} breadcrumb={breadcrumb}>
        <AccountCreateForm/>
    </AuthLayout>

export default UsersCreate
