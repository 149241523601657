import Icon, { IconProps } from './Icon'

const IconStepPassed = (props: IconProps) =>
    <Icon {...props} viewBox="0 0 32 32">
        <g clipPath="url(#6ez9zdroja)">
            <rect width="32" height="32" rx="16" fill="#EEF8FB"/>
            <path fillRule="evenodd" clipRule="evenodd" d="m22.795 9.853-9.547 9.214-2.533-2.707c-.466-.44-1.2-.466-1.733-.093-.52.387-.667 1.067-.347 1.613l3 4.88c.293.453.8.733 1.373.733a1.63 1.63 0 0 0 1.36-.733c.48-.627 9.64-11.547 9.64-11.547 1.2-1.226-.253-2.306-1.213-1.373v.013z" fill="#007A96"/>
            <rect x="1" y="1" width="30" height="30" rx="15" stroke="#007A96" strokeWidth="2"/>
        </g>
        <defs>
            <clipPath id="6ez9zdroja">
                <rect width="32" height="32" rx="16" fill="#fff"/>
            </clipPath>
        </defs>
    </Icon>

export default IconStepPassed
