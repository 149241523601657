import classnames from 'classnames'
import { HTMLAttributes } from 'react'

export const Badge = ({
    className = 'bg-gray-100 text-gray-700',
    children,
    ...props
}: HTMLAttributes<HTMLSpanElement>) =>
    <span className={classnames('badge', className)} {...props}>
        {children}
    </span>
