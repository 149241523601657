import { useState } from 'react'

import { Button, IconShieldTick, Popup } from '@/components'
import { InverterManufacturer } from '@/models'

type SystemIdWarningPopupProps = {
    show: boolean
    inverterManufacturer: InverterManufacturer
    onClose: () => void
    onSkip: () => void
    onAdd: () => void
}

export const SystemIdWarningPopup = ({
    show,
    inverterManufacturer,
    ...props
}: SystemIdWarningPopupProps) => {
    const [showGuide, setShowGuide] = useState(false)

    return <Popup open={show} onClose={props.onClose} className="lg:!p-10 w-full md:w-auto">
        <h3 className="flex gap-3 items-center text-xl font-semibold">
            <div>
                <IconShieldTick className="stroke-primary-700" size="xl"/>
            </div>
            Don’t Miss Out on Expedited Monitoring Connection
        </h3>

        <div className="flex flex-col items-start gap-8 mt-4 text-gray-500">
            <p>
                Adding your Manufacturer System ID helps us connect to your system more accurately, ensuring quicker
                and more precise monitoring data insights. This allows you to take full advantage of our fleet
                monitoring dashboard, providing real-time updates and enhanced system performance tracking.
            </p>

            {inverterManufacturer.systemIdGuide && <>
                <Button
                    design="btn-link"
                    type="button"
                    className="!text-primary-700"
                    onClick={() => setShowGuide(value => !value)}
                >
                    How to Find Manufacturer System ID
                </Button>

                {showGuide && <div className="text-sm text-gray-750">
                    {inverterManufacturer.systemIdGuide}
                </div>}
            </>}

            <div className="flex flex-col gap-4">
                <p>
                    Knowing your MSI is crucial for us to provide you with the most extensive and reliable monitoring
                    experience.
                </p>
                <p>Would you like to add it now?</p>
            </div>
        </div>

        <div className="flex justify-end gap-4 mt-8">
            <Button design="btn-secondary-gray" onClick={props.onSkip}>
                Skip for Now
            </Button>
            <Button onClick={props.onAdd}>
                Yes, Add Now
            </Button>
        </div>
    </Popup>
}
