import classnames from 'classnames'
import { useEffect, useState, ReactNode, useMemo } from 'react'

import {
    Button,
    Select,
    IconExpand01,
    IconMinimize01,
    IconSearch,
    SearchableSelect,
    Tabs,
    SalesGraph, InstallsGraph
} from '@/components'
import { FiltersType } from '@/containers'
import { useAuth } from '@/hooks'
import { Company, WarrantyProductType } from '@/models'
import { api } from '@/services'
import { WarrantySalesItemDataType } from '@/types'

type PeriodType = 'week' | 'month' | 'year' | 'quarter'

interface GraphReportFiltersType extends FiltersType {
    sort_by: PeriodType
    contractor_id: string | number
}

type GraphReportProps = {
    className?: string
    onChange: (key: string) => void
    processing: boolean
    onExpand?: (key: string, isExpanded: boolean) => void
    isExpanded?: boolean
    children?: ReactNode
}

const GraphReport = ({ className, processing, isExpanded, ...props }: GraphReportProps) => {
    const auth = useAuth()
    const [contractors, setContractors] = useState<Company[]>([])
    const [filters, setFilters] = useState<GraphReportFiltersType>({
        sort_by: 'month',
        contractor_id: ''
    })
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [reportType, setReportType] = useState(auth.user?.isSoftwareAffiliate ? 'sales' : 'installs')
    const [listData, setListData] = useState<WarrantySalesItemDataType[]>([])
    const [monthData, setMonthData] = useState({
        total_count: 0,
        total_sum: 0
    })
    const [yearData, setYearData] = useState({
        total_count: 0,
        total_sum: 0
    })

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const { startDate, endDate } = useMemo(() => {
        const startDateMap: { [key in PeriodType]: Date } = {
            year: new Date().clone().subtract('y', 3)
                .startOf('year'),
            week: new Date().clone().subtract('weeks', 5)
                .startOf('week'),
            quarter: new Date().clone().subtract('quarter', 3)
                .startOf('quarter'),
            month: new Date().clone().startOf('month')
                .subtract('M', 6)
        }

        const endDateMap: { [key in PeriodType]: Date } = {
            year: new Date().clone().endOf('year'),
            week: new Date().clone().startOf('week'),
            quarter: new Date().clone().endOf('quarter'),
            month: new Date().clone().endOf('month')
        }
        const startDate = startDateMap[filters.sort_by] || startDateMap.month
        const endDate = endDateMap[filters.sort_by] || endDateMap.month

        return { startDate, endDate }
    }, [filters.sort_by])

    const dates = useMemo(() => {
        const res = []
        const start = startDate.clone()
        const end = endDate.clone()

        while (start <= end) {
            res.push(start.clone())
            start.add(filters.sort_by, 1)
        }
        return res
    }, [startDate, endDate, filters.sort_by])

    const labels = useMemo(() => {
        let format = {}
        if (filters.sort_by === 'week') {
            format = { month: 'short', day: 'numeric', year: null }
            return dates.map(item => `${item.format(format)} - ${item.clone().endOf('week').format(format)}`)
        }
        if (filters.sort_by === 'month') {
            format = { month: 'short', day: null }
        }
        if (filters.sort_by === 'year') {
            format = { month: null, day: null }
        }
        if (filters.sort_by === 'quarter') {
            return dates.map(item =>
                `Q${Math.floor((item.getMonth() / 3) + 1)} ${item.format({
                    month: null,
                    day: null
                })}`
            )
        }

        return dates.map(item => item.format(format))
    }, [dates, filters.sort_by])

    const fetchData = async () => {
        const controller = new AbortController
        setAbortController(controller)
        try {
            const [list, [month], [year]] = await Promise.all([
                api.dashboard.warrantySales({
                    start_date: startDate.toISODate(),
                    end_date: endDate.toISODate(),
                    period: filters.sort_by,
                    contractor_id: filters.contractor_id
                }, { signal: controller.signal }),
                api.dashboard.warrantySales({
                    start_date: new Date().startOf('month').toISODate(),
                    end_date: new Date().endOf('month').toISODate(),
                    period: 'month',
                    contractor_id: filters.contractor_id
                }, { signal: controller.signal }),
                api.dashboard.warrantySales({
                    start_date: new Date().startOf('year').toISODate(),
                    end_date: new Date().endOf('year').toISODate(),
                    period: 'year',
                    contractor_id: filters.contractor_id
                }, { signal: controller.signal })
            ])
            setListData(list)
            setMonthData(month)
            setYearData(year)
        } finally {
            props.onChange('salesReport')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => {
        fetchData()
    }, [filters])

    const fetchContractors = async () => {
        const data = await Company.autocomplete()
        setContractors(data)
    }

    useEffect(() => {
        if (auth.user?.isAffiliate || auth.user?.isAdminOrStaffOrAdvisor) {
            fetchContractors()
        }
    }, [])

    const handleFiltersChange = (e: any) => {
        setFilters(filters => ({ ...filters, [e.target.name]: e.target.value }))
    }

    const timePeriods = [
        { value: 'week', title: 'By Week' },
        { value: 'month', title: 'By Month' },
        { value: 'quarter', title: 'By Quarter' },
        { value: 'year', title: 'By Year' }
    ]

    return <div className={`h-full flex flex-col ${className || ''}`}>
        <div className="flex flex-col gap-3 sm:flex-row justify-between mb-5 flex-wrap">
            <div className="flex">
                <div className="border-r border-gray-200 pr-4">
                    <h3 className="font-semibold text-primary-700 text-lg" data-test="dashboard-graph-report-last-month-value">
                        {reportType === 'sales' ? monthData.total_sum.money() : monthData.total_count.format()}
                    </h3>
                    <div className="text-sm text-gray-500 mt-1" data-test="dashboard-graph-report-last-month-title">
                        {reportType === 'sales' ? 'This Month' : 'Installs This Month'}
                    </div>
                </div>
                <div className="pl-4">
                    <h3 className="font-semibold text-gray-500 text-lg" data-test="dashboard-graph-report-last-year-value">
                        {reportType === 'sales' ? yearData.total_sum.money() : yearData.total_count.format()}
                    </h3>
                    <div className="text-sm text-gray-500 mt-1" data-test="dashboard-graph-report-last-year-title">
                        {reportType === 'sales' ? 'This Year' : `Total Installs of ${new Date().getFullYear()}`}
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:flex-wrap gap-4">
                {(auth.user?.isAffiliate || auth.user?.isAdminOrStaffOrAdvisor) && <SearchableSelect
                    id="graph-report-contractor-id"
                    options={contractors.map(item => ({ value: item.id, title: item.name }))}
                    name="contractor_id"
                    value={filters.contractor_id}
                    onChange={handleFiltersChange}
                    placeholder="Contractor"
                    preIcon={<IconSearch className="stroke-gray-500"/>}
                    data-test="dashboard-graph-report-contractor"
                />}

                {isExpanded ? <Tabs
                    design="buttons"
                    value={filters.sort_by}
                    tabs={timePeriods.map(item => ({ id: item.value, title: item.title }))}
                    onChange={id => handleFiltersChange({ target: { name: 'sort_by', value: id } })}
                    data-test="dashboard-graph-report-period"
                /> : <Select
                    id="graph-report-period"
                    options={timePeriods}
                    name="sort_by"
                    value={filters.sort_by}
                    onChange={handleFiltersChange}
                    data-test="dashboard-graph-report-period"
                />}

                <Select
                    data-test="dashboard-graph-toggle-button"
                    options={[
                        { value: 'sales', title: 'Warranty Charges Overtime' },
                        { value: 'installs', title: 'Installs Overtime' }
                    ]}
                    name="report_type"
                    value={reportType}
                    onChange={e => setReportType(e.target.value)}
                />

                <div className="hidden md:block">
                    {props.onExpand && <Button design="btn-link-gray" onClick={() => props.onExpand && props.onExpand('salesReport', !isExpanded)}>
                        {isExpanded ? <IconMinimize01 size="lg"/> : <IconExpand01 size="lg"/>}
                    </Button>}
                </div>
            </div>
        </div>
        <div className="relative h-[99%] w-[99%]">
            {reportType === 'sales'
                ? <SalesGraph
                    labels={labels}
                    data={listData}
                />
                : <InstallsGraph
                    labels={labels}
                    data={listData}
                />}
        </div>
        <div className="flex justify-center mt-3 gap-3">
            <div className="flex items-center text-gray-500 text-xs font-semibold" data-test="dashboard-graph-report-total-sales-label">
                {reportType === 'sales' && <div
                    className="rounded-full w-2 aspect-square h-2 bg-brand-blue-skies mr-2"
                    data-test="dashboard-graph-report-total-sales-icon"
                />}
                {reportType === 'sales' ? 'Total Sales' : 'Warranty Types:'}
            </div>
            {WarrantyProductType.all.map(item =>
                <div
                    key={item.key}
                    className="flex items-center text-gray-500 text-xs"
                    data-test={`dashboard-graph-report-${item.key}-label`}
                >
                    <div
                        className={classnames('rounded-full w-2 aspect-square h-2 mr-2', {
                            'bg-orange-400': item.color === 'warning',
                            'bg-purple-400': item.color === 'purple',
                            'bg-success-400': item.color === 'success'
                        })}
                        data-test={`dashboard-graph-report-${item.key}-icon`}
                    />
                    {item.title}
                </div>)}
        </div>
    </div>
}

export default GraphReport
