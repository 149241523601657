import { useNavigate, useParams } from 'react-router-dom'

import {
    Card, CompanyEditForm,
    IconUsers01, Tabs, UserEditForm,
    EditIntegrationsForm,
    NotificationsEditForm, PreferencesEditForm,
    PasswordEditForm, PageHeader
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAppSelector } from '@/hooks'
import { AuthUser } from '@/models'
import { cookies, feature } from '@/services'
import store, { login } from '@/store'

const Profile = () => {
    const auth = useAppSelector(({ auth }) => auth)
    const params = useParams()
    const navigate = useNavigate()

    const tabs = [
        { id: 'account', to: '/profile/account', title: 'Account' },
        ...auth.user?.isAffiliateOrContractor ? [{ id: 'company', to: '/profile/company', title: 'Company' }] : [],
        ...auth.user?.isContractor ? [{
            id: 'integrations',
            to: '/profile/integrations',
            title: 'Integrations'
        }] : [],
        { id: 'password', to: '/profile/password', title: 'Password' },
        { id: 'notifications', to: '/profile/notifications', title: 'Notifications' },
        ...feature('onboarding') && auth.user?.isContractor ? [{
            id: 'user-preferences',
            to: '/profile/user-preferences',
            title: 'User Preferences'
        }] : []
    ]
    const tab = params.tab || tabs[0].id

    const handleSuccess = async () => {
        const user = await await AuthUser.currentUser()
        await store.dispatch(login({ user, apiToken: cookies.get('api_token') }))
    }

    const handleExit = () => {
        if (auth.user?.isAdminOrStaffOrAdvisor) {
            navigate(`/users/${auth.user?.id}`)
        } else {
            navigate('/dashboard')
        }
    }

    const breadcrumb = [
        <IconUsers01 key={1} className="stroke-gray-500"/>,
        <span key={2}>User</span>,
        'Profile'
    ]

    return <AuthLayout
        breadcrumb={breadcrumb}
        heading={<PageHeader
            title={auth?.user?.name}
            backUrl="/dashboard"
            subtitle={
                <>
                    <p className="text-gray-500 text-base mt-2 flex items-center">
                        Account Type: <span className="bg-primary-50 px-2 py-1 text-primary-700 ml-1 text-sm capitalize">{auth.user?.accountType}</span>
                    </p>
                    {auth?.user?.company?.name && <p className="text-gray-500 text-base mt-2 flex items-center">
                        Company: <span className="bg-orange-50 px-2 py-1 text-orange-700 ml-1 text-sm">{auth.user?.company?.name}</span>
                    </p>}
                </>
            }
        />}
    >
        {<Tabs
            className="w-fit mb-8"
            size="sm"
            tabs={tabs}
            value={tab}
        />}
        {auth.user && <Card>
            {tab === 'account' && <UserEditForm
                user={auth.user}
                onSuccess={handleSuccess}
                onExit={handleExit}
            />}
            {tab === 'company' && auth.user.company && <CompanyEditForm
                company={auth.user.company}
                onSuccess={handleSuccess}
                onExit={handleExit}
            />}
            {tab === 'integrations' && <EditIntegrationsForm
                company={auth.user.company}
                onChange={handleSuccess}
            />}
            {tab === 'password' && <PasswordEditForm
                user={auth.user}
                onSuccess={handleSuccess}
                onExit={handleExit}
            />}
            {tab === 'notifications' && <NotificationsEditForm
                user={auth.user}
                onSuccess={handleSuccess}
                onExit={handleExit}
            />}
            {tab === 'user-preferences' && feature('onboarding') && <PreferencesEditForm
                user={auth.user}
                onSuccess={handleSuccess}
            />}
        </Card>}
    </AuthLayout>
}
export default Profile
