type Unit =
    'years' | 'year' | 'y' |
    'quarter' | 'quarters' | 'Q' |
    'month' | 'months' | 'M' |
    'week' | 'weeks' | 'w' |
    'day' | 'days' | 'd' |
    'hour' | 'hours' | 'h' |
    'minute' | 'minutes' | 'm' |
    'second' | 'seconds' | 's' |
    'millisecond' | 'milliseconds' | 'ms'

type DateFormatOptions = {
    weekday?: 'long' | 'short' | 'narrow' | undefined | null
    year?: 'numeric' | '2-digit' | undefined | null
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined | null
    day?: 'numeric' | '2-digit' | undefined | null
    hour?: 'numeric' | '2-digit' | undefined | null
    minute?: 'numeric' | '2-digit' | undefined | null
    second?: 'numeric' | '2-digit' | undefined | null
    timeZoneName? : 'long' | 'short' | 'shortOffset' | 'longOffset' | undefined | null
    hour12?: boolean
}

declare global {
    interface Date {
        format(options?: DateFormatOptions): string

        humanize(): string

        add(unit: Unit, value: number): Date

        subtract(unit: Unit, value: number): Date

        startOf(unit: Unit): Date

        endOf(unit: Unit): Date

        toISODate(): string

        toISODateTime(): string

        clone(): Date

        isValid(): boolean

        clearTimeZone(): Date
    }
}

Date.prototype.clearTimeZone = function (this: Date) {
    if (this.isValid()) {
        const date = new Date(this.toISOString().slice(0, -1))
        this.setTime(date.getTime())
        return this
    }
    return this
}

/**
 * Return date in this format 2023-01-19
 */
Date.prototype.toISODate = function (this: Date) {
    const month = this.getMonth() + 1
    const day = this.getDate()
    return `${this.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
}

/**
 * Return date in this format 2023-01-19 08:41:57
 */
Date.prototype.toISODateTime = function (this: Date) {
    const month = this.getMonth() + 1
    const day = this.getDate()
    const hour = this.getHours()
    const minute = this.getMinutes()
    const second = this.getSeconds()
    return `${this.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`} ${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`}`
}

Date.prototype.clone = function (this: Date) {
    return new Date(this)
}

Date.prototype.format = function (this: Date, {
    weekday,
    year = 'numeric',
    month = 'short',
    day = 'numeric',
    hour12 = true,
    hour,
    minute,
    second,
    timeZoneName
}: DateFormatOptions = {}) {
    try {
        return new Intl.DateTimeFormat('en-US', {
            weekday: weekday || undefined,
            year: year || undefined,
            month: month || undefined,
            day: day || undefined,
            hour: hour || undefined,
            minute: minute || undefined,
            timeZoneName: timeZoneName || undefined,
            second: second || undefined,
            hour12
        }).format(this)
    } catch (err) {
        return 'Invalid Date'
    }
}

Date.prototype.humanize = function (this: Date) {
    const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })
    const DIVISIONS: { amount: number, name: any }[] = [
        { amount: 60, name: 'seconds' },
        { amount: 60, name: 'minutes' },
        { amount: 24, name: 'hours' },
        { amount: 7, name: 'days' },
        { amount: 4.34524, name: 'weeks' },
        { amount: 12, name: 'months' },
        { amount: Number.POSITIVE_INFINITY, name: 'years' }
    ]
    let duration = (+this - +new Date()) / 1000

    for (const i in DIVISIONS) {
        const division = DIVISIONS[i]
        if (Math.abs(duration) < division.amount) {
            return formatter.format(Math.round(duration), division.name)
        }
        duration /= division.amount
    }
    return 'Invalid date'
}

const millisecondUnits = ['milliseconds', 'millisecond', 'ms']
const secondUnits = ['seconds', 'second', 's']
const minuteUnits = ['minutes', 'minute', 'm']
const hourUnits = ['hours', 'hour', 'h']
const dayUnits = ['days', 'day', 'd']
const weekUnits = ['weeks', 'week', 'w']
const monthUnits = ['months', 'month', 'M']
const quarterUnits = ['quarters', 'quarter', 'Q']
const yearUnits = ['years', 'year', 'y']

Date.prototype.add = function (this: Date, unit: Unit, value: number) {
    if (millisecondUnits.includes(unit)) {
        this.setMilliseconds(this.getMilliseconds() + value)
    }
    if (secondUnits.includes(unit)) {
        this.setSeconds(this.getSeconds() + value)
    }
    if (minuteUnits.includes(unit)) {
        this.setMinutes(this.getMinutes() + value)
    }
    if (hourUnits.includes(unit)) {
        this.setHours(this.getHours() + value)
    }
    if (dayUnits.includes(unit)) {
        this.setDate(this.getDate() + value)
    }
    if (weekUnits.includes(unit)) {
        this.setDate(this.getDate() + (value * 7))
    }
    if (monthUnits.includes(unit)) {
        this.setMonth(this.getMonth() + value)
    }
    if (quarterUnits.includes(unit)) {
        this.setMonth(this.getMonth() + (value * 3))
    }
    if (yearUnits.includes(unit)) {
        this.setFullYear(this.getFullYear() + value)
    }
    return this
}

Date.prototype.subtract = function (this: Date, unit: Unit, value: number) {
    if (millisecondUnits.includes(unit)) {
        this.setMilliseconds(this.getMilliseconds() - value)
    }
    if (secondUnits.includes(unit)) {
        this.setSeconds(this.getSeconds() - value)
    }
    if (minuteUnits.includes(unit)) {
        this.setMinutes(this.getMinutes() - value)
    }
    if (hourUnits.includes(unit)) {
        this.setHours(this.getHours() - value)
    }
    if (dayUnits.includes(unit)) {
        this.setDate(this.getDate() - value)
    }
    if (weekUnits.includes(unit)) {
        this.setDate(this.getDate() - (value * 7))
    }
    if (monthUnits.includes(unit)) {
        const month = this.getMonth() - value
        const daysInMonth = new Date(this.getFullYear(), this.getMonth(), 0).getDate()
        this.setMonth(month, daysInMonth < this.getDate() ? daysInMonth : this.getDate())
    }
    if (quarterUnits.includes(unit)) {
        this.setMonth(this.getMonth() - (value * 3))
    }
    if (yearUnits.includes(unit)) {
        this.setFullYear(this.getFullYear() - value)
    }
    return this
}

Date.prototype.startOf = function (this: Date, unit: Unit) {
    if (secondUnits.includes(unit)) {
        new Date(this.setHours(this.getHours(), this.getMinutes(), this.getSeconds(), 0))
    }
    if (minuteUnits.includes(unit)) {
        new Date(this.setHours(this.getHours(), this.getMinutes(), 0, 0))
    }
    if (hourUnits.includes(unit)) {
        new Date(this.setHours(this.getHours(), 0, 0, 0))
    }
    if (dayUnits.includes(unit)) {
        new Date(this.setHours(0, 0, 0, 0))
    }
    if (weekUnits.includes(unit)) {
        const day = this.getDay()
        const diff = this.getDate() - day + (day === 0 ? -6 : 1)
        return new Date(new Date(this.setDate(diff)).setHours(0, 0, 0, 0))
    }
    if (monthUnits.includes(unit)) {
        return new Date(new Date(this.getFullYear(), this.getMonth(), 1).setHours(0, 0, 0, 0))
    }
    if (quarterUnits.includes(unit)) {
        return new Date(new Date(this.getFullYear(), Math.floor(this.getMonth() / 3) * 3, 1).setHours(0, 0, 0, 0))
    }
    if (yearUnits.includes(unit)) {
        return new Date(new Date(this.getFullYear(), 0, 1).setHours(0, 0, 0, 0))
    }
    return this
}

Date.prototype.endOf = function (this: Date, unit: Unit) {
    if (secondUnits.includes(unit)) {
        new Date(this.setHours(this.getHours(), this.getMinutes(), this.getSeconds(), 999))
    }
    if (minuteUnits.includes(unit)) {
        new Date(this.setHours(this.getHours(), this.getMinutes(), 59, 999))
    }
    if (hourUnits.includes(unit)) {
        new Date(this.setHours(this.getHours(), 59, 59, 999))
    }
    if (dayUnits.includes(unit)) {
        new Date(this.setHours(23, 59, 59, 999))
    }
    if (weekUnits.includes(unit)) {
        const day = this.getDay()
        const diff = this.getDate() - day + (day === 0 ? -6 : 1) + 6
        return new Date(new Date(this.setDate(diff)).setHours(23, 59, 59, 999))
    }
    if (monthUnits.includes(unit)) {
        return new Date(new Date(this.getFullYear(), this.getMonth() + 1, 0).setHours(23, 59, 59, 999))
    }
    if (quarterUnits.includes(unit)) {
        return new Date(new Date(this.getFullYear(), (Math.floor(this.getMonth() / 3) + 1) * 3, 0)
            .setHours(23, 59, 59, 999))
    }
    if (yearUnits.includes(unit)) {
        return new Date(new Date(this.getFullYear(), 11, 31).setHours(23, 59, 59, 999))
    }
    return this
}

Date.prototype.isValid = function (this: Date) {
    return this.toDateString() !== 'Invalid Date'
}

export {}
