import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { AuthUser } from '@/models'
import { sessionStorage } from '@/services'
import cookies from '@/services/cookies'

const cookieUser = cookies.get('auth')

type AuthState = {
    user: AuthUser | null,
    apiToken: string | null
}
const initialState: AuthState = {
    user: cookieUser ? new AuthUser(JSON.parse(cookieUser)) : null,
    apiToken: cookies.get('api_token')
}

Sentry.setUser(initialState.user ? {
    id: initialState.user.id,
    email: initialState.user.email,
    username: initialState.user.name
} : null)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<AuthState>) => {
            state = {
                user: action.payload.user,
                apiToken: action.payload.apiToken
            }
            cookies.set('auth', action.payload.user?.toJSON() || '')
            Sentry.setUser(action.payload.user ? {
                id: action.payload.user.id,
                email: action.payload.user.email,
                username: action.payload.user.name
            } : null)
            return state
        },
        logout: state => {
            cookies.delete('auth')
            cookies.delete('api_token')
            sessionStorage.delete('seen_banners')
            state = { user: null, apiToken: null }
            Sentry.setUser(null)
            return state
        }
    }
})

export const { logout, login } = authSlice.actions

export default authSlice.reducer
