import { useEffect, useState } from 'react'

import { BarChart, Button, Select, IconExpand01, IconMinimize01 } from '@/components'
import { api } from '@/services'

type SalesByContractorProps = {
    className?: string
    onChange: any
    onExpand: any
    processing: boolean
    isExpanded: boolean
}

const SalesByContractor = ({ className, processing, isExpanded, ...props }: SalesByContractorProps) => {
    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [filters, setFilters] = useState<any>({
        period: 'month',
        sort_by: 'top'
    })
    const [data, setData] = useState<any[]>([])

    const fetchData = async () => {
        try {
            const controller = new AbortController
            setAbortController(controller)
            const res = await api.dashboard.salesByContractor(filters, { signal: controller.signal })
            setData(res)
        } finally {
            props.onChange('salesByContractor')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    useEffect(() => {
        fetchData()
    }, [filters])

    const handleFiltersChange = (e: any) => {
        setFilters((filters: any) => ({ ...filters, [e.target.name]: e.target.value }))
    }

    useEffect(() => {
        props.onChange('salesByContractor')
    }, [])

    useEffect(() => () => abortController?.abort('canceled'), [abortController])

    return <div className={className}>
        <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-display">Sales by Contractor</h3>

            <div className="hidden md:block">
                <Button design="btn-link-gray" onClick={() => props.onExpand('salesByContractor', !isExpanded)}>
                    {isExpanded ? <IconMinimize01 size="lg"/> : <IconExpand01 size="lg"/>}
                </Button>
            </div>
        </div>

        <div className={`flex flex-col lg:flex-row gap-3 flex-nowrap mb-5 ${isExpanded ? 'w-1/2' : 'w-full'}`}>
            <Select
                id="sales-type"
                options={[
                    { value: 'top', title: 'Top 10 Contractors' },
                    { value: 'bottom', title: 'Bottom 10 Contractors' }
                ]}
                name="sort_by"
                value={filters.sort_by}
                onChange={handleFiltersChange}
                className="flex-1"
            />

            <Select
                id="sales-period"
                options={[
                    { value: 'week', title: 'This Week' },
                    { value: 'month', title: 'This Month' },
                    { value: 'quarter', title: 'This Quarter' },
                    { value: 'year', title: 'This Year' }
                ]}
                name="period"
                value={filters.period}
                onChange={handleFiltersChange}
                className="flex-1"
            />
        </div>

        <div className="h-full">
            <BarChart
                datasets={[{
                    data: data.map(item => item.total_sales || 0),
                    backgroundColor: filters.sort_by === 'top' ? '#00a071' : '#e79100',
                    borderRadius: 4,
                    borderSkipped: false
                }]}
                labels={data.map(item => item.name)}
                options={{
                    responsive: true,
                    indexAxis: 'y',
                    scales: {
                        y: {
                            border: {
                                display: false,
                                color: '#61605f'
                            },
                            grid: {
                                display: false,
                                offset: true
                            }
                        },
                        x: {
                            grace: '15%',
                            suggestedMax: 10000,
                            ticks: {
                                callback: (value: number | string) => parseFloat(value.toString())
                                    .money({ maximumFractionDigits: 0 })
                            },
                            border: { display: false },
                            beforeUpdate(axis) {
                                if (axis.chart.data.labels) {
                                    axis.chart.data.labels = axis.chart.data.labels.map(item => typeof item === 'string' && item.length > 12 ? `${item.substring(0, 12)}...` : item)
                                }
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            backgroundColor: '#fff',
                            titleColor: '#403f35',
                            bodyColor: '#61605f',
                            bodyFont: { size: 14, weight: '400' },
                            displayColors: false,
                            borderColor: '#f5f4f1',
                            borderWidth: 1,
                            padding: {
                                x: 12,
                                y: 14
                            },
                            callbacks: {
                                title: tooltipItems => data[tooltipItems[0].dataIndex].name,
                                label(item: any) {
                                    return item.raw.money({ maximumFractionDigits: 0 })
                                }
                            }
                        },
                        datalabels: {
                            display: true,
                            color: '#61605f',
                            anchor: 'end',
                            align: 'right',
                            clamp: true,
                            formatter(value) {
                                return value.money({ maximumFractionDigits: 0 })
                            }
                        }
                    }
                }}
            />
        </div>
    </div>
}

export default SalesByContractor
