import { useMemo } from 'react'
import { createSearchParams, Link, useLocation } from 'react-router-dom'

import { Button, MonitoringIssuesChart, Tooltip, StatusGuidePopup } from '@/components'
import { useAnalytics, useQuery } from '@/hooks'
import { SystemStatusCategory } from '@/models'

type MonitoringDonutReportProps = {
    data: any
    isExpanded: boolean
}

const MonitoringDonutReport = ({ isExpanded, data }: MonitoringDonutReportProps) => {
    const { trackEvent } = useAnalytics()
    const location = useLocation()
    const query = useQuery()

    const trackHoverEvent = (action: string, label: string) => {
        trackEvent(`${action}_hover`, 'User Interaction', `${label} Hover`)
    }

    const getMonitoringIndexPage = (filters: any) => {
        if (location.pathname === '/monitoring') {
            return `/monitoring?${createSearchParams({ ...query, ...filters })}`
        }
        return `/monitoring?${createSearchParams(filters)}`
    }

    const connectedTiers = useMemo(() => SystemStatusCategory.connectedSitesCategory.tiers, [])

    return <div className={`flex flex-col gap-4 md:flex-row justify-between items-start w-full ${isExpanded ? '' : 'mb-8 xl:mb-0'}`}>
        <div className="grid gap-4 2xl:gap-0 2xl:flex">
            <div className={`flex w-72 max-w-full ${isExpanded ? 'flex-col gap-2.5' : 'flex-col xl:flex-row gap-4 xl:gap-8'}`}>
                <h4 className="text-sm font-body leading-5 text-gray-500 shrink-0" data-test="monitoring-index-issues-title">
                    Site Status Alert Prioritization
                </h4>

                <div
                    className="self-center md:self-end"
                    onMouseOver={() => trackHoverEvent('fleet_monitoring_summary', 'Fleet Monitoring Summary')}
                    onFocus={() => trackHoverEvent('fleet_monitoring_summary', 'Fleet Monitoring Summary')}
                >
                    {isExpanded
                        ? <MonitoringIssuesChart
                            key="full-chart"
                            data={data}
                            className="w-[140px]"
                        />
                        : <MonitoringIssuesChart
                            key="half-chart"
                            data={data}
                            className="w-[214px] !h-[107px]"
                            type="half-donut"
                        />}
                </div>
            </div>
            {isExpanded && <div className="font-body flex flex-col gap-2 lg:ml-6">
                {connectedTiers.map(item =>
                    <div key={item.value}>
                        <h5 className="leading-5 flex items-center gap-2">
                            <Button
                                design="btn-link"
                                className="flex gap-2 items-center"
                                href={getMonitoringIndexPage({ statuses: [item.value] })}
                                data-test={`monitoring-index-issues-${item.value}-title`}
                            >
                                {item.circleIcon} {item.title}
                            </Button>
                            <div className="flex">
                                {item.statuses.length > 1 && item.secondarySortedStatuses.map(item =>
                                    !!data.statuses_count?.[item.value] && <Tooltip
                                        className="hover:z-10"
                                        key={item.value}
                                        content={`${item.title} (${data.statuses_count[item.value].format()})`}
                                    >
                                        <Link
                                            to={getMonitoringIndexPage({ statuses: [item.value] })}
                                            className="rounded-full block"

                                            data-test={`monitoring-index-issues-${item.value}-icon`}
                                        >
                                            {item.iconBadge('xs')}
                                        </Link>
                                    </Tooltip>)}
                            </div>
                        </h5>
                        <p className="text-xs text-gray-500 leading-[18px]" data-test={`monitoring-index-issues-${item.value}-subtitle`}>
                            {item.subtitle}
                        </p>
                    </div>)}
            </div>}
        </div>

        <StatusGuidePopup compact={!isExpanded}/>
    </div>
}

export default MonitoringDonutReport
