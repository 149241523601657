import { useSearchParams } from 'react-router-dom'

export default () => {
    const [searchParams] = useSearchParams()
    const data: { [key:string]: any } = Object.fromEntries([...searchParams])

    // Parse Arrays and Objects
    for (const key in data) {
        const isArray = /[A-Za-z0-9]+\[[0-9]+]/i.test(key)
        const isObject = /[A-Za-z0-9]+\[(\w+)]/i.test(key)
        if (isArray) {
            const realKey = key.substring(0, key.indexOf('['))
            data[realKey] = data[realKey] ? [...data[realKey], data[key]] : [data[key]]
            delete data[key]
        } else if (isObject) {
            const [, realKey, childKey] = key.match(/^(\w+)\[(\w+)]$/) || ['']
            data[realKey] = data[realKey] ? {
                ...data[realKey],
                [childKey]: data[key]
            } : { [childKey]: data[key] }
            delete data[key]
        }
    }
    return data
}
