import http, { Options, Params, Payload } from '../http'

export type UpdateCompanyUsersPayloadType = {
    contacts: {
        id: number | string
        is_new?: boolean
        company_id: number | string
        role: string
        name: string
        email: string
    }
}

export default {
    downloadSystemData(id: string | number) {
        return http.get(`api/v1/company/${id}/systems/download`)
    },

    autocomplete(params?: Params) {
        return http.get('api/v1/company/autocomplete', params)
    },

    contractors(params?: Params, options?: Options) {
        return http.get('api/v1/company/affiliate-contractors', params, options)
    },

    async activities(id: string | number) {
        const res = await http.get(`api/v1/company/${id}/activities`)
        return res.data
    },

    eligibleCommissions(id: string | number, params?: Params, options?: Options) {
        return http.get(`api/v1/company/${id}/eligible-commission-rates`, params, options)
    },

    updateEligibleCommissions(id: string | number, payload: Payload, params?: Params, options?: Options) {
        return http.post(`api/v1/company/${id}/eligible-commission-rates`, payload, params, options)
    },

    updateCompanyUsers(
        id: string | number,
        payload: UpdateCompanyUsersPayloadType,
        params: any = {},
        options: any = {}
    ) {
        return http.put(`api/v1/company/${id}/users`, payload, params, options)
    }
}
