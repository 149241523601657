import { useState } from 'react'

import { ArticleForm, Button } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Article } from '@/models'

const ArticleEdit = ({ article, ...props }: { article: Article, onComplete: any }) => {
    const { error } = useToastNotifications()
    const [form, setForm] = useState({
        category_id: article.category_id,
        title: article.title,
        content: article.content
    })
    const [errors, setErrors] = useState({})
    const [processing, setProcessing] = useState(false)

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            await article.update(form)
            props.onComplete()
        } catch (err: any) {
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    return <form className="w-[50rem] max-w-full md:pr-8 flex flex-col gap-4" onSubmit={handleSubmit}>
        <h3 className="font-semibold">Edit Article</h3>

        <ArticleForm
            form={form}
            errors={errors}
            onChange={handleChange}
        />

        <div className="flex justify-end">
            <Button design="btn-secondary" hasError type="button" onClick={props.onComplete}>
                Cancel
            </Button>
            <Button className="ml-2" processing={processing}>
                Save
            </Button>
        </div>
    </form>
}

export default ArticleEdit
