import RouterView from './router'
import { LoadingBar, ToastNotifications } from '@/components'
import { AbilityContext } from '@/components/Can'
import { useAuth, useReleaseCheck } from '@/hooks'
import { getAbility } from '@/utils/ability'

const App = () => {
    const releaseCheck = useReleaseCheck()
    const auth = useAuth()

    return <AbilityContext.Provider value={getAbility()}>
        <main className={`main ${auth.user?.isImpersonated ? 'impersonated' : ''}`}>
            <LoadingBar/>
            <RouterView/>
            <ToastNotifications/>
            {releaseCheck}
        </main>
    </AbilityContext.Provider>
}

export default App
