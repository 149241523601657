import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Badge, Table, TableEditColumns, ColumnProps, RowProps, TableFiltersType } from '@/components'
import { useQuery } from '@/hooks'
import { Model } from '@/models'
import { Options, Params, sessionStorage } from '@/services'

export type MetaType = {
    total: number
    overall_count?: number
    current_page?: number
    per_page?: number
}
export interface FiltersType extends TableFiltersType {
    [key: string]: string | number | string[] | Date | null | boolean | undefined
}

export interface GlobalIndexDataType {
    meta: MetaType
    rows: Model[]
    selectedRows?: RowProps[] | 'all'
    excludedRows?: RowProps[]
    rest?: any
}

export type GlobalIndexChangeEvent = {
    name: 'meta' | 'rows' | 'selectedRows' | 'excludedRows' | 'rest',
    value: RowProps[] | MetaType
}

export interface GlobalIndexProps<T extends FiltersType = FiltersType> {
    id: string
    reload?: boolean
    columns: Array<ColumnProps>
    rows: Array<RowProps>
    data: GlobalIndexDataType
    api: (params?: Params, options?: Options) => Promise<any>
    placeholder?: ReactNode
    dataType: string
    getDefaultFilters?: () => T
    filters?: T
    defaultTableFilters?: FiltersType
    heading?: ReactNode
    leftSide?: ReactNode
    rightSide?: ReactNode
    infinity?: boolean
    selectable?: boolean
    searchable?: boolean
    sortable?: boolean
    searchPlaceholder?: string
    maxColumnsAmount?: number

    onChange: (e: GlobalIndexChangeEvent) => void
    onFiltersChange?: (filters: T) => void
    onReloadChange?: (value: boolean) => void
}

const GlobalIndex = <T extends FiltersType>({
    id,
    reload,
    data,
    columns,
    rows,
    heading,
    leftSide,
    rightSide,
    api,
    filters,
    defaultTableFilters,
    infinity,
    selectable,
    searchable,
    sortable,
    searchPlaceholder,
    maxColumnsAmount = 10,
    dataType = 'Items',
    placeholder,
    getDefaultFilters,
    ...props
}: GlobalIndexProps<T>) => {
    const query = useQuery()
    const location = useLocation()
    const [abortController, setAbortController] = useState<AbortController | null>()
    const [activeColumns, setActiveColumns] = useState<string[]>([])
    const [error, setError] = useState(false)
    const [processing, setProcessing] = useState<boolean | 'prev' | 'next'>(false)

    const getTableHeight = () => {
        const MIN_HEIGHT = 700
        const FILTERS_HEIGHT = 46 + 46
        const PADDINGS_HEIGHT = 64 + 64
        const height = window.innerHeight - FILTERS_HEIGHT - PADDINGS_HEIGHT
        return height < MIN_HEIGHT ? MIN_HEIGHT : height
    }

    const fetchData = async (tableFilters: FiltersType, isScrolled = false, isPrevious = false) => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        if (isScrolled) {
            setProcessing(isPrevious ? 'prev' : 'next')
        } else {
            setProcessing(true)
        }
        try {
            const { data: rows, meta, ...rest } =
                await api({ ...defaultTableFilters, ...tableFilters, ...filters }, { signal: controller.signal })
            const newData = rows.map((item: Model) => {
                item.page = meta.current_page
                return item
            })

            if (isScrolled) {
                props.onChange({ name: 'rows', value: isPrevious ? [...newData, ...data.rows] : [...data.rows, ...newData] })
            } else {
                props.onChange({ name: 'rows', value: newData })
            }

            props.onChange({ name: 'meta', value: meta })
            props.onChange({ name: 'rest', value: rest })
        } catch (err) {
            if (err === 'canceled' || (err instanceof Error && err.name !== 'AbortError')) {
                setError(true)
            }
            throw err
        } finally {
            setProcessing(false)
        }
    }

    const handleSelectedRowsChange = (selected: RowProps[]) => {
        props.onChange({ name: 'selectedRows', value: selected })
    }

    const handleExcludedRowsChange = (selected: RowProps[]) => {
        props.onChange({ name: 'excludedRows', value: selected })
    }

    useEffect(() => {
        fetchData(data.meta.current_page ? { ...query, page: 1 } : query)
    }, [filters])

    const handleReload = async () => {
        if (reload) {
            await fetchData({ ...query, page: 1 })
            if (props.onReloadChange) props.onReloadChange(false)
        }
    }

    useEffect(() => {
        handleReload()
    }, [reload])

    useEffect(() => {
        if (getDefaultFilters) {
            const newFilters = getDefaultFilters()
            const oldFilters = filters
            const isFiltersChanged = oldFilters && Object
                .keys(filters)
                .some(key => !!newFilters[key] !== !!newFilters[key] ||
                        newFilters[key]?.toString() !== oldFilters[key]?.toString())

            if (isFiltersChanged && props.onFiltersChange) props.onFiltersChange(newFilters as T)
        }
    }, [location])

    const handleRowClick = () => {
        if (location.search) {
            sessionStorage.set(`preservedQuery.${id}`, location.search)
        }
    }

    useEffect(() => {
        sessionStorage.delete(`preservedQuery.${id}`)
    }, [])

    const columnsMemo = useMemo(() =>
        columns.filter(({ field }) => columns.length <= maxColumnsAmount || activeColumns.includes(field)),
    [columns, maxColumnsAmount, activeColumns])
    const rowsMemo = useMemo(() => rows.map((item, index) => {
        item._page = data.rows[index].page
        return item
    }), [rows, data.rows])

    return <div className="global-index">
        <div className="global-index-heading">
            <div className="global-index-heading-left-side">
                {leftSide}

                <div className="global-index-heading-default-actions">
                    {columns.length > maxColumnsAmount && <TableEditColumns
                        name={`${id}-index-columns-edit`}
                        maxAmount={maxColumnsAmount}
                        columns={columns}
                        value={activeColumns}
                        onChange={setActiveColumns}
                        data-test={`${id}-columns-edit`}
                    />}

                    {(!!data.meta.overall_count || !!data.meta.total || data.meta.total === 0) &&
                        <Badge className="bg-primary-50 text-primary-600 whitespace-nowrap" data-test={`${id}-index-count-badge`}>
                            {data.meta.total.format()} {data.meta.overall_count && data.meta.overall_count !== data.meta.total ? ` of ${data.meta.overall_count.format()}` : ''} {dataType}
                        </Badge>}
                </div>
            </div>
            <div className="global-index-heading-right-side">
                {rightSide}
            </div>
        </div>
        <div className="global-index-table-container">
            <Table
                columns={columnsMemo}
                rows={rowsMemo}
                meta={data.meta}
                minHeight={getTableHeight()}
                placeholder={placeholder}
                errors={error}
                filters={filters}
                processing={processing}
                data-test={`${id}-index-table`}
                dataType={dataType}
                infinity={infinity}
                selectable={selectable}
                searchable={searchable}
                sortable={sortable}
                selectedRows={data.selectedRows}
                excludedRows={data.excludedRows}
                searchPlaceholder={searchPlaceholder}
                heading={heading}
                onChange={fetchData}
                onRowClick={handleRowClick}
                onSelectedRowsChange={handleSelectedRowsChange}
                onExcludedRowsChange={handleExcludedRowsChange}
            />
        </div>
    </div>
}

export default GlobalIndex
