import { IconHelpCircle } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusEnum } from '@/enums'
import Model from '@/models/Model'
import System from '@/models/System'
import SystemStatusCategory from '@/models/SystemStatusCategory'
import SystemTierCategory from '@/models/SystemTierCategory'

export const colorMap: { [key: string]: { bg: string, text: string, stroke: string, border: string } } = {
    success: { bg: 'bg-success-100', text: 'text-success-500', stroke: 'stroke-success-600', border: 'border-success-300' },
    error: { bg: 'bg-error-100', text: 'text-error-600', stroke: 'stroke-error-600', border: 'border-error-300' },
    warning: { bg: 'bg-warning-100', text: 'text-warning-600', stroke: 'stroke-warning-600', border: 'border-warning-300' },
    yellow: { bg: 'bg-yellow-200', text: 'text-yellow-900', stroke: 'stroke-yellow-900', border: 'border-yellow-300' },
    blue: { bg: 'bg-blue-100', text: 'text-blue-600', stroke: 'stroke-blue-600', border: 'border-blue-300' },
    default: { bg: 'bg-gray-200', text: 'text-gray-600', stroke: 'stroke-gray-600', border: 'border-gray-300' }
}

export const sizeMap: { [key: string]: string } = {
    xs: 'w-6 h-6',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12'
}

export default class SystemStatus extends Model {
    system?: System

    value: SystemStatusEnum

    title: string

    constructor({ troubleshooting, ...props }: any) {
        super(props)
        this.value = props.value
        this.title = props.title
        this._troubleshooting = troubleshooting
    }

    get troubleshooting() {
        const key = this.system?.inverterManufacturer?.key as string
        const isUsesMaintainer = this.system?.homeowner?.company?.enphaseIntegration?.isUsesMaintainer

        if (this._troubleshooting.default) {
            if (isUsesMaintainer && this._troubleshooting.maintainer && this._troubleshooting.maintainer[key]) {
                return this._troubleshooting.maintainer[key]
            } else if (this._troubleshooting[key]) {
                return this._troubleshooting[key]
            }
            return this._troubleshooting.default
        }
        return this._troubleshooting
    }

    static get defaultStatus() {
        return new SystemStatus({
            value: 'unknown',
            title: 'Unknown',
            icon: (size: IconSize) => <IconHelpCircle size={size}/>
        })
    }

    get isUnknown() {
        return this.value === SystemStatus.defaultStatus.value
    }

    get isKnown() {
        return !this.isUnknown
    }

    static get all() {
        return SystemTierCategory.all.reduce((acc: SystemStatus[], item) => [...acc, ...item.statuses], [])
    }

    get category() {
        return SystemStatusCategory.findByStatus(this.value)
    }

    get tier() {
        return SystemTierCategory.findByStatus(this.value)
    }

    static find(status: string): SystemStatus {
        return SystemStatus.all.find(({ value }) => value === status) || SystemStatus.defaultStatus
    }

    get colors() {
        const color = this.color || this.tier.color || this.category.color || 'default'
        return colorMap[color] || colorMap.default
    }

    public iconBadge(size = 'sm', className = '') {
        return <div
            style={{ boxShadow: '0 0 0 1px #fff' }}
            className={`flex justify-center items-center rounded-full text-sm font-display ${this.colors.bg} ${this.colors.stroke} ${this.colors.text} ${sizeMap[size]} ${className}`}
        >
            {this.icon(size)}
        </div>
    }
}
