import classnames from 'classnames'
import { ReactEventHandler, ReactNode, useMemo } from 'react'

import { Checkbox, IconArrowDown, RowProps } from '@/components'
import IconArrowUp from '@/components/icons/IconArrowUp'

type ReservedFields = '_page' | '_className' | '_tooltip' | '_path' | '_group' | 'onClick'

export type ColumnProps = {
    title: ReactNode
    title_raw?: string
    field: Exclude<string, ReservedFields>
    clickable?: boolean
    sticky?: boolean
    className?: string
    cellClassName?: string | ((row: RowProps) => string)
    sortable?: boolean
    showIf?: boolean
}

type TheadProps = {
    columns: Array<ColumnProps>
    selectedRows: Array<object> | 'all'
    excludedRows: Array<object>
    rows: Array<object>
    selectable?: boolean
    onChange: ReactEventHandler
    sortable?: boolean
    sortColumn: string | null
    descending: boolean
    onSortColumnChange: any
    onSortDirectionChange: any
    dataType?: string
    meta?: any
    'data-test'?: string
}

const Thead = ({
    columns,
    selectable,
    selectedRows,
    rows,
    sortColumn,
    descending,
    sortable,
    dataType,
    meta,
    excludedRows,
    'data-test': dataTest,
    ...props
}: TheadProps) => {
    const handleClick = async (column: ColumnProps) => {
        if (column.sortable && sortable) {
            props.onSortColumnChange('sort_column', column.field)
            props.onSortDirectionChange('descending', column.field === sortColumn ? !descending : false)
        }
    }

    const getSelectedCount = useMemo(() => selectedRows === 'all' && meta?.total
        ? meta.total - excludedRows.length
        : selectedRows.length,
    [selectedRows, excludedRows, meta])

    const getIsIndeterminate = useMemo(() => selectedRows === 'all'
        ? !!excludedRows.length
        : selectedRows.length !== rows.length && !!selectedRows.length,
    [selectedRows, excludedRows, rows])

    const getIsChecked = useMemo(() => selectedRows === 'all'
        ? !excludedRows.length
        : selectedRows.length === rows.length && !!selectedRows.length,
    [selectedRows, excludedRows, rows])

    return <thead>
        <tr>
            {selectable && <th className="w-14">
                <div className="thead-cell !pr-1.5">
                    <div className="flex items-center">
                        <Checkbox
                            name="rows"
                            value="all"
                            checked={getIsChecked}
                            onChange={props.onChange}
                            indeterminate={getIsIndeterminate}
                            data-test={`${dataTest}-header-checkbox`}
                        />
                    </div>
                </div>
            </th>}
            {columns.map(({
                sortable: columnSortable = true,
                showIf = true,
                sticky = false,
                ...column
            }, index) => {
                if (!showIf) return null
                const className = classnames(column.className, { 'sticky-column': sticky })
                const cellClassName = classnames('thead-cell !block', column.cellClassName, {
                    sortable: sortable && columnSortable,
                    '!pl-1.5': selectable
                })
                return <th
                    onClick={() => handleClick({ sortable: columnSortable, ...column })}
                    key={column.field}
                    className={className}
                    data-test={`${dataTest}-header-${column.field}`}
                >
                    <div className={cellClassName}>
                        {selectable && !index && <div className="whitespace-nowrap">
                            <span className="font-semibold">{getSelectedCount.format()}</span> {dataType} Selected
                        </div>}
                        <div className="flex items-center">
                            {column.title}
                            {sortable && sortColumn === column.field && <>
                                {descending
                                    ? <IconArrowDown className="stroke-gray-500 ml-1" size="sm"/>
                                    : <IconArrowUp className="stroke-gray-500 ml-1" size="sm"/>}
                            </>}
                        </div>
                    </div>
                </th>
            })}
        </tr>
    </thead>
}

export default Thead
