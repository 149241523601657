import { useState } from 'react'

import { Button, Popup } from '@/components'
import { InverterManufacturer } from '@/models'

const GeneracIntegrationInstructions = ({ className }: { className?: string }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpened, setIsOpened] = useState(false)

    const handleClickClose = () => {
        setIsOpen(false)
        setIsOpened(true)
    }

    return <>
        <Button className={className} onClick={() => setIsOpen(true)} type="button" design={isOpened ? 'btn-secondary-gray' : 'btn-primary'}>
            Set Up Your Fleet Account Integration Today
        </Button>

        <Popup open={isOpen} onClose={handleClickClose} className="w-full lg:w-[718px] lg:px-10">
            <h2 className="flex gap-3 items-center text-xl font-semibold">
                {InverterManufacturer.generac.getLogo()}
                Generac Integration Instructions
            </h2>

            <p className="mt-4 px-4 py-2 text-sm text-gray-750">
                For a detailed guide with images, download the full PDF instructions.
            </p>

            <p className="px-4 py-2 text-sm text-gray-750">
                Follow these simple steps to seamlessly integrate your Generac system
                with Solar Insure. <span className="font-semibold">Both company-level and site-level associations are
                required to avoid monitoring issues.</span>
            </p>

            <div className="mt-4 p-4 bg-primary-25 rounded-lg text-sm leading-5 text-gray-700 max-w-[40rem] flex flex-col gap-4">
                <h4 className="font-semibold">Company Level Association</h4>
                <ul className="list-decimal pl-4">
                    <li>
                        <span className="font-semibold">Log into</span> the Generac portal at <a href="https://mypwrview.generac.com/login">https://mypwrview.generac.com/</a> and select the three-dot menu.
                    </li>
                    <li>
                        Navigate to the &quot;<span className="font-semibold">Account Settings</span>&quot; and in the dropdown, go to the &quot;<span className="font-semibold">Company Associations</span>&quot; section. Here you will see a list of your installation partners.
                    </li>
                    <li>
                        Select the &quot;<span className="font-semibold">Associations Requests</span>&quot;.
                    </li>
                    <li>
                        If you need to request an association with a financing or O&M partner, click on the &quot;<span className="font-semibold">Request Association</span>&quot; button in the &quot;Your financing and O&M partners&quot; section to open up further options.
                    </li>
                    <li>
                        A pop-up will appear prompting you to associate companies with your PWRfleet. <span className="font-semibold">Enter monitoring@solarinsure.com</span> address into the field provided.
                    </li>
                </ul>
            </div>

            <div className="mt-4 p-4 bg-primary-25 rounded-lg text-sm leading-5 text-gray-700 max-w-[40rem] flex flex-col gap-4">
                <h4 className="font-semibold">Individual Site Association</h4>
                <ul className="list-decimal pl-4">
                    <li>
                        <span>
                            After completing the company-level association, navigate to the <span className="font-semibold">&quot;All Sites&quot;</span> list.</span>
                    </li>

                    <li>
                        <span><span className="font-semibold">Select a site</span> that has a Solar Insure warranty.</span>
                    </li>
                    <li>
                        <span>Go to the <span className="font-semibold">&quot;Access&quot;</span> tab on the left sidebar navigation menu.</span>
                    </li>
                    <li>
                        <span>Click on the <span className="font-semibold">&quot;Share Site&quot;</span> button.</span>
                    </li>
                    <li>
                        <span>
                            If you followed the company-level association steps first, monitoring@solarinsure.com
                            or Solar Insure should now be a selectable option. If you do not see it, click on
                            &quot;Company Settings&quot; to request an association with monitoring@solarinsure.com.
                        </span>
                    </li>
                </ul>
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-start mt-4 p-4 gap-4 items-start text-gray-700 text-sm">
                <div>
                    <p>
                        For personalized assistance, our Monitoring Specialist team is ready to help.
                        Contact us directly at:
                    </p>

                    <ul className="list-disc pl-5 mt-4">
                        <li>Email: <a href="mailto:valerie@solarinsure.com">valerie@solarinsure.com</a></li>
                        <li>Phone: <a href="tel:714-676-7373">714-676-7373</a></li>
                        <li>Visit: <a href="https://www.solarinsure.com" target="_blank" rel="noreferrer">www.solarinsure.com</a></li>
                    </ul>
                </div>
                <div>
                    <Button design="btn-secondary" className="self-end text-center" target="_blank" href="/resources/Generac_Fleet_&_Site_Level_Integrations.pdf">
                        Click here for a Visual Guide
                    </Button>
                    <p className="mt-4">
                        We&apos;re here to ensure your integration process is smooth and efficient.
                    </p>
                </div>
            </div>
        </Popup>
    </>
}

export default GeneracIntegrationInstructions
