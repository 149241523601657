import Fuse, { FuseResult, IFuseOptions } from 'fuse.js'

const DEFAULT_OPTIONS = {
    includeScore: true,
    includeMatches: true
}

export default (list: ReadonlyArray<string>, pattern: string | string[], options: IFuseOptions<any> = {}) => {
    const fuse = new Fuse(list, { ...DEFAULT_OPTIONS, ...options })
    const [res] = Array.isArray(pattern) ? pattern.reduce((acc: FuseResult<string>[], item) => {
        const res = fuse.search(item)
        if (!acc.length) return res
        if (!res.length) return acc
        if (typeof acc[0].score !== 'undefined' && typeof res[0].score !== 'undefined') {
            return acc[0].score > res[0].score ? res : acc
        }

        return acc
    }, []) : fuse.search(pattern)

    return res
}
