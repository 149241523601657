import { Input } from '@/components'

type IntegrationMaintainerProps = {
    form: any
    errors?: any
    onChange: any
    disabled?: boolean
    inputClassName?: string
}

const IntegrationMaintainer = ({ form, inputClassName, disabled, errors, ...props }: IntegrationMaintainerProps) =>
    <>
        <Input
            id="integration-portal-portal-namw"
            name="contact_name"
            label="Name"
            placeholder="Name"
            onChange={props.onChange}
            value={form.contact_name}
            errors={errors.contact_name}
            className={inputClassName}
            disabled={disabled}
        />

        <Input
            id="integration-portal-contact-email"
            name="contact_email"
            label="Contact Email"
            placeholder="Contact Email"
            onChange={props.onChange}
            value={form.contact_email}
            errors={errors.contact_email}
            className={inputClassName}
            disabled={disabled}
        />
    </>

export default IntegrationMaintainer
