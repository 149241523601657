const DEFAULT_LOCALE = 'en-US'
const DEFAULT_CURRENCY = 'USD'
interface NumberFormatOptions {
    localeMatcher?: string | undefined;
    style?: string | undefined;
    currency?: string | undefined;
    currencySign?: string | undefined;
    useGrouping?: boolean | undefined;
    minimumIntegerDigits?: number | undefined;
    minimumFractionDigits?: number | undefined;
    maximumFractionDigits?: number | undefined;
    minimumSignificantDigits?: number | undefined;
    maximumSignificantDigits?: number | undefined;
}

declare global {
    interface Number {
        money(options?: {
            locale?: string
            currency?: string
            maximumFractionDigits?: number
            minimumFractionDigits?: number
        }): string

        format(options?: {
            maximumFractionDigits?: number,
            minimumFractionDigits?: number,
        }): string
    }
}

Number.prototype.money = function (this: number, {
    locale = DEFAULT_LOCALE,
    currency = DEFAULT_CURRENCY,
    maximumFractionDigits = 2
} = {}) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency, maximumFractionDigits }).format(this)
}

Number.prototype.format = function (this: number, options?: NumberFormatOptions) {
    return new Intl.NumberFormat(DEFAULT_LOCALE, options).format(this)
}

export {}
