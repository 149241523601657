import { ChangeEvent, useEffect, useState } from 'react'

import { Button, Checkbox, IconAlertCircle, Popup, Radio, RowProps } from '@/components'
import { api } from '@/services'
import type { AddressType } from '@/types'

type Props = {
    isOpen: boolean
    form: any
    row: RowProps

    onChange: any
    onClose: () => void
}

const HandleUnverifiedAddressPopup = ({
    isOpen,
    row,
    ...props
}: Props) => {
    const [form, setForm] = useState<{ action: string }>({ action: '' })
    const [suggestions, setSuggestions] = useState<AddressType[]>([])
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.value
        }))
    }

    const fetchSuggestions = async () => {
        const res = await api.address.autocomplete(row.street_address || row.address)
        setSuggestions(res)
    }

    useEffect(() => {
        fetchSuggestions()
    }, [row])

    const handleConfirm = () => {
        const clone = [...props.form.warranties]
        const { index } = row
        if (form.action === 'allow') {
            clone[index as number].allow_invalid = true
        } else {
            const suggestion = suggestions[parseInt(form.action)]
            clone[index as number].street_address = suggestion.street_address
            clone[index as number].city = suggestion.city
            clone[index as number].state = suggestion.state
            clone[index as number].zip = suggestion.zip
        }
        props.onChange({ target: { name: 'warranties', value: clone } })
        props.onClose()
    }

    return <Popup open={isOpen} onClose={props.onClose} className="w-full md:w-auto">
        <div className="flex flex-col gap-6">
            <h4 className="text-xl text-gray-900 flex gap-3 items-center">
                <IconAlertCircle className="stroke-error-600" size="xl"/>
                Invalid Address
            </h4>
            <p className="text-gray-700">
                We detected an invalid address in row #{1 + (row.index as number)}. This means the address you supplied
                could not be verified. Invalid addresses can occur for several reasons, such as typos, incomplete
                details, or new constructions that may not yet be in the postal service verification database.
                {!!suggestions.length && 'Please review the suggestions below to correct the error:'}
            </p>
            <div className="flex gap-4 flex-col text-sm text-gray-700">
                {!!suggestions.length && <>
                    <p>
                        1. <span className="font-semibold">Select a suggested address:</span> Click on a suggestion to
                        automatically update the address fields.
                    </p>
                    <div className="flex flex-col gap-2">
                        {suggestions.map((item, index) => <Radio
                            key={index}
                            label={`${item.street_address}, ${item.city}, ${item.state}`}
                            name="action"
                            value={index}
                            onChange={handleChange}
                            checked={parseInt(form.action) === index}
                        />)}
                    </div>
                </>}

                <p>
                    {suggestions.length ? '2.' : ''} <span className="font-semibold">Accept Invalid Address:</span> If you are certain the address is
                    correct, especially in the case of new constructions, check the box below to proceed with the
                    invalid address.
                </p>

                <Checkbox
                    label="Allow Invalid Address"
                    name="action"
                    onChange={handleChange}
                    value="allow"
                    checked={form.action === 'allow'}
                />
            </div>

            <div className="flex flex-wrap gap-4 justify-center">
                <Button hasError design="btn-secondary-gray" type="button" onClick={props.onClose}>
                    Exit without Changing
                </Button>
                <Button type="button" onClick={handleConfirm} disabled={!form.action}>
                    Update Address
                </Button>
            </div>
        </div>
    </Popup>
}

export default HandleUnverifiedAddressPopup
