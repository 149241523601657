import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DNS,
    enabled: import.meta.env.PROD,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT || 'local',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
        'TypeError: Failed to fetch',
        'AbortError'
    ]
})
if (import.meta.env.PROD) Sentry.showReportDialog({ eventId: 'daybreak' })
