export default {
    start() {
        document.dispatchEvent(new CustomEvent('onLoaderStart'))
    },
    stop() {
        document.dispatchEvent(new CustomEvent('onLoaderStop'))
    },
    set(value?: number) {
        document.dispatchEvent(new CustomEvent('onLoaderChange', { detail: { value } }))
    }
}
