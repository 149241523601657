import { useParams } from 'react-router-dom'

import {
    Alert,
    Button,
    Card, DeviceAlerts, DevicesList,
    IconHelpCircle,
    IconLightBulb05, IconLightning02, Logo, MonitoringError, Tooltip, TroubleshootingGuidePopup
} from '@/components'
import { Warranty } from '@/models'

const MonitoringShow = ({ warranty }: { warranty: Warranty }) => {
    const { type, id } = useParams()

    const getErrors = () => {
        const errors = warranty.homeowner?.monitoring_data?.errors
        if (typeof errors?.message === 'string') return [errors.message]
        if (Array.isArray(errors)) {
            return errors.reduce((acc: any, item: any) => {
                try {
                    item = JSON.parse(item)
                } catch (e) {
                    // do nothing
                }
                if (typeof item === 'string') {
                    try {
                        return [...acc, JSON.parse(item).message]
                    } catch (e) {
                        return [...acc, item]
                    }
                } else if (typeof item === 'object') {
                    if (typeof item.message === 'string') {
                        return [...acc, `${item.message}${item.url ? ` (${item.url})` : ''}`]
                    } else if (typeof item.message === 'object') {
                        return [...acc, ...item.message]
                    }
                    return acc
                } else {
                    return acc
                }
            }, []).filter((item: string) => !item.includes('!doctype'))
        }
        return []
    }

    const getDetectedAtLabel = warranty?.homeowner?.system.status_change_detected_at &&
        <div className="flex gap-2 text-xs font-body text-gray-500 leading-6">
            Detected {new Date(warranty?.homeowner?.system.status_change_detected_at).clearTimeZone().format()}
            <Tooltip
                placement="bottom-end"
                content={<div className="max-w-[18.5rem]">
                    <h4 className="font-semibold mb-1">Detection Date</h4>
                    <p>
                        Solar Insure&apos;s Detection Date reflects when the company
                        became aware of a site&apos;s system status. Since Solar
                        Insure pulls data from the inverter manufacturer every 3-7
                        days, there may be a delay between the detection date and
                        the actual date when the status first appeared in the
                        manufacturer&apos;s database.
                    </p>
                </div>}
            >
                <IconHelpCircle className="stroke-gray-400" size="sm"/>
            </Tooltip>
        </div>

    return <>
        {!!getErrors().length && <Alert type="error" className="mb-6">
            <ul>
                {getErrors().map((item: any, index: number) => <li
                    key={index} dangerouslySetInnerHTML={{ __html: item }}
                />)}
            </ul>
        </Alert>}
        {!warranty.homeowner?.system?.systemStatus?.category.isConnected
            ? <MonitoringError warranty={warranty}/>
            : <>
                <div className="grid lg:grid-cols-3 lg:grid-rows-3 gap-6 mb-6">
                    <Card className="lg:row-span-2 lg:col-start-1">
                        <h2 className="card-title mb-3 font-semibold text-base text-gray-900 flex justify-between items-center" data-test="monitoring-details-system-status-title">
                            System Status {warranty.homeowner?.system.systemStatus.troubleshooting &&
                                <TroubleshootingGuidePopup system={warranty.homeowner?.system}/>}
                        </h2>
                        <p className="flex gap-2 text-gray-500 mb-6 text-xs lg:text-base" data-test="monitoring-details-last-updated-at">
                            Last Updated at
                            <span>
                                {warranty.homeowner?.monitoring_data?.updated_at
                                    ? new Date(`${warranty.homeowner?.monitoring_data?.updated_at}Z`).format({
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        timeZoneName: 'short',
                                        hour12: false
                                    })
                                    : '-'}
                            </span>
                        </p>
                        {warranty?.homeowner?.system.inverterManufacturer.isGenerac
                            ? warranty?.homeowner?.system.generacSystems?.map(item =>
                                <div key={item.id} className="card-row mb-6" data-test="monitoring-details-system-status-generac">
                                    <h3 className="card-row-title">System {item.serialNumber} Status</h3>
                                    <div>
                                        <div className="card-row-copy flex items-center gap-2">
                                            {item?.systemStatus?.iconBadge()}
                                            {item?.systemStatus.title}
                                        </div>
                                        {getDetectedAtLabel}
                                    </div>
                                </div>)
                            : <div className="card-row mb-6" data-test="monitoring-details-system-status">
                                <h3 className="card-row-title">System Status</h3>
                                <div>
                                    <div className="card-row-copy flex items-center gap-2">
                                        {warranty?.homeowner?.system?.systemStatus?.iconBadge()}
                                        {warranty.homeowner?.system?.systemStatus.title}
                                    </div>
                                    {getDetectedAtLabel}
                                </div>
                            </div>}
                        <div className="card-row mb-6" data-test="monitoring-details-installer">
                            <h3 className="card-row-title">Installer</h3>
                            <p className="card-row-copy">
                                {warranty.homeowner?.company?.name || '-'}
                            </p>
                        </div>
                    </Card>

                    <Card className="lg:row-span-1 lg:row-start-3 lg:col-start-1">
                        <h2 className="card-title mb-6 font-semibold text-base text-gray-900" data-test="monitoring-details-power-title">Power</h2>

                        <div className="card-row" data-test="monitoring-details-last-updated-power-production">
                            <h3 className="card-row-title">Last Updated Power Production</h3>
                            <p className="card-row-copy flex items-center font-semibold gap-2">
                                <IconLightning02 className="stroke-gray-900"/>
                                {isNaN(parseFloat(
                                    warranty?.homeowner?.monitoring_data.system?.summary?.current_power_production_w
                                ))
                                    ? '-'
                                    : parseFloat(
                                        warranty?.homeowner?.monitoring_data.system?.summary?.current_power_production_w
                                    )
                                        .format()}
                                <span className="text-gray-500 font-light">kWh</span>
                            </p>
                        </div>
                    </Card>

                    <Card className="lg:row-span-3">
                        <h2 className="card-title mb-6 font-semibold text-base text-gray-900" data-test="monitoring-details-info-title">Info</h2>

                        <div className="grid grid-cols-2">
                            <div className="card-row mb-6" data-test="monitoring-details-homeowner-name">
                                <h3 className="card-row-title">Homeowner Name</h3>
                                <p className="card-row-copy">
                                    {warranty.homeowner?.fullName}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-system-name">
                                <h3 className="card-row-title">System Name</h3>
                                <p className="card-row-copy">
                                    {warranty.homeowner?.system?.name || '-'}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-install-date">
                                <h3 className="card-row-title">Install Date</h3>
                                <p className="card-row-copy">
                                    {new Date(warranty.homeowner?.install_date).format()}
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-pto-date">
                                <h3 className="card-row-title">PTO Date</h3>
                                <p className="card-row-copy">
                                    {warranty?.homeowner?.monitoring_data?.system?.summary?.pto_date &&
                                    new Date(warranty?.homeowner?.monitoring_data.system.summary.pto_date).isValid()
                                        ? new Date(warranty?.homeowner?.monitoring_data.system.summary.pto_date)
                                            .format()
                                        : warranty?.homeowner?.monitoring_data?.system?.summary?.pto_date || '-'}
                                </p>
                            </div>
                        </div>
                        <div className="card-row mb-6" data-test="monitoring-details-system-size">
                            <h3 className="card-row-title">System Size</h3>
                            <p className="card-row-copy">{Warranty.getField('size_kw').format(warranty.size_kw)} kW</p>
                        </div>
                        <div className="card-row mb-6" data-test="monitoring-details-location">
                            <h3 className="card-row-title">Location</h3>
                            <p className="card-row-copy">
                                {warranty.homeowner?.address}
                            </p>
                        </div>
                        <div className="card-row mb-6" data-test="monitoring-details-inverter-model">
                            <h3 className="card-row-title">Inverter Model</h3>
                            <p className="card-row-copy">{warranty.inverter.model_name}</p>
                        </div>
                        <div className="card-row mb-6" data-test="monitoring-details-panel-model">
                            <h3 className="card-row-title">Panel Model</h3>
                            <p className="card-row-copy">{warranty.panel.model_name}</p>
                        </div>
                    </Card>

                    <Card className="lg:row-span-3">
                        <h2 className="mb-6 text-base font-semibold card-title flex justify-between items-center text-gray-900">
                            <span data-test="monitoring-details-energy-title">
                                Energy
                            </span>
                            <Button className="text-sm leading-6" design="btn-secondary" href={`/${type}/${id}/monitoring/energy-graph`} data-test="monitoring-details-view-graph-button">
                                View Graph
                            </Button>
                        </h2>

                        <div className="grid grid-cols-2">
                            <div className="col-span-2 text-sm mb-2" data-test="monitoring-details-past-seven-days-title">
                                <div className="text-warning-700 bg-warning-50 px-2.5 py-0.5 rounded-xl font-light inline-block leading-5">
                                    Past 7 Days
                                </div>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-produced-past-seven-days">
                                <h3 className="card-row-title">Produced</h3>
                                <p className="card-row-copy flex items-center font-semibold gap-2">
                                    <IconLightning02 className="stroke-gray-900"/>
                                    {warranty?.homeowner?.monitoring_data.system?.production?.past_seven_days?.sum || '-'}
                                    <span className="text-gray-500 font-light">kWh</span>
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-consumed-past-seven-days">
                                <h3 className="card-row-title">Consumed</h3>
                                <p className="card-row-copy flex items-center font-semibold gap-2">
                                    <IconLightBulb05 className="stroke-gray-900"/>
                                    {warranty?.homeowner?.monitoring_data.system?.consumption?.past_seven_days?.sum || '-'}
                                    <span className="text-gray-500 font-light">kWh</span>
                                </p>
                            </div>

                            <div className="col-span-2 text-sm mb-2" data-test="monitoring-details-month-to-date-title">
                                <div className="text-warning-700 bg-warning-50 px-2.5 py-0.5 rounded-xl font-light inline-block leading-5">
                                    Month To Date
                                </div>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-produced-month-to-date">
                                <h3 className="card-row-title">Produced</h3>
                                <p className="card-row-copy flex items-center font-semibold gap-2">
                                    <IconLightning02 className="stroke-gray-900"/>
                                    {warranty?.homeowner?.monitoring_data.system?.production?.month_to_date?.sum || '-'}
                                    <span className="text-gray-500 font-light">kWh</span>
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-consumed-month-to-date">
                                <h3 className="card-row-title">Consumed</h3>
                                <p className="card-row-copy flex items-center font-semibold gap-2">
                                    <IconLightBulb05 className="stroke-gray-900"/>
                                    {warranty?.homeowner?.monitoring_data.system?.consumption?.month_to_date?.sum || '-'}
                                    <span className="text-gray-500 font-light">kWh</span>
                                </p>
                            </div>

                            <div className="col-span-2 text-sm mb-2" data-test="monitoring-details-lifetime-title">
                                <div className="text-warning-700 bg-warning-50 px-2.5 py-0.5 rounded-xl font-light inline-block leading-5">
                                    Lifetime
                                </div>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-produced-lifetime">
                                <h3 className="card-row-title">Produced</h3>
                                <p className="card-row-copy flex items-center font-semibold gap-2">
                                    <IconLightning02 className="stroke-gray-900"/>
                                    {warranty?.homeowner?.monitoring_data.system?.production?.life_time?.sum || '-'}
                                    <span className="text-gray-500 font-light">kWh</span>
                                </p>
                            </div>
                            <div className="card-row mb-6" data-test="monitoring-details-consumed-lifetime">
                                <h3 className="card-row-title">Consumed</h3>
                                <p className="card-row-copy flex items-center font-semibold gap-2">
                                    <IconLightBulb05 className="stroke-gray-900"/>
                                    {warranty?.homeowner?.monitoring_data.system?.consumption?.life_time?.sum || '-'}
                                    <span className="text-gray-500 font-light">kWh</span>
                                </p>
                            </div>
                        </div>
                    </Card>
                </div>

                {warranty.homeowner?.system?.inverterManufacturer.isGenerac
                    ? <DeviceAlerts warranty={warranty}/>
                    : <DevicesList warranty={warranty}/>}

                {warranty.homeowner?.system?.inverterManufacturer.isEnphase && <div className="mt-6 flex justify-center">
                    <Logo type="poweredByEnphase" className="w-[246px]"/>
                </div>}
            </>}
    </>
}

export default MonitoringShow
