enum IntegrationCredentialStatusEnum {
    MAINTAINER = 'maintainer',
    VALID = 'valid',
    USES_MAINTAINER = 'uses_maintainer',
    NOT_REQUIRED = 'not_required',
    INVALID = 'invalid',
    MISSING = 'missing',
    UNSUPPORTED = 'unsupported',
    UNKNOWN = 'unknown'
}

export default IntegrationCredentialStatusEnum
