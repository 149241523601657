import { Chart as ChartJs } from 'chart.js'
import { useMemo } from 'react'

import { LineChart } from '@/components'
import { WarrantyProductType } from '@/models'
import { WarrantySalesItemDataType } from '@/types'


type SalesGraphProps = {
    labels: string[]
    data: WarrantySalesItemDataType[]
}

export const SalesGraph = ({
    labels,
    data
}: SalesGraphProps) => {
    const datasets = useMemo(() => {
        const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D
        const gradient1 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient1.addColorStop(0, 'rgba(62,188,218,0.1)')
        gradient1.addColorStop(0.5, 'transparent')
        const gradient2 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient2.addColorStop(0, 'rgba(255,165,0,0.1)')
        gradient2.addColorStop(0.5, 'transparent')
        const gradient3 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient3.addColorStop(0, 'rgba(121,109,178,0.1)')
        gradient3.addColorStop(0.5, 'transparent')
        const gradient4 = ctx.createLinearGradient(0, 0, 0, 300)
        gradient4.addColorStop(0, 'rgba(0,160,113,0.1)')
        gradient4.addColorStop(0.5, 'transparent')
        return [{
            label: 'Total Sales',
            data: data.map(item => item.total_sum),
            borderWidth: 2,
            borderColor: '#3ebcda',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient1
        }, {
            label: WarrantyProductType.solar.title,
            data: data.map(item => item.power_production_only_sum),
            borderWidth: 2,
            borderColor: '#ffa500',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient2
        }, {
            label: WarrantyProductType.battery20.title,
            data: data.map(item => item.production_with_20_year_separate_storage_sum),
            borderWidth: 2,
            borderColor: '#796db2',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient3
        }, {
            label: WarrantyProductType.battery30.title,
            data: data.map(item => item.production_with_30_year_separate_storage_sum),
            borderWidth: 2,
            borderColor: '#00a071',
            tension: 0.25,
            fill: true,
            backgroundColor: gradient4
        }]
    }, [data])
    return <LineChart
        datasets={datasets}
        labels={labels}
        plugins={[
            {
                beforeDraw: (chart: ChartJs) => {
                    if (!chart.tooltip?.opacity) return
                    const {
                        ctx,
                        chartArea: {
                            bottom,
                            top
                        }
                    } = chart
                    ctx.save()
                    ctx.lineWidth = 1
                    ctx.beginPath()
                    ctx.setLineDash([2, 2])
                    ctx.moveTo(chart.tooltip?.caretX || 0, bottom)
                    ctx.lineTo(chart.tooltip?.caretX || 0, top)
                    ctx.strokeStyle = '#d5d3d0'
                    ctx.stroke()
                }
            }
        ]}
    />
}
