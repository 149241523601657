import { SystemTierCategoryEnum } from '@/enums'
import { SystemStatusCategory } from '@/models/index'
import Model from '@/models/Model'
import SystemStatus, { colorMap, sizeMap } from '@/models/SystemStatus'

export default class SystemTierCategory extends Model {
    statuses: SystemStatus[]

    value: SystemTierCategoryEnum

    title: string

    constructor(props?: any) {
        super(props)
        this.value = props.value
        this.title = props.title
        this.statuses = props?.statuses?.map((item: any) => new SystemStatus(item)) || []
    }

    static get defaultTier() {
        return new SystemTierCategory({
            value: 'unknown',
            title: 'Unknown'
        })
    }

    get isUnknown() {
        return this.value === SystemTierCategory.defaultTier.value
    }

    get isKnown() {
        return !this.isUnknown
    }

    static get all() {
        return SystemStatusCategory.all.reduce((acc: SystemTierCategory[], item) => [...acc, ...item.tiers], [])
    }

    get category() {
        return SystemStatusCategory.findByTier(this.value)
    }

    hasStatus(status: SystemStatus | string) {
        return this.statuses
            .some(item => status instanceof SystemStatus ? item.value === status.value : item.value === status)
    }

    get secondarySortedStatuses() {
        return this.statuses
            .sort((a, b) => a.secondaryOrder && b.secondaryOrder ? a.secondaryOrder - b.secondaryOrder : 0)
    }

    static find(value: string) {
        return SystemTierCategory.all.find(item => item.value === value) ||
            SystemTierCategory.defaultTier
    }

    static findByStatus(value: string) {
        return SystemTierCategory.all.find(item => item.statuses.some(item => item.value === value)) ||
            SystemTierCategory.defaultTier
    }

    get circleIcon() {
        return <div className={`border-2 rounded-full w-3 h-3 ${this.colors.border}`}></div>
    }

    get colors() {
        const color = this.color || this.tier.color || this.category.color || 'default'
        return colorMap[color] || colorMap.default
    }

    public iconBadge(size = 'sm', className = '') {
        return <div
            style={{ boxShadow: '0 0 0 1px #fff' }}
            className={`flex justify-center items-center rounded-full text-sm font-display ${this.colors.bg} ${this.colors.stroke} ${this.colors.text} ${sizeMap[size]} ${className}`}
        >
            {this.icon(size)}
        </div>
    }
}
