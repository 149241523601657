import { useMemo, useState } from 'react'

import {
    Button,
    ContactForm,
    Select, Form
} from '@/components'
import { useAuth, useToastNotifications } from '@/hooks'
import { User } from '@/models'

type UserEditFormProps = {
    user: User,
    onSuccess?: (res: any) => void
    onExit: () => void
}
const UserEditForm = ({ user, ...props }: UserEditFormProps) => {
    const { success } = useToastNotifications()
    const auth = useAuth()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})

    const getForm = (): any => ({
        id: user.id,
        role: (user.isAdminOrStaffOrAdvisor ? user.role : user.company.type.key) || '',
        name: user.name || '',
        email: user.email || '',
        job_title: user.job_title || '',
        phone: user.phone || ''
    })
    const [form, setForm] = useState<any>(getForm())

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }

    const isDirty = useMemo(() => Object.keys(form)
        .some(key => getForm()[key] !== form[key]), [form, user])

    const handleSubmit = async (e?: any) => {
        e?.preventDefault()
        setProcessing(true)
        setErrors({})

        const payload = { ...form }
        if (['software_affiliate', 'lender_affiliate'].includes(payload.role)) {
            payload.role = 'conglomerate'
        }

        try {
            const res = user.id === auth.user?.id
                ? await User.updateSelf(payload)
                : await user.update(payload)
            if (props.onSuccess) await props.onSuccess(res)
            success('User successfully updated!')
            props.onExit()
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    return <Form isDirty={isDirty} confirmation onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-6">
            <Select
                id="role"
                name="role"
                label="Role*"
                className="col-span-1"
                options={user.isAffiliateOrContractor
                    ? [...User.companyAccountTypes, ...User.companyUserAccountTypes]
                    : User.userAccountTypes}
                value={form.role}
                onChange={handleChange}
                disabled={user.isAffiliateOrContractor}
            />
        </div>

        <ContactForm
            form={form}
            errors={errors}
            onChange={handleChange}
        />

        <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end">
            <Button design="btn-secondary-gray" onClick={props.onExit} type="button">
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing} disabled={!isDirty}>
                Save and Exit
            </Button>
        </div>
    </Form>
}

export default UserEditForm
