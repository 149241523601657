import { useEffect, useMemo, useState } from 'react'

import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateAdd.svg'
import {
    Select,
    MonitoringHeader,
    MonitoringReport,
    SearchableSelect,
    Image,
    WarrantyCreateButton,
    PageHeader,
    Tooltip,
    MonitoringStatusDropdown, InverterManufacturerDropdown, ExportButton, ExportFileType
} from '@/components'
import { AuthLayout, GlobalIndex, GlobalIndexDataType, FiltersType } from '@/containers'
import {
    InverterManufacturerEnum,
    SystemStatusCategoryEnum,
    SystemTierCategoryEnum,
    WarrantyProductTypeEnum
} from '@/enums'
import { useAuth, useNavigateWithQuery, useQuery, useAnalytics } from '@/hooks'
import { Company, InverterManufacturer, System, Warranty, WarrantyProductType } from '@/models'

interface MonitoringIndexData extends GlobalIndexDataType {
    rows: System[]
    selectedRows: System[] | 'all'
    excludedRows: System[]
}

interface MonitoringIndexFilters extends FiltersType {
    company_id: string
    manufacturers: InverterManufacturerEnum[]
    statuses: string[]
    type: WarrantyProductTypeEnum
}

const MonitoringIndex = () => {
    const query = useQuery()
    const navigateWithQuery = useNavigateWithQuery()
    const auth = useAuth()
    const { trackEvent } = useAnalytics()

    const [abortController, setAbortController] = useState<AbortController | null>(null)
    const [data, setData] = useState<MonitoringIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        selectedRows: [],
        excludedRows: []
    })
    const [contractors, setContractors] = useState<Company[]>([])

    const getDefaultFilters = () => {
        const statuses = query.statuses || []
        const manufacturers = query.manufacturers || []
        return {
            company_id: query.company_id || '',
            statuses: Array.isArray(statuses) ? statuses : [statuses],
            manufacturers: Array.isArray(manufacturers) ? manufacturers : [manufacturers],
            type: query.type || ''
        }
    }
    const [filters, setFilters] = useState<MonitoringIndexFilters>(getDefaultFilters())

    const [summary, setSummary] = useState({
        sites: { total: 0 },
        manufacturers: {},
        groups: {
            [SystemTierCategoryEnum.TIER_1]: 0,
            [SystemTierCategoryEnum.TIER_2]: 0,
            [SystemTierCategoryEnum.TIER_3]: 0,
            [SystemTierCategoryEnum.NORMAL_SITES]: 0,
            [SystemStatusCategoryEnum.MONITORED]: 0
        },
        statuses_count: {}
    })

    const handleExport = async (format: ExportFileType) => {
        trackEvent(`export_monitoring_${format}`, 'User Interaction', 'Export Systems')

        return System.export(data.selectedRows === 'all'
            ? { excluded_ids: data.excludedRows.map(({ id }) => id), select_all: true, ...query, format }
            : { ids: data.selectedRows.map(({ id }) => id), format }
        )
    }

    const handleChange = ({ name, value }: any) => {
        setData(data => ({ ...data, [name]: value }))
    }
    const handleFiltersChange = ({ target: { name, value } }: any) => {
        navigateWithQuery({ page: null, [name]: value })
        setFilters({ ...filters, [name]: value })
    }

    const fetchSummary = async () => {
        abortController?.abort('canceled')
        const controller = new AbortController
        setAbortController(controller)
        const { data } = await System.summary(filters, { signal: controller.signal })
        setSummary(data)
    }

    const fetchContractors = async () => {
        const data = await Company.onlyContractors()
        setContractors(data)
    }

    useEffect(() => {
        if (auth.user?.isAdminOrStaffOrAdvisor) {
            fetchContractors()
        }
    }, [])

    useEffect(() => {
        fetchSummary()
    }, [filters])

    const contractorOptions = useMemo(() => contractors.map(item => ({
        value: item.id,
        title: item.name
    })), [contractors])

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.warranty_id,
        status: <div className="flex items-center gap-3">
            <Tooltip content={<div className="p-1">
                <div className="flex items-start">
                    <div>
                        {item.systemStatus.iconBadge()}
                    </div>
                    <div className="flex flex-col gap-1 pl-2">
                        <span className="font-bold">{item.systemStatus.title}:</span>
                        <div className="font-normal">
                            {item.systemStatus.description}
                        </div>
                    </div>
                </div>
            </div>}>
                {item.systemStatus.iconBadge()}
            </Tooltip>
            <div className="flex flex-col">
                {item.systemStatus.title}
                {item.status_change_detected_at &&
                    <div className="text-xs font-body text-gray-500 font-normal leading-[14px]">
                        Detected {new Date(item.status_change_detected_at).format()}
                    </div>}
            </div>
        </div>,
        customer_name: item.customer_name,
        contractor: item.company?.name || '-',
        manufacturer: InverterManufacturer.find(item.manufacturer)?.title,
        location: `${item.city}, ${item.state}`,
        system_size: `${Warranty.getField('size_kw')
            .format(item.system_size)} kW`,
        pto_date: item.pto_date && new Date(item.pto_date).isValid() ? new Date(item.pto_date).format() : '-',
        inverter_model: item.inverter_model || '-',
        plan_number: item.plan_number || '-',
        last_updated_at: item.last_updated_at && new Date(item.last_updated_at).isValid() ? new Date(item.last_updated_at).format() : '-',
        registration_date: item.registration_date && new Date(item.registration_date).isValid() ? new Date(item.registration_date).format() : '-',
        system_id: item.system_id || '-',
        panel_model: item.panel_model || '-',
        installation_date: item.installation_date && new Date(item.installation_date).isValid() ? new Date(item.installation_date).format() : '-',

        type: new Warranty(item).productType.icons('xs', true),
        num_panels: item.num_panels,
        battery_coverage_end_date: item.battery_coverage_end_date?.isValid()
            ? item.battery_coverage_end_date.format() : '-',
        battery_make: item.battery_make,
        battery_size_kw: item.battery_size_kw
            ? `${Warranty.getField('battery_size_kw').format(item.battery_size_kw)} kW` : '-',
        detection_date: item.status_change_detected_at && new Date(item.status_change_detected_at).format(),
        salesforce_id: item.company.unique_id,
        _path: `/monitoring/${item.warranty_id}/monitoring`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Monitoring"/>}>

        <MonitoringHeader/>

        <MonitoringReport
            data={summary}
        />

        <GlobalIndex
            id="monitoring"
            infinity
            selectable
            searchPlaceholder="Search by Name, Email, Address, Plan ID, System ID, or More"
            dataType="Systems"
            data={data}
            filters={filters}
            defaultTableFilters={{ descending: true }}
            getDefaultFilters={getDefaultFilters}
            maxColumnsAmount={6}
            onChange={handleChange}
            onFiltersChange={filters => setFilters(filters)}
            api={(...args) => System.index(...args)}
            leftSide={<>
                {auth.user?.isAdminOrStaffOrAdvisor &&
                    <SearchableSelect
                        id="company-id"
                        name="company_id"
                        value={filters.company_id}
                        placeholder="Any Contractor"
                        options={contractorOptions}
                        onChange={handleFiltersChange}
                        data-test="monitoring-index-company-id-filter"
                    />}
                <InverterManufacturerDropdown
                    name="manufacturers"
                    id="manufacturers"
                    value={filters.manufacturers}
                    onChange={handleFiltersChange}
                    summary={summary}
                    data-test="monitoring-index-manufacturers-filter"
                />
                <MonitoringStatusDropdown
                    name="statuses"
                    id="warranty-statuses"
                    value={filters.statuses}
                    onChange={handleFiltersChange}
                    summary={summary}
                    data-test="monitoring-index-statuses-filter"
                />
                <Select
                    name="type"
                    id="warranty-type"
                    className="grow"
                    options={[
                        { value: '', title: 'Any Type' },
                        ...WarrantyProductType.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2">
                                <div className="w-12">{item.icons()}</div>
                                {item.title}
                            </div>
                        }))
                    ]}
                    value={filters.type}
                    onChange={handleFiltersChange}
                    data-test="monitoring-index-type-filter"
                />
            </>}
            rightSide={<>
                <ExportButton
                    disabled={!data.selectedRows.length}
                    onExport={handleExport}
                    data-test="monitoring-index"
                />
            </>}
            placeholder={data.meta.total === 0 && !auth.user?.isAdvisor && <>
                <Image
                    className="w-30"
                    src={tableEmptyStateAdd}
                />
                <div className="mt-4 flex flex-col items-center">
                    <h4 className="text-center font-light">Start by Adding a New Warranty</h4>
                    <p className="text-center text-gray-500 font-light text-sm mt-1">
                        Monitoring Data for registered warranties will live here. Start registering Solar Insure
                        Warranties to see monitoring connections.
                    </p>
                    <WarrantyCreateButton className="mt-6"/>
                </div>
            </>}
            columns={[
                { title: 'System Status', field: 'status', cellClassName: '!py-3.5' },
                { title: 'Homeowner Name', field: 'customer_name' },
                { title: 'Contractor', field: 'contractor', showIf: auth.user?.isAdminOrStaffOrAdvisor },
                { title: 'Manufacturer', field: 'manufacturer' },
                { title: 'Location', field: 'location' },
                { title: 'System Size', field: 'system_size' },
                { title: 'PTO Date', field: 'pto_date', sortable: false },
                { title: 'Inverter Model', field: 'inverter_model' },
                { title: 'Plan ID', field: 'plan_number' },
                { title: 'Last Update Date', field: 'last_updated_at' },
                { title: 'Submission Date', field: 'registration_date' },
                { title: 'System ID', field: 'system_id' },
                { title: 'Panel Model', field: 'panel_model' },
                { title: 'Installation Date', field: 'installation_date' },

                { title: 'Warranty Type', field: 'type' },
                { title: 'Number of Panels', field: 'num_panels' },
                { title: 'Battery Coverage End Date', field: 'battery_coverage_end_date' },
                { title: 'Battery Make', field: 'battery_make' },
                { title: 'Battery Storage', field: 'battery_size_kw' },
                { title: 'Detection Date', field: 'detection_date' },
                { title: 'Contractor SalesForce Unique ID', field: 'salesforce_id', showIf: auth.user?.isAdminOrStaffOrAdvisor }
            ]}
            rows={rowsMemo}
        />
    </AuthLayout>
}

export default MonitoringIndex
