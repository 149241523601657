import { InputHTMLAttributes } from 'react'

export interface Props
    extends InputHTMLAttributes<HTMLInputElement> {
    label: string
}

export const Radio = ({ label, ...props }: Props) => <label data-test={`${props.id}-radio-label`} className={`radio${props.checked ? ' checked' : ''}`}>
    <input data-test={`${props.id}-radio`} type="radio" {...props}/>
    <div>
        <div className="radio-icon"/>
    </div>
    {label}
</label>
