import { useEffect, useRef, useState } from 'react'

import { Button, Popup } from '@/components'
import { api } from '@/services'

const useReleaseCheck = () => {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const [isOutdated, setIsOutdated] = useState(false)
    const handleReleaseCheck = async () => {
        if (isOutdated || import.meta.env.DEV) return

        const res = await api.http.request(api.http.getUrl(import.meta.url), { method: 'HEAD' })

        if (
            res.headers.get('Content-Type')?.includes('text/html') ||
            res.headers.get('Content-Disposition')?.includes('index.html')
        ) {
            setIsOutdated(true)
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            handleReleaseCheck()
        }, 1000 * 60)
    }, [])

    const handleClickReload = () => {
        location.reload()
    }


    return <Popup open={isOutdated}>
        <div className="flex flex-col justify-center gap-4 items-center">
            <h2 className="font-semibold text-lg">
                A new update is ready!
            </h2>

            <p className="text-gray-700">
                Please refresh the page to get the latest version.
            </p>

            <Button onClick={handleClickReload}>
                Refresh
            </Button>
        </div>
    </Popup>
}

export default useReleaseCheck
