import { Card, CompanyIntegrationCard } from '@/components'
import { Company } from '@/models'

type CompanyIntegrationsSectionProps = {
    company: Company
}

const CompanyIntegrationsSection = ({ company }: CompanyIntegrationsSectionProps) =>
    <>
        <h2 className="text-lg mb-4 font-semibold">Integrations</h2>

        <div className="flex w-full items-center mb-6 gap-4">
            <div className="flex flex-col pb-1 border-b-2 border-b-orange-400 grow">
                <h3>Supported Manufacturers</h3>
            </div>
            <div className="flex items-center text-sm font-normal">
                {company.monitoringStatus.getBadge()}
                <div className="ml-2">
                    {company.monitoringStatus.title}
                    {company.monitoringStatus.subTitle &&
                        <p className="italic text-xs text-gray-500">{company.monitoringStatus.subTitle}</p>}
                </div>
            </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-4">
            {company.supportedIntegrations.map(item => <Card className="w-full" key={item.id}>
                <CompanyIntegrationCard integration={item} company={company}/>
            </Card>)}
        </div>

        {company.integrations.some(item => item.isUnsupported) && <>
            <div className="flex w-full items-center mt-6 mb-6">
                <div className="flex flex-col pb-1 border-b-2 border-b-orange-400 grow">
                    <h3>Unsupported Manufacturers</h3>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 justify-between gap-5">
                {company.unsupportedIntegrations.map(item => <Card className="w-full" key={item.id}>
                    <CompanyIntegrationCard integration={item} company={company}/>
                </Card>)}
            </div>
        </>}
    </>

export default CompanyIntegrationsSection
