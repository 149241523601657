import { ChangeEvent, ReactNode, useMemo, useState } from 'react'

import { Select, SelectOptionProps, Switch } from '@/components'
import { SystemStatus, SystemStatusCategory } from '@/models'

type MonitoringStatusDropdownProps = {
    onChange: (e: { target: { value: string[], name: string } }) => void
    value: string[]
    name: string
    id: string
    'data-test': string
    summary?: any
    className?: string
}

const MonitoringStatusDropdown = ({
    value,
    name,
    id,
    'data-test': dataTest,
    summary,
    className,
    ...props
}: MonitoringStatusDropdownProps) => {
    const [showInactiveStatuses, setShowInactiveStatuses] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === 'toggle') {
            setShowInactiveStatuses(value => !value)
            return
        }
        props.onChange({ target: { value: e.target.value as unknown as string[], name } })
    }

    const getStatusCount = (statusKey: string) => {
        if (summary) {
            const count = summary.statuses_count?.[statusKey] || summary.groups?.[statusKey] || 0
            return count.format()
        }

        return ''
    }

    const statusOptions = useMemo<SelectOptionProps[]>(() => [
        {
            value: 'toggle',
            title: <div className="flex w-full gap-4 items-center justify-between font-body text-gray-900 text-sm">
                Show Inactive Issues

                <Switch
                    name="toggle"
                    input-size="sm"
                    checked={showInactiveStatuses}
                    value="toggle"
                    onChange={() => {}}
                    onClick={() => {}}
                />
            </div>
        },
        {
            value: '',
            title: 'Any Status'
        },
        ...SystemStatusCategory.all.reduce((acc: SelectOptionProps[], category, index) =>
            showInactiveStatuses || summary.groups?.[category.value] ? [
                ...acc,
                {
                    value: category.value,
                    title: <div className="font-semibold flex gap-3 items-center justify-between">
                        {category.title}
                        <div className="text-purple-600 bg-purple-100 w-8 h-8 flex justify-center items-center rounded-full text-2xs">
                            {getStatusCount(category.value)}
                        </div>
                    </div>,
                    group: index > 0
                },
                ...category.tiers.reduce((acc: SelectOptionProps[], tier, _, all) =>
                    showInactiveStatuses || summary.groups?.[tier.value] || all.length === 1 ? [
                        ...acc,
                        ...all.length > 1 ? [{
                            value: tier.value,
                            parent: category.value,
                            title: (checkbox: ReactNode) => <div className="flex flex-col w-full gap-4 font-semibold text-sm">
                                <div className="flex gap-3 items-center justify-between">
                                    <span>• {tier.title}</span> {getStatusCount(tier.value)}
                                </div>
                                <div className="flex gap-2 items-center">
                                    {checkbox}
                                    <div className="flex gap-2 items-center">
                                        {value.includes(tier.value) || value.includes(category.value)
                                            ? 'Deselect' : 'Select'} {tier.title}
                                    </div>
                                </div>
                            </div>
                        }] : [],
                        ...tier.statuses.reduce((acc: SelectOptionProps[], status: SystemStatus) =>
                            showInactiveStatuses || summary.statuses_count?.[status.value] ? [
                                ...acc,
                                {
                                    value: status.value,
                                    parent: all.length > 1 ? tier.value : category.value,
                                    title: <div className="flex justify-between font-body text-gray-900 text-sm gap-4">
                                        <div className="flex gap-2 items-center">
                                            {status.iconBadge('xs')} {status.title}
                                        </div>
                                        {getStatusCount(status.value)}
                                    </div>
                                }
                            ] : acc as SelectOptionProps[], [])
                    ] : acc as SelectOptionProps[], [])
            ] : acc as SelectOptionProps[], [])
    ], [value, summary, showInactiveStatuses])

    return <Select
        id={id}
        name={name}
        className={className}
        data-test={dataTest}
        persistent
        options={statusOptions}
        multiple
        value={value}
        onChange={handleChange}
    />
}

export default MonitoringStatusDropdown
