import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CompanyCreateForm from './CompanyCreateForm'
import UserCreateForm from './UserCreateForm'
import { Button, Card, Select } from '@/components'
import { User } from '@/models'

const AccountsCreateForm = () => {
    const navigate = useNavigate()
    const [role, setRole] = useState('')

    const { type } = User.allAccountTypes.find(({ value }) => value === role) || {}

    return <Card>
        <div className="grid lg:grid-cols-2 gap-6">
            <Select
                id="account-type"
                name="role"
                label="Account Type*"
                className="col-span-1"
                options={[{ value: '', title: 'Account Type' }, ...User.allAccountTypes]}
                value={role}
                onChange={(e: any) => setRole(e.target.value)}
            />
            {type === 'user' && <UserCreateForm role={role}/>}

            {(type === 'company' || type === 'company-user') && <CompanyCreateForm role={role}/>}

            {!role && <div className="lg:col-span-2 flex justify-end">
                <Button type="button" design="btn-secondary-gray" hasError onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button className="ml-3" disabled>
                    Create Account
                </Button>
            </div>}
        </div>
    </Card>
}

export default AccountsCreateForm
