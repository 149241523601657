import { ReactNode, useState } from 'react'

import { Button, Card, IconChevronDown, IconDownload, Table } from '@/components'
import { Warranty, WarrantyProductType } from '@/models'

type Item = {
    id: number,
    title: ReactNode
    body: ReactNode
}

const FAQ = ({ className }: { className?: string }) => {
    const [openItems, setOpenItems] = useState<number[]>([])
    const items: Item[] = [
        {
            id: 1,
            title: 'How do I convert an excel spreadsheet to a csv?',
            body: <div>
                Quick Steps:
                <ul className="pl-5 list-decimal mt-4">
                    <li>Open your Excel file.</li>
                    <li>Click &apos;File&apos; &gt; &apos;Save As&apos;.</li>
                    <li>Select &apos;CSV (Comma delimited) (*.csv)&apos; in the file type options.</li>
                    <li>Name your file and click &apos;Save&apos;.</li>
                </ul>

                <p className="mt-6">
                    Need a visual walkthrough?

                    <Button design="btn-link" className="!text-primary-700 !inline" href="https://knowledgebase.constantcontact.com/email-digital-marketing/articles/KnowledgeBase/6409-saving-an-excel-file-as-a-csv-file?lang=en_US">
                        Check out our step-by-step guide
                    </Button>
                </p>
            </div>
        },
        {
            id: 2,
            title: 'How should I name my columns in the CSV file?',
            body: <div>
                Start with clear column names like &apos;First Name&apos;, &apos;Last Name&apos;, &apos;Address&apos;,
                etc. Clear column names help in accurate data mapping and processing.
            </div>
        },
        {
            id: 3,
            title: 'What key details should my CSV file include?',
            body: <div className="flex flex-col gap-4">
                Ensure your CSV includes these key details: &apos;Installer Name&apos;, &apos;Manufacturer System
                ID&apos;, etc. Use our sample template as a guide to ensure all necessary fields are included.

                <Button design="btn-secondary-gray" onClick={Warranty.downloadExampleCsv} className="gap-2 self-center">
                    <IconDownload/>
                    Download sample CSV
                </Button>
            </div>
        },
        {
            id: 4,
            title: 'What fields are required? What format does the data have to be in?',
            body: <div className="flex flex-col gap-4">
                <h4 className="font-semibold">Header Column Format</h4>
                <p>
                    Your csv file should have header columns containing a title in the first row. See header column
                    formats below.
                </p>
                <Table
                    className="shadow-md stripped-table"
                    sortable={false}
                    pagination={false}
                    searchable={false}
                    useQueryParams={false}
                    columns={[
                        {
                            field: 'id',
                            title: ' ',
                            cellClassName: 'border-r text-xs',
                            className: 'border-r'
                        },
                        {
                            field: 'a',
                            title: 'A',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'b',
                            title: 'B',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'c',
                            title: 'C',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'd',
                            title: 'D',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'e',
                            title: 'E',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'f',
                            title: 'F',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'g',
                            title: 'G',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: '...',
                            title: '...',
                            cellClassName: 'text-xs'
                        }
                    ]}
                    rows={[{
                        id: 1,
                        a: 'First Name',
                        b: 'Last Name',
                        c: 'Phone',
                        d: 'Email',
                        e: 'Street Address',
                        f: 'City',
                        g: 'State',
                        '...': '...'
                    }]}
                />

                <h4 className="font-semibold">Expected Fields: Homeowner Data</h4>
                <p>
                    When we refer to the &quot;homeowner,&quot; we mean the head of household. This is the person who is
                    most likely to respond in cases where there is an issue with their solar system. We understand that
                    many homes may have multiple owners, but for simplicity and efficiency in emergency situations, we
                    request the details of just one primary contact. This ensures that we have a direct line of
                    communication for any urgent matters related to their system performance. As a reminder, TPO systems
                    are not eligible for 30 year limited warranties.
                </p>
                <Table
                    className="shadow-md stripped-table"
                    sortable={false}
                    pagination={false}
                    searchable={false}
                    useQueryParams={false}
                    columns={[
                        {
                            field: 'required_fields',
                            title: <span className="font-semibold">Required Fields</span>,
                            cellClassName: 'border-r font-semibold text-xs',
                            className: 'border-r'
                        },
                        {
                            field: 'first_name',
                            title: 'First Name',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'last_name',
                            title: 'Last Name',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'phone',
                            title: 'Phone',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'email',
                            title: 'Email',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'street_address',
                            title: 'Street Address',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'city',
                            title: 'City',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'state',
                            title: 'State',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'zip',
                            title: 'ZIP',
                            cellClassName: 'text-xs'
                        }
                    ]}
                    rows={[{
                        id: 1,
                        required_fields: 'Sample Data',
                        first_name: 'John',
                        last_name: 'Dow',
                        phone: '123-456-7891',
                        email: 'joe@gmail.com',
                        street_address: '55 Anton Blvd',
                        city: 'Costa Mesa',
                        state: 'CA',
                        zip: '9226'
                    }]}
                />

                <h4 className="font-semibold">Expected Fields: Solar System Data</h4>
                <p>
                    Accurate and detailed information about the solar system installed at the homeowner&apos;s property
                    is crucial for effective warranty service and maintenance. Below are the specific data fields that
                    need to be filled out by our partners when enrolling a system:
                </p>
                <Table
                    className="shadow-md stripped-table"
                    sortable={false}
                    pagination={false}
                    searchable={false}
                    useQueryParams={false}
                    columns={[
                        {
                            field: 'required_fields',
                            title: <span className="font-semibold">Required Fields</span>,
                            cellClassName: 'border-r font-semibold text-xs',
                            className: 'border-r'
                        },
                        {
                            field: 'panel_model',
                            title: 'Panel Make & Model',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'inverter_model',
                            title: 'Inverter Make & Model',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'num_panels',
                            title: '# of Panels',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'panel_wattage',
                            title: 'Panel Wattage (W)',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'size_kw',
                            title: 'System Size (kW)',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'install_date',
                            title: 'Installation Date',
                            cellClassName: 'text-xs'
                        }
                    ]}
                    rows={[{
                        id: 1,
                        required_fields: 'Sample Data',
                        panel_model: 'REC REC420AA-PURE-R',
                        inverter_model: 'Enphase IQ8-60-2-US',
                        num_panels: '40',
                        panel_wattage: '400',
                        size_kw: '16.00',
                        install_date: '2/1/2024'
                    }]}
                />
                <Table
                    className="shadow-md max-w-2xl stripped-table"
                    sortable={false}
                    pagination={false}
                    searchable={false}
                    useQueryParams={false}
                    columns={[
                        {
                            field: 'recommended_fields',
                            title: <span className="font-semibold">Recommended Field</span>,
                            cellClassName: 'border-r font-semibold text-xs',
                            className: 'border-r'
                        },
                        {
                            field: 'num_microinverters',
                            title: '# of Microinverters/Optimizers*',
                            cellClassName: 'text-xs'
                        },
                        {
                            field: 'system_id',
                            title: 'Manufacturer System Identifier*',
                            cellClassName: 'text-xs'
                        }
                    ]}
                    rows={[{
                        id: 1,
                        recommended_fields: 'Sample Data',
                        num_microinverters: '40',
                        system_id: '2823478917'
                    }]}
                />

                <p className="font-semibold">*Special Field Definitions:</p>

                <ul className="pl-5 list-decimal">
                    <li>
                        <span className="font-semibold">Number of Microinverters/Optimizers:</span> The count of
                        microinverters or optimizers used in the system. This number often matches the panel count;
                        however, configurations can vary.
                    </li>
                    <li>
                        <span className="font-semibold">Manufacturer System Identifier:</span> A unique identifier
                        provided by the manufacturer used for system monitoring. This field is not currently required,
                        but including it on the registration form greatly increases Solar Insure’s ability to connect a
                        warranty with monitoring data. Each manufacturer has a different name for this value, as shown
                        below:

                        <ul className="pl-5 list-disc">
                            <li>Enphase: System ID</li>
                            <li>SolarEdge: Site ID</li>
                            <li>Generac: Site ID</li>
                        </ul>
                    </li>
                </ul>
            </div>
        },
        {
            id: 5,
            title: 'Can I register Battery Warranties in the Bulk Upload?',
            body: <div>
                Registering battery coverage through the bulk upload is not currently supported.
                Only {WarrantyProductType.solar.title} warranties can be uploaded in bulk. Please use the link at the
                top of the page to go to <Button href="/warranties/create" design="btn-link" className="!text-primary-700 !inline">Single Warranty Registration</Button>.
            </div>
        }
    ]

    const handleClick = (item: Item) => {
        setOpenItems(state => state.includes(item.id)
            ? state.filter(id => id !== item.id)
            : [...state, item.id])
    }

    return <Card className={`${className || ''} flex flex-col gap-4`}>
        <div className="flex flex-col gap-4">
            <h3 className="font-semibold text-gray-700">
                Frequently Asked Questions:
            </h3>

            <div className="flex flex-col gap-2">
                {items.map(item =>
                    <div className={`px-1 lg:px-2 py-0.5 lg:py-1 rounded-lg transition-all${openItems.includes(item.id) ? ' bg-primary-25 pt-2 pb-4' : ''}`} key={item.id}>
                        <Button design="btn-link" className="!lg:text-base  !font-semibold !text-gray-700 !gap-4 text-left" onClick={() => handleClick(item)}>
                            <div>
                                <IconChevronDown size="lg" className={`stroke-gray-700 transition-all ${openItems.includes(item.id) ? 'rotate-180' : ''}`}/>
                            </div>
                            {item.title}
                        </Button>

                        {openItems.includes(item.id) && <div className="text-gray-700 text-sm mt-4 px-2 lg:px-10">
                            {item.body}
                        </div>}
                    </div>)}
            </div>
        </div>
    </Card>
}

export default FAQ
