import kebabCase from 'lodash/kebabCase'
import pluralize from 'pluralize'

declare global {
    interface String {
        pluralize(count: number): string

        kebabCase(): string

        capitalize(): string

        indicesOf(searchStr: string): number[]
    }
}

String.prototype.pluralize = function (this: string, count: number) {
    return pluralize(this, count)
}
String.prototype.kebabCase = function (this: string) {
    return kebabCase(this)
}
String.prototype.capitalize = function (this: string) {
    return this.split(' ')
        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
}
String.prototype.indicesOf = function (this: string, searchStr: string) {
    if (searchStr.length === 0) return []
    let startIndex = 0
    let index = -1
    const indices = []
    while ((index = this.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index)
        startIndex = index + searchStr.length
    }
    return indices
}
