import { useCallback } from 'react'

import { useAuth } from '@/hooks'
import { gaEvent } from '@/utils'

type TrackEvent = (action: string, category: string, label: string, value?: number) => void

const useAnalytics = (): { trackEvent: TrackEvent } => {
    const auth = useAuth()

    const trackEvent = useCallback((action: string, category: string, label: string, value = 1) => {
        gaEvent({
            action,
            category,
            label,
            value
        }, {
            user_detail: {
                name: auth.user?.name,
                email: auth.user?.email,
                role: auth.user?.role
            }
        })
    }, [])

    return { trackEvent }
}

export default useAnalytics
