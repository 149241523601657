import {
    BatteriesForm, BatteriesFormDataType,
    Button, FormChangeEventHandler,
    FormErrorsType,
    InverterForm, InverterFormDataType,
    PanelForm, PanelFormDataType
} from '@/components'
import { Company, WarrantyProductType } from '@/models'

export interface CreateWarrantySystemFormDataType extends
    PanelFormDataType, InverterFormDataType, BatteriesFormDataType {}

type WarrantyCreateSystemStepProps = {
    form: CreateWarrantySystemFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    onPrevious: () => void
    processing: boolean
    company?: Company
}
export const WarrantyCreateSystemStep = ({
    form,
    errors,
    company,
    processing,
    ...props
}: WarrantyCreateSystemStepProps) =>
    <div className="grid lg:grid-cols-2 gap-3 lg:gap-6 mt-6 lg:mt-12.5" data-test="warranty-create-step-2">
        <h3 className="font-semibold lg:col-span-2">{WarrantyProductType.solar.secondaryTitle}</h3>

        <h3 className="font-semibold lg:col-span-2">Panel Information</h3>
        <PanelForm form={form} errors={errors} onChange={props.onChange}/>

        <hr className="lg:col-span-2"/>

        <h3 className="font-semibold lg:col-span-2">Inverter Information</h3>
        <InverterForm form={form} errors={errors} onChange={props.onChange} mode="create"/>

        {company?.isEligibleForBatteries &&
            <BatteriesForm form={form} errors={errors} onChange={props.onChange} company={company}/>}

        <div className="flex flex-col xl:flex-row gap-3 justify-between lg:col-span-2">
            <p className="text-sm">
                {company?.isEligibleForBatteries && <>
                    <Button
                        design="btn-link"
                        size="btn-sm"
                        href="https://www.solarinsure.com/battery"
                        target="_blank"
                        className="mr-1.5 !text-primary-800 !inline-block"
                    >
                        Learn More
                    </Button>
                    <span className="text-xs text-gray-500">about our Warranty Solutions & Battery Coverage</span>
                </>}
            </p>
            <div className="flex justify-end gap-3">
                <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                    Back
                </Button>
                <Button processing={processing} data-test="warranty-create-step-2-continue-button">
                    Save & Generate Certificate
                </Button>
            </div>
        </div>
    </div>
