import { ReactNode, useState } from 'react'

import { IconCheck, IconCopy04, IconCopy07, IconReloadCw05 } from '@/components/icons'
import { Button, Tooltip } from '@/components/ui'
import { useToastNotifications } from '@/hooks'

type ClickToCopyProps = {
    content: string | (() => Promise<string>)
    children?: ReactNode
    tooltipText?: ReactNode
    tooltipCopyingText?: ReactNode
    tooltipCopiedText?: ReactNode
}

const ClickToCopy = ({
    content,
    children = <IconCopy04 size="sm" className="stroke-gray-700"/>,
    tooltipText = <div className="flex items-center gap-1"><IconCopy07 className="stroke-brand-blue-skies"/> Click to copy</div>,
    tooltipCopyingText = <div className="flex items-center gap-1"><IconReloadCw05 className="stroke-brand-blue-skies animate-spin"/> Copying</div>,
    tooltipCopiedText = <div className="flex items-center gap-1"><IconCheck className="stroke-brand-eco-green"/> Copied</div>
}: ClickToCopyProps) => {
    const { error } = useToastNotifications()
    const [isCopied, setIsCopied] = useState(false)
    const [processing, setProcessing] = useState(false)
    const handleClickCopy = async () => {
        if (window.isSecureContext) {
            if (typeof content === 'function') {
                setProcessing(true)
                try {
                    const res = await content()
                    setTimeout(async () => {
                        try {
                            await navigator.clipboard.writeText(res)
                            setIsCopied(true)
                        } catch (err: any) {
                            error('Could not copy password. Please try again', 'Failed')
                        }
                    }, 0)
                } finally {
                    setProcessing(false)
                }
            } else {
                setIsCopied(true)
                navigator.clipboard.writeText(content)
            }
        } else {
            error('This feature requires a secure origin.', 'Could not copy text')
        }
    }

    const getContent = () => {
        if (processing) {
            return tooltipCopyingText
        }
        return isCopied ? tooltipCopiedText : tooltipText
    }

    return <Tooltip content={getContent()} hideOnClick={false}>
        <Button type="button" design="btn-link" className={processing ? 'animate-pulse' : ''} onClick={handleClickCopy} processing={processing}>
            {children}
        </Button>
    </Tooltip>
}

export default ClickToCopy
