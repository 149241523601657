import { useEffect, useState } from 'react'

import { Checkbox, Select, Input, TextArea } from '@/components'
import { Product } from '@/models'

type ProductFormProps = {
    form: any
    errors: any
    onChange: any
}
const ProductForm = ({ form, errors, ...props }: ProductFormProps) => {
    const [types, setTypes] = useState<any[]>([])
    const fetchTypes = async () => {
        const res = await Product.indexTypes()
        setTypes(res.data)
    }

    useEffect(() => {
        fetchTypes()
    }, [])

    const getTypeProperties = () => types
        .find(item => item.id?.toString() === form.product_type_id?.toString())?.properties || []

    return <>
        <Input
            id="model_name"
            name="model_name"
            label="Model Name*"
            placeholder="Model Name"
            onChange={props.onChange}
            value={form.model_name}
            errors={errors.model_name}
        />

        <Select
            id="product-type-id"
            name="product_type_id"
            label="Type*"
            options={[{
                value: '',
                title: 'Type'
            }, ...types.map((item: any) => ({ value: item.id, title: item.name }))]}
            value={form.product_type_id}
            errors={errors.product_type_id}
            onChange={props.onChange}
        />

        <Select
            id="pdf-template-id"
            name="pdf_template_id"
            label="PDF Template"
            className="text-sm font-body leading-[1.43] text-gray-700"
            options={[{
                value: '',
                title: 'Default'
            }]}
            value={form.pdf_template_id}
            errors={errors.pdf_template_id}
            onChange={props.onChange}
        />
        {
            getTypeProperties().map((item: any, index: number) => {
                const name = `properties[${item.id}]`
                if (item.type === 'Boolean') {
                    return <Checkbox
                        key={index}
                        id={name}
                        name={name}
                        label={item.description}
                        defaultChecked={form[name]}
                        onChange={props.onChange}/>
                } else if (item.type === 'Text' || item.type === 'Json') {
                    return <TextArea
                        key={index}
                        id={name}
                        name={name}
                        label={item.description}
                        onChange={props.onChange}
                        defaultValue={form[name]}
                    />
                }
                return <Input
                    key={index}
                    id={name}
                    name={name}
                    label={item.description}
                    onChange={props.onChange}
                    defaultValue={form[name]}
                />
            })
        }
    </>
}

export default ProductForm
