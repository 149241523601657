import { Button, IconBell, IconDatabase02 } from '@/components'

const ConfirmationStep = () => <div className="text-center text-gray-900">
    <h3 className="flex items-center gap-1 mb-2 text-center justify-center font-semibold">
        <div className="w-12 h-12 rounded-full bg-success-50 flex justify-center items-center">
            <div className="w-9 h-9 rounded-full bg-success-100 flex justify-center items-center">
                <IconDatabase02 className="stroke-success-700"/>
            </div>
        </div>

        Successfully Queued Bulk Upload!
    </h3>
    <p>We have received your request to update warranties.</p>
    <p className="mb-2">Our system will process them shortly and we will notify you once it{'\''}s done.</p>

    <p className="flex items-center justify-center gap-2 mb-2">
        Feel free to close this page and check the notifications button <Button square design="btn-secondary-gray"><IconBell/></Button> for updates on the upload status.
    </p>

    <p className="text-primary-700">
        Queue failure possibility. Notification will be sent if it happens
    </p>

    <div className="flex justify-end gap-3 mt-4">
        <Button href="/warranties-by-contractors">
            Close
        </Button>
    </div>
</div>

export default ConfirmationStep
