import { useState } from 'react'

import {
    Card,
    ClickToCopy,
    IconAlertCircle,
    IconEye,
    IconEyeOff,
    IconPasscode,
    IconUser01
} from '@/components'
import { Integration } from '@/models'


const EnphaseCredentialsSection = ({ integration }: { integration: Integration }) => {
    const [showSecret, setShowSecret] = useState(false)

    const getCardType = () => {
        if (integration.isMissing) return 'error'
        if (integration.isInvalid) return 'warning'
        return undefined
    }

    return <Card type={getCardType()}>
        <h3 className="text-gray-900 font-semibold mb-4">Login Credentials</h3>

        <div className="flex items-center mb-4 break-anywhere">
            <IconUser01 className="stroke-gray-700 mr-4 shrink-0"/>
            {integration.user
                ? <>
                    <span className="mr-2 text-sm">{integration.user}</span>
                    <ClickToCopy content={integration.user}/>
                </>
                : <div className={integration.isInvalidOrMissing ? integration.credentialStatus.color : ''}>
                    _____________________
                </div>}
        </div>
        <div className="flex items-center break-anywhere">
            <IconPasscode className="stroke-gray-700 mr-4 shrink-0"/>

            {integration.secret
                ? <div className="flex gap-1 items-center text-sm">
                    {integration.secret?.split('').map((item: string) => showSecret ? item : '•')}
                    <button onClick={() => setShowSecret(state => !state)}>
                        {showSecret
                            ? <IconEyeOff className="stroke-gray-500" size="sm"/>
                            : <IconEye className="stroke-gray-500" size="sm"/>}
                    </button>
                    {integration.isInvalid ? <IconAlertCircle size="sm"/> : <ClickToCopy content={integration?.secret}/>}
                </div>
                : <div className={integration.isInvalidOrMissing ? integration.credentialStatus.color : ''}>
                    _____________________
                </div>}
        </div>

        {integration.isInvalidOrMissing && <div className="text-xs mt-4">
            {integration.credentialStatus.title}
        </div>}
    </Card>
}


export default EnphaseCredentialsSection
