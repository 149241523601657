import classnames from 'classnames'
import { HTMLAttributes } from 'react'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
    size?: string
    type?: 'warning' | 'error' | 'success'
}

export const Card = ({
    className,
    children,
    size,
    type,
    ...props
}: CardProps) => <div className={classnames('card', size, type, className)} {...props}>
    {children}
</div>
