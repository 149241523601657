import { ReactNode } from 'react'

import { IconAlertCircle, IconCheckVerified01 } from '@/components'
import { ToastNotification } from '@/models'

export default {
    success(message: ReactNode, title = 'Success!', delay?: number | 'permanent') {
        return new ToastNotification(<div className="flex gap-4">
            <div>
                <div className="w-10 h-10 rounded-full bg-success-50 flex justify-center items-center">
                    <div className="w-8 h-8 rounded-full bg-success-100 flex justify-center items-center stroke-success-700">
                        <IconCheckVerified01/>
                    </div>
                </div>
            </div>
            <div>
                <h3 className="text-gray-900 text-sm">{title}</h3>
                <p className="text-gray-500 text-sm">{message}</p>
            </div>
        </div>, delay)
    },
    error(message: ReactNode, title = 'Error!', delay?: number) {
        return new ToastNotification(<div className="flex gap-4">
            <div>
                <div className="w-10 h-10 rounded-full bg-error-50 flex justify-center items-center">
                    <div className="w-8 h-8 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                        <IconAlertCircle/>
                    </div>
                </div>
            </div>
            <div>
                <h3 className="text-gray-900 text-sm">{title}</h3>
                <p className="text-gray-500 text-sm">{message}</p>
            </div>
        </div>, delay)
    },
    custom(message: ReactNode, delay?: number) {
        return new ToastNotification(message, delay)
    }
}
