import { IconAlertCircle, IconCheck, IconHelpCircle, IconPasscodeLock } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { IntegrationCredentialStatusEnum } from '@/enums'

export default [
    {
        index: 0,
        key: IntegrationCredentialStatusEnum.MAINTAINER,
        title: 'Maintainer',
        icon: (size?: IconSize) => <IconHelpCircle size={size} className="stroke-gray-600"/>,
        color: 'text-gray-500',
        secondaryColor: 'bg-gray-100'
    },
    {
        index: 1,
        key: IntegrationCredentialStatusEnum.VALID,
        title: 'Credentials Submitted',
        icon: (size?: IconSize) => <IconCheck size={size} className="stroke-success-600"/>,
        color: 'text-success-600',
        secondaryColor: 'bg-success-100'
    },
    {
        index: 2,
        key: IntegrationCredentialStatusEnum.USES_MAINTAINER,
        title: 'Credentials Submitted',
        icon: (size?: IconSize) => <IconCheck size={size} className="stroke-success-600"/>,
        color: 'text-success-600',
        secondaryColor: 'bg-success-100'
    },
    {
        index: 3,
        key: IntegrationCredentialStatusEnum.NOT_REQUIRED,
        title: 'Credentials Not Required'
    },
    {
        index: 4,
        key: IntegrationCredentialStatusEnum.INVALID,
        title: 'Invalid Required Credentials',
        icon: (size?: IconSize) => <IconPasscodeLock size={size} className="stroke-warning-600"/>,
        color: 'text-orange-700',
        secondaryColor: 'bg-warning-100',
        message: 'Invalid Credentials - Please update field'
    },
    {
        index: 5,
        key: IntegrationCredentialStatusEnum.MISSING,
        title: 'Missing Required Credentials',
        icon: (size?: IconSize) => <IconAlertCircle size={size} className="stroke-error-600"/>,
        color: 'text-error-500',
        secondaryColor: 'bg-error-100',
        message: 'A warranty registration was detected using this inverter manufacturer. All contractors must provide Solar Insure monitoring access to stay compliant with our certification requirements.'
    },
    {
        index: 6,
        key: IntegrationCredentialStatusEnum.UNSUPPORTED,
        title: 'Unsupported'
    },
    {
        index: 7,
        key: IntegrationCredentialStatusEnum.UNKNOWN,
        title: 'Unknown',
        icon: (size?: IconSize) => <IconHelpCircle size={size} className="stroke-gray-600"/>,
        color: 'text-gray-600',
        secondaryColor: 'bg-gray-100'
    }
]
