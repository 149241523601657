import { FormChangeEventHandler, FormDataType, FormErrorsType, Input } from '@/components'
import { Company, Warranty, WarrantyProductType } from '@/models'

export interface PolicyRatesFormDataType extends FormDataType {
    policy_rate: number
    battery_rate: number
}

type PolicyRatesFormProps = {
    form: PolicyRatesFormDataType
    warranty: Warranty
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    company: Company
    inputClassName?: string
}

export const PolicyRatesForm = ({
    form,
    inputClassName,
    warranty,
    errors,
    company,
    ...props
}: PolicyRatesFormProps) =>
    <>
        <Input
            id="policy-rate"
            name="policy_rate"
            label={`${WarrantyProductType.solar.secondaryTitle} Rate*`}
            placeholder="Policy rate"
            onChange={props.onChange}
            value={form.policy_rate}
            errors={errors.policy_rate}
            mask={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />

        <Input
            id="battery-rate"
            name="battery_rate"
            label={`${warranty?.batteries.length ? warranty?.productType.secondaryTitle : company.batteryEligibility.secondaryTitle} Rate*`}
            placeholder="Battery rate"
            onChange={props.onChange}
            value={form.battery_rate}
            errors={errors.battery_rate}
            mask={{
                numeral: true,
                numeralDecimalScale: 2,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'none'
            }}
        />
    </>
