import { ReactNode } from 'react'

import { NOTIFICATION_TYPES, NotificationTypeEnum } from '@/enums'

export class NotificationType {
    key: NotificationTypeEnum

    message: (data: any) => ReactNode

    icon: ReactNode | ((data: any) => ReactNode)

    showTimestamp: boolean

    onComplete?: (data: any) => void

    constructor(props: any) {
        this.key = props.key
        this.icon = props.icon
        this.message = props.message
        this.onComplete = props.onComplete
        this.showTimestamp = props.showTimestamp !== false
    }

    static get all() {
        return NOTIFICATION_TYPES.map(item => new NotificationType(item))
    }

    static find(key: NotificationTypeEnum) {
        return NotificationType.all.find(item => item.key === key) ||
            NotificationType.defaultType
    }

    static defaultType = NotificationType.all.find(item =>
        item.key === NotificationTypeEnum.DEFAULT) as NotificationType
}
