import Device from '@/models/Device'
import Model from '@/models/Model'
import System from '@/models/System'
import Warranty from '@/models/Warranty'

export default class DeviceCategory extends Model {
    provider: 'enphase' | 'solaredge' | 'generac'

    devices: Device[]

    warranty: Warranty

    system: System

    type: string

    static enphaseDeviceTypes = [
        { key: 'gateways', title: 'Gateways' },
        { key: 'meters', title: 'Meters' },
        { key: 'enpowers', title: 'Enpower System Controllers' },
        { key: 'encharges', title: 'Encharge Batteries' },
        { key: 'micros', title: 'Micros' }
    ]

    static solarEdgeDeviceTypes = [
        { key: 'gateways', title: 'Gateways' },
        { key: 'meters', title: 'Meters' },
        { key: 'sensors', title: 'Sensors' },
        { key: 'batteries', title: 'Batteries' },
        { key: 'micros', title: 'Inverters' }
    ]

    static generacDeviceTypes = [
        {
            key: 'inverters',
            title: 'Inverters',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'serialNumber', title: 'Serial Number' },
                { key: 'name', title: 'Device Name' },
                { key: 'pvlRatedPowerWDC', title: 'Rated Power (W, DC)' },
                { key: 'statusBadge', title: 'Status' }
            ]
        },
        {
            key: 'pvls',
            title: 'PV Links',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'name', title: 'Device Name' },
                { key: 'pvlRatedCurrentA', title: 'Rated Current (A)' },
                { key: 'pvlRatedPowerWDC', title: 'Rated Power (W, DC)' },
                { key: 'statusBadge', title: 'Status' }
            ]
        },
        {
            key: 'batteries',
            title: 'Batteries',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'name', title: 'Device Name' },
                { key: 'pvlRatedCurrentA', title: 'Rated Charge Capacity (A)' },
                { key: 'pvlRatedPowerWDC', title: 'Rated Power (W, DC)' },
                { key: 'statusBadge', title: 'Status' }
            ]
        },
        {
            key: 'loadcontrollers',
            title: 'Load Controllers',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'name', title: 'Device Name' },
                { key: 'statusBadge', title: 'Status' }
            ]
        },
        {
            key: 'generators',
            title: 'Generators',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'name', title: 'Device Name' }
            ]
        },
        {
            key: 'rgms',
            title: 'RGMs',
            defaultStatus: 'disabled',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'name', title: 'Device Name' },
                { key: 'serialNumber', title: 'Serial Number' }
            ]
        },
        {
            key: 'beacons',
            title: 'Beacons',
            defaultStatus: 'disabled',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'name', title: 'Device Name' }
            ]
        },
        {
            key: 'icms',
            title: 'ICMs',
            defaultStatus: 'disabled',
            attributes: [
                { key: 'deviceRcpn', title: 'Device RCP' },
                { key: 'manufacturer', title: 'Manufacturer' },
                { key: 'name', title: 'Device Name' }
            ]
        }
    ]

    constructor(props?: any) {
        super(props)
        this.id = props.id || props.type
        this.provider = props.provider
        this.warranty = props.warranty
        this.system = props.system
        this.type = props.type
        this.id = `${props.system.id}-${props.type}`
        this.devices = props.devices
            .map((item: any) => new Device({ ...item, category: this }))
            .filter((item: any) => !item.isVirtual)
    }

    get allDeviceTypes() {
        return [
            ...DeviceCategory.generacDeviceTypes,
            ...DeviceCategory.enphaseDeviceTypes,
            ...DeviceCategory.solarEdgeDeviceTypes,
            {
                key: 'unknown',
                title: 'Unknown'
            }
        ]
    }

    get deviceType(): any {
        return this.allDeviceTypes.find(({ key }) => key === this.type) ||
            this.allDeviceTypes.find(({ key }) => key === 'unknown')
    }

    get title() {
        return this.deviceType.title
    }

    get attributes() {
        return this.deviceType.attributes || []
    }

    get normalDevices() {
        return this.devices.filter(item => item.isNormal)
    }

    get disabledDevices() {
        return this.devices.filter(item => item.isDisabled)
    }

    get erroredDevices() {
        return this.devices.filter(item => item.isErrored)
    }

    get statusIcon() {
        if (this.system.inverterManufacturer.isEnphase || this.system.inverterManufacturer.isGenerac) {
            if (this.erroredDevices.length) {
                return Device.getIcon('error', 'xs', true,
                    <span className="text-error-600 text-sm">{this.erroredDevices.length}</span>)
            }
            if (this.disabledDevices.length) {
                return Device.getIcon('disabled', 'xs')
            }
            return Device.getIcon('normal', 'xs')
        } else if (this.system.inverterManufacturer.isSolaredge) {
            return <span>({this.devices.length})</span>
        }

        return null
    }
}
