import { toastNotifications } from '@/services'

export type ResponseBody = {
    status: 'Ok' | 'Failed'
    data: { message?: 'string', [key: string]: any },
    meta: { [key: string]: any }
}

const getFileInfo = (res: Response) => {
    const [, fileName] = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
        res.headers.get('content-disposition') as string) || []
    return {
        name: fileName?.replaceAll('"', ''),
        type: res.headers.get('content-disposition')
            ?.split(';')[0]
    }
}

export default async (res: Response): Promise<any> => {
    if (!res.ok) {
        throw res as unknown as Error
    }

    const contentType = res.headers.get('content-type') as string
    if (contentType.includes('application/json')) {
        const response: ResponseBody = await res.json()
        if (response.data?.message) {
            toastNotifications.success(response.data.message)
        }
        return response
    } else if ([
        'text/csv',
        'text/json',
        'application/zip',
        'application/pdf'
    ].some(type => contentType.includes(type))) {
        const file = await res.blob()
        const { type, name } = getFileInfo(res)
        if (type === 'attachment') {
            const a = document.createElement('a')
            a.setAttribute('href', URL.createObjectURL(file))
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            a.remove()
        }
        return file
    }

    return res
}
