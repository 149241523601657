import Cleave from 'cleave.js'
import { CleaveOptions } from 'cleave.js/options'
import { useEffect, useRef } from 'react'
import 'cleave.js/dist/addons/cleave-phone.us'

const useCleave = (
    options: CleaveOptions | undefined,
    value: string | undefined,
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
) => {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (options && inputRef.current) {
            const cleave = new Cleave(inputRef.current, {
                ...options,
                onValueChanged(e) {
                    if (value?.toString() !== e.target.value?.toString() && onChange) {
                        onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
                    }
                }
            })

            return () => {
                cleave.destroy()
            }
        }
    }, [options, value, onChange])

    return inputRef
}

export default useCleave
