import { useState } from 'react'

import { Button, Popup } from '@/components'
import { InverterManufacturer } from '@/models'

const SolarEdgeIntegrationInstructions = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpened, setIsOpened] = useState(false)

    const handleClickClose = () => {
        setIsOpen(false)
        setIsOpened(true)
    }

    return <>
        <Button onClick={() => setIsOpen(true)} type="button" design={isOpened ? 'btn-secondary-gray' : 'btn-primary'}>
            Set Up Your Fleet Account Integration Today
        </Button>

        <Popup open={isOpen} onClose={handleClickClose}>
            <h2 className="flex gap-3 items-center text-xl font-semibold">
                {InverterManufacturer.solaredge.getLogo('lg')}
                SolarEdge Integration Instructions
            </h2>

            <p className="mt-4 px-4 py-2 text-sm text-gray-750">
                For a detailed guide with images, download the full PDF instructions.
            </p>

            <div className="mt-4 p-4 bg-primary-25 rounded-lg text-sm leading-5 text-gray-700 max-w-[40rem] flex flex-col gap-4">
                <h4 className="font-semibold">Follow these simple steps to seamlessly integrate your SolarEdge system with Solar Insure:</h4>

                <ul className="list-decimal pl-4">
                    <li>
                        <span className="font-semibold">Log into</span> the SolarEdge Monitoring Portal at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" className="font-semibold">https://monitoring.solaredge.com/solaredge-web/p/login</a>
                    </li>
                    <li>
                        Access &quot;<span className="font-semibold">My Account</span>&quot; via &quot;<span className="font-semibold">Monitoring Admin</span>&quot; dropdown and select &quot;<span className="font-semibold">Associated Accounts</span>&quot;.
                    </li>
                    <li>
                        Click to &quot;<span className="font-semibold">Add Associated Account</span>&quot;, enter <a href="mailto:monitor@solarinsure.com" className="font-semibold">monitor@solarinsure.com</a> address into the field provided, choose &quot;<span className="font-semibold">View Only</span>&quot; and &quot;<span className="font-semibold">Per Site</span>&quot;, then send the invitation. Solar Insure will receive an invitation email once the sharing is initiated.
                    </li>
                    <li>
                        Search and <span className="font-semibold">select</span> a homeowner’s site using the top-left search function. Select the &quot;<span className="font-semibold">Admin</span>&quot; icon to manage &quot;<span className="font-semibold">Site Access</span>&quot;.
                    </li>
                    <li>
                        Go to &quot;<span className="font-semibold">Site Access</span>&quot;, set Solar Insure&apos;s access to &quot;<span className="font-semibold">View/Edit</span>&quot;, and save.
                    </li>
                    <li>
                        In &quot;<span className="font-semibold">Site Details</span>&quot;, adjust user preferences under &quot;<span className="font-semibold">Site Access</span>&quot; as needed.
                    </li>
                </ul>

                <p>
                    For personalized assistance, our Monitoring Specialist team is ready to help. Contact us directly
                    at:
                </p>

                <ul className="list-disc ml-4">
                    <li>Email: <a href="mailto:valerie@solarinsure.com">valerie@solarinsure.com</a></li>
                    <li>Phone: <a href="tel:714-676-7373">714-676-7373</a></li>
                    <li>Visit: <a href="https://www.solarinsure.com" target="_blank" rel="noreferrer">www.solarinsure.com</a></li>
                </ul>

                <p>
                    We&apos;re here to ensure your integration process is smooth and efficient.
                </p>

                <Button design="btn-secondary" className="self-end" target="_blank" href="/resources/SolarEdge_Monitoring_Integrations.pdf">
                    Click here for a Visual Guide
                </Button>
            </div>
        </Popup>
    </>
}

export default SolarEdgeIntegrationInstructions
