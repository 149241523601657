import { AbilityBuilder, PureAbility, AbilityClass } from '@casl/ability'

import store from '@/store'

export type Actions = string//'create' | 'read' | 'update' | 'delete'
type Subjects = string

export type AppAbilityType = PureAbility<[Actions, Subjects]>
export const AppAbility = PureAbility as AbilityClass<AppAbilityType>

export const getAbility = () => {
    const { auth } = store.getState()
    const permissions = auth.user?.permissions || ['read_warranties']
    const { can, rules } = new AbilityBuilder(AppAbility)

    // This logic depends on how the
    // server sends you the permissions array
    if (permissions) {
        permissions.forEach((permission: string) => {
            const [action, subject] = permission.split('_')
            can(action, subject)
        })
    }

    return new AppAbility(rules)
}

getAbility()
store.subscribe(getAbility)
