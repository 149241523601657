import { useMemo, useState } from 'react'

import {
    Button,
    IconDownload,
    IconUpload01,
    IconX,
    Switch,
    Tooltip,
    IconLockUnlocked, Select, PageHeader, Feature, IconCheck
} from '@/components'
import { AuthLayout, FiltersType, GlobalIndex, GlobalIndexDataType } from '@/containers'
import { useAuth, useNavigateWithQuery, useToastNotifications, useQuery } from '@/hooks'
import { AuthUser, User } from '@/models'

interface UserIndexData extends GlobalIndexDataType {
    rows: User[]
}
interface UsersIndexFilters extends FiltersType {
    role: string
}

const UsersIndex = () => {
    const navigateWithQuery = useNavigateWithQuery()
    const { success } = useToastNotifications()
    const query = useQuery()
    const auth = useAuth()
    const getDefaultFilters = () => ({ role: query.role || '' })

    const [filters, setFilters] = useState<UsersIndexFilters>(getDefaultFilters())
    const [reload, setReload] = useState(false)
    const [data, setData] = useState<UserIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleChange = ({ name, value }: any) => {
        setData(data => ({ ...data, [name]: value }))
    }

    const handleFiltersChange = (e: any) => {
        navigateWithQuery({ page: null, [e.target.name]: e.target.value })
        setFilters(filters => ({ ...filters, [e.target.name]: e.target.value }))
    }

    const handleStatusChange = async (user: User, e: any) => {
        await user.destroy()
        success(`User successfully ${e.target.checked ? 'suspended' : 'reinstated'}.`)
        setReload(true)
    }

    const handleToggleVerificationCLick = async (user: User) => {
        await user.toggleVerification()
        setReload(true)
    }

    const handleResetPasswordClick = async (user: User) => {
        await AuthUser.forgotPassword({ email: user.email })
        success(`Reset link successfully sent to ${user.email}.`)
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        name: item.name,
        company: item.company?.name,
        email: item.email,
        account_type: item.accountType,
        status: <Switch
            checked={!item.deleted_at}
            onChange={e => handleStatusChange(item, e)}
            disabled={item.isAdmin || !auth.user?.isAdminOrStaff}
            input-size="sm"
        />,
        status_raw: item.status,
        last_online: item.last_online,
        actions: <div className="flex items-center gap-2">
            <Tooltip disabled={!auth.user?.isAdminOrStaff} content="Reset Password">
                <Button disabled={!auth.user?.isAdminOrStaff} design="btn-link" onClick={() => handleResetPasswordClick(item)}>
                    <IconLockUnlocked className="stroke-gray-300 hover:stroke-orange-600"/>
                </Button>
            </Tooltip>
            <Tooltip content={item.email_verified_at ? 'Mark As Not Verified' : 'Mark As Verified'} disabled={item.isAdmin || !auth.user?.isAdminOrStaff}>
                <Button design="btn-link" onClick={() => handleToggleVerificationCLick(item)} disabled={item.isAdmin || !auth.user?.isAdminOrStaff}>
                    {item.email_verified_at
                        ? <IconX className="stroke-gray-300 hover:stroke-error-600"/>
                        : <IconCheck className="stroke-gray-300 hover:stroke-success-600"/>}
                </Button>
            </Tooltip>

            {item.isContractor && <Tooltip disabled={!auth.user?.isAdminOrStaff} content="Upload Warranties">
                <Button disabled={!auth.user?.isAdminOrStaff} design="btn-link" href={`/warranties/upload?user_id=${item.id}`}>
                    <IconUpload01 className="stroke-gray-300 hover:stroke-purple-600"/>
                </Button>
            </Tooltip>}

            <Feature name="onboarding">
                <Tooltip content="Download">
                    <Button design="btn-link">
                        <IconDownload className="stroke-gray-300 hover:stroke-primary-600"/>
                    </Button>
                </Tooltip>
            </Feature>
        </div>,

        _path: `/users/${item.id}`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Users"/>}>
        <GlobalIndex
            id="users"
            dataType="Users"
            api={(...args) => User.index(...args)}
            data={data}
            onChange={handleChange}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            onFiltersChange={filters => setFilters(filters)}
            reload={reload}
            onReloadChange={setReload}
            columns={[
                { title: 'User Name', field: 'name' },
                { title: 'Company', field: 'company' },
                { title: 'User Email', field: 'email' },
                { title: 'Account Type', field: 'account_type' },
                { title: 'Status', field: 'status', clickable: false },
                { title: 'Last Online', field: 'last_online' },
                { title: 'Actions', field: 'actions', clickable: false, sortable: false }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <Select
                    name="role"
                    id="account-type"
                    options={[
                        { value: '', title: 'Any Account Type' },
                        { value: 'advisor', title: 'Advisor' },
                        { value: 'administrator', title: 'Administrator' },
                        { value: 'staff', title: 'Staff' },
                        { value: 'contractor', title: 'Contractor User' },
                        { value: 'conglomerate', title: 'Affiliate User' }
                    ]}
                    value={filters.role}
                    onChange={handleFiltersChange}
                />
            </>}
            rightSide={<>
                {auth.user?.isAdminOrStaff && <Button href="/users/create">
                    New Account
                </Button>}
            </>}
        />
    </AuthLayout>
}

export default UsersIndex
