import classnames from 'classnames'

import { Button, Dropdown, IconHexagon } from '@/components'
import { InverterManufacturer, Warranty } from '@/models'

type WarrantyGoToManufacturerButtonProps = {
    warranty: Warranty
}

export const WarrantyGoToManufacturerButton = ({ warranty }: WarrantyGoToManufacturerButtonProps) =>
    warranty.homeowner?.system?.manufacturerUrl
        ? <Button
            design="btn-secondary-gray"
            target="_blank"
            disabled={warranty.warrantyStatus.isInactive}
            href={warranty.homeowner?.system?.manufacturerUrl}
        >
            <div className="mr-2">
                {warranty.homeowner.system.inverterManufacturer.getLogo()}
            </div>
            Go to {warranty.homeowner.system.inverterManufacturer.title}
        </Button>
        : <Dropdown
            disabled={warranty.warrantyStatus.isInactive}
            button={<Button design="btn-secondary-gray" disabled={warranty.warrantyStatus.isInactive}>
                <IconHexagon
                    className={classnames(
                        'mr-2', warranty.warrantyStatus.isInactive ? 'stroke-warning-100' : 'stroke-warning-600')}
                    size="md"
                />
                Go to Manufacturer Site
            </Button>}
        >
            <div className="px-2">
                {InverterManufacturer.allSupported.map(item => <Button
                    key={item.key}
                    design="btn-link-gray"
                    className="w-full !justify-start !py-2.5"
                    target="_blank"
                    href={item.getUrl()}
                >
                    <div className="mr-2">
                        {item.getLogo()}
                    </div>
                    {item.title}
                </Button>)}
            </div>
        </Dropdown>
