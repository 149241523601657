import { api, excel, Params, Payload } from '@/services'
import { JobsBatchType } from '@/types'

export default {
    validateHomeowner(payload: Payload) {
        return api.http.post('api/v1/warranty/potential-duplicate', payload)
    },
    getDuplicates(payload: Payload) {
        return api.http.post('api/v1/warranty/find-potential-duplicates', payload)
    },
    validateSystem(params: Params) {
        return api.http.get('api/v1/warranty/preview', params)
    },
    validate(id: string | number, payload: Payload) {
        payload.append('_method', 'PATCH')
        return api.http.post(`api/v1/warranty/${id}/validate`, payload)
    },
    send(warrantyIds: (string | number)[]) {
        return api.http.post('api/v1/warranty/email-many', { warranty_ids: warrantyIds })
    },
    markUnique(id: string | number, reason: string) {
        return api.http.post(`api/v1/warranty/${id}/mark-as-unique`, { reason })
    },
    upload(payload: Payload, params?: Params) {
        return api.http.post('api/v1/warranty/bulk-upload', payload, params)
    },
    download(id: string | number) {
        return api.http.get(`api/v1/warranty/${id}/download-pdf`)
    },
    monitoringUpdate(id: string | number) {
        return api.http.put(`api/v1/warranty/${id}/monitoring_update`)
    },
    stream(id: string | number) {
        return api.http.get(`api/v1/warranty/${id}/stream-pdf`)
    },
    downloadMany(params: {
        ids?: (string | number)[]
        select_all?: boolean
        start_date?: string
        end_date?: string
        company_id?: string | number
        search?: string
        state?: string
        type?: string
        statuses?: string[],
        warranties_by_contractors?: boolean

    }) {
        return api.http.post('api/v1/warranty/download-many', params)
    },
    export(params: Params) {
        return api.http.get('api/v1/warranty/export', params)
    },
    bulkCancel(params: Params): Promise<{ data: JobsBatchType }> {
        return api.http.post('api/v1/warranty/delete-many', params)
    },
    cancelRequest(id: string | number) {
        return api.http.delete(`api/v1/warranty/warranty-cancellation-requests/${id}`)
    },
    cancelEditRequest(id: string | number) {
        return api.http.delete(`api/v1/warranty/warranty-update-requests/${id}`)
    },
    cancellationRequest(payload: Payload) {
        return api.http.post('api/v1/warranty/warranty-cancellation-requests', payload)
    },
    approveCancellationRequest(id: string | number) {
        return api.http.post(`api/v1/warranty/warranty-cancellation-requests/${id}/approve`)
    },
    denyCancellationRequest(id: string | number) {
        return api.http.post(`api/v1/warranty/warranty-cancellation-requests/${id}/deny`)
    },
    updateRequest(id: string | number, action: 'approve' | 'deny') {
        return api.http.post(`api/v1/warranty/warranty-update-requests/${id}/${action}`)
    },
    getCalculatePrices(id: string | number, payload: Payload) {
        return api.http.post(`api/v1/warranty/${id}/calculate-price`, payload)
    },
    match(payload: Payload, params?: { validation_only: boolean }) {
        return api.http.post('api/v1/warranty/match', payload, params)
    },
    downloadExampleCsv() {
        const json = [
            {
                'First Name': 'John',
                'Last Name': 'Doe',
                Phone: '(757) 867-5309',
                Email: 'jdoe@email.com',
                'Street Address': '1234',
                City: 'Some Ln	Santa Fe',
                State: 'NM',
                ZIP: '87501',
                'Panel Make & Model': 'Q.CELLS 475W Q.PEAK DUO XL-G10 SERIES',
                'Inverter Make & Model': 'Enphase IQ8-60-2-US Microinverter',
                'Number of Panels': '34',
                'System Size (kW)': '12.58',
                'Installation Date': '2/1/2021',
                'Number of Microinverters/Optimizers': '34',
                'Manufacturer System Identifier(Enphase/SolarEdge System ID)  ': '4358973'
            },
            {
                'First Name': 'Jane',
                'Last Name': 'Doe',
                Phone: '(757) 867-5300',
                Email: 'jdoe123@email.com',
                'Street Address': '5678 Another Rd',
                City: 'Chandler',
                State: 'Arizona',
                ZIP: '85286',
                'Panel Make & Model': 'REC Solar REC420AA-PURE-R Alpha Series 420 Watt Module',
                'Inverter Make & Model': 'SolarEdge SE7600H-US',
                'Number of Panels': '28',
                'System Size (kW)': '10.08',
                'Installation Date': '2/24/2021',
                'Number of Microinverters/Optimizers': '28',
                'Manufacturer System Identifier(Enphase/SolarEdge System ID)  ': '4340083'
            }
        ]

        excel.export(json, 'solarinsure-example-upload')
    }
}
