import { Tooltip } from '@/components'
import { IconProps, IconSize } from '@/components/icons/Icon'
import { WARRANTY_PRODUCT_TYPES, WarrantyProductTypeEnum } from '@/enums'

const COLOR_MAP = {
    warning: 'bg-warning-100 stroke-warning-600',
    purple: 'bg-purple-100 stroke-purple-600',
    success: 'bg-success-100 stroke-success-600'
}

export class WarrantyProductType {
    key: WarrantyProductTypeEnum

    title: string

    secondaryTitle: string

    subtitle: string

    color: 'warning' | 'purple' | 'success'

    IconComponent: (props: IconProps) => JSX.Element

    constructor(props: any) {
        this.key = props.key
        this.title = props.title
        this.secondaryTitle = props.secondaryTitle
        this.subtitle = props.subtitle
        this.color = props.color
        this.IconComponent = props.IconComponent
    }

    static get all() {
        return WARRANTY_PRODUCT_TYPES.map(item => new WarrantyProductType(item))
    }

    static find(key: WarrantyProductTypeEnum) {
        return WarrantyProductType.all.find(item => item.key === key) ||
            WarrantyProductType.defaultType as WarrantyProductType
    }

    static get defaultType() {
        return WarrantyProductType.all.find(item => item.key === WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY)
    }

    static get solar() {
        return WarrantyProductType.find(WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY) as WarrantyProductType
    }

    static get battery20() {
        return WarrantyProductType.find(WarrantyProductTypeEnum.PRODUCTION_WITH_20_YEAR_SEPARATE_STORAGE) as
            WarrantyProductType
    }

    static get battery30() {
        return WarrantyProductType.find(WarrantyProductTypeEnum.PRODUCTION_WITH_30_YEAR_SEPARATE_STORAGE) as
            WarrantyProductType
    }

    icons(size: IconSize = 'xs', tooltip: boolean | 'secondary' = false) {
        return <Tooltip
            key={this.key}
            disabled={!tooltip}
            content={tooltip === 'secondary'
                ? this.secondaryTitle
                : <div className="flex flex-col">{this.title}:<span className="italic">{this.subtitle}</span></div>}
            className="flex w-fit"
        >
            <>
                {this.key !== WarrantyProductTypeEnum.POWER_PRODUCTION_ONLY &&
                    WarrantyProductType.solar.icon(size, false)}
                {this.icon(size, false)}
            </>
        </Tooltip>
    }

    icon(size: IconSize = 'xs', tooltip: boolean | 'secondary' = false) {
        const Icon = this.IconComponent
        return <Tooltip
            key={this.key}
            disabled={!tooltip}
            content={tooltip === 'secondary'
                ? this.secondaryTitle
                : <div className="flex flex-col">{this.title}:<span className="italic">{this.subtitle}</span></div>}
            className={`${COLOR_MAP[this.color]} warranty-type-icon hover:z-10`}
        >
            <Icon size={size}/>
        </Tooltip>
    }
}
