import { useState } from 'react'

import { Button, Popup, IconTools } from '@/components'
import { System } from '@/models'

type TroubleshootingGuidePopupProps = {
    system: System
}

const TroubleshootingGuidePopup = ({ system }: TroubleshootingGuidePopupProps) => {
    const [isOpen, setIsOpen] = useState(false)

    return <>
        <Button design="btn-link" className="!text-primary-800" onClick={() => setIsOpen(true)}>
            Troubleshooting Guide
        </Button>

        <Popup open={isOpen} onClose={() => setIsOpen(false)} className="md:min-w-[718px]">
            <div className="p-4">
                <div className="flex gap-4">
                    <IconTools size="xl" className="stroke-primary-700"/>
                    <h3 className="font-display">
                        Troubleshoot Your System
                    </h3>
                </div>
                <div className="flex items-center mt-6 gap-3 text-sm font-semibold">
                    {system.systemStatus.iconBadge()}
                    {system.systemStatus.title}:
                </div>
                <div className="mt-2 text-sm font-normal">
                    {system.systemStatus.description}
                </div>
                {system.systemStatus.troubleshooting}
            </div>
        </Popup>
    </>
}

export default TroubleshootingGuidePopup
