import { Button, IconClose } from '@/components'
import { useAppSelector } from '@/hooks'
import { ToastNotification } from '@/models'

const ToastNotifications = () => {
    const notifications = useAppSelector(({ toastNotifications }) => toastNotifications)

    return <div className="toast-notifications" data-test="notifications">
        {notifications.map((item: ToastNotification) =>
            <div key={item.id} className="toast-notification" onMouseEnter={item.freeze} onMouseLeave={item.closeWithDelay}>
                <Button design="btn-link" className="toast-notification-close" onClick={item.close}>
                    <IconClose/>
                </Button>
                {item.message}
            </div>
        )}
    </div>
}

export default ToastNotifications
