import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AppState = {
    redirectUrl: string
}
const initialState: AppState = { redirectUrl: '' }

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setRedirectUrl: (state, action: PayloadAction<string>) => {
            if (action.payload === state.redirectUrl) return state
            state = {
                ...state,
                redirectUrl: action.payload
            }
            return state
        }
    }
})

export const { setRedirectUrl } = appSlice.actions

export default appSlice.reducer
