import {
    Chart as ChartJs,
    LineController,
    DoughnutController,
    BarElement,
    BarController,
    LineElement,
    PointElement,
    ArcElement, CategoryScale, LinearScale, Tooltip, ChartOptions, Filler
} from 'chart.js'
import { ChoroplethController, ColorScale, GeoFeature, ProjectionScale } from 'chartjs-chart-geo'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Chart as ReactChart } from 'react-chartjs-2'

ChartJs.register(
    BarController,
    LineController,
    DoughnutController,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Filler,
    ChoroplethController,
    ProjectionScale,
    ColorScale,
    GeoFeature,
    ChartDataLabels
)

ChartJs.defaults.set('plugins.datalabels', { display: false })

type ChartProps = {
    type: 'line' | 'doughnut' | 'bar' | 'choropleth',
    data: any
    plugins?: any
    options: ChartOptions
    onInit?: any
    customTooltip?: (index: number) => ReactNode
}

const Chart = ({ type, data, options, customTooltip, plugins = [], ...props }: ChartProps) => {
    const chartRef = useRef(null)
    const [active, setActive] = useState({
        show: false,
        index: 0,
        x: 0,
        y: 0
    })

    useEffect(() => {
        if (chartRef.current && props.onInit) {
            props.onInit(chartRef.current)
        }
    }, [])

    return <>
        <ReactChart
            ref={chartRef}
            type={type}
            data={data}
            onMouseLeave={() => setActive({ ...active, show: false })}
            options={{
                ...options,
                plugins: {
                    ...options.plugins,
                    tooltip: {
                        ...options.plugins?.tooltip,
                        ...customTooltip ? {
                            enabled: false,
                            external: context => {
                                const tooltipModel = context.tooltip
                                const [activeEl] = tooltipModel.getActiveElements()
                                if (activeEl && (activeEl.index !== active.index || !active.show)) {
                                    const positionY = tooltipModel.caretY
                                    const positionX = window.innerWidth < 768 &&
                                        (window.innerWidth - 150 < tooltipModel.caretX)
                                        ? tooltipModel.caretX - 150
                                        : tooltipModel.caretX + 8
                                    setActive({
                                        show: true,
                                        index: activeEl.index,
                                        x: positionX,
                                        y: positionY
                                    })
                                }
                            }
                        } : {}
                    }
                }
            }}
            plugins={plugins}
        />

        {!!(customTooltip && active.show && active.index >= 0) && <div
            className="absolute -translate-y-1/2 z-40"
            style={{ left: `${active.x}px`, top: `${active.y}px` }}
            onMouseEnter={() => setActive({ ...active, show: true })}
            onMouseLeave={() => setActive({ ...active, show: false })}
        >
            {customTooltip(active.index)}
        </div>}
    </>
}

export default Chart
