import Model from './Model'
import { api, Options, Params, Payload } from '@/services'

export default class Product extends Model {
    static endpoint = 'api/v1/products'

    update = (payload: Payload, params?: Params, options?: Options) => {
        if (payload instanceof FormData) {
            payload.append('_method', 'PATCH')
            payload.append('id', this.id.toString())
            return api.http.post(Product.endpoint, payload, params, options)
        }
        return api.http.put(Product.endpoint, { id: this.id, ...payload }, params, options)
    }

    static onlyBatteries = async () => {
        const res = await api.products.batteries()
        return res.data.map((item: any) => new Product(item))
    }

    static indexTypes = () => api.products.indexTypes()
}
