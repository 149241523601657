import { ReactNode, useState } from 'react'

import { Button, IconChevronUp, StatusGuideExpandingItem } from '@/components'
import { useAuth } from '@/hooks'
import { SystemStatusCategory, SystemStatus, SystemTierCategory } from '@/models'

type StatusGuideExpansionSectionProps = {
    statusCategory: SystemStatusCategory
}

const StatusGuideExpansionSection = ({ statusCategory }: StatusGuideExpansionSectionProps) => {
    const auth = useAuth()
    const [isExpanded, setIsExpanded] = useState(false)

    const handleExpandToggleClick = () => {
        setIsExpanded(isExpanded => !isExpanded)
    }

    return <>
        <hr className="my-6"/>
        <div>
            <Button design="btn-link" onClick={() => handleExpandToggleClick()} className="gap-2">
                <IconChevronUp className={`stroke-black transition-all ${!isExpanded ? 'rotate-180' : ''}`}/>
                <div className="text-gray-800 text-lg font-semibold flex items-center">
                    <span className="mr-5">{statusCategory.title}</span>
                    {(statusCategory.isConnected ? statusCategory.tiers : statusCategory.statuses)
                        .map((item: SystemStatus | SystemTierCategory, index: number) =>
                            <div key={item.value} className={index > 0 ? '-ml-1' : ''}>
                                {item.iconBadge('sm')}
                            </div>)}
                </div>
            </Button>
            <p className="mt-4 text-gray-600 text-xs">
                {statusCategory.description}
            </p>

            {isExpanded && <div className="mt-4">
                {statusCategory.instructions.map((item: { title: string, content: ReactNode }, index: number) =>
                    <div className="my-2" key={index}>
                        <StatusGuideExpandingItem
                            title={<p className="text-primary-800 font-normal text-sm">{item.title}</p>}
                            content={item.content}
                            showChevron={false}
                        />
                    </div>)}
                {statusCategory.isConnected
                    ? statusCategory.tiers.map((item: SystemTierCategory) => <div key={item.value} className="my-2">
                        <StatusGuideExpandingItem
                            title={<div className="flex items-center gap-2 ml-4 text-sm">
                                {item.iconBadge('sm')} {item.title}{item.subtitle && ` - ${item.subtitle}`}
                            </div>}
                            content={<div>
                                {item.description}
                                <div className="flex mt-4 flex-col gap-4">
                                    {item.statuses.length > 1 && item.secondarySortedStatuses.map(
                                        (item: SystemStatus) => <div className="flex gap-2 items-start" key={item.value}>
                                            <div>{item.iconBadge('xs')}</div>
                                            <div><span className="font-semibold whitespace-nowrap">{item.title}:</span> {item.description}</div>
                                        </div>)}
                                </div>
                            </div>}/>
                    </div>)
                    : statusCategory.statuses.map((item: SystemStatus) => <div key={item.value} className="my-2">
                        <StatusGuideExpandingItem
                            title={<div className="flex items-center gap-2 ml-4 text-sm">{item.iconBadge('sm')} {item.title}</div>}
                            content={<div>
                                <div className="flex mt-4 flex-col gap-4 items-start">
                                    {item.description}
                                    {auth.user?.isContractor && item.action}
                                </div>
                            </div>}
                        />
                    </div>)}
            </div>}
        </div>
    </>
}

export default StatusGuideExpansionSection
