import { FormEvent, useEffect, useState } from 'react'

import {
    BatteryWarrantyPreview,
    Card, CreateWarrantyHomeownerFormDataType, CreateWarrantySystemFormDataType, FormChangeEvent,
    IconCertificate,
    PageHeader, SearchableSelect,
    Steps, SystemIdWarningPopup, WarrantyCreateFinalizeStep,
    WarrantyCreateHomeownerStep, WarrantyCreatePreviewStep,
    WarrantyCreateSystemStep
} from '@/components'
import { AuthLayout } from '@/containers'
import { InverterManufacturerEnum } from '@/enums'
import { useAuth, useToastNotifications } from '@/hooks'
import { Company, InverterManufacturer, Warranty } from '@/models'

const breadcrumb = [
    <IconCertificate key={1} className="stroke-gray-500"/>,
    <span key={2}>Add new Warranty</span>,
    'Single Warranty'
]

interface WarrantyCreateFormType extends CreateWarrantyHomeownerFormDataType, CreateWarrantySystemFormDataType {
    company_id: string
}

const WarrantiesCreate = () => {
    const auth = useAuth()
    const { error } = useToastNotifications()
    const [step, setStep] = useState(0)
    const [showBatteryPreview, setShowBatteryPreview] = useState(false)
    const [showSystemIdWarning, setShowSystemIdWarning] = useState(false)
    const [errors, setErrors] = useState<any>([])
    const [contractors, setContractors] = useState<Company[]>([])
    const [warranty, setWarranty] = useState<Warranty | null>(null)
    const [processing, setProcessing] = useState(false)
    const [previewPdf, setPreviewPdf] = useState<Blob | null>(null)
    const [duplicates, setDuplicates] = useState<Warranty[]>([])
    const [form, setForm] = useState<WarrantyCreateFormType>({
        company_id: '',

        first_name: '',
        last_name: '',
        email: '',
        phone: '',

        street_address: '',
        city: '',
        state: '',
        zip: '',

        duplicate_override: undefined,
        allow_invalid: undefined,

        num_panels: '',
        panel_name: '',
        panel_wattage: '',
        size_kw: '',

        inverter_name: '',
        inverter_manufacturer: '',
        num_microinverters: '',
        install_date: new Date(),
        submission_date: auth.user?.isAdminOrStaff ? new Date() : undefined,
        system_id: '',

        batteries: []
    })

    const fetchContractors = async () => {
        const data = await Company.onlyContractors()
        setContractors(data)
    }

    useEffect(() => {
        if (auth.user?.isAdminOrStaff) fetchContractors()
    }, [])

    const handleChange = (e: FormChangeEvent) => {
        const isChecked = e.target.checked ? 1 : 0
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.type === 'checkbox'
                ? isChecked
                : e.target.value
        }))
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setErrors({})
        setProcessing(true)

        const payload: any = { ...form }
        payload.batteries = payload.batteries.map(({ product, ...item }: any) => item)
        try {
            if (step === 0) {
                await Warranty.validateHomeowner(payload)
                setStep(1)
            }
            if (step === 1) {
                const res = await Warranty.validateSystem(payload)
                setPreviewPdf(res)
                if (!form.system_id && auth.user?.isContractor && e) {
                    setShowSystemIdWarning(true)
                } else if (payload.batteries.length && auth.user?.isContractor) {
                    setShowBatteryPreview(true)
                } else {
                    setStep(2)
                }
            }
            if (step === 2) {
                const res = await Warranty.store(payload)
                setWarranty(new Warranty(res.data))
                setStep(3)
            }
        } catch (err: any) {
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
                if (err.errors.street_address && err.errors.street_address.includes('Potential Duplicate Warning.')) {
                    const res = await Warranty.getDuplicates(payload)
                    setDuplicates(res.data || [])
                } else {
                    setDuplicates([])
                }
            }
        } finally {
            setProcessing(false)
        }
    }

    const company = auth.user?.isAdminOrStaff
        ? contractors.find(item => item.id === form.company_id)
        : auth.user?.company

    return <AuthLayout heading={<PageHeader title="Create New Single Warranty" backUrl="/warranties"/>} breadcrumb={breadcrumb}>
        <Card>
            <form onSubmit={handleSubmit} className="md:px-2 lg:px-10" noValidate>
                <Steps
                    className="md:px-5 xl:px-40"
                    steps={[
                        'Homeowner Info',
                        'System Info',
                        'Preview Certificate',
                        'Finalize Warranty'
                    ]}
                    step={step}
                />

                {step === 0 && <>
                    {auth.user?.isAdminOrStaff && <>
                        <h3 className="font-semibold lg:col-span-2 mt-6 lg:mt-12.5 mb-6">Company Info</h3>
                        <SearchableSelect
                            id="company-id"
                            name="company_id"
                            errors={errors.company_id}
                            label="Company*"
                            placeholder="Company"
                            className="lg:col-span-2"
                            options={contractors.map((item: any) => ({
                                value: item.id,
                                title: item.name
                            }))}
                            value={form.company_id}
                            onChange={handleChange}
                        />
                    </>}

                    <WarrantyCreateHomeownerStep
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        duplicates={duplicates}
                        processing={processing}
                    />
                </>}

                {step === 1 && <WarrantyCreateSystemStep
                    form={form}
                    company={company}
                    errors={errors}
                    onChange={handleChange}
                    onPrevious={() => setStep(0)}
                    processing={processing}
                />}

                {step === 2 && <WarrantyCreatePreviewStep
                    form={form}
                    onPrevious={() => setStep(1)}
                    processing={processing}
                    pdf={previewPdf}
                />}

                {step === 3 && <WarrantyCreateFinalizeStep
                    warranty={warranty}
                />}
            </form>
        </Card>

        {auth.user?.isContractor && auth.user?.company && showBatteryPreview && <BatteryWarrantyPreview
            form={form}
            company={auth.user?.company}
            onClose={() => setShowBatteryPreview(false)}
            onNext={() => {
                setShowBatteryPreview(false)
                setStep(2)
            }}
        />}

        <SystemIdWarningPopup
            show={showSystemIdWarning}
            onClose={() => setShowSystemIdWarning(false)}
            inverterManufacturer={InverterManufacturer.find(form.inverter_manufacturer as InverterManufacturerEnum)}
            onSkip={() => {
                setShowSystemIdWarning(false)
                if (form.batteries.length && auth.user?.isContractor) {
                    setShowBatteryPreview(true)
                } else {
                    setStep(2)
                }
            }}
            onAdd={() => {
                setShowSystemIdWarning(false)
                const el = document.querySelector('[name="system_id"]') as HTMLInputElement
                el.focus()
            }}
        />
    </AuthLayout>
}

export default WarrantiesCreate
