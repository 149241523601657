import { ReactNode } from 'react'

import statuses from './modules/integration-credential-statuses'
import { Tooltip } from '@/components'
import { BadgeSizes, IconSize } from '@/components/icons/Icon'
import { IntegrationCredentialStatusEnum } from '@/enums'

export type StatusKey = IntegrationCredentialStatusEnum

export default class IntegrationCredentialStatus {
    key: StatusKey

    rawTitle: string

    message: string

    color: string

    secondaryColor: string

    discovered_at: Date | null

    index: number

    icon: (size?: IconSize) => ReactNode

    constructor({
        status: key,
        discovered_at: discoveredAt
    }: {
        status: StatusKey,
        discovered_at?: string,
        status_change_detected_at?: string
    } = { status: IntegrationCredentialStatusEnum.UNKNOWN }) {
        this.key = key
        this.discovered_at = discoveredAt ? new Date(discoveredAt) : null
        const status = IntegrationCredentialStatus.find(key)
        this.rawTitle = status.title
        this.color = status.color
        this.index = status.index
        this.secondaryColor = status.secondaryColor
        this.message = status.message
        this.icon = status.icon || (() => null)
    }

    get title() {
        return <span className={this.color}>{this.rawTitle}</span>
    }

    get subTitle() {
        return this.discovered_at ? `(Discovered ${new Date(this.discovered_at).humanize()})` : ''
    }

    static find(key: StatusKey): any {
        return statuses.find(item => item.key === key) ||
            IntegrationCredentialStatus.find(IntegrationCredentialStatusEnum.UNKNOWN)
    }

    static get all() {
        return statuses.map(item => new IntegrationCredentialStatus({ status: item.key as StatusKey }))
    }

    getBadge(size: IconSize = 'md') {
        const icon = this.icon(size)
        if (!icon) return null
        return <Tooltip content={<span>{this.rawTitle} {this.subTitle}</span>}>
            <div className={`rounded-full flex justify-center items-center ${this.secondaryColor} ${BadgeSizes[size]}`}>
                {icon}
            </div>
        </Tooltip>
    }
}
