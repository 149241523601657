import { useState } from 'react'

import {
    Button, CardCheckbox,
    IconBankNote, IconCertificate, IconFileShield, IconBarLineChart
} from '@/components'
import { User } from '@/models'

type UserEditFormProps = {
    user: User,
    onSuccess?: any
}
const UserEditForm = ({ user, ...props }: UserEditFormProps) => {
    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState<any>({ preferred_features: user.preffered_features || [] })

    const handleChange = ({ target: { value } }: any) => {
        const preferredFeatures = [...form.preferred_features]
        const index = preferredFeatures.indexOf(value)
        if (index === -1) {
            preferredFeatures.push(value)
        } else {
            preferredFeatures.splice(index, 1)
        }
        setForm({ ...form, preferred_features: preferredFeatures })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        try {
            const res = await user.update(form)
            if (props.onSuccess) props.onSuccess(res)
        } finally {
            setProcessing(false)
        }
    }

    return <form onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
        <div className="lg:col-span-2">
            <h3 className="font-bold mb-2">Define Your Preferences</h3>
            <p className="text-gray-500">Help us learn about your workflow needs so we can create a tailored experience. Please select the one or more features you’d expect to use most frequently. This data will help Solar Insure customize platform designs to specific user needs in the future.</p>
        </div>

        <div className="lg:col-span-2 grid lg:grid-cols-4 gap-3">
            <CardCheckbox
                icon={<IconCertificate size="sm"/>}
                checked={form.preferred_features.includes('warranties')}
                value="warranties"
                onChange={handleChange}
                label={<>
                    <h6>Warranty Registration</h6>
                    <p>Entering new homeowner data to register warranties</p>
                </>}
            />
            <CardCheckbox
                icon={<IconBarLineChart size="sm"/>}
                checked={form.preferred_features.includes('monitoring')}
                value="monitoring"
                onChange={handleChange}
                label={<>
                    <h6>Monitoring</h6>
                    <p>Reviewing and troubleshooting fleet monitoring data</p>
                </>}
            />
            <CardCheckbox
                icon={<IconBankNote size="sm"/>}
                checked={form.preferred_features.includes('invoicing')}
                value="invoicing"
                onChange={handleChange}
                label={<>
                    <h6>Invoicing</h6>
                    <p>Reviewing and paying invoices for monthly warranty sales</p>
                </>}
            />
            <CardCheckbox
                icon={<IconFileShield size="sm"/>}
                checked={form.preferred_features.includes('claims')}
                value="claims"
                onChange={handleChange}
                label={<>
                    <h6>Claims Filing <span className="italic">Coming Soon</span></h6>
                    <p>Filing and reviewing claims for component issues</p>
                </>}
            />
        </div>

        <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end">
            <Button design="btn-secondary-gray" hasError href={`/users/${user.id}`}>
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing}>
                Save
            </Button>
        </div>
    </form>
}

export default UserEditForm
