import classnames from 'classnames'
import React, { ChangeEvent, FocusEvent, TextareaHTMLAttributes, useState } from 'react'

import { FormControl, FormControlProps } from '@/components'
import 'react-quill/dist/quill.snow.css'

const ReactQuill = React.lazy(() => import('react-quill'))

export interface TextEditorProps extends FormControlProps, TextareaHTMLAttributes<HTMLTextAreaElement> {
    value?: string
    defaultValue?: string
}

const colors = [
    'transparent', '#fff', '#000', '#fd0', '#00a878', '#3ebcda', '#f0eeea',
    '#e3f3eb', '#c5e6d6', '#98d3b8', '#00a071', '#008054', '#006a40', '#004822',
    '#e2f3f1', '#c0e5e1', '#8fd2cb', '#009d95', '#007d76', '#006862', '#004641',
    '#f5f4f1', '#edebe7', '#a2a19e', '#61605f', '#4d4c4b', '#403f35', '#181717',
    '#e1f2f8', '#bee4f0', '#8bd1e6', '#009ab7', '#007a96', '#006480', '#00425d',
    '#e1f2f8', '#bee4f0', '#2eb2d0', '#00526d', '#00425d', '#003f59', '#002d47',
    '#edeff5', '#daddea', '#909dc2', '#576fa3', '#7c8cb7', '#3a5c96', '#003d7b',
    '#f0eef7', '#e0dbed', '#c0b7da', '#796db2', '#6256a5', '#51479b', '#322d7d',
    '#ffeaed', '#fed3d9', '#f9acb8', '#e75c7d', '#dd3367', '#c80255', '#90002c',
    '#fffbd4', '#fff37c', '#ffe200', '#f1c907', '#e4be0d', '#d5b211', '#806b18',
    '#ffebce', '#ffd8a2', '#ffb953', '#e79100', '#ca7a00', '#904a00', '#6b2a00',
    '#e1f2f8', '#bee4f0', '#8bd1e6', '#009ab7', '#007a96', '#006480', '#00425d',
    '#ffe9e4', '#ffd5cb', '#ffb4a5', '#ff4a41', '#df1022', '#bd000a', '#820000'
]

export const TextEditor = ({
    id,
    label,
    errors = [],
    warnings = [],
    hint,
    preIcon,
    postIcon,
    transparent,
    tooltip,
    className,
    'input-size': size,
    'data-test': dataTest,
    children,
    ...props
}: TextEditorProps) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleChange = (value: string) => {
        if (props.onChange) {
            props.onChange({
                target: {
                    value,
                    name: props.name
                }
            } as ChangeEvent<HTMLTextAreaElement>)
        }
    }

    const handleFocus = () => {
        setIsFocused(true)
        if (props.onFocus) {
            props.onFocus({
                target: {
                    value: props.value,
                    name: props.name
                }
            } as FocusEvent<HTMLTextAreaElement>)
        }
    }

    const handleBlur = () => {
        setIsFocused(false)
        if (props.onFocus) {
            props.onFocus({
                target: {
                    value: props.value,
                    name: props.name
                }
            } as FocusEvent<HTMLTextAreaElement>)
        }
    }

    return <FormControl
        id={id}
        label={label}
        input-size={size}
        data-test={dataTest}
        preIcon={preIcon}
        postIcon={postIcon}
        errors={errors}
        warnings={warnings}
        hint={hint}
        transparent={transparent}
        tooltip={tooltip}
        className={classnames('form-control-texteditor', className)}
        data-value={props.value}
    >
        <ReactQuill
            id={id}
            theme="snow"
            className={classnames('form-control-element', { focus: isFocused })}
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'image', 'video', 'link'],

                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }],

                    [{ header: [1, 2, 3, false] }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    [{ color: colors }, { background: colors }],
                    [{ align: [] }],

                    ['clean']
                ]
            }}
            {...props}
            value={props.value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    </FormControl>
}
