import { Link } from 'react-router-dom'

import { Card } from '@/components'
import Logo from '@/components/Logo'

const Index = () =>
    <div className="policy bg-orange-500 p-5">
        <Link to="/" className="intro-y flex items-center pt-5 justify-center pb-5" data-test="privacy-policy-home-link">
            <Logo type="primaryWhite" className="w-60" data-test="privacy-policy-logo"/>
        </Link>
        <Card className="max-w-4xl mx-auto" data-test="privacy-policy-content">
            <h1>Privacy Policy</h1>
            <p><strong>Last updated:</strong> June 01, 2022</p>
            <p>At Solar Insure, Inc. the protection of the information we collect about you is important to us. This web site, www.solarinsure.com (the “<strong>Site</strong>”), is owned and operated by Solar Insure, Inc. (“<strong>Solar Insure</strong>,” “<strong>we</strong>,” “<strong>our</strong>,” or “<strong>us</strong>”). This Privacy Policy applies to any applications or web sites that we own and control and that posts a link to this Privacy Policy (including the Site), any mobile application, and any interactive features, widgets, plug-ins, applications, content, downloads and other services that we may own and control and make available through the Site (collectively, the “<strong>Services</strong>”). By accessing our Site and Services, you consent to this Privacy Policy and our <a href="/terms-of-service">Terms of Use</a> (“<strong>Terms</strong>”), including our collection, use, and sharing of your information, as described below. To the extent that we provide you with notice on our Site or Services of different or additional privacy policies or practices (e.g. at the point of collection), those additional privacy policies shall govern such data collection and use.
            </p>
            <p>
                If you do not agree to our Terms and the collection, use and sharing of your information as detailed in
                this Privacy Policy, please do not access or otherwise use our Site or Services or any information or
                content contained on the Site or Services.
            </p>
            <p>
                We reserve the right to change this Privacy Policy at any time and we will post notice on our Site or
                inform you of any such changes. Your use of the Site or Services following the posting of any updated
                Privacy Policy constitutes your acceptance of such updated Privacy Policy. We reserve the right to apply
                the amended terms to the information that we have already collected, subject to any legal constraints.
                We will not, however, use your previously collected Personal Information (defined herein) in a manner
                materially different than represented at the time of collection. To the extent any provision of this
                Privacy Policy is found by a competent tribunal to be invalid or unenforceable, such provision shall be
                severed to the extent necessary for the remainder to be valid and enforceable.
            </p>
            <h1>1. Collecting and Using Your Personal Information</h1>
            <h2>1.1 Types of Information Collected</h2>
            <h3>1.1a Personal Information</h3>
            <p>
                While using our Services, we may ask you to provide us with certain personally identifiable information
                (“<strong>Personal Information</strong>”) that can be used to contact or identify you. Personal
                Information may include, but is not limited to:
            </p>
            <ul>
                <li>
                    <p>
                        Business and personal contact information, such as your first and last name, email and physical
                        addresses, phone number, professional title, and company name.
                    </p>
                </li>
                <li>
                    <p>
                        Content you choose to upload to the Service, such as text, images, audio, video, and digital
                        signatures, along with the metadata associated with the files you upload.
                    </p>
                </li>
                <li>
                    <p>
                        Profile information, such as your username and password that you may set to establish an online
                        account with us, your account name, account number, photograph, interests, and preferences.
                    </p>
                </li>
                <li>
                    <p>
                        Feedback or correspondence, such as information you provide when you contact us with questions,
                        feedback, or otherwise correspond with us online.
                    </p>
                </li>
                <li>
                    <p>
                        Demographic Information, such as your street address, city, state, country of residence, and
                        postal code.
                    </p>
                </li>
                <li>
                    <p>
                        Energy information, such as your energy production, solar installation contract, energy
                        consumption, battery settings and usage history, other load consumption or production, and
                        other information relating to any of your energy resources.
                    </p>
                </li>
                <li>
                    <p>
                        Site information, such as information related to your solar installation including roof type,
                        house size, device configuration settings, appliance usage, microinverter details, system
                        configuration settings, electrical circuit breaker and panel details, permit information, and
                        photographs.
                    </p>
                </li>
                <li>
                    <p>
                        Marketing information, such as your preferences for receiving communications about our
                        activities, events, and publications, and details about how you engage with our communications.
                        We also may conduct user surveys, and research and analysis about your use of, or interest in,
                        our products and surveys.
                    </p>
                </li>
                <li>
                    <p>
                        Other information that we may collect which is not specifically listed here but which we will
                        use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.
                    </p>
                </li>
            </ul>
            <h3>1.1b Usage Data</h3>
            <p>
                “<strong>Usage Data</strong>“ is data collected automatically, either generated by the use of the Site
                or Services (for example, the duration of a Site page visit).
            </p>
            <p>
                Usage Data may include information such as your Device&apos;s Internet Protocol address
                (e.g. IP address), browser type, browser version, the pages of our Site or Services that you visit, the
                time and date of your visit, the time spent on those pages, unique Device identifiers and other
                diagnostic data. A “<strong>Device</strong>” is any device that can access the Site or Services such as
                a computer, a mobile phone or a digital tablet.
            </p>
            <p>
                When you access the Site or Services by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device you use, Your mobile device
                unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other diagnostic data.
            </p>
            <p>
                We may also collect information that your browser sends whenever you visit our Site or Services or when
                you access the Site or Services by or through a mobile device.
            </p>
            <h3>1.1c Tracking Technologies and Cookies</h3>
            <p>
                We use Cookies and similar tracking technologies to track the activity on our Service and store certain
                information. “<strong>Cookies</strong>”&nbsp;are small files placed on your computer, mobile device or
                any other device by a website, containing the details of your browsing history on that website (among
                its many uses). The technologies we use may include:
            </p>
            <ul>
                <li>
                    <p>
                        <strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on your Device. You
                        can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                        However, if you do not accept Cookies, you may not be able to use some parts of our Site or
                        Services. Unless you have adjusted your browser setting so that it will refuse Cookies, our Site
                        or Services may use Cookies.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Web Beacons.</strong>&nbsp;Certain sections of our Site or Services and our emails may
                        contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags,
                        and single-pixel gifs) that permit Solar Insure, for example, to count users who have visited
                        those pages or opened an email and for other related website statistics (for example, recording
                        the popularity of a certain section and verifying system and server integrity).
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Embedded Scripts.</strong> An embedded script is programming code that is designed to
                        collect information about your interactions with the Site, such as the links you click on. The
                        code is temporarily downloaded onto your Device from our web server or a third-party service
                        provider, is active only while you are connected to the Site, and is deactivated or deleted
                        thereafter.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Browser Fingerprinting.</strong> Collection and analysis of information from your
                        Device, such as, without limitation, your operating system, plug-ins, system fonts and other
                        data, for purposes of identification.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>ETag, or Entity Tag.</strong> A feature of the cache in browsers. It is an opaque
                        identifier assigned by a web server to a specific version of a resource found at a URL. If the
                        resource content at that URL ever changes, a new and different ETag is assigned. Used in this
                        manner ETags are a form of Device Identifier. ETag tracking may generate unique tracking values
                        even where the consumer blocks HTTP and/or HTML5 cookies.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Recognition Technologies.</strong>&nbsp;Technologies, including application of
                        statistical probability to data sets, which attempt to recognize or make assumptions about users
                        and Devices (e.g., that a user of multiple devices in the same user).
                    </p>
                </li>
            </ul>
            <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies here: <a href="https://www.termsfeed.com/blog/cookies/">All About Cookies by TermsFeed</a>.
            </p>
            <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
            <ul>
                <li>
                    <p><strong>Necessary / Essential Cookies</strong></p>
                    <p>Type: Session Cookies</p>
                    <p>Administered by: Us</p>
                    <p>
                        Purpose: These Cookies are essential to provide you with services available through the Site and
                        to enable you to use some of its features. They help to authenticate users and prevent
                        fraudulent use of user accounts. Without these Cookies, the services that you have asked for
                        cannot be provided, and we only use these Cookies to provide you with those services.
                    </p>
                </li>
                <li>
                    <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Site.</p>
                </li>
                <li>
                    <p><strong>Functionality Cookies</strong></p>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>
                        Purpose: These Cookies allow us to remember choices you make when you use the Site, such as
                        remembering your login details or language preference. The purpose of these Cookies is to
                        provide you with a more personal experience and to avoid you having to re-enter your preferences
                        every time you use the Site.
                    </p>
                </li>
            </ul>
            <p>
                For more information about the cookies we use and your choices regarding cookies, please visit our
                Cookies Policy or the Cookies section of our Privacy Policy.
            </p>
            <h2>1.2 Types of Information we do not collect</h2>
            <p>
                Solar Insure will not access private data for system owners that are not registered with the Solar
                Insure Monitoring and Warranty and for which we do not have express consent from that system owner.
            </p>
            <h2>1.3 Use of Your Personal Information</h2>
            <p>The Company may use Personal Information for the following purposes:</p>
            <ul>
                <li>
                    <p>
                        <strong>To provide and maintain our Service</strong>, including monitoring the usage of our Site
                        and Services.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>To manage Your Account:</strong> to manage your registration of a unique account
                        (“<strong>Account</strong>”) as a user of the Site or Services, if applicable. The Personal
                        Information you provide can give you access to different functionalities of the Site and
                        Services that are available to you as a registered user.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>For the performance of a contract:</strong> &nbsp;the development, compliance and
                        undertaking of the purchase contract for the products, items or services you have purchased or
                        of any other contract with us through the Site or Services.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>To contact You:</strong> To contact you by email, telephone calls, SMS, or other
                        equivalent forms of electronic communication, such as a mobile application&apos;s push
                        notifications regarding updates or informative communications related to the functionalities,
                        products or contracted services, including the security updates, when necessary or reasonable
                        for their implementation.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>To provide You</strong> with news, special offers and general information about other
                        goods, services and events we offer that are similar to those that you have already purchased or
                        enquired about, unless you have opted not to receive such information.
                    </p>
                </li>
                <li>
                    <p><strong>To manage Your requests:</strong> To attend and manage your requests to us.</p>
                </li>
                <li>
                    <p>
                        <strong>For business transfers:</strong> We may use your information to evaluate or conduct a
                        merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of
                        some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or
                        similar proceeding, in which Personal Information held by us about our Site or Services users is
                        among the assets transferred.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>For other purposes</strong>: We may use your information for other purposes, such as
                        data analysis, identifying usage trends, determining the effectiveness of our promotional
                        campaigns and to evaluate and improve our Site or Services, products, services, marketing and
                        your experience.
                    </p>
                </li>
            </ul>
            <p>We may share your Personal Information in the following situations:</p>
            <ul>
                <li>
                    <p>
                        <strong>With Service Providers:</strong> &nbsp;We may share your Personal Information with
                        service providers to monitor and analyze the use of our Site or Services, to contact you.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>For business transfers:</strong> We may share or transfer your Personal Information in
                        connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                        acquisition of all or a portion of our business to another company.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>With Affiliates:</strong> We may share your information with our affiliates, in which
                        case we will require those affiliates to honor this Privacy Policy. Affiliates include our
                        parent company and any other subsidiaries, joint venture partners or other companies that we
                        control or that are under common control with us.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>With business partners:</strong> We may share your information with our business
                        partners to offer you certain products, services or promotions.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>With other users:</strong> when you share Personal Information or otherwise interact in
                        the public areas with other users, such information may be viewed by all users and may be
                        publicly distributed outside.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>With Your consent</strong>: We may disclose your Personal Information for any other
                        purpose with your consent.
                    </p>
                </li>
            </ul>
            <h2>1.4 Changing Information and Communication Preferences</h2>
            <p>You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of any Account. The Site or Services may allow you to review, correct or update Personal Information you have provided through the Site’s forms or otherwise. For example, when you edit your Personal Information or change your preferences on the Site, information that you remove may persist internally for our administrative purposes. We may provide marketing communications, and you may be able to change your preferences with respect to such communications through the Site. Please note that we reserve the right to send you certain communications relating to your account or use of our Site or Services, such as administrative and service announcements and these transactional account messages may be unaffected if you choose to opt-out from receiving our marketing communications. If you have any questions about the Privacy Policy or practices described in it, please contact us at: <a href="mailto:admin@solarinsure.com">admin@solarinsure.com</a>.
            </p>
            <p>You can always contact us in order to (i) remove the Personal Information that you have provided to us from our systems, (ii) update the Personal Information that you have provided to us, and (iii) change your preferences with respect to our use of your Personal Information by emailing us at <a href="mailto:admin@solarinsure.com">admin@solarinsure.com</a>. If so, we will use good faith efforts to make requested changes in our then active databases as soon as reasonably practicable (but we may retain prior information as business records). Please note that it is not always possible to completely remove or delete all of your information from our databases and that residual data may remain on backup media or for other reasons.
            </p>
            <h2>1.5 Transfer of Your Personal Data</h2>
            <p>
                Your information, including Personal Information, is processed at Solar Insure’s operating offices and
                in any other places where the parties involved in the processing are located. It means that this
                information may be transferred to—and maintained on—computers located outside of your state, province,
                country or other governmental jurisdiction where the data protection laws may differ than those from
                your jurisdiction.
            </p>
            <p>
                Your consent to this Privacy Policy followed by your submission of such information represents your
                agreement to that transfer.
            </p>
            <p>
                Solar Insure will take all steps reasonably necessary to ensure that your data is treated securely and
                in accordance with this Privacy Policy.
            </p>
            <h2>1.6 Disclosure of Your Personal Data</h2>
            <h3>1.6a Business Transactions</h3>
            <p>
                If Solar Insure is involved in a merger, acquisition or asset sale, your Personal Information may be
                transferred. We will provide notice before your Personal Information is transferred and becomes subject
                to a different Privacy Policy.
            </p>
            <h3>1.6b Law enforcement</h3>
            <p>
                Under certain circumstances, Solar Insure may be required to disclose your Personal Information if
                required to do so by law or in response to valid requests by public authorities (e.g. a court or a
                government agency).
            </p>
            <h3>1.6c Other Legal Requirements</h3>
            <p>
                Solar Insure may disclose your Personal Information in the good faith belief that such action is
                necessary to:
            </p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of Solar Insure</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Site or Services</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>
            <h3>1.6d Security of Your Personal Information</h3>
            <p>
                The security of your Personal Information is important to us, but remember that no method of
                transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute
                security. For example, we use Secure Sockets Layer (SSL) for our registration and ordering functions,
                and we utilize a PCI-certified service provider to process credit card payments. We also expect our
                service providers to protect information in the same manner.
            </p>
            <h3>1.6e Children&apos;s Privacy</h3>
            <p>
                Our Site and Services are not intended for children under the age of 13, and we do not knowingly collect
                Personal Information from anyone under the age of 13. If we become aware that we have collected Personal
                Information from anyone under the age of 13 without verification of parental consent, we take steps to
                remove that information from our servers. If you believe we might have any Personal Information from or
                about a child, please contact us at&nbsp;
                <a href="mailto:admin@solarinsure.com">admin@solarinsure.com</a>
            </p>
            <h3>1.6g Transfer of Information to the United States</h3>
            <p>
                If you are from a non-U.S. country, please be aware that the Personal Information you submit, including
                information provided through our Site and Services, is being sent to a location in the United States.
                The data protection laws in the United States are likely different from those of the country in which
                you are located, and your Personal Information may be subject to access requests from governments,
                courts, or law enforcement in the United States according to laws of the United States. By providing
                such Personal Information through the Site or Services, you are consenting to (and represent that you
                have authority to consent to), the transfer of such information to the United States for the uses and
                purposes described in this Privacy Policy. Without limiting the above, for the avoidance of doubt we do
                not have an establishment or target people in the EEA or UK.
            </p>
            <h1>2. Links to Other Websites</h1>
            <p>
                Our Service may contain links to other websites that are not operated by us. If you click on a third
                party link, you will be directed to that third party’s site. We strongly advise you to review the
                Privacy Policy of every site you visit. We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites or services.
            </p>
            <h2>2.1 Embedded and Linked Services with Third Parties</h2>
            <p>
                We may provide certain parts of our Site or Services in association with third parties, such as
                promotional partners, service providers, or affiliates. These “co-branded areas” will identify the third
                party. If you choose to use these co-branded areas, we may share your information with the identified
                third party, and that third party may also collect information from you, in addition to the information
                that we collect, as described above. You should review the privacy policies of those identified third
                parties to understand how they collect and use information.
            </p>
            <h1>3. Changes to this Privacy Policy</h1>
            <p>We may update our Privacy Policy from time to time.</p>
            <p>
                We will let you know via email and/or a prominent notice on our Site or Service, prior to the change
                becoming effective and update the “Last updated” date at the top of this Privacy Policy.
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.
            </p>
            <h1>4. Contact Us</h1>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                <li><strong>By email:</strong> <a href="mailto:admin@solarinsure.com">admin@solarinsure.com</a></li>
            </ul>
        </Card>
    </div>

export default Index
