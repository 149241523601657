import { IconBatteryCharging2, IconFileCheck, IconHelpCircle, IconSliders, IconSpeedometer } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { SystemStatusEnum } from '@/enums'

export default [
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_3,
        title: 'Low Impact Alert - Level 3',
        refreshable: true,
        secondaryOrder: 6,
        description: 'Low-tier alert, indicates a minor issue that should be addressed to maintain system efficiency. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        icon: () => 3,
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_2,
        title: 'Low Impact Alert - Level 2',
        refreshable: true,
        icon: () => 2,
        secondaryOrder: 7,
        description: 'Alert signifies a small impact issue, low priority but warrants monitoring. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.IMPACT_ALERT_LEVEL_1,
        title: 'Low Impact Alert - Level 1',
        refreshable: true,
        icon: () => 1,
        secondaryOrder: 8,
        description: 'Minimal impact alert, indicating very low risk, to be addressed in routine maintenance checks. For detailed information, please visit your SolarEdge Monitoring portal. Solar Insure only retrieves the Impact Indicator score (1-9), calculated by SolarEdge based on potential energy loss.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">SolarEdge Monitoring Portal</span> at <a href="https://monitoring.solaredge.com/solaredge-web/p/login" target="_blank" rel="noreferrer">https://monitoring.solaredge.com/solaredge-web/p/login</a>.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the &apos;
                    <span className="font-semibold">Sites&apos; list</span>.
                </li>
                <li>
                    <span className="font-semibold">Navigate</span> to the &apos;
                    <span className="font-semibold">Alerts&apos; tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Click</span> on the <span className="font-semibold">hyperlinked Alert type</span> in the &apos;
                    <span className="font-semibold">Alert Type&apos; column</span>.
                </li>
                <li>
                    <span className="font-semibold">Review</span> the <span className="font-semibold">troubleshooting tips</span> in the alert details pop-up.
                </li>
                <li>
                    <span className="font-semibold">Contact</span>
                    <span className="font-semibold">SolarEdge support</span> for additional assistance if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.BATTERY,
        title: 'AC Batteries Not Reporting',
        refreshable: true,
        secondaryOrder: 1,
        icon: (size: IconSize) => <IconBatteryCharging2 size={size}/>,
        description: 'AC battery units are not communicating, possibly due to interference or connection issues.This can usually be addressed by the system owner and should not require and on-site service visit.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>, and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Verify</span> no interfering devices are on the same circuit as the Gateway.
                </li>
                <li>
                    <span className="font-semibold">Remove</span> potential sources of interference, such as CFLs.
                </li>
                <li>
                    <span className="font-semibold">Check</span> the AC Battery status on the <span className="font-semibold">Devices</span> list if issues persist.
                </li>
                <li>
                    <span className="font-semibold">Consult</span> the <span className="font-semibold">Enphase AC Battery Manual</span> or <span className="font-semibold">contact Customer Support</span> if needed.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENCHARGE,
        title: 'Battery Not Reporting',
        refreshable: true,
        secondaryOrder: 2,
        icon: (size: IconSize) => <IconBatteryCharging2 size={size}/>,
        description: 'Battery disconnected and not reporting data. Momentary disruptions in communication due to changes in environmental variables are normal.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>, and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Wait</span> 15 minutes for potential self-diagnosis and recovery.
                </li>
                <li>
                    <span className="font-semibold">Check</span> the Battery&apos;s State of Charge; allow 24 hours if recently depleted.
                </li>
                <li>
                    <span className="font-semibold">Reset</span> the Zigbee communication kit if necessary.
                </li>
                <li>
                    <span className="font-semibold">Contact Enphase Support</span> for unresolved issues.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.METER,
        title: 'Meter Not Reporting',
        refreshable: true,
        secondaryOrder: 3,
        icon: (size: IconSize) => <IconSpeedometer size={size}/>,
        description: 'The system has a revenue grade meter (RGM) that has stopped communicating with the gateway. Occasional instances of this message may be ignored, as a temporary interference to the radio signal may cause this condition.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>, and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Devices tab</span> to see device details.
                </li>
                <li>
                    <span className="font-semibold">Work with</span> the homeowner to remotely confirm if the USB ZigBee radio stick is properly inserted.
                </li>
                <li>
                    <span className="font-semibold">Power cycle</span> the Gateway and <span className="font-semibold">check system status</span> after 15 minutes.
                </li>
                <li>
                    <span className="font-semibold">Install</span> a repeater if signal issues persist, per the <span className="font-semibold">RGM Manual</span>.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ENPOWER,
        title: 'System Controller Not Reporting',
        refreshable: true,
        secondaryOrder: 4,
        icon: (size: IconSize) => <IconSliders size={size}/>,
        description: 'The system controller is offline. Momentary disruptions in communication are normal and are seen due to changes in environmental variables.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>.
                </li>
                <li>
                    <span className="font-semibold">Go</span> to the <span className="font-semibold">Events Tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Look into</span> issues related to system controllers by <span className="font-semibold">selecting</span> the <span className="font-semibold">event name</span>.
                </li>
                <li>
                    <span className="font-semibold">Apply</span> the recommended actions provided by Enphase.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.ACTIVATION,
        title: 'Activation Incomplete',
        refreshable: true,
        secondaryOrder: 5,
        icon: (size: IconSize) => <IconFileCheck size={size}/>,
        description: 'System activation is not fully completed and may need additional setup or configuration steps.',
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <span className="font-semibold block mt-2">For Enphase systems,</span>
            <ol className="mt-4 mb-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>, and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Activations tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Confirm</span> all four stages of system activation in Enlighten have been completed.
                </li>
                <li>
                    <span className="font-semibold">Revisit</span> each stage to ensure all checks are cleared.
                </li>
                <li>
                    <span className="font-semibold">Contact Enphase support</span> for additional assistance if needed.
                </li>
            </ol>
            <span className="font-semibold">For SolarEdge Systems, </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log</span> into the <span className="font-semibold">SolarEdge Monitoring Portal</span> at https://monitoring.solaredge.com/solaredge-web/p/login.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the <span className="font-semibold">&apos;Sites&apos; list.</span>
                </li>
                <li>
                    <span className="font-semibold">A Pending status</span> usually indicates that the system has not achieved PTO. <span className="font-semibold">Confirm</span> site connection details.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.UNKNOWN_STATUS,
        title: 'Unknown Status',
        secondaryOrder: 9,
        refreshable: true,
        icon: (size: IconSize) => <IconHelpCircle size={size}/>,
        description: 'This status is assigned when the system status is not known. Log into Generac for more details',
        troubleshooting: <div className="font-normal text-sm p-3 bg-error-300 mt-6 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <span className="font-semibold block mt-2">For Enphase systems,</span>
            <ol className="mt-4 mb-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log into</span> the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/, <span className="font-semibold">select</span> the homeowner&apos;s site from the <span className="font-semibold">systems list</span>, and <span className="font-semibold">navigate</span> to the <span className="font-semibold">Activations tab</span>.
                </li>
                <li>
                    <span className="font-semibold">Confirm</span> all four stages of system activation in Enlighten have been completed.
                </li>
                <li>
                    <span className="font-semibold">Revisit</span> each stage to ensure all checks are cleared.
                </li>
                <li>
                    <span className="font-semibold">Contact Enphase support</span> for additional assistance if needed.
                </li>
            </ol>
            <span className="font-semibold">For SolarEdge Systems, </span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Log</span> into the <span className="font-semibold">SolarEdge Monitoring Portal</span> at https://monitoring.solaredge.com/solaredge-web/p/login.
                </li>
                <li>
                    <span className="font-semibold">Locate</span> the associated homeowner in the <span className="font-semibold">&apos;Sites&apos; list.</span>
                </li>
                <li>
                    <span className="font-semibold">A Pending status</span> usually indicates that the system has not achieved PTO. <span className="font-semibold">Confirm</span> site connection details.
                </li>
            </ol>
        </div>
    }
]
