import { Link, To, useParams } from 'react-router-dom'

import { ClickToCopy, IconPasscode, IconUser01, PageHeader, Tooltip } from '@/components'
import { useAuth } from '@/hooks'
import { Integration, Warranty } from '@/models'

type WarrantyHeaderProps = {
    warranty: Warranty
    backUrl: To
}

export const WarrantyHeader = ({ backUrl, warranty }: WarrantyHeaderProps) => {
    const { tab = 'warranty' } = useParams()
    const auth = useAuth()

    const handleCopyPassword = async (): Promise<string> =>
        warranty.homeowner?.company?.enphaseIntegration?.showCredentials()

    const getAffiliateLabel = () => {
        if (warranty.affiliate?.name) {
            if (auth.user?.isAdminOrStaffOrAdvisor) {
                return <Link
                    to={`/companies/${warranty.affiliate?.id}`}
                    className="ml-1.5 px-2 py-1 bg-blue-50 text-blue-700 text-xs block"
                >
                    {warranty.affiliate?.name}
                </Link>
            }
            return <div className="ml-1.5 px-2 py-1 bg-blue-50 text-blue-700 text-xs">
                {warranty.affiliate?.name}
            </div>
        }
        return <div className="ml-1.5 px-2 py-1 bg-error-50 text-error-700 text-xs">
            None
        </div>
    }

    return <PageHeader
        title={<div className="flex gap-4 items-center">
            {warranty.homeowner?.fullName}
            {warranty.warrantyStatus.badge(false, false)}
        </div>}
        backUrl={backUrl}
        subtitle={
            <div className="flex flex-col md:flex-row justify-between w-full mt-4 lg:mt-7 font-body text-sm text-gray-500 gap-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 max-w-96">
                    <div className="flex items-center gap-1">
                        Warranty Type: {warranty.productType.icons('xs', true)}
                    </div>
                    <div className="flex items-center gap-1 sm:justify-end">
                        Monitoring Status: <Tooltip content={warranty?.homeowner?.system?.systemStatus?.title}>{warranty?.homeowner?.system?.systemStatus?.iconBadge('xs')}</Tooltip>
                    </div>
                    {(auth.user?.isAdminOrStaffOrAdvisor || auth.user?.isAffiliate || auth.user?.isSubsidiary) &&
                        <>
                            <div className="flex items-center gap-1">
                                Contractor:
                                {auth.user?.isAdminOrStaffOrAdvisor
                                    ? <Link
                                        to={`/companies/${warranty.homeowner?.company?.id}`}
                                        className="text-blue-700 blue-dark-50 ml-1.5 bg-blue-50 px-2 py-1 text-xs block"
                                    >
                                        {warranty.homeowner?.company?.name}
                                    </Link>
                                    : <div className="text-blue-700 blue-dark-50 ml-1.5 bg-blue-50 px-2 py-1 text-xs">
                                        {warranty.homeowner?.company?.name}
                                    </div>}
                            </div>

                            <div className="flex items-center gap-1 sm:justify-end">
                                Affiliate: {getAffiliateLabel()}
                            </div>
                        </>}
                    {(auth.user?.isAdminOrStaffOrAdvisor || auth.user?.isSoftwareAffiliate) &&
                        <>
                            <div className="flex items-center gap-1">
                                Warranty Price:
                                <div className="text-gray-500 bg-warning-50 ml-1.5 bg-blue-50 px-2 py-1 text-xs">
                                    {parseFloat(warranty.price).money()}
                                </div>
                            </div>

                            {
                                warranty.eligible_commission !== null &&
                                warranty.eligible_commission !== undefined &&
                                warranty.affiliate?.isFullSubcontractorMatch &&
                                auth.user?.isAdminOrStaffOrAdvisor &&
                                <div className="flex gap-1 items-center sm:justify-end">
                                    Eligible Commission:
                                    <div className="text-gray-500 bg-success-100 ml-1.5 px-2 py-1 text-xs">
                                        {parseFloat(warranty.eligible_commission).money()}
                                    </div>
                                </div>
                            }
                        </>}
                </div>

                {auth.user?.isAdminOrStaffOrAdvisor &&
                    tab === 'monitoring' &&
                    warranty.homeowner?.company?.enphaseIntegration &&
                    warranty.homeowner.system.inverterManufacturer.isEnphase &&
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-1 justify-start md:justify-end items-center">
                            <Tooltip content={`${warranty.homeowner.company.name}'s Enphase User Name`}>
                                <IconUser01 className="stroke-gray-500"/>
                            </Tooltip>
                            <div className="bg-primary-50 flex gap-1 rounded-md p-1.5 items-center">
                                {warranty.homeowner.company.enphaseIntegration.isUsesMaintainer
                                    ? Integration.MAINTAINER_USERNAME
                                    : warranty.homeowner.company.enphaseIntegration.user}
                                <ClickToCopy content={warranty.homeowner.company.enphaseIntegration.isUsesMaintainer
                                    ? Integration.MAINTAINER_USERNAME
                                    : warranty.homeowner.company.enphaseIntegration.user}/>
                            </div>
                        </div>
                        <div className="flex gap-1 justify-start md:justify-end items-center">
                            <Tooltip content={`${warranty.homeowner.company.name}'s Enphase Password`}>
                                <IconPasscode className="stroke-gray-500"/>
                            </Tooltip>
                            <div className="bg-primary-50 flex gap-1 rounded-md p-1.5 items-center">
                                ••••••••••••••••
                                <ClickToCopy content={handleCopyPassword}/>
                            </div>
                        </div>
                    </div>}
            </div>
        }
    />
}
