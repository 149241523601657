import { ReactNode } from 'react'

import emptyState from '@/assets/images/table/tableEmptyStateDefault.svg'
import loadingState from '@/assets/images/table/tableLoadingStateDefault.svg'
import { Button, IconAlertCircle, IconSearch, Image } from '@/components'

type TablePlaceholderProps = {
    content: ReactNode
    processing: boolean | string
    rows: any
    filters: any
    dataType?: string
    errors: any
}

const TablePlaceholder = ({ content, processing, rows, dataType, errors, filters }: TablePlaceholderProps) => {
    const getContent = () => {
        if (processing === true) {
            return <Image
                className="w-30 animate-pulse"
                src={loadingState}
            />
        }
        if (errors) {
            return <>
                <div className="w-14 h-14 rounded-full bg-warning-50 flex justify-center items-center">
                    <div className="w-10 h-10 rounded-full bg-warning-100 flex justify-center items-center">
                        <IconAlertCircle className="stroke-warning-600"/>
                    </div>
                </div>

                <h4 className="mt-4 font-light">Something went wrong...</h4>
                <p className="text-sm text-gray-500 font-light mt-1 text-center">
                    We had some trouble loading this page. Please refresh the page to try again or get in touch if the
                    problem sticks around!
                </p>

                <Button className="mt-6" size="btn-lg" onClick={() => window.location.reload()}>
                    Refresh page
                </Button>
            </>
        }

        if (!rows.length && Object.values(filters).some(item => item)) {
            return <>
                <div className="w-14 h-14 rounded-full bg-blue-50 flex justify-center items-center">
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex justify-center items-center">
                        <IconSearch className="stroke-blue-600"/>
                    </div>
                </div>

                <h4 className="mt-4 font-light">No records found</h4>
                {filters.search
                    ? <p className="text-sm text-gray-500 font-light mt-1 text-center">
                        Your search “{filters.search}” did not match any {dataType}. Please try again.
                    </p>
                    : <p className="text-sm text-gray-500 font-light mt-1 text-center">
                        The filters you applied did not match any records in the table. Please try adjusting your
                        filters and searching again.
                    </p>}
            </>
        }

        if (content) {
            return content
        }
        return <Image
            className="w-30"
            src={emptyState}
        />
    }

    if (rows.length) return null

    return <div className="w-full h-full bg-white flex justify-center py-16">
        <div className="max-w-[22rem] flex flex-col items-center">
            {getContent()}
        </div>
    </div>
}

export default TablePlaceholder
