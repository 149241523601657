import { useParams } from 'react-router-dom'

import { Button, Card } from '@/components'
import { Device, Warranty } from '@/models'

type DevicesListProps = {
    warranty: Warranty
}

const DevicesList = ({ warranty }: DevicesListProps) => {
    const { type } = useParams()

    return <div className="grid lg:grid-cols-3 gap-6 mb-6">
        <Card className="lg:col-span-3">
            <h2 className="card-title mb-6 font-semibold text-base text-gray-900 flex justify-between items-center">
                <span data-test="monitoring-details-devices-title">
                    Devices
                </span>
                <Button className="leading-6 text-sm" design="btn-secondary" href={`/${type}/${warranty.id}/devices`} data-test="monitoring-details-devices-view-more">
                    View More
                </Button>
            </h2>
            <div className="flex flex-wrap gap-4">
                {warranty.homeowner?.system?.deviceCategories.map(item => {
                    if (!item.devices.length) return null
                    return <Card key={item.type} className="flex-1">
                        <div className="flex justify-between mb-3">
                            <h3 className="text-lg" data-test={`monitoring-details-devices-${item.title?.toLowerCase()}-title`}>{item.title} ({item.devices.length})</h3>
                        </div>
                        {warranty.homeowner?.system?.inverterManufacturer.isEnphase && <div className="card-row flex flex-col gap-2">
                            {item.normalDevices.length > 0 && <div className="card-row-copy flex items-center gap-2" data-test="monitoring-details-devices-normal-devices">
                                {Device.getIcon('normal')}
                                Normal ({item.normalDevices.length})
                            </div>}
                            {item.disabledDevices.length > 0 && <div className="card-row-copy flex items-center gap-2" data-test="monitoring-details-devices-disabled-devices">
                                {Device.getIcon('disabled')}
                                Disabled ({item.disabledDevices.length})
                            </div>}
                            {item.erroredDevices.length > 0 && <div className="card-row-copy flex items-center gap-2" data-test="monitoring-details-devices-errored-devices">
                                {Device.getIcon('error')}
                                Error ({item.erroredDevices.length})
                            </div>}
                        </div>}
                    </Card>
                })}
            </div>
        </Card>
    </div>
}

export default DevicesList
