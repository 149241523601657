import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
    Card,
    Button,
    ClickToCopy,
    CustomManufacturersForm,
    IconCopy02,
    IconEye,
    IconEyeOff
} from '@/components'
import { IntegrationCredentialStatusEnum } from '@/enums'
import { useToastNotifications, useScrollToAnchor } from '@/hooks'
import { Company, Integration, InverterManufacturer } from '@/models'
import { uuid } from '@/services'

type EditCustomManufacturerFormProps = {
    company: Company
    className: string
    onChange: () => void
}

const EditCustomManufacturerForm = ({ company, className = '', ...props }: EditCustomManufacturerFormProps) => {
    const location = useLocation()
    const [showPassword, setShowPassword] = useState<Array<number | string>>([])
    const [mode, setMode] = useState<'view' | 'edit' | 'create'>(location.hash.substring(1) === 'unsupported' ? 'create' : 'view')
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const { success, error } = useToastNotifications()
    useScrollToAnchor()
    const getCreateForm = () => ({
        integrations: [{
            id: uuid(),
            company_id: company.id,
            status: IntegrationCredentialStatusEnum.UNSUPPORTED,
            provider: '',
            user: '',
            secret: ''
        }]
    })

    const getEditForm = () => ({
        integrations: company.unsupportedIntegrations.map(item => ({
            id: item.id,
            company_id: company.id,
            status: IntegrationCredentialStatusEnum.UNSUPPORTED,
            provider: item.provider || '',
            user: item.user || '',
            secret: item.secret || ''
        }))
    })

    const [form, setForm] = useState<any>(getEditForm())

    const handleChange = ({ target: { value, name } }: any) => {
        setForm((state: any) => ({ ...state, [name]: value }))
    }

    const handleDelete = async (integration: any) => {
        setProcessing(true)
        try {
            if (mode === 'edit') {
                await company.unsupportedIntegrations.find(item => item.id === integration.id)?.destroy()
                company.integrations = company.integrations.filter(item => item.id !== integration.id)
                await props.onChange()
                setForm(getEditForm())
            } else {
                setForm(getCreateForm())
            }
        } finally {
            setProcessing(false)
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})

        try {
            for (const index in form.integrations) {
                try {
                    const { id, ...integration } = form.integrations[index]
                    if (mode === 'create') {
                        await Integration.store(integration)
                    } else {
                        await company.unsupportedIntegrations.find(item => item.id === id)
                            ?.update(integration)
                    }
                } catch (err: any) {
                    if (err.errors) {
                        setErrors((errors: any) =>
                            ({ integrations: { ...errors.integrations || {}, [index]: err.errors } }))
                    }
                }
            }
            if (Object.keys(errors).length) {
                error('Something went wrong!')
                throw new Error()
            }
            await props.onChange()
            setMode('view')
            success(`Integration Credential${form.integrations.length > 1 ? 's' : ''} updated successfully!`)
        } finally {
            setProcessing(false)
        }
    }

    const handleTogglePassword = (id: number | string) => {
        if (showPassword.includes(id)) {
            setShowPassword(state => state.filter(item => item !== id))
        } else {
            setShowPassword(state => [...state, id])
        }
    }

    const getIsDisabled = () => {
        if (mode === 'edit') {
            return company.unsupportedIntegrations.every((item: Integration, index: number) =>
                item.provider === form.integrations[index].provider &&
                item.user === form.integrations[index].user &&
                item.secret === form.integrations[index].secret)
        }

        return !form.integrations[0].provider ||
            !form.integrations[0].user ||
            !form.integrations[0].secret
    }

    useEffect(() => {
        if (mode === 'create') {
            setForm(getCreateForm())
        } else {
            setForm(getEditForm())
        }
    }, [mode])

    return <>
        <h3 className="text-lg flex gap-1 mt-12" id="unsupported">
            Unsupported Manufacturer Integrations:
        </h3>

        <Card className={`p-4 rounded-lg !bg-gray-100 flex flex-col gap-6 ${className}`}>
            <span className="text-left">
                We suggest adding credentials for all manufacturers you use, even those not yet supported. This step can
                expedite Solar Insure troubleshooting assistance and may be required for claim validation. Only
                inverters from supported manufacturers can integrate with and appear on our Fleet Monitoring Tool in the
                Daybreak Monitoring tab, but all warranties featuring inverters from our Approved Vendor List(AVL) are
                eligible for claims processing. For a comprehensive list of supported vendors, please refer to
                the <a className="text-primary-800 underline" target="_blank" rel="noreferrer"
                    href="https://www.solarinsure.com/solarinsure-approved-vendor-list-avl">AVL List</a>.
            </span>

            {(company.unsupportedIntegrations.length === 0) &&
                <div className="lg:col-span-2 flex flex-col md:flex-row items-start md:items-center">
                    <Button className="mt-4 md:mt-0 flex items-center gap-2" design="btn-secondary"
                        type="button"
                        onClick={() => setMode('create')}
                        name="add_unsupported_manufacturer"
                    >
                        Add Another Manufacturer
                    </Button>
                </div>}

            {form.integrations.length > 0 && <>
                <hr className="lg:col-span-2"/>
                <div className="text-gray-700 text-sm">
                    <h4>Instructions</h4>
                    <ul className="list-disc pl-4 mt-4">
                        <li>
                            Fill out the form below for <span className="font-semibold">Each Manufacturer</span> you
                            work with that is not currently integrated with Solar Insure’s platform.
                            For Example, <span className="italic">if you work with both Hoymiles and AP Systems inverters, you’ll need to click the “Add Manufacturer” button.</span>
                        </li>
                        <li className="mt-4">
                            Include the monitoring login access credentials for the manufacturer. This allows our
                            monitoring
                            experts to troubleshoot fleet health issues.
                        </li>
                    </ul>
                </div>


                {mode !== 'edit' && !!company.unsupportedIntegrations.length &&
                    <div className="flex flex-col gap-6 mb-6">
                        {company.unsupportedIntegrations.map(item =>
                            <div key={item.id} className="bg-white rounded-lg px-6 py-2 flex flex-wrap gap-8 text-sm leading-5">
                                <div className="flex flex-col gap-0.5">
                                    <div className="font-semibold text-gray-800">Inverter Company</div>
                                    <div className="flex gap-1 items-center break-anywhere">
                                        {InverterManufacturer.find(item.provider).title}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0.5">
                                    <div className="font-semibold text-gray-800">Monitoring Portal Login Username</div>
                                    <div className="flex gap-1 items-center break-anywhere">
                                        {item.user}
                                        <ClickToCopy content={item.user}>
                                            <IconCopy02 className="stroke-gray-500" size="sm"/>
                                        </ClickToCopy>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-0.5">
                                    <div className="font-semibold text-gray-800">Monitoring Portal Login Password</div>
                                    <div className="flex gap-1 items-center break-anywhere">
                                        {item.secret?.split('').map((char: string) => showPassword.includes(item.id) ? char : '•')}
                                        <button type="button" onClick={() => handleTogglePassword(item.id)}>
                                            {showPassword.includes(item.id)
                                                ? <IconEyeOff className="stroke-gray-500" size="sm"/>
                                                : <IconEye className="stroke-gray-500" size="sm"/>}
                                        </button>
                                        <ClickToCopy content={item.secret}>
                                            <IconCopy02 className="stroke-gray-500" size="sm"/>
                                        </ClickToCopy>
                                    </div>
                                </div>
                            </div>)}
                    </div>}

                <form onSubmit={handleSubmit}>
                    {(mode === 'edit' || mode === 'create') &&
                        <CustomManufacturersForm
                            form={form}
                            onChange={handleChange}
                            onDelete={handleDelete}
                            errors={errors}
                            className="my-6"
                            inputClassName="!w-full md:w-64"
                        />}

                    <div className="flex flex-wrap gap-3 items-center justify-between">
                        <div className="flex gap-3 items-center">
                            {mode === 'view' &&
                                <Button
                                    design="btn-link"
                                    className="!text-primary-800"
                                    processing={processing}
                                    type="button"
                                    onClick={() => setMode('edit')}
                                >
                                    Edit Credentials
                                </Button>}
                            {['edit', 'create'].includes(mode) && <>
                                <Button
                                    onClick={() => setMode('view')}
                                    processing={processing}
                                    design="btn-secondary"
                                    hasError
                                    type="button"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    processing={processing}
                                    disabled={getIsDisabled()}
                                >
                                    Save Credentials
                                </Button>
                            </>}
                        </div>

                        <Button
                            design={mode === 'view' ? 'btn-primary' : 'btn-link'}
                            processing={processing}
                            type="button"
                            onClick={() => setMode('create')}
                            disabled={mode !== 'view'}
                        >
                            Add{!!form.integrations.length && ' Another'} Manufacturer
                        </Button>
                    </div>
                </form>
            </>}
        </Card>
    </>
}

export default EditCustomManufacturerForm
