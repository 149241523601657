import classnames from 'classnames'
import { ReactNode, useMemo } from 'react'

import { Checkbox, IconCheck } from '@/components'

type ValueType = string | number

export type SelectOptionProps = {
    value: string | number
    parent?: string | number
    allOptions?: SelectOptionProps[]
    title: ReactNode | ((checkbox: ReactNode) => ReactNode)
    className?: string
    group?: boolean
    readonly?: boolean
    multiple?: boolean
    name?: string
    active?: boolean,
    'data-test'?: string
    selectedOption?: SelectOptionProps
    selectedValue?: string | readonly string[] | number

    onChange?: (value: string | number) => void
}

const SelectOption = ({
    title,
    value,
    readonly,
    parent,
    allOptions = [],
    group,
    selectedOption,
    className,
    active,
    multiple,
    'data-test': dataTest,
    ...props
}: SelectOptionProps) => {
    const handleClick = () => {
        if (props.onChange && !readonly) {
            props.onChange(value)
        }
    }

    const checked = useMemo(() => {
        if (multiple && value && value !== 'toggle') {
            const selectedValue = Array.isArray(props.selectedValue) ? props.selectedValue : []
            const getAncestors = (value: ValueType): SelectOptionProps[] => {
                const option = allOptions.find(item => item.value === value)
                if (option?.parent) {
                    const parent = allOptions.find(item => item.value === option.parent)
                    return parent ? [parent, ...getAncestors(option.parent)] : []
                }
                return []
            }
            return selectedValue.includes(value) || getAncestors(value).some(item => selectedValue.includes(item.value))
        }
        return false
    }, [allOptions, props.selectedValue])

    const indeterminate = useMemo(() => {
        if (multiple && value && value !== 'toggle') {
            const selectedValue = Array.isArray(props.selectedValue) ? props.selectedValue : []
            const getDescendants = (value: ValueType): SelectOptionProps[] =>
                allOptions.reduce((acc: SelectOptionProps[], option) =>
                    option.parent === value ? [...acc, option, ...getDescendants(option.value)] : acc, [])
            return getDescendants(value)
                .some(item => selectedValue.includes(item.value))
        }
        return false
    }, [allOptions, props.selectedValue])

    const getTitle = () => {
        if (multiple && value && value !== 'toggle') {
            const checkbox = <Checkbox
                name={`${props.name}[]`}
                value={value}
                checked={checked}
                indeterminate={indeterminate}
                onChange={() => {}}
                onClick={() => {}}
            />
            if (typeof title === 'function') {
                return title(checkbox)
            }
            return <div className="flex w-full gap-2 items-center justify-between">
                {checkbox}
                <div className="grow">
                    {title}
                </div>
            </div>
        } else if (title) {
            return title
        }

        return <span></span>
    }

    return <>
        {group && <li className="w-full px-4 py-2"><hr/></li>}

        <li data-test={`${dataTest}-option-${value}`} className={className}>
            <button type="button" onClick={handleClick} className={classnames({ active, readonly })}>
                <>
                    {getTitle()}
                    {selectedOption?.value === value && !multiple &&
                        <IconCheck className="stroke-primary-700 ml-2"/>}
                </>
            </button>
        </li>
    </>
}

export default SelectOption
