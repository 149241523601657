import { useEffect, useMemo, useState } from 'react'

import { SearchableSelect, PageHeader } from '@/components'
import { AuthLayout, GlobalIndex, GlobalIndexDataType } from '@/containers'
import { useAuth, useNavigateWithQuery, useQuery } from '@/hooks'
import { Company } from '@/models'

interface ContractorIndexData extends GlobalIndexDataType {
    rows: Company[]
}

const ContractorsIndex = () => {
    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const auth = useAuth()
    const getDefaultFilters = () => ({ contractor_id: query.contractor_id || '' })

    const [contractors, setContractors] = useState<Company[]>([])
    const [filters, setFilters] = useState(getDefaultFilters())
    const [data, setData] = useState<ContractorIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: []
    })

    const handleChange = ({ name, value }: any) => {
        setData(data => ({ ...data, [name]: value }))
    }

    const fetchAllContractors = async () => {
        const data = await Company.onlyContractors()
        setContractors(data)
    }

    useEffect(() => {
        fetchAllContractors()
    }, [])

    const handleFiltersChange = (e: any) => {
        navigateWithQuery({ page: null, [e.target.name]: e.target.value })
        setFilters(filters => ({ ...filters, [e.target.name]: e.target.value }))
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        name: item.name,
        warranties_this_month: item.warranties_this_month.format(),
        warranties_lifetime: item.warranties_lifetime.format(),
        warranties_max_created_at: item.last_warranty_date ? new Date(item.last_warranty_date).format() : '-',
        warranties_sum_eligible_commission: item.lifetime_eligible_commission.money(),
        _path: `/contractors/${item.id}`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Contractors"/>}>
        <GlobalIndex
            id="contractors"
            dataType="Contractors"
            api={Company.contractors}
            data={data}
            onChange={handleChange}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            onFiltersChange={setFilters}
            columns={[
                { title: 'Contractor Name', field: 'name' },
                { title: 'Warranties This Month', field: 'warranties_this_month' },
                { title: 'Lifetime Warranties', field: 'warranties_lifetime' },
                { title: 'Last Warranty Date', field: 'warranties_max_created_at' },
                { title: 'Lifetime Eligible Commission', field: 'warranties_sum_eligible_commission', showIf: auth.user?.isSoftwareAffiliate }
            ]}
            rows={rowsMemo}
            leftSide={<>
                <SearchableSelect
                    id="contractor-id"
                    name="contractor_id"
                    placeholder="Contractor"
                    onChange={handleFiltersChange}
                    value={filters.contractor_id}
                    options={contractors.map((item: any) => ({ value: item.id, title: item.name }))}
                />
            </>}
        />
    </AuthLayout>
}

export default ContractorsIndex
