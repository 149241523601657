import { ChangeEvent, useState } from 'react'

import { Input, InputProps } from '@/components/ui/Input'
import { useDebounce } from '@/hooks'
import { api } from '@/services'
import { AddressType } from '@/types'

interface AddressInputProps extends InputProps {
    'data-index': string
}

export const AddressInput = ({ ...props }: AddressInputProps) => {
    const [addresses, setAddresses] = useState<AddressType[]>([])

    const debouncedSearch = useDebounce(async (value: string) => {
        if (value) {
            const res = await api.address.autocomplete(value)
            setAddresses(res)
        } else {
            setAddresses([])
        }
    }, 400)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e)
        debouncedSearch(e.target.value)
    }
    const handleSuggestionSelect = (index: number) => {
        if (props.onChange) {
            const address = addresses[index]
            if (props.name === 'address') {
                props.onChange({
                    target: {
                        name: 'address',
                        value: `${address.street_address} ${address.city}, ${address.state} ${address.zip} `,
                        dataset: { index: props['data-index'] }
                    }
                } as unknown as ChangeEvent<HTMLInputElement>)
            } else {
                const getEvent = (key: 'street_address' | 'city' | 'state' | 'zip') => ({
                    target: {
                        name: key,
                        value: address[key],
                        dataset: { index: props['data-index'] }
                    }
                } as unknown as ChangeEvent<HTMLInputElement>)
                props.onChange(getEvent('street_address'))
                props.onChange(getEvent('city'))
                props.onChange(getEvent('state'))
                props.onChange(getEvent('zip'))
            }
        }
    }
    return <Input
        {...props}
        suggestions={addresses.map(
            (item: any) => `${item.street_address} ${item.secondary_line || ''}${item.city}, ${item.state} ${item.zip}`)}
        onSuggestionSelect={handleSuggestionSelect}
        onChange={handleChange}
        autoComplete="off"
    />
}
