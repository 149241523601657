import { useState } from 'react'

import { Button, DatePicker, IconPercent03, Input, Table } from '@/components'
import { useToastNotifications } from '@/hooks'
import { Company, WarrantyProductType } from '@/models'

type EligibleCommissionEditFormProps = {
    company: Company
    onSuccess: any
}

const EligibleCommissionEditForm = ({ company, ...props }: EligibleCommissionEditFormProps) => {
    const { success } = useToastNotifications()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})

    const [form, setForm] = useState<any>({
        solar_commission_rate: company.currentEligibleCommission?.solar_commission_rate || 0,
        battery_commission_rate: company.currentEligibleCommission?.battery_commission_rate || 0,
        effective_date: company.currentEligibleCommission?.effective_date
            ? new Date(company.currentEligibleCommission?.effective_date)
            : ''
    })

    const handleChange = ({ target: { name, value } }: any) => {
        setForm((form: any) => ({ ...form, [name]: value }))
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            const payload = { ...form }
            payload.effective_date = form.effective_date ? form.effective_date.toISODate() : ''
            await company.updateEligibleCommissions(payload)
            props.onSuccess()
            success('Commission Rate Saved - The new commission rate will be applied on the defined effective date.')
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    const getIsValid = () => form.solar_commission_rate <= 1 &&
        form.solar_commission_rate >= 0 &&
        form.battery_commission_rate <= 1 &&
        form.battery_commission_rate >= 0 &&
        +form.effective_date >= +new Date().startOf('day')

    return <form className="p-4" onSubmit={handleSubmit}>
        <h3 className="flex mb-2 gap-2 text-xl font-semibold text-gray-900">
            <IconPercent03 size="lg" className="mr-1 stroke-primary-700"/>
            Eligible Commission Rate Update
        </h3>
        <p className="text-gray-500 mb-6">
            Welcome to the Commission Rate Update interface. Here, you can modify the
            commission rate that affiliate companies receive for their subcontractor warranties.
        </p>

        <div className="grid grid-cols-3 gap-6 mb-6">
            <Input
                id="solar-commission-rate"
                name="solar_commission_rate"
                label={<span className="flex gap-1 items-center">
                    {WarrantyProductType.solar.icon()}
                    Solar Rate
                </span>}
                onChange={handleChange}
                value={form.solar_commission_rate}
                errors={errors.solar_commission_rate}
                hint="Enter rate as 0-1 decimal"
                mask={{
                    numeral: true,
                    numeralIntegerScale: 1,
                    numeralDecimalScale: 4,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />
            <Input
                id="battery-commission-rate"
                name="battery_commission_rate"
                label={<span className="flex gap-1 items-center">
                    {WarrantyProductType.battery20.icon()}
                    Battery Rate
                </span>}
                onChange={handleChange}
                value={form.battery_commission_rate}
                errors={errors.battery_commission_rate}
                hint="Enter rate as 0-1 decimal"
                mask={{
                    numeral: true,
                    numeralIntegerScale: 1,
                    numeralDecimalScale: 4,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
            />
            <DatePicker
                id="start-date"
                name="effective_date"
                label="Effective Start Date"
                onChange={handleChange}
                value={form.effective_date}
                errors={errors.effective_date}
                hint="Choose a future or current date."
                options={{
                    minDate: new Date().subtract('day', 1),
                    singleMode: true
                }}
            />
        </div>

        {!!company.eligible_commission_rates?.length && <>
            <h4 className="font-semibold mb-6">Historical Rates</h4>
            <Table
                className="mb-8"
                pagination={false}
                sortable={false}
                searchable={false}
                useQueryParams={false}
                columns={[
                    {
                        field: 'solar_rate',
                        title: 'Solar Rate',
                        className: '!border-b-2 !border-primary-500',
                        cellClassName: 'text-gray-500'
                    },
                    {
                        field: 'battery_rate',
                        title: 'Battery Rate',
                        className: '!border-b-2 !border-primary-500',
                        cellClassName: 'text-gray-500'
                    },
                    {
                        field: 'effective_date',
                        title: 'Effective Start Date',
                        className: '!border-b-2 !border-primary-500',
                        cellClassName: 'text-gray-500'
                    },
                    {
                        field: 'end_date',
                        title: 'Effective End Date',
                        className: '!border-b-2 !border-primary-500',
                        cellClassName: 'text-gray-500'
                    }
                ]}
                rows={company.eligible_commission_rates.map((item: any, index: number) => ({
                    id: index,
                    solar_rate: <div className="py-2.5 font-semibold">
                        {item.solar_commission_rate.format(undefined, { minimumFractionDigits: 4 })}
                    </div>,
                    battery_rate: <div className="py-2.5 font-semibold">
                        {item.battery_commission_rate.format(undefined, { minimumFractionDigits: 4 })}
                    </div>,
                    effective_date: new Date(item.effective_date).format(),
                    end_date: company.eligible_commission_rates[index - 1]?.effective_date &&
                        new Date(company.eligible_commission_rates[index - 1]?.effective_date).subtract('day', 1).format()
                }))}
            />
        </>}

        <div className="flex justify-end">
            <Button design="btn-primary" processing={processing} disabled={!getIsValid()}>
                Save Changes
            </Button>
        </div>
    </form>
}

export default EligibleCommissionEditForm
