import User from '@/models/User'
import { api, cookies } from '@/services'

export default class AuthUser extends User {
    static async signIn(payload: { email: string, password: string }) {
        const { data } = await api.auth.signIn(payload)
        cookies.set('api_token', data.api_token)
        const { data: user } = await api.auth.user()
        return { user: new AuthUser(user), apiToken: data.api_token }
    }

    static async forgotPassword(payload: { email: string }) {
        const { data } = await api.auth.forgotPassword(payload)
        return data
    }

    static async resetPassword(payload: { email: string }) {
        const { data } = await api.auth.resetPassword(payload)
        return data
    }

    get isAuthenticated() {
        return !!cookies.get('api_token')
    }

    static async currentUser() {
        const { data: user } = await api.auth.user()
        return new AuthUser(user)
    }

    toJSON() {
        return JSON.stringify(this.raw)
    }

    get isImpersonated() {
        return this.email === this.impersonateEmail
    }
}
