import { api, Options, Params } from '@/services'

export default {
    report() {
        return api.http.get('api/v1/monitoring/report')
    },
    summary(params: Params, options: Options) {
        return api.http.get('api/v1/monitoring/summary', params, options)
    },
    export(params: Params) {
        return api.http.get('api/v1/monitoring/export', params)
    }
}
