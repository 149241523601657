import { useMemo, useState } from 'react'
import { flushSync } from 'react-dom'

import {
    Button,
    Form,
    PasswordForm
} from '@/components'
import { useAuth, useToastNotifications } from '@/hooks'
import { User } from '@/models'

type PasswordEditFormProps = {
    user: User,
    onSuccess?: (res: any) => void
    onExit: () => void
}

const PasswordEditForm = ({ user, ...props }: PasswordEditFormProps) => {
    const auth = useAuth()
    const { success, error } = useToastNotifications()
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState<any>({})
    const [form, setForm] = useState<any>({
        current_password: user.id === auth.user?.id ? '' : undefined,
        password: '',
        password_confirmation: ''
    })

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }

    const handleSubmit = async (e?: any) => {
        e?.preventDefault()
        setProcessing(true)
        setErrors({})
        try {
            const res = user.id === auth.user?.id
                ? await User.updatePasswordSelf(form)
                : await user.updatePassword(form)

            flushSync(() => {
                setForm({
                    current_password: user.id === auth.user?.id ? '' : undefined,
                    password: '',
                    password_confirmation: ''
                })
            })
            success('Password successfully updated.')
            if (props.onSuccess) await props.onSuccess(res)
            props.onExit()
        } catch (err: any) {
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }

    const isDirty = useMemo(() => form.current_password || form.password || form.password_confirmation, [form])

    return <Form isDirty={isDirty} confirmation onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
        <h3 className="font-semibold lg:col-span-2 font-body">Update Password</h3>
        <PasswordForm
            form={form}
            errors={errors}
            onChange={handleChange}
            inputClassName="col-start-1"
        />
        <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end">
            <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing} disabled={!isDirty}>
                Save and Exit
            </Button>
        </div>
    </Form>
}

export default PasswordEditForm
