import { ReactNode } from 'react'

type GuestLayoutProps = {
    children: ReactNode
}

const GuestLayout = ({ children }: GuestLayoutProps) => <div className="flex h-full">
    {children}
</div>

export default GuestLayout
