import { ReactNode } from 'react'

export const sizes = {
    xs: 12,
    sm: 16,
    md: 20,
    lg: 24,
    xl: 28,
    xxl: 32
}

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export type IconProps = {
    className?: string,
    size?: IconSize,
    viewBox?: string,
    children?: ReactNode
}

export const BadgeSizes = {
    xs: 'w-6 h-6',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
    xl: 'w-14 h-14',
    xxl: 'w-16 h-16'
}

const Icon = ({ size = 'md', className, viewBox = '0 0 24 24', children, ...props }: IconProps) => {
    const finalSize = sizes[size as keyof typeof sizes]
    return <svg
        width={finalSize}
        height={finalSize}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
    >
        {children}
    </svg>
}

export default Icon
