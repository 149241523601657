export type ErrorsType = string | string[]

export type FormErrorsType = {
    [key: string]: ErrorsType
}

type InputErrorProps = {
    errors: ErrorsType
}

export const InputError = ({ errors, ...props }: InputErrorProps) =>
    <>
        {typeof errors === 'string'
            ? <div className="text-error-500 text-sm mt-1.5" {...props}>{errors}</div>
            : errors.map((item: string, index: number) =>
                <div className="text-error-500 text-sm mt-1.5" {...props} key={index}>{item}</div>)}
    </>
