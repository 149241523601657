import { Button, IconPasscode, IconPasscodeLock, IconSearchFile01, IconSlashCircle01 } from '@/components'
import { IconSize } from '@/components/icons/Icon'
import { InverterManufacturerEnum, SystemStatusEnum } from '@/enums'

export default [
    {
        value: SystemStatusEnum.AUTH_ERROR,
        title: 'Authorization Error',
        refreshable: true,
        icon: (size: IconSize) => <IconSearchFile01 size={size}/>,
        description: 'We have located this system in the inverter manufacturer\'s database, but Solar Insure lacks the necessary permissions to access the monitoring data. This is often due to restrictive settings or insufficient access rights configured in the monitoring portal or system.',
        troubleshooting: {
            [InverterManufacturerEnum.SOLAREDGE]: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For SolarEdge Systems:</span>
                <div className="my-4 italic">
                    Authorization errors from SolarEdge are most often caused by the submission of a limited scope API
                    key that only grants Solar Insure access to one individual site, instead of an entire fleet. To
                    resolve this issue, please follow these steps:
                </div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>Log into <a href="https://monitoring.solaredge.com/solaredge-web/p/login" className="text-primary-700 underline">monitoring.solaredge.com.</a> </li>
                    <li>Click on &apos;Monitoring Admin&apos; and select &apos;My Account&apos;.</li>
                    <li>
                        Navigate to the &apos;Company Details&apos; tab, scroll down, and click the
                        checkbox indicating you have read, understood, and agreed to the
                        SolarEdge API T&C.
                    </li>
                    <li>Generate and Copy the API Key.</li>
                    <li>Click the Save button.</li>
                    <li>Login to Solar Insure.</li>
                    <li>Click on your avatar in the top right corner and select &apos;Profile&apos;.</li>
                    <li>Go to the &apos;Integrations&apos; tab.</li>
                    <li>Open the section for SolarEdge.</li>
                    <li>Replace the existing API key with the newly generated key from SolarEdge.</li>
                    <li>Save the changes.</li>
                    <li>Click on the refresh monitoring button to initiate a new attempt to pull monitoring data.</li>
                </ol>
            </div>,
            [InverterManufacturerEnum.ENPHASE]: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For Enphase Systems:</span>
                <div className="my-4">
                    Authorization errors from Enphase are most often caused by insufficient data access permissions.
                    To share data for a specific solar system with an external platform like the Solar Insure Daybreak
                    Platform, the company that owns the Enphase Enlighten Portal account must also be listed as the
                    PV System Maintainer (instead of listing a third-party company). Make sure the company in the PV
                    System Maintainer field is the same as the company owning the account (you can see the account
                    company on the home dashboard). To resolve this issue:
                </div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>Login into the <a href="https://enlighten.enphaseenergy.com/" target="_blank" rel="noreferrer" className="text-primary-700 underline">Enphase Enlighten Portal.</a></li>
                    <li>Locate and select the specific system that is experiencing the error to view its details.</li>
                    <li>Go to the &apos;Settings&apos; page for that system at the site level.</li>
                    <li>Scroll down to the &apos;Access&apos; section on this page.</li>
                    <li>Check if the &apos;PV System Maintainer&apos; field lists the name of your company.</li>
                    <li>
                        If the field is not populated or incorrect, update it accordingly to ensure proper access
                        permissions are set for Solar Insure to connect and retrieve data. Save any changes made.
                    </li>
                    <li>Click on the refresh monitoring button to initiate a new attempt to pull monitoring data.</li>
                </ol>
                <div className="italic mt-4">
                    If the issue persists for 10 days after making a correction to the &apos;PV System Maintainer&apos;
                    field, or if you were unable to find this specific issue, please contact Enphase customer support.
                    There may be other permission settings on the Enphase site that Solar Insure is currently
                    unaware of.
                </div>
            </div>,
            [InverterManufacturerEnum.GENERAC]: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For Generac Systems</span>
                <div className="my-4">
                    Your company successfully associated your PWRfleet account with Solar Insure
                    at the company level, but we were unable to view details for this specific site.
                    This most often occurs when a company forgets to share an individual site. You must share access
                    with Solar Insure at the fleet and site level for data to appear on the Solar Insure platform.
                </div>
                <span className="font-semibold">Step One: Verify Manufacturer System ID Registered on the Warranty</span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                    <li>
                        Click on the &quot;Edit Warranty&quot; option to access and modify
                        the warranty registration details.
                    </li>
                    <li>
                        Carefully verify that the Manufacturer System Identifier entered is correct.
                        Cross-reference this ID with the site listed in your <a href="https://pwrfleet.generac.com/login" target="_blank" rel="noreferrer" className="text-primary-700 underline">PWRfleet portal.</a>
                        <div>
                            a. Navigate to the &quot;All Sites&quot; list to find the related system.
                        </div>
                        <div>
                            b. Click on the system to open its details page.
                        </div>
                        <div>
                            c. The Site ID can be found in the URL of the site. It is the unique
                            identifier after /sites/ in the URL.
                        </div>
                    </li>
                    <li>
                        If the system ID does not match, update the ID on
                        Solar Insure Daybreak Platform and save the changes.
                    </li>
                </ol>
                <div className="font-semibold mt-4">Step Two: Associate an Individual Site with Solar Insure</div>
                <ol className="mt-4 list-decimal pl-5">
                    <li>Log into the <a href="https://pwrfleet.generac.com/login" target="_blank" rel="noreferrer" className="text-primary-700 underline">PWRfleet portal.</a></li>
                    <li>
                        Navigate to the &quot;All Sites&quot; list to find the related system. Click on the system
                        to open its details page.
                    </li>
                    <li>Click on the “Access” tab in the left sidebar.</li>
                    <li>Select “Share Site”</li>
                    <li>Select Solar Insure and click “Share”. </li>
                    <li>
                        Return to the Daybreak platform. Click on the refresh monitoring button to
                        initiate a new attempt to pull monitoring data.
                    </li>
                </ol>
                <Button design="btn-secondary" className="block text-center mt-4 w-fit" target="_blank" href="/resources/Generac_Fleet_&_Site_Level_Integrations.pdf">
                    Click here for a Visual Guide
                </Button>
            </div>,
            maintainer: {
                [InverterManufacturerEnum.ENPHASE]: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                    <span className="font-semibold">For Enphase Systems using the Authorized Subcontractor Method to Share Monitoring Data</span>
                    <div className="my-4">
                        Your company has chosen to share monitoring data for all registered
                        warranties using the Authorized Subcontractor method in Enlighten. For this
                        method, you need to individually set up data access for every individual
                        system that is registered on the Daybreak platform. An authorization
                        error most likely indicates that you have not yet set up access
                        permissions to share monitoring data with Solar Insure. This system
                        needs to be accessible in Solar Insure&apos;s Enlighten Maintainer
                        Account to show up in Daybreak. Follow these
                        steps to resolve the issue:
                    </div>
                    <span className="font-semibold">Step One: Verify Manufacturer System ID</span>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                        <li>
                            Click on the &quot;Edit Warranty&quot; option to access and modify
                            the warranty registration details.
                        </li>
                        <li>
                            Carefully verify that the Manufacturer System Identifier entered is
                            correct. Cross-reference this ID with the site listed
                            in the Enphase Enlighten Portal to ensure accuracy.
                        </li>
                        <li>
                            If the system ID does not match, update the ID and save the changes.
                        </li>
                    </ol>
                    <div className="font-semibold mt-4">Step Two: Set Up Authorized Subcontractor Access</div>
                    <div className="my-4">
                        If the System is New and Has Not Yet Been Activated in the Enlighten Portal:
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>
                            Log into the Enphase Enlighten Portal.
                        </li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view its details.
                        </li>
                        <li>
                            Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.
                        </li>
                        <li>
                            Review the Activation checklist. If Step 4 is still not complete, add Solar Insure
                            as an Authorized Subcontractor.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate
                            a new attempt to pull monitoring data.
                        </li>
                    </ol>
                    <div className="my-4">If the System Has Already Been Activated:</div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>
                            Log into the Enphase Enlighten Portal.
                        </li>
                        <li>
                            Locate and select the specific system that is experiencing the error to view its details.
                        </li>
                        <li>
                            Go to the &apos;Settings&apos; page for that system by clicking on the gear icon.
                        </li>
                        <li>
                            Scroll down to the System Access section.
                        </li>
                        <li>
                            In the &apos;Companies with Access&apos; section, add the email
                            monitoring@solarinsure.com in the
                            Email Address input field. Select that email address as an authorized subcontractor
                            and hit save.
                        </li>
                        <li>
                            Return to the Daybreak platform. Click on the refresh monitoring button to initiate a
                            new attempt to pull monitoring data.
                        </li>
                    </ol>
                </div>
            },
            default: <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Login</span> into the <span className="font-semibold">Enphase Enlighten Portal</span> at https://enlighten.enphaseenergy.com/.
                    </li>
                    <li>
                        <span className="font-semibold">Locate</span> and <span className="font-semibold">select</span> the specific system that is experiencing the error to view its details.
                    </li>
                    <li>
                        Go to the <span className="font-semibold">&apos;Settings&apos; page</span> for that system at the site level.
                    </li>
                    <li>
                        <span className="font-semibold">Scroll down</span> to the <span className="font-semibold">&apos;Access&apos;</span> section on this page.
                    </li>
                    <li>
                        <span className="font-semibold">Check</span> if the <span className="font-semibold">&apos;PV System Maintainer&apos;</span> field lists the name of the your company. If not, please update it accordingly.
                    </li>
                    <li>
                        If the field is not populated or incorrect, <span className="font-semibold">update</span> it accordingly to ensure proper access permissions are set for Solar Insure to connect and retrieve data. Save any changes made.
                    </li>
                    <li>
                        If you&apos;re not ready to provide your personal credentials, note that the
                        &apos;Solar Insure Maintainer Account&apos; will be used temporarily for system
                        monitoring,
                    </li>
                    <li>
                        If the issue persists for 10 days after making a correction to the <span className="font-semibold">&apos;PV System Maintainer&apos;</span> field, or if you were unable to find this specific issue, please <span className="font-semibold">contact customer support.</span> There may be other permission settings on the Enphase site we are currently unaware of.
                    </li>
                </ol>
            </div>
        }
    },
    {
        value: SystemStatusEnum.UNKNOWN_MANUFACTURER,
        title: 'Unsupported Manufacturer',
        icon: (size: IconSize) => <IconSlashCircle01 size={size}/>,
        description: 'Monitoring is currently unavailable for this inverter manufacturer on the Daybreak Platform. However, claims for inverters on our AVL will still be honored. To get assistance from Solar Insure for troubleshooting or expediting claims, you can provide your manufacturer monitoring portal credentials in the Integrations tab in your Profile to offer an alternative way to view site issues without the Solar Insure Fleet Monitoring dashboard.',
        action: <Button href="/profile/integrations#unsupported" className="!font-normal">
            Update Credentials
        </Button>,
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    If using inverters other than our currently supported inverter manufacturers,
                    monitoring integration is not yet available.
                </li>
                <li>
                    If the manufacturer of your solar inverter isn&apos;t currently supported in our
                    integrations, we recommend securely providing your monitoring login details to
                    our customer support team for enhanced troubleshooting
                    assistance. <span className="font-semibold">Click</span> your avatar in the top
                    right-hand corner of the Solar Insure
                    platform, <span className="font-semibold">select &quot;Profile&quot;</span> from the
                    dropdown menu, and then <span className="font-semibold">go</span> to
                    the <span className="font-semibold">&quot;Integrations&quot;</span> tab.
                </li>
                <li>
                    <span className="font-semibold">Stay updated</span> on when your manufacturer will be supported by checking announcements or <span className="font-semibold">contacting customer service.</span>
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.INTEGRATION_UPDATE_REQUIRED,
        title: 'Integration Update Required',
        refreshable: true,
        icon: (size: IconSize) => <IconPasscodeLock size={size}/>,
        description: 'Your monitoring credentials for one or more inverter manufacturers are invalid. To enable Solar Insure Monitoring updates, please update your credentials and check back for a revised connection status',
        action: <Button href="/profile/integrations" className="!font-normal">
            Update Credentials
        </Button>,
        troubleshooting: <div className="font-normal text-sm p-3 bg-blue-50 rounded">
            <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
            <ol className="mt-4 list-decimal pl-5">
                <li>
                    <span className="font-semibold">Click</span> on your avatar in the top right-hand corner of the Solar Insure platform.
                </li>
                <li>
                    From the dropdown menu that appears, <span className="font-semibold">select</span> the <span className="font-semibold">&quot;Profile&quot;</span> option.
                </li>
                <li>
                    Once in your profile, <span className="font-semibold">navigate</span> to the <span className="font-semibold">&quot;Integrations&quot;</span> tab.
                </li>
                <li>
                    Carefully <span className="font-semibold">check</span> that all required credentials fields are filled out correctly. <span className="font-semibold">Ensure</span> there are no error messages indicating invalid field values.
                </li>
                <li>
                    If you made any changes or updates to the credentials, be sure to <span className="font-semibold">save</span> these changes before exiting.
                </li>
                <li>
                    After updating your credentials, Solar Insure will attempt to connect sites to
                    the monitoring for the related manufacturer. Please allow up to 10 days for this
                    process to complete, assuming there are no additional issues.
                </li>
            </ol>
        </div>
    },
    {
        value: SystemStatusEnum.MISSING_INTEGRATION,
        title: 'Missing Integration',
        refreshable: true,
        icon: (size: IconSize) => <IconPasscode size={size}/>,
        description: 'You are missing the required fleet monitoring credentials for one or more inverter manufacturers.',
        action: <Button href="/profile/integrations" className="!font-normal">
            Update Credentials
        </Button>,
        troubleshooting: {
            [InverterManufacturerEnum.GENERAC]: <div>
                <div className="font-normal text-sm p-3 bg-blue-25 rounded text-gray-700">
                    <div className="my-4">
                        The &quot;Missing Credentials&quot; error for Generac systems can show when a company has
                        correctly associated their Generac account at the company level, but they have not
                        yet associated any of their sites at the site level OR when a company has failed
                        to associate at both the company and site level. We cannot verify a company-level
                        connection until at least one site is associated.
                    </div>

                    <span className="font-semibold">Step One: Verify that a valid Manufacturer System ID is populated on the Warranty Details</span>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Navigate to the &quot;Homeowner Details - Warranty Tab&quot;.</li>
                        <li>
                            Click on the &quot;Edit Warranty&quot; option to access and modify the
                            warranty registration details.
                        </li>
                        <li>
                            Ensure that the Manufacturer System Identifier is populated and correct.
                            This should be populated with the site ID for the related installation found
                            in your PWRfleet portal.
                            <div>
                                a. In PWRfleet, Navigate to the &quot;All Sites&quot; list to find the related system.
                            </div>
                            <div>b. Click on the system to open its details page.</div>
                            <div>c. The Site ID can be found in the URL of the site. It is the unique
                            identifier after /sites/ in the URL.</div>
                        </li>
                        <li>Update the ID on the Solar Insure Daybreak Platform and save the changes.</li>
                    </ol>
                    <div className="font-semibold mt-4">
                        Step Two: Verify that you have set up Monitoring Data Sharing Access
                        at the Company Level on PWRfleet
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the Generac portal at <a href="https://mypwrview.generac.com/login" className="text-primary-700 underline">https://mypwrview.generac.com/</a> and select the three-dot menu.</li>
                        <li>
                            Navigate to &quot;Account Settings&quot; and in the dropdown, go to the &quot;Company
                            Associations&quot; section. Here you will see a list of your installation partners.
                        </li>
                        <li>Select &quot;Associations Requests&quot;.</li>
                        <li>If you need to request an association with a financing or O&M partner, click on
                            the &quot;Request Association&quot; button in the &quot;Your financing and O&M
                            partners&quot; section to open up further options.</li>
                        <li>
                            A pop-up will appear prompting you to associate companies with your PWRfleet.
                            Enter monitoring@solarinsure.com address into the field provided.
                        </li>
                    </ol>
                    <div className="font-semibold mt-4">
                        Step Three: Verify that this Individual Site is Shared with Solar Insure
                    </div>
                    <ol className="mt-4 list-decimal pl-5">
                        <li>Log into the PWRfleet portal.</li>
                        <li>Navigate to the &quot;All Sites&quot; list to find the related system.
                        Click on the system to open its details page.</li>
                        <li>Click on the &quot;Access&quot; tab in the left sidebar.</li>
                        <li>Select &quot;Share Site&quot;.</li>
                        <li>Select Solar Insure and click &quot;Share&quot;.</li>
                        <li>Return to the Daybreak platform. Click on the refresh monitoring button
                        to initiate a new attempt to pull monitoring data.</li>
                    </ol>
                </div>
            </div>,
            default: <div className="font-normal text-sm p-3 bg-blue-50 rounded">
                <span className="font-semibold">For efficient resolution of any system issues, please follow these steps:</span>
                <ol className="mt-4 list-decimal pl-5">
                    <li>
                        <span className="font-semibold">Click</span> on your avatar in the top right-hand corner of the Solar Insure platform.
                    </li>
                    <li>
                        From the dropdown menu that appears, <span className="font-semibold">select</span> the <span className="font-semibold">&quot;Profile&quot;</span> option.
                    </li>
                    <li>
                        Once in your profile, <span className="font-semibold">navigate</span> to the <span className="font-semibold">&quot;Integrations&quot;</span> tab.
                    </li>
                    <li>
                        Carefully <span className="font-semibold">check</span> that all required credentials fields are filled out correctly. <span className="font-semibold">Ensure</span> there are no error messages indicating invalid field values.
                    </li>
                    <li>
                        If you made any changes or updates to the credentials, be sure to <span className="font-semibold">save</span> these changes before exiting.
                    </li>
                    <li>
                        After updating your credentials, Solar Insure will attempt to connect sites to
                        the monitoring for the related manufacturer. Please allow up to 10 days for this
                        process to complete, assuming there are no additional issues.
                    </li>
                </ol>
            </div>
        }
    }
]

