import { useState } from 'react'

import {
    AddressForm,
    Button,
    HomeownerForm,
    Popup,
    IconCertificate,
    Tabs, WarrantyDetails, FormErrorsType, HomeownerFormDataType, AddressFormDataType, FormChangeEventHandler
} from '@/components'
import { Warranty } from '@/models'

export interface CreateWarrantyHomeownerFormDataType extends HomeownerFormDataType, AddressFormDataType {}

type WarrantyCreateHomeownerStepProps = {
    form: CreateWarrantyHomeownerFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    processing: boolean
    duplicates?: Warranty[]
}
export const WarrantyCreateHomeownerStep = ({
    form,
    errors,
    processing,
    duplicates,
    ...props
}: WarrantyCreateHomeownerStepProps) => {
    const [showPreview, setShowPreview] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number>(-1)

    const showSimilarWarranty = () => {
        setShowPreview(true)
        setActiveIndex(0)
    }

    return <>
        <div className="grid lg:grid-cols-2 gap-3 lg:gap-6 mt-6 lg:mt-12.5" data-test="warranty-create-step-1">
            <h3 className="font-semibold lg:col-span-2">Customer Information</h3>
            <HomeownerForm form={form} errors={errors} onChange={props.onChange}/>

            <hr className="lg:col-span-2"/>
            <h3 className="font-semibold lg:col-span-2">Address</h3>
            <AddressForm form={form} errors={errors} onChange={props.onChange}/>

            {!!(errors?.street_address && duplicates?.length && !form.duplicate_override) &&
                <div className="lg:col-span-2">
                    <p className="text-error-700 font-semibold">Potential Duplicate Warning.</p>
                    <p>
                        <span className="inline-block mr-1">
                            <Button type="button" design="btn-link" onClick={showSimilarWarranty}>
                                <span className="text-primary-700">
                                    Plan #{duplicates[0]?.policy_num} {duplicates[0]?.homeowner?.full_name}
                                </span>
                            </Button>
                        </span>
                        <span className="text-error-700">
                            already exists at this address. If this is a unique registration, select &apos;Allow
                            Duplicate Address&apos; checkbox.
                        </span>
                    </p>
                </div>}

            <div className="flex justify-end lg:col-span-2">
                <Button processing={processing} data-test="warranty-create-step-1-continue-button">
                    Continue
                </Button>
            </div>
        </div>
        <Popup open={showPreview} onClose={() => setShowPreview(false)}>
            <div className="flex items-center gap-2 mb-4">
                <IconCertificate className="stroke-gray-700"/>
                <span className="font-semibold text-xl">Existing Warranty Preview</span>
            </div>
            <div>
                <Tabs
                    className="mb-8"
                    size="sm"
                    value={activeIndex}
                    onChange={id => setActiveIndex(id as number)}
                    tabs={duplicates?.map((item, index) => ({
                        id: index,
                        title:`Plan ID ${item.policy_num}`
                    })) || []}
                />
                {(duplicates?.length && duplicates[activeIndex]) && <div className="grid lg:grid-cols-2 gap-1 mb-6">
                    <WarrantyDetails warranty={duplicates[activeIndex]}/>
                </div>}
            </div>
        </Popup>
    </>
}
