enum CompanyMonitoringStatusEnum {
    VALID = 'valid',
    MISSING = 'missing',
    INVALID = 'invalid',
    NOT_REQUIRED = 'not_required',
    INCOMPLETE = 'incomplete',
    UNKNOWN = 'unknown'
}

export default CompanyMonitoringStatusEnum
