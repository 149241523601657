import classnames from 'classnames'
import { TextareaHTMLAttributes } from 'react'

import { FormControl, FormControlProps } from '@/components'

export interface TextAreaProps extends FormControlProps, TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const TextArea = ({
    id,
    label,
    errors = [],
    warnings = [],
    hint,
    preIcon,
    postIcon,
    transparent,
    tooltip,
    className,
    'input-size': size,
    'data-test': dataTest,
    ...props
}: TextAreaProps) => {
    dataTest = dataTest || id

    return <FormControl
        id={id}
        label={label}
        input-size={size}
        data-test={dataTest}
        preIcon={preIcon}
        postIcon={postIcon}
        errors={errors}
        warnings={warnings}
        hint={hint}
        transparent={transparent}
        tooltip={tooltip}
        className={classnames('form-control-textarea', className)}
        data-value={props.value}
    >
        <textarea
            id={id}
            data-test={`${dataTest}-input`}
            {...props}
        />
    </FormControl>
}
