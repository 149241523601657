import classnames from 'classnames'
import { InputHTMLAttributes, ReactNode, useEffect, useRef } from 'react'

import { IconCheck, IconMinus } from '@/components'

export interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: ReactNode
    indeterminate?: boolean
    'data-test'?: string
}

export const Checkbox = ({
    label,
    indeterminate = false,
    'data-test': dataTest,
    ...props
}: CheckBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.indeterminate = indeterminate && !props.checked
        }
    }, [indeterminate])

    const className = classnames('checkbox', {
        indeterminate,
        disabled: props.disabled,
        checked: props.checked
    })

    const computedDataTest = dataTest || props.id

    return <label data-test={dataTest} className={className}>
        {label && <span data-test={`${dataTest}-label`} className="ml-2">{label}</span>}
        <input
            ref={inputRef}
            data-test={`${computedDataTest}-checkbox`}
            {...props}
            type="checkbox"
            onClick={e => {
                if (props.onClick) {
                    e.stopPropagation()
                    props.onClick(e)
                }
            }}
        />
        <IconCheck size="sm" className="checkbox-icon"/>
        <IconMinus size="sm" className="minus-icon"/>
    </label>
}
