import { IconCheck } from '@/components'
import { Integration } from '@/models'

const IntegrationSetupConfirmedBanner = ({ integration }: { integration: Integration }) => {
    if (!integration.isComplianceVerified) return null
    return <div className="w-full flex justify-between items-start gap-3 my-4 px-6 py-3 text-success-500 bg-success-50 text-xs rounded-2xl">
        <p>
            <strong>Integration Setup Confirmed:</strong> Solar_Insure_Maintainer_Account <br/>
            At least one solar system with an active warranty has been located within our Solar Insure
            owned Maintainer account <br/>
            for this manufacturer. Please ensure each new Solar Insure warranted system is similarly
            associated. Thank you for your cooperation!
        </p>
        <div className="rounded-full flex justify-center items-center bg-success-100 w-6 h-6">
            <IconCheck className="stroke-success-600" size="sm"/>
        </div>
    </div>
}

export default IntegrationSetupConfirmedBanner
