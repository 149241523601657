import { IconLoading02, Popup } from '@/components'

type PostSubmitPopupProps = {
    isOpen: boolean
    onClose: any
}

const PostSubmitPopup = ({ isOpen, ...props }: PostSubmitPopupProps) =>
    <Popup open={isOpen} onClose={props.onClose} className="w-full md:w-auto">
        <h2 className="flex flex-wrap gap-3 items-center text-lg lg:text-xl pt-8">
            <IconLoading02 size="lg" className="stroke-primary-600 shrink-0"/>
            <span className="font-semibold">Credentials Submitted:</span>
            <span>Monitoring Connection Pending</span>
        </h2>

        <div className="text-base lg:text-lg flex flex-col gap-6 text-gray-750 lg:mt-8 lg:px-8 max-w-[45rem]">
            <div className="flex flex-col gap-2">
                <p className="px-4 py-2">
                    Thank you for submitting your credentials. Here&apos;s what&apos;s happening next:
                </p>
                <ol className="list-disc px-4 py-2">
                    <li>
                        <span className="font-bold">Connecting Warranties: </span>
                        <span>We&apos;re diligently matching each registered warranty to its corresponding system
                            in the manufacturer&apos;s database.</span>
                    </li>
                    <li>
                        <span className="font-bold">Monitoring Updates: </span>
                        <span>Successful matches will lead to continuous monitoring status updates with system
                            data, and component details.
                        </span>
                    </li>
                    <li>
                        <span className="font-bold">Unmatched Systems: </span>
                        <span>In cases where a system can&apos;t be matched, it will be listed as `Unmonitored` on
                        the Monitoring tab. Clear instructions will be provided at the site level for resolving
                        these specific instances.</span>
                    </li>
                </ol>
                <p className="font-semibold text-blue-700">This process may take up to 3 hours, varying with your volume of registrations.</p>
            </div>
        </div>
    </Popup>

export default PostSubmitPopup
