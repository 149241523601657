import { feature } from '@/services'

type FeatureProps = {
    name: string,
    active?: boolean
    children: JSX.Element | null
}
const Feature = ({ name, children, active = true }: FeatureProps) => feature(name, active) ? children : null

export default Feature
