import { useEffect, useMemo, useState } from 'react'

import { Button, PdfViewer } from '@/components'
import { Warranty } from '@/models'

type WarrantyCreateFinalizeStepProps = {
    warranty?: Warranty | null
}
export const WarrantyCreateFinalizeStep = ({ warranty }: WarrantyCreateFinalizeStepProps) => {
    const [pdf, setPdf] = useState<any>(null)

    const fetchPdf = async () => {
        const res = await warranty?.stream()
        setPdf(res)
    }

    useEffect(() => {
        fetchPdf()
    }, [])


    const previewUrl = useMemo(() => pdf ? URL.createObjectURL(pdf) : '', [pdf])

    return <div className="mt-12.5" data-test="warranty-create-step-4">
        <div className="my-8 h-screen">
            {previewUrl && <PdfViewer
                src={previewUrl}
                toolbar={false}
                className="h-screen"
                width="100%"
            />}
        </div>

        <div className="flex flex-wrap justify-end gap-3">
            <Button design="btn-secondary-gray" type="button" onClick={() => warranty?.download()}>
                Download Certificate PDF
            </Button>
            <Button design="btn-secondary-gray" type="button" onClick={() => warranty?.send()}>
                Email Certificate to Homeowner
            </Button>
            <Button type="button" href={`/warranties/${warranty?.id}/warranty`} data-test="warranty-create-step-3-exit-button">
                Exit
            </Button>
        </div>
    </div>
}
