import { useMemo, useState } from 'react'

import { Button, Form, NotificationsForm } from '@/components'
import { useAuth } from '@/hooks'
import { User } from '@/models'

type UserEditFormProps = {
    user: User,
    onSuccess?: (res: any) => void
    onExit: () => void
}
const NotificationsEditForm = ({ user, ...props }: UserEditFormProps) => {
    const [processing, setProcessing] = useState(false)
    const auth = useAuth()
    const getForm = (): any => {
        const settings = user.preferences?.notification_settings
            ? JSON.parse(user.preferences.notification_settings)
            : {}
        return {
            uploadStatus: {
                database: settings.uploadStatus?.database || false,
                mail: settings.uploadStatus?.mail || false
            },
            inactiveCompanies: user.isAdminOrStaffOrAdvisor ? {
                database: settings.inactiveCompanies?.database || false,
                mail: settings.inactiveCompanies?.mail || false
            } : undefined,
            credentials_update: user.isAdminOrStaffOrAdvisor ? {
                database: settings.credentials_update?.database || false,
                mail: settings.credentials_update?.mail || false
            } : undefined,
            company_updates: user.isAdminOrStaffOrAdvisor ? {
                database: settings.company_updates?.database || false,
                mail: settings.company_updates?.mail || false
            } : undefined,
            monthly_reports: user.isAdminOrStaffOrAdvisor ? {
                database: settings.monthly_reports?.database || false,
                mail: settings.monthly_reports?.mail || false
            } : undefined,
            fraud_review: user.isAdmin ? { mail: settings.fraud_review?.mail || false } : undefined
        }
    }
    const [form, setForm] = useState<any>(getForm())

    const handleChange = ({ target: { name, checked } }: any) => {
        const [key, type] = name.split('.')
        setForm({ ...form, [key]: { ...form[key], [type]: checked } })
    }

    const handleSubmit = async (e?: any) => {
        e?.preventDefault()
        setProcessing(true)
        try {
            const res = user.id === auth.user?.id
                ? await User.updatePreferencesSelf({ notification_settings: form })
                : await user.updatePreferences({ notification_settings: form })
            if (props.onSuccess) await props.onSuccess(res)
            props.onExit()
        } finally {
            setProcessing(false)
        }
    }

    const isDirty = useMemo(() => Object.keys(form).some(key =>
        getForm()[key]?.database !== form[key]?.database || getForm()[key]?.mail !== form[key]?.mail), [form, user])

    return <Form isDirty={isDirty} confirmation onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
        <h3 className="font-semibold lg:col-span-2 font-body">Notification Preferences</h3>
        <div className="lg:col-span-2 grid lg:grid-cols-5 gap-3 lg:gap-0">
            <NotificationsForm
                form={form}
                onChange={handleChange}
            />
        </div>

        <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end">
            <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing} disabled={!isDirty}>
                Save and Exit
            </Button>
        </div>
    </Form>
}

export default NotificationsEditForm
