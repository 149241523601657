import { useMemo } from 'react'

import { BarChart } from '@/components'
import { WarrantyProductType } from '@/models'
import { WarrantySalesItemDataType } from '@/types'


type InstallsGraphProps = {
    labels: string[]
    data: WarrantySalesItemDataType[]
}

export const InstallsGraph = ({
    labels,
    data
}: InstallsGraphProps) => {
    const datasets = useMemo(() => [
        {
            label: WarrantyProductType.battery30.title,
            data: data.map(item => item.production_with_30_year_separate_storage_count),
            tension: 0.25,
            fill: true,
            backgroundColor: '#98d3b8',
            borderColor: '#98d3b8',
            maxBarThickness: 50
        },
        {
            label: WarrantyProductType.battery20.title,
            data: data.map(item => item.production_with_20_year_separate_storage_count),
            tension: 0.25,
            fill: true,
            backgroundColor: '#c0b7da',
            borderColor: '#c0b7da',
            maxBarThickness: 50
        },
        {
            label: WarrantyProductType.solar.title,
            data: data.map(item => item.power_production_only_count),
            tension: 0.25,
            fill: true,
            backgroundColor: '#ffdc62',
            borderColor: '#ffdc62',
            maxBarThickness: 50
        }
    ], [data])
    return <BarChart
        datasets={datasets}
        labels={labels}
        options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: true,
                    grid: { color: '#f5f4f1' },
                    border: { display: false }
                },
                x: {
                    stacked: true,
                    grid: { display: false },
                    border: { display: false }
                }
            },
            elements: {
                point: {
                    radius: 0,
                    hoverRadius: 0
                }
            },
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                tooltip: {
                    usePointStyle: true,
                    padding: 12,
                    backgroundColor: '#181717',
                    bodySpacing: 8,
                    boxPadding: 8,
                    boxWidth: 8,
                    boxHeight: 8,
                    titleFont: { size: 12, weight: '400' },
                    bodyFont: { size: 12 },
                    callbacks: {
                        labelPointStyle(): any {
                            return {
                                pointStyle: 'circle',
                                rotation: 0
                            }
                        },
                        labelColor(tooltipItem): any {
                            return {
                                borderColor: tooltipItem.dataset.borderColor,
                                backgroundColor: tooltipItem.dataset.borderColor
                            }
                        },
                        label(tooltipItem): string | string[] | void {
                            if (typeof tooltipItem.raw === 'number') {
                                return `${tooltipItem.raw} Installs`
                            }
                            return '0 Installs'
                        }
                    }
                }
            }
        }}
    />
}
