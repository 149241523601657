import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import {
    Card,
    IconCertificate,
    PageHeader,
    Steps,
    StepVerifyColumns,
    StepConfirmation,
    FAQ,
    StepUpload,
    Button,
    IconArrowRight, StepValidateData
} from '@/components'
import { ColumnType, WARRANTY_COLUMNS } from '@/components/bulk-upload/default-coolumns'
import { AuthLayout } from '@/containers'
import { useAnalytics, useAppDispatch, useAppSelector, useDebounce, useToastNotifications } from '@/hooks'
import { AuthUser, User, Warranty } from '@/models'
import { JSONResult, fuse, cookies, loadingBar } from '@/services'
import { login, fetchNotifications, showNotifications } from '@/store'

const breadcrumb = [
    <IconCertificate key={1} className="stroke-gray-500"/>,
    <Link key={2} to="/warranties">Warranties</Link>,
    <Link key={2} to="/warranties/create">Add a New Warranty</Link>,
    'Bulk Upload'
]

const WarrantiesUpload = () => {
    const dispatch = useAppDispatch()
    const { trackEvent } = useAnalytics()
    const uploadAs = useLoaderData() as User | null
    const navigate = useNavigate()
    const { success, error } = useToastNotifications()
    const auth = useAppSelector(({ auth }) => auth)
    const [step, setStep] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [data, setData] = useState<JSONResult>([])
    const [errors, setErrors] = useState({})
    const [form, setForm] = useState<{ warranties: JSONResult }>({ warranties: [] })

    const defaultColumns = useMemo(() => {
        const columns = WARRANTY_COLUMNS.filter(column => !column.admin || auth.user?.isAdminOrStaff)
        const user = uploadAs || auth.user
        const savedColumns = user?.preferences?.warranty_prefs
        return savedColumns ? columns.map(item => {
            const pref = savedColumns.find(({ value }: any) => value === item.value)
            return {
                ...item,
                index: pref?.index === 0 || pref?.index ? pref.index : item.index,
                header: pref?.header ? pref.header : item.header
            }
        }) : columns
    }, [])
    const [columns, setColumns] = useState<ColumnType[]>(defaultColumns)

    useEffect(() => {
        setForm({
            ...form,
            warranties: data.map((item: any) => {
                const values = Object.values(item)
                return columns.reduce((acc, {
                    value,
                    index,
                    show
                }) => {
                    if (!show) return acc
                    return {
                        ...acc,
                        [value]: index === null ? '' : values[index as number]
                    }
                }, {})
            })
        })
    }, [data, columns])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
    }

    const handleFileUpload = (data: JSONResult) => {
        setData(data)
        setErrors({})
        const headers = Object.keys(data[0])
        const columns = defaultColumns.reduce((acc: any[], item) => {
            const res = fuse(headers.map(item => item.toLowerCase()), [
                item.title.toLowerCase(),
                item.value,
                ...item.header ? [item.header?.toLowerCase()] : [],
                ...item.keywords || []
            ])

            const score = typeof res?.score === 'undefined' ? 1 : res?.score
            const index = typeof res?.refIndex === 'undefined' ? null : res?.refIndex
            const header = index === null ? '' : headers[index]

            const takenIndex = acc.findIndex(item => item.index === index)
            // Col umn with same index found
            if (takenIndex > -1) {
                const taken = acc[takenIndex]
                // Taken column have less score or less priority or hidden
                if (taken.score >= score || !taken.show || !item.show || (!taken.required && item.required)) {
                    if (taken.show && item.show) {
                        acc[takenIndex] = { ...taken, score: 1, index: null, header: '' }
                    }
                    return [...acc, { ...item, score, index, header }]
                }
                return [...acc, { ...item, score: 1, index: null, header: '' }]
            }

            return [...acc, { ...item, score, index, header }]
        }, [])
        setColumns(columns)
    }

    const handleSubmit = async () => {
        trackEvent('click_bulk_upload_step_2_save', 'User Interaction', 'Bulk Upload Save Button')
        setProcessing(true)
        setErrors({})
        const user = uploadAs || auth.user
        const payload = {
            warranties: form.warranties,
            columns,
            user_id: uploadAs ? user?.id : undefined
        }
        try {
            await Warranty.upload(payload)
            const user = await AuthUser.currentUser()
            dispatch(login({ user, apiToken: cookies.get('api_token') }))
            dispatch(fetchNotifications)
            const toast = success('Bulk upload is being processed. You can track the progress in the notifications center.')
            toast.onClose = () => dispatch(showNotifications())
            setStep(3)
        } catch (err: any) {
            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
            }
            throw err
        } finally {
            setProcessing(false)
        }
    }

    const handleComplete = () => {
        navigate('/warranties')
    }

    const handleValidate = async (form: any) => {
        setProcessing(true)
        loadingBar.start()
        try {
            await Warranty.validateUpload({ ...form, user_id: uploadAs ? uploadAs.id : undefined })
            setErrors({})
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
            loadingBar.stop()
        }
    }

    const debounceValidate = useDebounce(form => {
        handleValidate(form)
    }, 500)

    useEffect(() => {
        if (step === 2) {
            debounceValidate(form)
        }
    }, [form.warranties])

    return <AuthLayout
        heading={
            <PageHeader
                title={<div className="w-full flex flex-col xl:flex-row gap-4 justify-between xl:items-center mt-7.5">
                    Bulk Upload

                    <div className="flex flex-col lg:flex-row items-start gap-4 text-sm lg:text-base">
                        <span className="text-gray-700">Need to register a Battery Warranty?</span>
                        <Button design="btn-link" className="!text-primary-800 !stroke-primary-800 !text-sm !lg:text-base gap-2" href="/warranties/create">
                            <div><IconArrowRight/></div>
                            Go to Single Warranty Registration
                        </Button>
                    </div>
                </div>}
                backUrl="/warranties/create"
            />
        }
        breadcrumb={breadcrumb}
    >
        <Card className="lg:!p-8">
            <Steps
                className="mt-4 lg:px-8"
                step={step}
                steps={[
                    'Upload File',
                    'Verify Columns',
                    'Validate Data',
                    'Confirmation'
                ]}
            />

            <div className="mt-8">
                {step === 0 && <StepUpload
                    onChange={handleFileUpload}
                    onNext={() => setStep(1)}
                />}
                {step === 1 && <StepVerifyColumns
                    form={form}
                    columns={columns}
                    headers={Object.keys(data[0])}
                    onNext={async () => {
                        await handleValidate(form)
                        setStep(2)
                    }}
                    onPrev={() => setStep(0)}
                    onChange={handleChange}
                    onColumnsChange={setColumns}
                    processing={processing}
                />}
                {step === 2 && <StepValidateData
                    form={form}
                    errors={errors}
                    columns={columns}
                    headers={Object.keys(data[0])}
                    onNext={handleSubmit}
                    onPrev={() => setStep(1)}
                    onChange={handleChange}
                    processing={processing}
                    uploadAs={uploadAs}
                />}

                {step === 3 && <StepConfirmation
                    onNext={handleComplete}
                />}
            </div>
        </Card>

        {step === 0 && <FAQ className="mt-8"/>}
    </AuthLayout>
}

export default WarrantiesUpload
