const ContactInfo = () =>
    <div className="bg-primary-800 rounded p-3 text-primary-100 font-body hidden md:flex flex-col gap-2">
        <h4 className="text-lg" data-test="sidebar-contact-us-title">Contact Us</h4>
        <a href="tel:714-625-8204" data-test="sidebar-contact-us-phone">714-625-8204</a>
        <a
            className="text-xs"
            href="mailto:service@solarinsure.com"
            data-test="sidebar-contact-us-email-service"
        >
            service@solarinsure.com
        </a>
        <a
            className="text-xs"
            href="mailto:claims@solarinsure.com"
            data-test="sidebar-contact-us-email-claims"
        >
            claims@solarinsure.com
        </a>
        <a
            className="text-xs"
            href="mailto:accounting@solarinsure.com"
            data-test="sidebar-contact-us-email-accounting"
        >
            accounting@solarinsure.com
        </a>
    </div>

export default ContactInfo
