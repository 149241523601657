import { Navigate } from 'react-router-dom'

import { toastNotifications } from '@/services'
import store, { logout, setRedirectUrl } from '@/store'

type ErrorResponseBody = {
    status: 'Ok' | 'Failed'
    message?: string
    error?: string | { [key: string]: string }
    errors?: string | { [key: string]: string }
}

const UNAUTHENTICATED_STATUS = 401
const UNAUTHORIZED_STATUS = 403

const ERROR_MESSAGES: {
    [key: number]: string
} = {
    [UNAUTHORIZED_STATUS]: 'Action is not authorized.',
    [UNAUTHENTICATED_STATUS]: 'Action is not authorized.'
}

export default async (errRes: Response | 'canceled') => {
    if (errRes !== 'canceled') {
        const contentType = errRes.headers?.get('content-type') as string
        if (contentType && contentType.includes('application/json')) {
            const res: ErrorResponseBody = await errRes.json()
            if (!res.message?.includes('abort')) {
                if (res.error) res.errors = res.error

                if (ERROR_MESSAGES[errRes.status]) {
                    toastNotifications.error(ERROR_MESSAGES[errRes.status])
                    if (errRes.status === UNAUTHORIZED_STATUS) {
                        return <Navigate to="/"/>
                    }
                    if (errRes.status === UNAUTHENTICATED_STATUS) {
                        await store.dispatch(logout())
                        if (!location.pathname.includes('login')) {
                            store.dispatch(setRedirectUrl(`${location.pathname}${location.search}`))
                            return <Navigate to="/login"/>
                        }
                    }
                } else if (typeof res.errors === 'string') {
                    toastNotifications.error(res.errors)
                } else if (res.errors?.message) {
                    toastNotifications.error(res.errors?.message)
                } else {
                    // Do nothing
                }
            }
            throw res as unknown as Error
        }
    } else {
        // Do nothing
    }
    throw errRes as unknown as Error
}
