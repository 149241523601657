import { useEffect, useMemo, useState } from 'react'

import tableEmptyStateAdd from '@/assets/images/table/tableEmptyStateAdd.svg'
import {
    DatePicker,
    SearchableSelect,
    WarrantyCreateButton,
    WarrantyIndexButtons, Image, PageHeader, Select, IconHelpCircle, Tooltip
} from '@/components'
import { AuthLayout, FiltersType, GlobalIndex, GlobalIndexDataType } from '@/containers'
import { useAuth, useNavigateWithQuery, useQuery } from '@/hooks'
import { Company, Warranty, WarrantyProductType } from '@/models'
import { api } from '@/services'

export interface WarrantyByContractorsIndexData extends GlobalIndexDataType {
    rows: Warranty[]
    selectedRows: Warranty[] | 'all'
    excludedRows: Warranty[]
}

export interface WarrantyByContractorsIndexFilters extends FiltersType {
    company_id: string
    type: string
    state: string
    start_date: Date | null
    end_date: Date | null
}
export const WarrantiesByContractorsIndex = () => {
    const navigateWithQuery = useNavigateWithQuery()
    const query = useQuery()
    const auth = useAuth()

    const getDefaultFilters = () => ({
        company_id: query.company_id || '',
        type: query.type || '',
        state: query.state || '',
        // Adding ' 00:00' to the end of string date to clear timezone
        start_date: query.start_date ? new Date(`${query.start_date} 00:00`) : null,
        end_date: query.end_date ? new Date(`${query.end_date} 00:00`) : null
    })
    const [companies, setCompanies] = useState<Company[]>([])
    const [affiliates, setAffiliates] = useState<Company[]>([])
    const [filters, setFilters] = useState<WarrantyByContractorsIndexFilters>(getDefaultFilters())
    const [states, setStates] = useState<any>({})
    const [reload, setReload] = useState(false)
    const [data, setData] = useState<WarrantyByContractorsIndexData>({
        meta: {
            total: 0,
            overall_count: 0,
            current_page: 0
        },
        rows: [],
        selectedRows: [],
        excludedRows: []
    })

    const fetchCompanies = async () => {
        const data = await Company.autocomplete()
        setCompanies(data.filter((item: Company) => item.isContractor))
        setAffiliates(data.filter((item: Company) => item.isAffiliate))
    }

    const fetchStates = async () => {
        const res = await api.address.states()
        setStates(res)
    }

    useEffect(() => {
        fetchCompanies()
        fetchStates()
    }, [])

    useEffect(() => {
        setData(prevData => ({ ...prevData, selectedRows: [] }))
    }, [filters])

    const handleChange = ({ name, value }: any) => {
        setData(data => ({ ...data, [name]: value }))
    }

    const handleFiltersChange = ({ target: { name, value } }: any) => {
        if (name === 'range') {
            navigateWithQuery({ page: null, start_date: value.start?.toISODate(), end_date: value.end?.toISODate() })
            setFilters({ ...filters, start_date: value.start, end_date: value.end })
        } else {
            setFilters({ ...filters, [name]: value })
            navigateWithQuery({ page: null, [name]: value })
        }
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        type: item.productType.icons('xs', true),
        'company.name': item.company?.name,
        'homeowner.created_at': new Date(item.submission_date).format(),
        customer_info: item.customer_info.full_name,
        'homeowner.street_address': <>
            <div className="text-gray-900">{item.install_address.street_address}</div>
            <div className="text-gray-500">{item.install_address.city}, {item.install_address.state} {item.install_address.zip}</div>
        </>,
        city_state: `${item.install_address.city}, ${item.install_address.state}`,
        unique_id: item.unique_id,
        'warranties.size_w': Warranty.getField('size_kw').format(item.size_kw),
        'warranties.sent_at': item.sent_date?.format(),
        'homeowner.install_date': item.install_date?.format(),
        'warranties.policy_num': item.policy_num,
        'panel.model_name': item.panel_model,
        'inverter.model_name': item.inverter_model,
        'warranties.eligible_commission': item.eligible_commission ? item.eligible_commission.money() : '',
        _path: `/warranties-by-contractors/${item.id}/warranty`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Warranties by Contractors"/>}>
        <GlobalIndex
            id="warranties-by-contractors"
            infinity
            data={data}
            filters={filters}
            getDefaultFilters={getDefaultFilters}
            maxColumnsAmount={4}
            api={(...args) => Warranty.index(...args)}
            selectable
            searchPlaceholder="Find Homeowners by Email, Address, Plan ID, System ID, or More"
            onChange={handleChange}
            onFiltersChange={filters => setFilters(filters)}
            dataType="Warranties"
            reload={reload}
            onReloadChange={setReload}
            columns={[
                { title: 'Type', field: 'type' },
                { title: 'Contractor', field: 'company.name' },
                { title: 'Submission Date', field: 'homeowner.created_at' },
                { title: 'Homeowner Name', field: 'customer_info' },
                { title: 'City, State', field: 'city_state' },

                { title: 'Address', field: 'homeowner.street_address' },
                { title: 'Salesforce Unique ID', field: 'unique_id' },
                { title: 'System Size', field: 'warranties.size_w' },
                { title: 'Emailed Date', field: 'warranties.sent_at' },
                { title: 'Install Date', field: 'homeowner.install_date' },
                { title: 'Policy Number', field: 'warranties.policy_num' },
                { title: 'Panel Model', field: 'panel.model_name' },
                { title: 'Inverter Model', field: 'inverter.model_name' },
                {
                    title: <div><Tooltip interactive hideOnClick placement="bottom" content={Company.eligibleCommissionTooltipContent} className="flex gap-1 items-center">
                        Eligible Commission
                        <IconHelpCircle className="stroke-gray-400" size="sm"/>
                    </Tooltip></div>,
                    title_raw: 'Eligible Commission',
                    field: 'warranties.eligible_commission',
                    showIf: auth.user?.isAdminOrStaffOrAdvisor
                }
            ]}
            rows={rowsMemo}
            placeholder={data.meta.total === 0 && (auth.user?.isAdminOrStaff || auth.user?.isContractor) && <>
                <Image
                    className="w-30"
                    src={tableEmptyStateAdd}
                />
                <div className="mt-4 flex flex-col items-center">
                    <h4 className="text-center font-light">Start by Adding a New Warranty</h4>
                    <p className="text-center text-gray-500 font-light text-sm mt-1">
                        Any data from registered warranties will live here. Start registering Solar Insure warranties
                        today
                    </p>
                    <WarrantyCreateButton className="mt-6"/>
                </div>
            </>}
            leftSide={<>
                <DatePicker
                    clearable
                    id="date-range"
                    name="range"
                    placeholder="Date range"
                    value={{
                        start: filters.start_date,
                        end: filters.end_date
                    }}
                    onChange={handleFiltersChange}
                    options={{ singleMode: false, maxDate: new Date() }}
                    data-test="warranties-by-contractor-index-date-range-filter"
                />
                <Select
                    name="type"
                    id="warranty-type"
                    options={[
                        { value: '', title: 'Any Type' },
                        ...WarrantyProductType.all.map(item => ({
                            value: item.key,
                            title: <div className="flex gap-2">
                                <div className="w-12">{item.icons()}</div>
                                {item.title}
                            </div>
                        }))
                    ]}
                    value={filters.type}
                    onChange={handleFiltersChange}
                    data-test="warranties-by-contractor-index-type-filter"
                />
                <Select
                    id="state"
                    name="state"
                    options={[{
                        value: '',
                        title: 'Any State'
                    }, ...Object.keys(states).map(key => ({ value: key, title: `${states[key]}, ${key}` }))]}
                    disabled={!Object.keys(states).length}
                    value={filters.state}
                    onChange={handleFiltersChange}
                    data-test="warranties-by-contractor-index-state-filter"
                />
                <SearchableSelect
                    id="company-id"
                    name="company_id"
                    value={filters.company_id}
                    placeholder="Any Contractor"
                    options={companies.map(item => ({ value: item.id, title: item.name }))}
                    onChange={handleFiltersChange}
                    data-test="warranties-by-contractor-index-contractor-filter"
                />
                {auth.user?.isAdminOrStaffOrAdvisor && <Select
                    id="company-id"
                    name="company_id"
                    value={filters.company_id}
                    disabled={!affiliates.length}
                    options={[
                        { value: '', title: 'Any Affiliate' },
                        ...affiliates.map(item => ({
                            value: item.id,
                            title: item.name
                        }))
                    ]}
                    onChange={handleFiltersChange}
                    data-test="warranties-by-contractor-index-affiliate-filter"
                />}
            </>}
            rightSide={<>
                <WarrantyIndexButtons
                    data={data}
                    filters={filters}
                    onChange={() => setReload(true)}
                    warrantiesByContractors={true}
                    data-test="warranties-by-contractor-index"
                />
            </>}
        />
    </AuthLayout>
}
