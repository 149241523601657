import Model from './Model'
import { api } from '@/services'

export default class Article extends Model {
    static endpoint = 'api/v1/support/articles'

    feedback = (payload: any) => api.support.feedback({ article_id: this.id, ...payload })

    static deleteNotification = (id: number) => api.support.deleteNotification(id)
}
