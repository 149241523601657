const getDefaultCookieDomain = () => {
    const isIp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(location.hostname)
    const res = /\.(.*)\.(.*)/.exec(location.hostname)
    return res && res[0] !== '.vercel.app' && !isIp ? res[0] : ''
}

export default class Cookie {
    static set(name: string, value: string | number, domain: string = getDefaultCookieDomain(), days = 1) {
        let expires = ''
        if (days) {
            const date = new Date()
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
            expires = `; expires=${date.toUTCString()}`
        }
        document.cookie = `${name}=${value || ''}${expires};domain=${domain};path=/`
    }

    static get(name: string): string | null {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null
        return null
    }

    static delete(name: string, path = '/', domain: string = getDefaultCookieDomain()) {
        if (this.get(name)) {
            document.cookie = `${name}=;path=${path};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`
            return true
        }
        return false
    }
}
