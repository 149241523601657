export default class SessionStorage {
    static get isAvailable() {
        try {
            const testKey = 'test'
            const storage = window.sessionStorage
            storage.setItem(testKey, '1')
            storage.removeItem(testKey)
            return true
        } catch (error) {
            return false
        }
    }

    static set(name: string, value: any) {
        if (!this.isAvailable) return
        return sessionStorage.setItem(name, typeof value === 'object' ? JSON.stringify(value) : value)
    }

    static get(name: string) {
        if (!this.isAvailable) return
        const value = sessionStorage.getItem(name) || ''
        try {
            return JSON.parse(value)
        } catch (e) {
            return value
        }
    }

    static delete(name: string) {
        if (!this.isAvailable) return
        return sessionStorage.removeItem(name)
    }
}
