import { ReactNode } from 'react'

import loginBackground from '@/assets/images/loginBackground.svg'
import loginBackgroundOverlay from '@/assets/images/loginBackgroundOverlay.svg'
import { Image, Logo } from '@/components'


type LoginWrapperProps = {
    children: ReactNode
}

const LoginWrapper = ({ children }: LoginWrapperProps) =>
    <div className="flex w-full login-container font-light h-full">
        <div className="w-1/2 max-w-3xl flex relative hidden md:flex">
            <div className="login-background-overlay w-full h-full">
                <Image
                    src={loginBackgroundOverlay}
                    className="w-full h-full object-cover object-right login-background-overlay"
                    pictureClassName="w-full h-full"
                />
            </div>
            <div className="login-background">
                <Image
                    src={loginBackground}
                    className="w-full h-full object-cover object-right login-background-overlay login-background"
                    pictureClassName="w-full h-full"
                />
                <Logo
                    type="primaryWhite"
                    className="mb-4 mx-auto primary-logo absolute -translate-x-1/2 -translate-y-1/2 left-[40%] top-[50%]"
                />
            </div>
        </div>
        <div className="flex items-center flex-col justify-center w-full md:w-1/2">
            {children}
        </div>
    </div>

export default LoginWrapper
