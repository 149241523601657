import { Input, TextEditor } from '@/components'

type ArticleFormProps = {
    form: {
        title: string
        content: string
    }
    errors: {
        title?: string | string[]
        content?: string | string[]
    }
    onChange: any
}
const ArticleForm = ({ form, errors, ...props }: ArticleFormProps) => <>
    <Input
        id="title"
        name="title"
        label="Title*"
        placeholder="Title"
        onChange={props.onChange}
        value={form.title}
        errors={errors.title}
    />

    <TextEditor
        id="content"
        name="content"
        label="Content*"
        placeholder="Content"
        onChange={props.onChange}
        value={form.content}
        errors={errors.content}
    />
</>

export default ArticleForm
