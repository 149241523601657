import classnames from 'classnames'
import { ChangeEvent, ReactNode } from 'react'

import { IconCheck } from '../icons'

type CardCheckboxProps = {
    id?: string
    label?: ReactNode
    icon?: ReactNode
    className?: string
    disabled?: boolean
    checked: boolean
    value?: string | number
    name?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const CardCheckbox = ({
    icon,
    label,
    className,
    disabled,
    checked,
    ...props
}: CardCheckboxProps) => <label className={classnames('card-checkbox', className, {
    disabled,
    checked
})}>
    {icon && <span className="card-checkbox-icon">
        <span>
            {icon}
        </span>
    </span>}
    {label && <span className="card-checkbox-label">{label}</span>}
    <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        {...props}
    />
    <span className="card-checkbox-check-icon">
        <IconCheck size="xs" className="stroke-white"/>
    </span>
</label>
