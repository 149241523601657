import { GeneracSystemStatusEnum, SystemStatusEnum } from '@/enums'
import Device from '@/models/Device'
import { DeviceCategory, SystemStatus, System, InverterManufacturer } from '@/models/index'

interface GeneracSystemType {
    devices: any
    summary: any
    system?: System
}

export default class GeneracSystem {
    id: string

    deviceCategories: DeviceCategory[]

    systemStatus: SystemStatus

    serialNumber: string

    constructor(props: GeneracSystemType = {
        devices: {},
        summary: {}
    }) {
        this.id = props.summary.systemId
        this.serialNumber = props.summary.serialNumber
        this.systemStatus = SystemStatus.find(GeneracSystem.statusMap[props.summary.statusName])
        this.deviceCategories = Object.keys(props.devices).map(key => new DeviceCategory({
            ...props.devices[key],
            id: `${props.summary.systemId} ${key}`,
            type: key,
            provider: InverterManufacturer.generac.key,
            devices: props.devices[key],
            warranty: props.devices.warranty,
            system: props.system
        }))
    }

    static statusMap: { [key: string]: SystemStatusEnum } = {
        [GeneracSystemStatusEnum.SUCCESS]: SystemStatusEnum.NORMAL,
        [GeneracSystemStatusEnum.ERROR]: SystemStatusEnum.COMPONENT_ERROR,
        [GeneracSystemStatusEnum.DISCONNECTED]: SystemStatusEnum.SYSTEM_DISCONNECTED,
        [GeneracSystemStatusEnum.NEVER_CONNECTED]: SystemStatusEnum.NEVER_CONNECTED,
        [GeneracSystemStatusEnum.UNKNOWN]: SystemStatusEnum.UNKNOWN_STATUS
    }

    get allDevices(): Device[] {
        return this.deviceCategories.reduce((acc: Device[], item) => [...acc, ...item.devices], [])
    }

    get normalDevices() {
        return this.allDevices.filter(item => item.isNormal)
    }

    get disabledDevices() {
        return this.allDevices.filter(item => item.isDisabled)
    }

    get erroredDevices() {
        return this.allDevices.filter(item => item.isErrored)
    }
}
