import { ReactNode, useState } from 'react'

import { Button, IconChevronUp } from '@/components'

type StatusGuideExpandingItemProps = {
    title: ReactNode,
    content: ReactNode,
    showChevron?: boolean
}

const StatusGuideExpandingItem = ({
    title,
    content,
    showChevron = true
}: StatusGuideExpandingItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const handleExpandToggleClick = () => {
        setIsExpanded(isExpanded => !isExpanded)
    }

    return <div className={`py-4 ${isExpanded ? 'bg-gray-100 rounded-md' : ''} ${showChevron ? 'px-6' : ''} ${!showChevron && isExpanded ? 'px-6' : ''}`}>
        <Button design="btn-link" onClick={handleExpandToggleClick} className="gap-2">
            {showChevron && <IconChevronUp className={`stroke-black transition-all ${!isExpanded ? 'rotate-180' : ''}`}/>}
            <span className="text-gray-800 text-lg font-semibold">
                {title}
            </span>
        </Button>
        {isExpanded && <div className="mt-4 text-xs text-gray-750 font-body">
            {content}
        </div>}
    </div>
}

export default StatusGuideExpandingItem
