import { useEffect, useState } from 'react'

import ReportBox from './ReportBox'
import { IconCertificate, IconCurrencyDollar, Tooltip } from '@/components'
import { WarrantyProductType } from '@/models'
import { api } from '@/services'

type ReportBoxesProps = {
    className?: string
    onChange: any
    processing: boolean
}

const ReportBoxes = ({ className, processing, ...props }: ReportBoxesProps) => {
    const [installsForMonth, setInstallsForMonth] = useState({
        power_production_only_count: 0,
        production_with_20_year_separate_storage_count: 0,
        production_with_30_year_separate_storage_count: 0,
        total_count: 0
    })
    const [installsForYear, setInstallsForYear] = useState({
        power_production_only_count: 0,
        production_with_20_year_separate_storage_count: 0,
        production_with_30_year_separate_storage_count: 0,
        total_count: 0
    })

    const [salesForYear, setSalesForYear] = useState({
        power_production_coverage_sum: 0,
        storage_20_year_coverage_sum: 0,
        storage_30_year_coverage_sum: 0,
        total_sum: 0
    })

    const [abortController, setAbortController] = useState<AbortController | null>(null)

    useEffect(() => () => {
        abortController?.abort('canceled')
    }, [abortController])

    const fetchInstallsForMonth = async (controller: AbortController) => {
        const [curMonth] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('month').toISODate(),
            end_date: new Date().toISODate(),
            period: 'month'
        }, { signal: controller.signal })

        setInstallsForMonth(curMonth)
    }
    const fetchInstallsForYear = async (controller: AbortController) => {
        const [curYear] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('year').toISODate(),
            end_date: new Date().toISODate(),
            period: 'year'
        }, { signal: controller.signal })
        setInstallsForYear(curYear)
    }
    const fetchSalesForYear = async (controller: AbortController) => {
        const [curYear] = await api.dashboard.warrantySales({
            start_date: new Date().startOf('year').toISODate(),
            end_date: new Date().toISODate(),
            period: 'year'
        }, { signal: controller.signal })
        setSalesForYear(curYear)
    }

    const fetchData = async () => {
        try {
            const controller = new AbortController
            await setAbortController(controller)
            await Promise.all([
                fetchInstallsForMonth(controller),
                fetchInstallsForYear(controller),
                fetchSalesForYear(controller)
            ])
        } finally {
            props.onChange('reportBoxes')
        }
    }

    useEffect(() => {
        if (processing) {
            fetchData()
        }
    }, [processing])

    const boxes = [
        {
            key: 'installs-this-month',
            icon: <IconCertificate/>,
            title: 'Installs This Month',
            className: 'col-span-12 lg:col-span-6 xl:col-span-4',
            value: <div data-test="dashboard-report-box-installs-this-month-value">{installsForMonth.total_count}</div>,
            rows: [
                {
                    key: 'solar',
                    value: installsForMonth.power_production_only_count,
                    title: WarrantyProductType.solar.title,
                    icon: WarrantyProductType.solar.icon('xs', true)
                },
                {
                    key: 'battery-20',
                    value: installsForMonth.production_with_20_year_separate_storage_count,
                    title: WarrantyProductType.battery20.title,
                    icon: WarrantyProductType.battery20.icons('xs', true)
                },
                {
                    key: 'battery-30',
                    value: installsForMonth.production_with_30_year_separate_storage_count,
                    title: WarrantyProductType.battery30.title,
                    icon: WarrantyProductType.battery30.icons('xs', true)
                }
            ]
        },
        {
            key: 'installs-this-year',
            icon: <IconCertificate/>,
            title: 'Installs This Year',
            className: 'col-span-12 lg:col-span-6 xl:col-span-4',
            value: <div data-test="dashboard-report-box-installs-this-year-value">{installsForYear.total_count}</div>,
            rows: [
                {
                    key: 'solar',
                    value: installsForYear.power_production_only_count,
                    title: WarrantyProductType.solar.title,
                    icon: WarrantyProductType.solar.icon('xs', true)
                },
                {
                    key: 'battery-20',
                    value: installsForYear.production_with_20_year_separate_storage_count,
                    title: WarrantyProductType.battery20.title,
                    icon: WarrantyProductType.battery20.icons('xs', true)
                },
                {
                    key: 'battery-30',
                    value: installsForYear.production_with_30_year_separate_storage_count,
                    title: WarrantyProductType.battery30.title,
                    icon: WarrantyProductType.battery30.icons('xs', true)
                }
            ]
        },
        {
            key: 'sales-this-year',
            icon: <IconCurrencyDollar/>,
            title: 'Sales This Year',
            className: 'col-span-12 xl:col-span-8 flex !flex-row flex-wrap lg:flex-nowrap',
            value: <div className="lg:w-full" data-test="dashboard-report-box-sales-this-year-value">{salesForYear.total_sum.money()}</div>,
            rows: [
                {
                    key: 'power_production_coverage_sum',
                    value: salesForYear.power_production_coverage_sum.money(),
                    title: WarrantyProductType.solar.secondaryTitle,
                    icon: WarrantyProductType.solar.icon('xs', 'secondary')
                },
                {
                    key: 'production_with_20_year_separate_coverage_sum',
                    value: salesForYear.storage_20_year_coverage_sum.money(),
                    title: WarrantyProductType.battery20.secondaryTitle,
                    icon: WarrantyProductType.battery20.icon('xs', 'secondary')
                },
                {
                    key: 'production_with_30_year_separate_coverage_sum',
                    value: salesForYear.storage_30_year_coverage_sum.money(),
                    title: WarrantyProductType.battery30.secondaryTitle,
                    icon: WarrantyProductType.battery30.icon('xs', 'secondary')
                }
            ]
        }
    ]

    return <>
        {boxes.map(item =>
            <ReportBox
                key={item.key}
                className={`${className} ${item.className}`}
                icon={item.icon}
                title={item.title}
                value={item.value}
                data-test={`dashboard-report-box-${item.key}`}
            >
                <div className="flex flex-col gap-1 text-sm leading-6">
                    {item.rows.map(row => <div key={row.key} className="flex gap-2 justify-start font-semibold">
                        <div className="flex items-center w-12">{row.icon}</div>
                        <Tooltip
                            content={`${row.value} ${row.title}`}
                            data-test={`dashboard-report-box-${item.key}-${row.key}-data`}
                        >
                            <span
                                data-test={`dashboard-report-box-${item.key}-${row.key}-value`}
                            >{row.value}</span> <span
                                className="font-light"
                                data-test={`dashboard-report-box-${item.key}-${row.key}-title`}
                            >{row.title}</span>
                        </Tooltip>
                    </div>)}
                </div>
            </ReportBox>)}
    </>
}

export default ReportBoxes
