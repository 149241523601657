import {
    Button,
    IconXCircle,
    WarrantyReviewCancelOrReinstateRequestButton, IconFlipBackward
} from '@/components'
import { useAuth } from '@/hooks'
import { Warranty } from '@/models'

type WarrantyReviewReinstateOrCancelBannerProps = {
    warranty: Warranty
    onChange: () => void
}

export const WarrantyReviewReinstateOrCancelBanner = ({
    warranty,
    ...props
}: WarrantyReviewReinstateOrCancelBannerProps) => {
    const auth = useAuth()
    const request = warranty.pending_cancellation_requests[0] as {
        id: string,
        cancellation_reason: string,
        created_at: string
    }

    const handleCancelRequest = async () => {
        await Warranty.revertCancellationRequest(request.id)
        props.onChange()
    }

    return <div className="flex w-full justify-between items-center rounded border border-error-200 shadow-md mb-6 py-2 px-4 bg-white">
        <div className="flex items-center">
            <div className="w-13 h-13 rounded-full bg-error-50 flex justify-center items-center">
                <div className="w-9 h-9 rounded-full bg-error-100 flex justify-center items-center stroke-error-700">
                    {warranty.warrantyStatus.isCancellationRequested
                        ? <IconXCircle size="lg"/>
                        : <IconFlipBackward size="lg"/>}
                </div>
            </div>
            <p className="leading-5 ml-4">
                <span className="font-medium text-black mr-2">
                    A {warranty.warrantyStatus.isCancellationRequested ? 'cancellation' : 'reinstatement'} was requested
                    on {new Date(request.created_at).format()}.
                </span>
                {auth.user?.isContractor && <>
                    <br/>
                    <span className="mt-1 text-gray-600 block">
                        A Solar Insure representative will reach out shortly to review the request.
                    </span>
                </>}
            </p>
        </div>
        {auth.user?.isAdminOrStaff &&
            <WarrantyReviewCancelOrReinstateRequestButton
                warranty={warranty}
                onChange={props.onChange}
            >
                <Button
                    className="whitespace-nowrap"
                    type="button"
                    design="btn-secondary"
                >
                    See Details
                </Button>
            </WarrantyReviewCancelOrReinstateRequestButton>}

        {auth.user?.isContractor &&
            new Date(request.created_at) > new Date().subtract('hours', 24) &&
            <Button
                tooltip="Cancel Request"
                className="whitespace-nowrap"
                type="button"
                design="btn-secondary"
                onClick={handleCancelRequest}
            >
                Cancel Request
            </Button>}
    </div>
}
