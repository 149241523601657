import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'

export const ENABLED = import.meta.env.PROD && import.meta.env.VITE_GTAG_ID

export const initGA = () => {
    if (ENABLED) {
        ReactGA.initialize(import.meta.env.VITE_GTAG_ID)
    }
}

export const gaEvent = (optionsOrName: UaEventOptions | string, params?: any) => {
    if (ENABLED) {
        ReactGA.event(optionsOrName, params)
    }
}

export const gaSend = (fieldObject: any) => {
    if (ENABLED) {
        ReactGA.send(fieldObject)
    }
}

initGA()
