import { useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router-dom'

import {
    CompanyEditForm,
    Tabs,
    Card,
    EditIntegrationsForm,
    IconBuilding07,
    CompanyUsersEditForm,
    CompanyHeader
} from '@/components'
import { AuthLayout } from '@/containers'
import { Company } from '@/models'

const CompaniesEdit = () => {
    const { affiliateId, id, ...params } = useParams()
    const data = useLoaderData() as Company
    const navigate = useNavigate()

    const [company, setCompany] = useState(data)

    const basePath = useMemo(() => affiliateId
        ? `/companies/${affiliateId}/subsidiaries/${id}`
        : `/companies/${id}`,
    [affiliateId, id]
    )
    const tabs = useMemo(() => [
        { id: 'company', title: 'Company', to: `${basePath}/edit/company` },
        { id: 'integrations', title: 'Integrations', to: `${basePath}/edit/integrations` },
        { id: 'users', title: 'Users', to: `${basePath}/edit/users` }
    ], [])
    const tab = params.tab || tabs[0].id
    const activeTab = useMemo(() => tabs.find(item => item.id === tab) || tabs[0], [tab])
    const breadcrumb = useMemo(() => {
        if (affiliateId) {
            return [
                <IconBuilding07 key={1} className="stroke-gray-500"/>,
                <Link key={2} to="/companies">Companies</Link>,
                <Link key={2} to={`/companies/${affiliateId}`}>Company Details</Link>,
                <Link key={2} to={`/companies/${affiliateId}/subsidiaries/${params.id}`}>Subcontractor Details</Link>,
                `Edit Subcontractor - ${activeTab.title}`
            ]
        }
        return [
            <IconBuilding07 key={1} className="stroke-gray-500"/>,
            <Link key={2} to="/companies">Companies</Link>,
            <Link key={3} to={`${basePath}`}>Company Details</Link>,
            `Edit Company - ${activeTab.title}`
        ]
    }, [activeTab, affiliateId])

    const fetchCompany = async () => {
        const res = await Company.show(id as string) as Company
        flushSync(() => {
            setCompany(res)
        })
    }

    const handleExit = () => {
        navigate(`/companies/${company.id}`)
    }

    return <AuthLayout heading={<CompanyHeader company={company} backUrl={`${basePath}`}/>} breadcrumb={breadcrumb}>
        {<Tabs
            className="w-fit mb-8"
            size="sm"
            tabs={tabs}
            value={tab}
        />}
        <Card>
            {tab === 'company' && <CompanyEditForm
                company={company}
                onSuccess={fetchCompany}
                onExit={handleExit}
            />}
            {tab === 'integrations' && <EditIntegrationsForm
                company={company}
                onChange={fetchCompany}
                basePath={`${basePath}/edit`}
            />}
            {tab === 'users' && <CompanyUsersEditForm
                company={company}
                onSuccess={fetchCompany}
                onExit={handleExit}
            />}
        </Card>
    </AuthLayout>
}

export default CompaniesEdit
