import MenuItem, { MenuItemProps } from './MenuItem'

const MenuItems = ({ items, className }: { items: Array<MenuItemProps>, className?: string }) =>
    <div className={`nav-items ${className}`}>
        {items.map(({ showIf = true, ...item }: MenuItemProps) =>
            showIf && <MenuItem
                key={item.id}
                item={item}
            />)}
    </div>

export default MenuItems
