import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
    Card,
    EditCustomManufacturerForm,
    EditEnphaseForm,
    EditGeneracForm,
    EditSolarEdgeForm,
    PostSubmitPopup,
    Tabs,
    IntegrationTutorialPopup
} from '@/components'
import { Company } from '@/models'

type EditIntegrationsFormProps = {
    company: Company
    onChange: () => void
    basePath?: string
}

const EditIntegrationsForm = ({ company, basePath = '/profile', ...props }: EditIntegrationsFormProps) => {
    const params = useParams()
    const [tab, setTab] = useState<string | number | null>(params.provider || 'enphase')
    const [isPostSubmitPopupOpen, setIsPostSubmitPopupOpen] = useState(false)

    const tabs = [
        {
            id: 'enphase',
            title: 'Enphase',
            icon: company.enphaseIntegration?.credentialStatus.icon(),
            to: `${basePath}/integrations/enphase`,
            form: <EditEnphaseForm
                company={company}
                integration={company.enphaseIntegration}
                onChange={props.onChange}
                onAdded={() => setIsPostSubmitPopupOpen(true)}
            />
        },
        {
            id: 'solaredge',
            title: 'SolarEdge',
            icon: company.solarEdgeIntegration?.credentialStatus.icon(),
            to: `${basePath}/integrations/solaredge`,
            form: <EditSolarEdgeForm
                company={company}
                integration={company.solarEdgeIntegration}
                onChange={props.onChange}
                onAdded={() => setIsPostSubmitPopupOpen(true)}
            />
        },
        {
            id: 'generac',
            title: 'Generac',
            icon: company.generacIntegration?.credentialStatus.icon(),
            to: `${basePath}/integrations/generac`,
            form: <EditGeneracForm
                company={company}
                integration={company.generacIntegration}
                onChange={props.onChange}
                onAdded={() => setIsPostSubmitPopupOpen(true)}
            />
        }
    ]

    const getActiveTab = () => tabs.find(item => item.id === tab) || tabs[0]

    return <div className="company-integrations">
        <div className="flex flex-wrap gap-2 justify-between">
            <div className="flex flex-col gap-1">
                <h3 className="text-lg">Select Manufacturer to Enter Monitoring Credentials</h3>
                <p className="text-gray-500 text-sm">
                    Your privacy and security are our top priority.
                    Your monitoring credentials will be used exclusively <br/>
                    to gather and archive data pertaining to systems covered by verified Solar Insure warranties.
                </p>
            </div>

            <div className="flex items-center mb-4 text-sm font-normal">
                {company.monitoringStatus.getBadge()}
                <div className="ml-2">
                    {company.monitoringStatus.title}
                    {company.monitoringStatus.subTitle &&
                        <p className="italic text-xs text-gray-500">{company.monitoringStatus.subTitle}</p>}
                </div>
            </div>
        </div>

        <div className="mt-8">
            <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2">
                <h3 className="text-lg">
                    Supported Manufacturer Integrations:
                </h3>
                <IntegrationTutorialPopup/>
            </div>

            <Tabs
                className="mt-4"
                design="buttons-secondary"
                tabs={tabs}
                value={tab}
                onChange={setTab}
            />
        </div>

        <Card className="mt-8">
            <h3 className="card-title !text-base mb-6">
                {getActiveTab().title}
            </h3>

            {getActiveTab().form}

        </Card>
        <EditCustomManufacturerForm
            className="mt-4"
            company={company}
            onChange={props.onChange}
        />

        <PostSubmitPopup isOpen={isPostSubmitPopupOpen} onClose={() => setIsPostSubmitPopupOpen(false)}/>
    </div>
}

export default EditIntegrationsForm
