import { useState } from 'react'

import { Button, TextArea } from '@/components'
import { Article } from '@/models'

const VoteForm = ({ article }: { article: Article }) => {
    const [errors, setErrors] = useState<any>({})
    const [processing, setProcessing] = useState(false)
    const [showThankYou, setShowThankYou] = useState(false)
    const [form, setForm] = useState({
        feedback: '',
        voted_helpful: null
    })

    const handleChange = ({ target: { name, value } }: any) => {
        setForm({ ...form, [name]: value })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        try {
            await article.feedback(form)
            setShowThankYou(true)
        } catch (err: any) {
            if (err.errors) {
                setErrors(err.errors)
            }
        } finally {
            setProcessing(false)
        }
    }
    return showThankYou ? <div className="text-lg">Thank you for your feedback!</div> : <form onSubmit={handleSubmit}>
        <div className="flex flex-col md:flex-row items-center">
            <span className="text-lg font-light">Was this tutorial helpful?</span>
            <div className="flex gap-4 md:ml-2">
                <Button
                    design="btn-secondary"
                    onClick={handleChange}
                    processing={processing}
                    value="1"
                    name="voted_helpful"
                    className="font-light"
                >
                    Yes, Thank You
                </Button>
                <Button
                    design="btn-secondary-gray"
                    onClick={handleChange}
                    processing={processing}
                    value="0"
                    name="voted_helpful"
                    type="button"
                    className="font-light"
                >
                    No, Not Really
                </Button>
            </div>
        </div>
        {form.voted_helpful === '0' && <>
            <TextArea
                rows={6}
                id="feedback"
                name="feedback"
                placeholder="Your feedback"
                onChange={handleChange}
                value={form.feedback}
                errors={errors.feedback}
                className="mt-4"
            />
            <Button type="submit" className="w-full mt-4" disabled={processing}>Save</Button>
        </>}
    </form>
}

export default VoteForm
